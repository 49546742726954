import React from "react";
import { useEffect } from "react";
import { useState } from "react"; import Swal from 'sweetalert2';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReatilerService from "../../services/Reatiler";
import WorkOrderService from "../../services/WorkOrderService";
import ProductService from "../../services/ProductService";
import { Accordion, Button, Col, Row, Table, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { changecostworkorderedit, changestaffcancleqty, changeworkordereditdiscount, changeworkordereditdiscount1, changeworkorderedittext, changeworkordereditunitOfMeasurement, geteditdatatoloadworkorder, perworkorderedit, qtyworkorderedit, removefromcartfromworkorderedit, workordereditaddtocart } from "../../../redux/Action/workorderEdit";
import { MDBCard } from "mdb-react-ui-kit";
import { BsFillEyeFill } from "react-icons/bs";
import { BASE_URL } from "../../services/Api";
import axios from "axios";
import authHeader from "../../services/auth-header";
import AuthService from "../../services/auth.service";

export default function Edit() {
  const[imagedisplay,setdiplay]=useState([])

  const [Reatilerg, setret] = useState([]);
  const [retailerpin, setRetailerPin] = useState([]);
  const [pinCode, setPinCode] = useState('');
  const [workOrderDate, setworkOrderDate] = useState('');
  const [retailerId, setretailerId] = useState('');
  const [retailername, setretailername] = useState('');
  const [orderStatus, setorderStatus] = useState('');
  const [remarks, setremarks] = useState('');
  const [product, setproduct] = useState([]);
  const [boxProductDiscount, setboxProductDiscount] = useState('');
  const [schemeboxProductDiscount, setschemeboxProductDiscount] = useState('');
  const [kgProductDiscount, setkgProductDiscount] = useState('');
  const [schemekgProductDiscount, setschemekgProductDiscount] = useState('');
  const [corporaetProductDiscount, setcorporaetProductDiscount] = useState('');
  const [schemecorporateProductDiscount, setschemecorporateProductDiscount] = useState('');
  const [cookerProductDiscount, setcookerProductDiscount] = useState('');
  const [schemecookerProductDiscount, setschemecookerProductDiscount] = useState('');
  const [noshProductDiscount, setnoshProductDiscount] = useState('');
  const [schemenoshProductDisocunt, setschemenoshProductDisocunt] = useState('');
  const [distributorId, setDistributorId] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState([]);
  const [retailerAddress, setretailerAddress] = useState('');
  const [ret_Address, setret_Address] = useState([]);
  ////////////////////////
  const [totalPcsQuantity, setTotalPcsQuantity] = useState(0);
  const [totalnet, settotalnet] = useState();
  const [grossfull, setgrossfull] = useState();
  const [gstvalue, setgstvalue] = useState();
  const [amount, setamount] = useState();
  const [kg, setkg] = useState();
  //////////////////////////////////////
  const [sumbox, setsumbox] = useState(0);
  const [sumofcorp, setcorp] = useState(0);
  const [sumofcorpcs, setcorpc] = useState(0);
  const [boxamt, setboxsmt] = useState(0);
  const [amt, setamt] = useState(0);
  const [kg1, setkg1] = useState(0);
  const [kg2, setkg2] = useState(0);
  const [kg3, setkg3] = useState(0);

  const [c1, setc1] = useState(0);
  const [c2, setc2] = useState(0);
  const [c3, setc3] = useState(0);

  const [n1, setn1] = useState(0);
  const [n2, setn2] = useState(0);
  const [n3, setn3] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = 'font-size 2s';
    element.style.fontSize = '30px';
    element.style.fontWeight = '600';
    // font-weight: 600;
    setTimeout(() => {
       
      document.getElementById("typer1").classList.add('promote');
      element.style.fontSize = '24px';
    }, 1000);
  };

  const user = AuthService.getCurrentUser();

const [iddeliveryAddress, setiddeliveryAddress] = useState([])


  /////////////////////
  const { id } = useParams(); const { pointindex } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workorderItem = useSelector((state) => state.workordereditdata);
  let grossAmount = workorderItem.length && workorderItem.map(item => Math.round(Number(item.total))).reduce((prev, next) => Math.round(prev - (-next)));
  // console.log("mello", workorderItem)
  useEffect(() => {
    /* workorder get data */
    WorkOrderService.getWorkOrder(id).then(res => {
      // console.log("mello",res.data)
      setretailerId(res.data.ret_id);
      setPinCode(res.data.retailerPinCode);
      setorderStatus(res.data.orderStatus);
      setworkOrderDate(res.data.workOrderDate);
      setremarks(res.data.remarks);
      setDistributorId(res.data.dist_id);
      setret_Address(res.data.deliveryAddress);
      setretailerAddress(res.data.retailerAddress);
      let aa = res.data.ret_id;
// console.log(res.data.deliveryAddress)
      ReatilerService.getRetailerGet(aa).then(res1 => {
        if ((res1.data).deliveryAddress.length == 0) {
          setDeliveryAddress([]);
        } else {
          setDeliveryAddress(res1.data.deliveryAddress);
         
        }
        setretailername(res1.data.tradeName);
        setboxProductDiscount(res1.data.boxProductDiscount);
        setschemeboxProductDiscount(res1.data.schemeboxProductDiscount);
        setkgProductDiscount(res1.data.kgProductDiscount);
        setschemekgProductDiscount(res1.data.schemekgProductDiscount);
        setcorporaetProductDiscount(res1.data.corporaetProductDiscount);
        setschemecorporateProductDiscount(res1.data.schemecorporateProductDiscount);
        setcookerProductDiscount(res1.data.cookerProductDiscount);
        setschemecookerProductDiscount(res1.data.schemecookerProductDiscount);
        setnoshProductDiscount(res1.data.noshProductDiscount);
        setschemenoshProductDisocunt(res1.data.schemenoshProductDisocunt);

      })
    })


    /* Retailer Data */
    // ReatilerService.getReatilerTable().then((res) => {
    //   let aa = res.data;
    //   setret(aa.map(user => ({ value: user.id, label: user.tradeName })));
    //   setRetailerPin(res.data);
    // });

    /* Product Data */

    axios.get( BASE_URL + 'product/page1/0/100/asc/id/%20',{
      headers:authHeader()
  }).then(res=>{

    setproduct((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
  })


    dispatch(geteditdatatoloadworkorder(id));
  }, []);
  
    // Retailer Address OnChange
    let getRetailerAddressChange = (e) => {
      setretailerAddress(e.value);
      setret_Address( e.value );
    }

  const customFilter = (e) => {
    let name = e;
    axios.get( BASE_URL + `product/page1/0/100/asc/id/${name.length>0 ?name:encodeURIComponent(' ')}`,{
        headers:authHeader()
    }).then(res=>{
      setproduct(res.data.Index ? (res.data.Index).map(use=>({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })) : (res.data.data).map(use=>({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })))
    })
  }

  /* Product View */
  const [show, setShow] = useState(false);
  const [productdata, setProductdata] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log(workorderItem)

  const productdetails = (e) => {
    ProductService.getProductEdit(e).then(res => {
      setProductdata(res.data);
      setdiplay(res.data.productImages);

    })
  }
  function pinCodedata(e) {
    const name = e.value;
    setretailerId(name)
    retailerpin.map(user => {
      if (user.id == name) {
        setPinCode(user.pinCode);
        setboxProductDiscount(user.boxProductDiscount);
        setkgProductDiscount(user.kgProductDiscount);
        setcorporaetProductDiscount(user.corporaetProductDiscount);
        setcookerProductDiscount(user.cookerProductDiscount);
        setnoshProductDiscount(user.noshProductDiscount);
        setschemeboxProductDiscount(user.schemeboxProductDiscount);
        setschemekgProductDiscount(user.schemekgProductDiscount);
        setschemecorporateProductDiscount(user.schemecorporateProductDiscount);
        setschemecookerProductDiscount(user.schemecookerProductDiscount);
        setschemenoshProductDisocunt(user.schemenoshProductDisocunt);
      }
    })
  }
  function RetailerName1(e) {
    setretailername(e);
  }

  const datevalue_1 = new Date(workOrderDate);
  const defaultValue_1 = datevalue_1.toLocaleDateString('en-CA');

  function deleteItem(e) {
    const filteredItems = workorderItem.filter(item => item.id === e);

    if (filteredItems.length == "0") {
      dispatch(workordereditaddtocart(e, ({
        "schemeboxProductDiscount": schemeboxProductDiscount,
        "schemekgProductDiscount": schemekgProductDiscount,
        "schemecorporateProductDiscount": schemecorporateProductDiscount,
        "schemecookerProductDiscount": schemecookerProductDiscount,
        "schemenoshProductDisocunt": schemenoshProductDisocunt,
        "kgProductDiscount": kgProductDiscount,
        "boxProductDiscount": boxProductDiscount,
        "corporaetProductDiscount": corporaetProductDiscount,
        "cookerProductDiscount": cookerProductDiscount,
        "noshProductDiscount": noshProductDiscount
      })));
    }
  }

  const SubmitForm = () => {
    setIsLoading(true); 
    let retailerPinCode = pinCode;
    let workOderItems = workorderItem;
    let ret_id = retailerId;
    let dist_id = distributorId;

let deliveryAddress=ret_Address;
    let kgProductTotalQtyKg = kg1;
    let kgProductTotalQtyPcs = kg2;
    let kgProductTotalprice = kg3;
    let boxProductTotalQtyPcs = sumbox;
    let boxProductTotalprice = boxamt;
    let corporateProductTotalQtyPcs = sumofcorp;
    let corporateProductTotalprice = amt;
    let cookerProductTotalQtyPcs = c1;
    let cookerProductTotalprice = c3;
    let noshProductTotalQtyPcs = n1;
    let noshProductTotalprice = n2;
    let totalQtyKg = kg;
    let totalQtyPcs = totalPcsQuantity;
    let grossTotal = grossfull;
    let netValue = totalnet;
    let taxAmount = gstvalue;
    const workorder = {
      workOrderDate,
      ret_id,
      dist_id,
      retailerPinCode,
      orderStatus,
      remarks,
      workOderItems,
      kgProductTotalQtyKg,
      kgProductTotalQtyPcs,
      kgProductTotalprice,
      boxProductTotalQtyPcs,
      boxProductTotalprice,
      corporateProductTotalQtyPcs,
      corporateProductTotalprice,
      cookerProductTotalQtyPcs,
      cookerProductTotalprice,
      noshProductTotalQtyPcs,
      noshProductTotalprice,
      totalQtyKg,
      totalQtyPcs,
      grossTotal,
      netValue,
      taxAmount,
      retailerAddress,
      deliveryAddress
    }

    WorkOrderService.getWorkOrderEdit(id, workorder).then(res => {
      navigate('/Newoo/1')
      let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      Toast.fire({
        icon: 'success',
        title: 'Successfully Updated'
      })
    }).catch(error => {
      console.error('Error adding  :', error);
      if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `
          <div>
            <p id="issueText">Why do I have this issue?</p>
            <ul>
        
            <li id="typer1">You might have an internet issue</li>
            </ul>
          </div>
        `,
        didOpen: () => {
          applyFontSizeTransition('issueText');
        }
      });
    }
    }).finally(() => {
      setIsLoading(false); 
    });

  }

  useEffect(() => {
    calculateSum()
  }, [workorderItem]);

  const calculateSum = () => {
    let workOderItems = workorderItem.map((order) => {
      const measurement = parseFloat(order.measurement);
      return isNaN(measurement) || !isFinite(measurement) ? 0 : measurement;
    }).reduce((prev, curr) => prev + curr, 0);

    console.log(workorderItem);

    workOderItems = parseFloat(workOderItems.toFixed(2));
    setTotalPcsQuantity(workOderItems);



    ///////////////////////////////////////////////////
    let totalnet = workorderItem.map((order) => parseFloat(order.total))
      .reduce((prev, curr) => prev + curr, 0);
    totalnet = parseFloat(totalnet.toFixed(2));
    settotalnet(totalnet)
    ////////////////////////////////////////////////////////////////
    let grossnet = workorderItem.map((order) => parseFloat(order.grossamount))
      .reduce((prev, curr) => prev + curr, 0);

    grossnet = parseFloat(grossnet.toFixed(2));
    setgrossfull(grossnet)
    ////////////////////////////////////////

    let gst = workorderItem.map((order) => parseFloat(order.gstvalue))
      .reduce((prev, curr) => prev + curr, 0);
    let gt = parseFloat(gst.toFixed(2))
    setgstvalue(gt)
    ////////////////////////////////////////////////////////////////
    let amt = workorderItem.map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0);
    const tt = parseFloat(amt.toFixed(2))
    setamount(tt)
    // workOderItems.map((item) => {
    //   setTotalPcsQuantity(Number(totalPcsQuantity) + Number(item.measurement))
    //   console.log(totalPcsQuantity)
    // })
    //////////////////////////
    let kg1 = workorderItem
      .map((order) => {
        const parsedQty = parseFloat(order.qty);
        return isNaN(parsedQty) || !isFinite(parsedQty) ? 0 : parsedQty;
      })
      .reduce((prev, curr) => prev + curr, 0);

    const tt1 = parseFloat(kg1.toFixed(2));
    setkg(tt1);
    /////////////////////
    /////////////////////////////////////////////////
    let amt12 = workorderItem
      .map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0);


    const kgCorporateProducts = workorderItem.filter((order) => order.productType === "boxproduct")
    setsumbox(kgCorporateProducts.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0))



    setboxsmt(workorderItem.filter((order) => order.productType === "boxproduct").map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0))




    const sumkgCor = workorderItem.filter((order) => order.productType === "corporateproduct")
    setcorp(sumkgCor.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0))


    const sumpccor = workorderItem.filter((order) => order.productType === "corporateproduct")
    setcorpc(sumpccor.map((order) => parseFloat(order.qty))
      .reduce((prev, curr) => prev + curr, 0),)
    setamt(sumpccor.map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0),)


    const kgproductpc = workorderItem.filter((order) => order.productType === "kgproduct")
    const time = kgproductpc.map((order) => parseFloat(order.qty))
      .reduce((prev, curr) => prev + curr, 0)
    const time2 = kgproductpc.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0)
    const totalkgg = kgproductpc.map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0)
    setkg1(parseFloat(time).toFixed(2))
    setkg2(time2)
    setkg3(totalkgg)

    const cooker = workorderItem.filter((order) => order.productType === "cookerproduct")
    const pcs = cooker.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0)
    setc1(pcs)
    const c3total = cooker.map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0)
    setc3(c3total)


    const noshing = workorderItem.filter((order) => order.productType === "noshproduct")
    const nospcs = noshing.map((order) => {
      const measurement = parseFloat(order.measurement);
      return isNaN(measurement) || !isFinite(measurement) ? 0 : measurement;
    }).reduce((prev, curr) => prev + curr, 0);
    setn1(nospcs)
    const nospcs1 = noshing.map((order) => {
      const measurement1 = parseFloat(order.qty);
      return isNaN(measurement1) || !isFinite(measurement1) ? 0 : measurement1;
    }).reduce((prev, curr) => prev + curr, 0);
    // setn3(nospcs1)
    setn3(parseFloat(nospcs1).toFixed(2))

    const nostotal = noshing.map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0)
    setn2(nostotal)
    console.log(nostotal)



  }

  const handledelete = (id) => {
    // console.log(id);
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        WorkOrderService.getDeleteWorkOrder(id).then(res => {

          // window.location.reload();
          console.log(res.data);
          if (res.data == 200 || res.data == 201) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            navigate(`/Newoo/${pointindex}`)
          }
        })

      }
    })
  }



  return (
    <MDBCard data-aos="fade">
       <div className='d-flex bd-highlight'>
        <div className="card-header card-title w-100">Edit Secondary Order </div>
        {/* <Link className='p-2 w-200 card-header card-title bd-highlight' to={'/workordertable'}><Button>Back</Button></Link> */}
        <Link className='prox btn' to={`/Newoo/${pointindex}`}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"/><polyline points="12 19 5 12 12 5"/></svg></Link>
        {user.roles[0] === 'ROLE_ADMIN' &&<a className='delete  btn' onClick={()=>handledelete(id)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg></a>}
     
      </div>
      <div className='card-body'>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Work Order Id:<span>100000001</span> </Accordion.Header>
            <Accordion.Body>
              <Row md={3}>
                <Col>
                  <label class="form-label">WorkOrderDate: </label>
                  <input type="date" name="workOrderDate" value={defaultValue_1} class="form-control" onChange={(e) => setworkOrderDate(e.target.value)} />
                </Col>
                <Col>
                  <label class="form-label">Retailer Name: </label>
                  <input type="text" className='form-control' value={retailername} readOnly />
                </Col>
                <Col>
                  <label class="form-label">Retailer Address: </label>
          {/* <Select value={ret_Address} options={deliveryAddress} onChange={(e) => { getRetailerAddressChange(e) }} /> */}
          <select className="form-select" value={ret_Address}  onChange={(e) => {setret_Address(e.target.value) }}>
            <option>fewf</option>
{
  deliveryAddress.map((item)=>{
    return<>
    <option id={item.id} value={item.id}>{item.delivery_address}</option>
    </>
  })
}

          </select>
                </Col>
                <Col>
                  <label class="form-label">Retailer pinCode: </label>
                  <input type="number" value={pinCode} name="retailerPinCode" class="form-control" />
                </Col>
              </Row>
              <Row md={3}>
                <Col>
                  <label className='form-label'>Order-Status</label>
                  <select class="form-select" value={orderStatus} name="orderStatus" onChange={(e) => setorderStatus(e.target.value)}>
                    <option >Choose the status</option>
                    <option value="true">Active</option>
                    <option value="false">InActive</option>
                  </select>
                </Col>
                {/* <Col>
                    <label className='form-label'>Sales id</label>
                    <input type="number" value={1} className='form-control' />
                  </Col> */}

                {/* <Col>
                    <label className='form-label'>Created by</label>
                    <input type="text" className='form-control' value={"Admin"} />
                  </Col> */}
              </Row>
              <Row md={10}>
                <Col>
                  <label className='form-label'>Remarks</label>
                  <textarea className='form-control' name="remarks" value={remarks} onChange={(e) => setremarks(e.target.value)} />
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>

        </Accordion><br></br>
        <label class="form-label"><b>Add&nbsp;Product</b> </label>
        <Select options={product} onChange={(options) => deleteItem(options.value)} onInputChange={(e) => customFilter(e) } /><br></br>

        <Table striped responsive hover size="sm">
          <thead className='p-3 mb-2 bg-success text-white'>
            <tr>
              <th style={{ width: '20%' }}>PRODUCT&nbsp;NAME</th>
              <th style={{ width: '9%' }}>UOM</th>
              <th style={{ width: '8%' }}>QTY(PCS)</th>
              <th style={{ width: '8%' }}>QTY(KG)</th>
              {/* <th style={{ width: '8%' }}>Staff&nbsp;Cancel&nbsp;QTY</th>
              <th style={{ width: '8%' }}>Retailer&nbsp;Cancel&nbsp;QTY</th> */}
              <th style={{ width: '8%' }}>RATE (₹)</th>
              <th style={{ width: '8%' }}>GROSS AMOUNT(₹)</th>
              <th style={{ width: '8%' }}>TRADE DISCOUNT %</th>
              <th style={{ width: '8%' }}>SCHEME DISCOUNT %</th>
              <th style={{ width: '8%' }}>NET AMOUNT (₹)</th>
              {/* <th>Per&nbsp;Unit&nbsp;Rate</th> */}
              <th style={{ width: '8%' }}>GST VALUE (₹)</th>
              <th style={{ width: '8%' }}>TOTAL (₹)</th>
              <th>View</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody style={{ overflowY: "scroll" }}>
            {workorderItem.map((item) =>
              <tr key={item.id} >
                <td> <Form.Group as={Col} md="12" >
                  <Form.Control type="text" value={item.productName} size="sm" />
                </Form.Group>
                  <Form.Control as="textarea" rows={2} onChange={(e) => dispatch(changeworkorderedittext(e.target.value, item))} value={item.text} className='form-control' />
                </td>
                <td><Form.Group as={Col} md="12">
                  <Form.Select disabled={item.unitofmeasurement == "box" ? true : item.productType !== "kgproduct" ? true : false} value={item.unitofmeasurement} onChange={(e) => { dispatch(changeworkordereditunitOfMeasurement(e.target.value, item, item.unitofmeasurement)); }} size="sm" >
                    <option>{item.unitofmeasurement == "box" ? "PCS" : item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                    <option value="pcs">PCS</option>
                    <option value="kg">KG</option>

                  </Form.Select>
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" name="measurement" onChange={(e) => { dispatch(perworkorderedit(e.target.value, item, item.unitofmeasurement)); calculateSum(); }} disabled={item.unitofmeasurement == "kg" ? true : false || item.unitofmeasurement == "" ? true : false} value={item.measurement} size="sm" />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" min="0" name="qty" onChange={(e) => { dispatch(qtyworkorderedit(e.target.value, item, item.unitofmeasurement)); calculateSum(); }} disabled={item.unitofmeasurement == "pcs" || item.unitofmeasurement == "box" ? true : item.productType == "boxproduct" ? true : false || item.unitofmeasurement == "" ? true : false} value={item.unitofmeasurement == "box" ? '' : item.productType == "boxproduct" ? "" : item.qty} size="sm" />
                </Form.Group></td>
                {/* <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" min="0" name="staffCancleQty" onChange={(e) => { dispatch(changestaffcancleqty(e.target.value, item)) }}  value={item.staffCancleQty} size="sm" />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" min="0" name="retailerCancleQty" onChange={(e) => { dispatch(qtyworkorderedit(e.target.value, item, item.unitofmeasurement)); calculateSum(); }}  value={item.retailerCancleQty} size="sm" />
                </Form.Group></td> */}
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" min="0" disabled={item.unitofmeasurement == "" ? true : false} onChange={(e) => { dispatch(changecostworkorderedit(e.target.value, item, item.unitofmeasurement)); calculateSum(); }} value={item.dlp} size="sm" />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" min="0" disabled={item.unitofmeasurement == "" ? true : false} value={Number(item.grossamount)} size="sm" readOnly requied />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" min="0" disabled={item.unitofmeasurement == "" ? true : false} value={Number(item.discount || '')} size="sm" readOnly />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" min="0" disabled={item.unitofmeasurement == "" ? true : false} onChange={(e) => { dispatch(changeworkordereditdiscount1(e.target.value, item, item.unitofmeasurement)); calculateSum(); }} value={Number(item.discount1)} size="sm" requied />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" min="0" disabled={item.unitofmeasurement == "" ? true : false} value={Number(item.total)} size="sm" readOnly requied />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" min="0" disabled={item.unitofmeasurement == "" ? true : false} value={Number(item.gstvalue)} size="sm" readOnly requied />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" min="0" disabled={item.unitofmeasurement == "" ? true : false} value={Number(item.amount)} size="sm" readOnly requied />
                </Form.Group></td>
                <td>
                  <div onClick={(e) => { handleShow(e); productdetails(item.id) }} style={{ textAlign: 'center', border: '1px solid grey', marginTop: '15px', cursor: 'pointer', padding: '4px 8px' }} > <BsFillEyeFill style={{ color: 'var(--bs-link-color)' }} /></div>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    size='lg'
                  >
                     <Modal.Header closeButton style={{ background: 'white', color: 'white' }}>
                     <Modal.Title style={{color:"#164194"}}>Product Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {imagedisplay.map((user,index) => (
                <img src={"http://43.225.54.138:8080/scm/Images/" + user.name} className="images" style={{ width: '200px', height: '150px' }} />
              ))}
                      <Row className="mb-1">
                        <Form.Group as={Col} md="12" controlId="formBasicName">
                          <span style={{ fontSize: '18px', fontWeight: 600 }}>Product Name : - {productdata.productName}</span>

                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Short Name : - {productdata.shortName}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Ean Code No : - {productdata.eanCode}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Standard Qty Per Box : - {productdata.standardQtyPerBox}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Groups : - {productdata.productGroup}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Category : - {productdata.category}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>UOM Primary : - {productdata.uomPrimary}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>UOM Secondary : - {productdata.uomSecondary}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>DLP(₹) : - {productdata.dlp}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>MRP(₹) : - {productdata.mrp}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Capacity(ml) : - {productdata.capacity}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Diameter : - {productdata.diameter}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>HSN Code : - {productdata.hsnCode}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Brand Name : - {productdata.brand ? "Neelam" : ''}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Product Type : - {productdata.productType}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>IGST% : - {productdata.igst}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>CGST% : - {productdata.cgst}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>SGST% : - {productdata.sgst}</span>
                        </Form.Group>
                      </Row>
                    </Modal.Body>
                  </Modal>
                </td>
                <td>
                  <Form.Group as={Col} md="12">
                    <Form.Control type='button' className='btn btn-danger' value="X" onClick={() => dispatch(removefromcartfromworkorderedit(item.id))} size="sm" />
                  </Form.Group>
                </td>
                {/* <button className="form-control btn-sm" id='c17' onClick={() => dispatch(removefromcart(item.id))}>x</button> */}
              </tr>
            )}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              {/* <td></td> */}
              <td>
                {/* <span className="cname">Gross&nbsp;amount&nbsp;<b>₹</b></span> */}
              </td>
              <td>
                {/* <Form.Group as={Col} md="12">
                    <Form.Control type="text" value={grossAmount} size="sm" />
                  </Form.Group> */}
              </td>
              <td></td>
              <td></td>
            </tr>
            {workorderItem.length !== 0 &&
              (
                <tr>
                  <td className="bold">Total</td>
                  <td></td>
                  <th className="bold">{totalPcsQuantity}</th>
                  <th className="bold">{kg}</th>
                  {/* <td></td>
                <td></td> */}
                  <td></td>
                  <th className="bold"> {grossfull}</th>
                  <th className="bold"></th>
                  <td></td>
                  <th className="bold">{totalnet}</th>

                  <th className="bold">{gstvalue}</th>
                  <th className="bold">{amount}</th>
                  <th className="bold"></th>
                  <th className="bold"></th>
                  {/* <td>Total Amount</td> */}
                </tr>
              )}
          </tbody>
        </Table>
        <table className='table'>

          <tr>

            {/* {workorderItem.length !== 0 && (
<>
 <td className='qtyn nn'><b>{totalPcsQuantity}</b></td>
 <td className='kgg'><b>{kg}</b></td>
 <td className='gross'><b>{grossfull}</b></td>
 <td className='netn'><b>{gstvalue}</b></td>
 <td className='gstn'><b>{totalnet}</b></td>
 <td className='total'><b>{amount}</b></td>
</>
)} */}
          </tr>

        </table>
        <table>
          <tr>
            {workorderItem.length !== 0 && (
              <>
                <tr>
                  <th className='namee'>Summary</th>
                  <th className='namee'> Total Qty(KG)</th>
                  <th className='namee'>Total Qty(Pcs)</th>
                  <th className='namee'>Total price</th>
                </tr>
                <tr>
                  <th>Box Product</th>
                  <th></th>
                  <th>{sumbox}</th>
                  <th>{boxamt}</th>
                </tr>
                <tr>
                  <th>KG Product</th>
                  <th>{kg1}</th>
                  <th>{kg2}</th>
                  <th>{kg3}</th>
                </tr>
                <tr>
                  <th>Corporate Product</th>
                  <th></th>
                  <th>{sumofcorp}</th>
                  <th>{amt}</th>
                </tr>
                <tr>
                  <th>Cooker Product</th>
                  <th></th>
                  <th>{c1}</th>
                  <th>{c3}</th>
                </tr>
                <tr>
                  <th>Nosh Product</th>
                  <th>{n3}</th>
                  <th>{n1}</th>
                  <th>{n2}</th>
                </tr>
                <tr>
                  <th>Total</th>
                  <th>{kg}</th>
                  <th>{totalPcsQuantity}</th>
                  <th>{amount}</th>
                </tr>
              </>
            )}
          </tr>



        </table>
        <br></br>
        <Button variant="primary" style={{width:'auto'}} onClick={SubmitForm} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
      </div>

    </MDBCard>
  )
}