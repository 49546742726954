import axios from "axios";
import React, { useEffect, useState } from "react";
// import Select from 'react-select/dist/declarations/src/Select'
import Select from "react-select";
import authHeader from "../services/auth-header";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import { BASE_URL } from "../services/Api";

const Myreportcomponent = ({ title, data, axiostable, link }) => {
  const [disword, setdisword] = useState(encodeURIComponent(" "));
  const [dis, setdistributordata] = useState([]);
  const [id, setid] = useState([]);
  const [startDate, setdate1] = useState([]);
  const [endDate, setdate2] = useState([]);
  const [dataalginment, setdataalign] = useState([]);
  const [retdata, setretdata] = useState([]);
  const [nearea, setneware] = useState([]);
  const [dist_roles, setdist_roles] = useState("");

  const currentuser = AuthService.getCurrentUser();

  function getdistributorbyroles() {
    axios
      .get(BASE_URL + "distributor/getById/" + currentuser.id, {
        headers: authHeader(),
      })
      .then((res) => {
        setdist_roles(res.data.tradeName);
        setid(res.data.id);
      });
  }

  useEffect(() => {
    {
      currentuser.roles[0] == "ROLE_DISTRIBUTOR" && getdistributorbyroles();
    }

    axios
      .get(
        `http://43.225.54.138:8080/scm/api/${axiostable}/page/0/10/asc/id/${
          disword.length ? disword : encodeURIComponent(" ")
        }`,
        { headers: authHeader() }
      )
      .then((response) => {
        console.log(response);
        if (axiostable == "retailer") {
          if (response.data.data == undefined) {
            setdistributordata(
              response.data.Index.map((use) => ({
                value: use.id,
                label: use.retailername,
              }))
            );
          }
          if (response.data.Index == undefined) {
            setdistributordata(
              response.data.data.map((use) => ({
                value: use.id,
                label: use.retailername,
              }))
            );
          }
        } else {
          if (axiostable === "distributor") {
            if (response.data) {
              if (response.data.data === undefined && response.data.Index) {
                setdistributordata(
                  response.data.Index.map((use) => ({
                    value: use.id,
                    label: use.trade_Name,
                  }))
                );
              } else if (
                response.data.Index === undefined &&
                response.data.data
              ) {
                setdistributordata(
                  response.data.data.map((use) => ({
                    value: use.id,
                    label: use.trade_Name,
                  }))
                );
              } else {
                console.error("Unexpected structure in response.data.");
              }
            } else {
              console.error("response.data is undefined or null.");
            }
          }
        }
      });
  }, [disword]);

  const navigate = useNavigate();

  const deleteItem = (val) => {
    setid(val);
  };
  const [loading, setLoading] = useState(false);

  // const handleAxiosRequest = (url, setDataCallback) => {
  //     setLoading(true);
  //     axios.get(url, { headers: authHeader() })
  //         .then(response => {
  //             setDataCallback(response.data);
  //         })
  //         .catch(error => {
  //             console.error('Error fetching data:', error);
  //         })
  //         .finally(() => {
  //             setLoading(false);
  //         });
  // };

  // const handleSubmit = () => {
  //     if (link === 'DistributorId' || link === 'RetailerId') {
  //         const apiUrl = `http://43.225.54.138:8080/scm/api/so/pendingSoBy${link}/${id}/${startDate}/${endDate}`;
  //         handleAxiosRequest(apiUrl, link === 'DistributorId' ? setdataalign : setretdata);
  //     } else if (link === 'outdiststock/report') {
  //         const apiUrl = `http://43.225.54.138:8080/scm/api/${link}/${startDate}/${endDate}/${id}`;
  //         handleAxiosRequest(apiUrl, setneware);
  //     }
  // };
  const handlesubmit = () => {
    if (link === "DistributorId" || link === "RetailerId") {
      setLoading(true);

      axios
        .get(
          `http://43.225.54.138:8080/scm/api/so/pendingSoBy${link}/${id}/${startDate}/${endDate}`,
          { headers: authHeader() }
        )
        .then((response) => {
          if (link === "DistributorId") {
            //console.log(response.data,'distributor data.......................')

            setdataalign(response.data);
          }
          if (link === "RetailerId") {
            console.log(response.data, "retailer data........................");

            setretdata(response.data);
          }
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (link === "outdiststock/report") {
      setLoading(true);

      axios
        .get(
          `http://43.225.54.138:8080/scm/api/${link}/${startDate}/${endDate}/${id}`,
          { headers: authHeader() }
        )
        .then((response) => {
          console.log(response.data);
          if (link === "outdiststock/report") {
            console.log(response.data);
            setneware(response.data);
          }
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    console.log("first");
  };
  const handledate = (id, value) => {
    if (id === "startDate") {
      setdate1(value);
    } else {
      setdate2(value);
    }
  };
  const handledis = (id) => {
    console.log(id);
    // navigate(`/SalesOrderView/${id}/0`)
  };
  return (
    <>
      <div className="card">
        <div className="">
          <h1>{title}</h1>
        </div>
        <div className="card-body">
          <div className="row">
            {data.map((item, index) => (
              <div key={index} className="col-6">
                {item.type === "text" && (
                  <>
                    <label className="form-label">{item.label}</label>
                    <input
                      id={item.id}
                      type={item.type}
                      className="form-control"
                    />
                  </>
                )}

                {item.type === "Date" && (
                  <>
                    <label className="form-label">{item.label}</label>
                    <input
                      id={item.id}
                      onChange={(e) => handledate(e.target.id, e.target.value)}
                      type={item.type}
                      className="form-control"
                    />
                  </>
                )}

                {item.type === "select" && (
                  <>
                    <label className="form-label">{item.label}</label>
                    {currentuser.roles[0] == "ROLE_DISTRIBUTOR" ? (
                      <input
                        type="text"
                        className="form-control"
                        value={dist_roles}
                        readOnly
                      />
                    ) : (
                      <Select
                        options={dis}
                        onInputChange={(inputValue) => setdisword(inputValue)}
                        onChange={(options) => deleteItem(options.value)}
                      />
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
          <br></br>
          <div className="row">
            <div className="col">
              <button
                className="btn btn-primary"
                onClick={() => handlesubmit()}
                disabled={loading}
              >
                {loading ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>

          {nearea.length !== 0 ? (
            <table className="table thrtable ">
              <tr>
                <th>Sr.No</th>
                <th>Product Name</th>
                <th>Previous Closing</th>
                <th>Outward Quantity</th>
                <th>Inward Quantity</th>
                <th>Closing Stock</th>
                <th>Amount</th>
              </tr>
              {nearea.map((item, index) => {
                return (
                  <tr className="the" onClick={() => handledis(item.id)}>
                    <td className="datatabletd">{index + 1}</td>
                    <td className="datatabletd">{item.product_Name}</td>
                    <td className="datatabled">{item.previousclosing}</td>
                    <td className="datatabletd">{item.outwardqty}</td>
                    <td className="datatabletd">{item.inwardqty}</td>
                    <td className="datatabletd">{item.closingstock}</td>
                    <td className="datatabletd">{item.amount}</td>
                  </tr>
                );
              })}
            </table>
          ) : (
            ""
          )}
          {dataalginment.length !== 0 ? (
            <table className="table thrtable ">
              <tr>
                <th>id</th>
                <th>Product Name</th>
                <th>EANCODE</th>
                <th>SO(PCS)QTY</th>
                <th>SO(KG)QTY</th>
                <th>Pending Cancel (PCS)QTY</th>
                <th>Pending Cancel (KG)QTY</th>
                <th>Action</th>
              </tr>
              {dataalginment.map((item) => {
                return (
                  <tr onClick={() => handledis(item.id)}>
                    <td>{item.salesorderitem_id}</td>
                    <td>{item.product_name}</td>
                    <td>{item.ean_code}</td>
                    <td>{item.sopcsqty}</td>
                    <td>{item.sokgqty}</td>
                    <td>{item.dist_pendingcancelpcsqty}</td>
                    <td>{item.dist_pendingcancelkgqty}</td>
                    <td>
                        <Link
                          to={`/cancelQuntityDistributorSales/${item.salesorderitem_id}`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-eye"
                          >
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                          </svg>
                        </Link>
                      </td>
                  </tr>
                );
              })}
            </table>
          ) : (
            ""
          )}
          {
            // "buyerorder": "SO/DTR/1169",
            // "voucherno": "O/DTR/1169",
            // "dispatchedthrough": "TCI Express Limited (R-61)",
            // "destination": "Kolkata",
            // "termsofdelivery": "termsofdelivery",
            retdata.length !== 0 ? (
              <table className="table thrtable">
                <tr>
                  <th>id</th>
                  <th>Product Name</th>
                  <th>EANCODE</th>
                  <th>SO(PCS)QTY</th>
                  <th>SO(KG)QTY</th>
                  <th>Pending Cancel (PCS)QTY</th>
                  <th>Pending Cancel (KG)QTY</th>
                  <th>Action</th>
                </tr>
                {retdata.map((item) => {
                  return (
                    <tr onClick={() => handledis(item.salesorderitem_id)}>
                      <td>{item.salesorderitem_id}</td>
                      <td>{item.product_name}</td>
                      <td>{item.ean_code}</td>
                      <td>{item.sopcsqty}</td>
                      <td>{item.sokgqty}</td>
                      <td>{item.pendingcancelpcsqty}</td>

                      <td>{item.pendingcancelkgqty}</td>
                      <td>
                        <Link
                          to={`/cancelQuntitySalesOrder/${item.salesorderitem_id}`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-eye"
                          >
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                          </svg>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </table>
            ) : (
              ""
            )
          }
        </div>
      </div>
    </>
  );
};

export default Myreportcomponent;
