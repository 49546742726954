import React, { useEffect, useState } from "react";
import { MDBCard, MDBCardBody } from "mdbreact";
import axios from "axios";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import { Link, useNavigate } from "react-router-dom";

function Groupn1IndexTable() {
  const [getData, setGetData] = useState([]);
  const navigate = useNavigate();

  async function getGroupData() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn1`, {
        headers: authHeader(),
      });

      setGetData(response.data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  function viewHandler() {
    navigate("/view/group1/1/1");
  }

  useEffect(() => {
    getGroupData();
  }, []);
  return (
    <div>
      <MDBCard data-aos="fade">
        <div className="card-header flex">
          <h2 className="projection-header">Group 1</h2>
          {getData && getData.length === 0 && (
            <Link to="/create/group1">
              <button className="btn btn-primary thh">Add Group 1</button>
            </Link>
          )}
        </div>

        <MDBCardBody>
          <div className="entryandsearch">
            <div className="bottole"></div>
          </div>

          <table className="table thrtable table-animation">
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody className="the">
              {getData &&
                getData.map((data) => (
                  <tr onClick={viewHandler}>
                    <td>{data.id}</td>
                    <td>{data.title}</td>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-eye"
                      >
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                        <circle cx="12" cy="12" r="3" />
                      </svg>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </MDBCardBody>
      </MDBCard>
    </div>
  );
}

export default Groupn1IndexTable;
