import React, { useEffect, useState } from "react";
import { MDBCard } from "mdbreact";
import { Form, Col, Row, Button, Pagination } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

const RetailerByGroupReport = () => {
  const [groupn1Options, setGroupN1Options] = useState();
  const [groupn2Options, setGroupN2Options] = useState();
  const [groupn3Options, setGroupN3Options] = useState();
  const [groupn4Options, setGroupN4Options] = useState();
  const [groupn5Options, setGroupN5Options] = useState();
  const [group1Value, setGroup1Value] = useState();
  const [group2Value, setGroup2Value] = useState();
  const [group3Value, setGroup3Value] = useState();
  const [group4Value, setGroup4Value] = useState();
  const [group5Value, setGroup5Value] = useState();

  const [group1Search, setGroup1Search] = useState("");
  const [group2Search, setGroup2Search] = useState("");
  const [group3Search, setGroup3Search] = useState("");
  const [group4Search, setGroup4Search] = useState("");
  const [group5Search, setGroup5Search] = useState("");

  const [pageno, setPageNo] = useState(0);

  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState("asc");
  const [field, setField] = useState("id");
  const [pages, setPages] = useState();
  const [search, setSearch] = useState(encodeURIComponent(" "));
  const [retailerGroupId, setRetailerGroupId] = useState({
    group1: {
      id: "",
    },
    group2: {
      id: "",
    },
    group3: {
      id: "",
    },
    group4: {
      id: "",
    },
    group5: {
      id: "",
    },
  });

  const [retailerData, setRetailerData] = useState([]);
  const [distributorData, setDistributorData] = useState([]);

  const [salesOrderData, setSalesOrderData] = useState([]);

  const [disabled1, setDisabled1] = useState(false);
  const [disabled2, setDisabled2] = useState(false);
  const [disabled3, setDisabled3] = useState(false);
  const [disabled4, setDisabled4] = useState(false);
  const [disabled5, setDisabled5] = useState(false);

  async function groupn1() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn1`, {
        headers: authHeader(),
      });

      setGroupN1Options(response.data.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn2() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn2/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group2Search}`, {
        headers: authHeader(),
      });

      setGroupN2Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn3() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn3/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group3Search}`, {
        headers: authHeader(),
      });

      setGroupN3Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn4() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn4/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group4Search}`, {
        headers: authHeader(),
      });

      setGroupN4Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn5() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn5/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group5Search}`, {
        headers: authHeader(),
      });

      setGroupN5Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function getRetailerData() {
    let count = 0;
    let cnt = 0;
    let obj = retailerGroupId;

    for (const key of Object.keys(retailerGroupId)) {
      if (obj[key]?.id?.length === 0 || obj[key].id === "neelam") {
        obj[key].id = "neelam";
      } else if (obj[key]?.id?.length !== 0 && obj[key].id !== "neelam") {
        if (obj.group1?.id?.length !== 0 && obj?.group1?.id !== "neelam") {
          cnt++;
        } else {
          cnt = 0;
        }
      }
    }

    if (cnt === 0 || cnt === 1) {
      count = 0;
    } else if (cnt > 1) {
      count = cnt;
    }

    try {
      const response = await axios.get(
        `${BASE_URL}retailer/groupn/page/retaileranddistributorwithgroupn/0/20/asc/id/${retailerGroupId.group1.id}/${retailerGroupId.group2.id}/${retailerGroupId.group3.id}/${retailerGroupId.group4.id}/${retailerGroupId.group5.id}/${count}`,
        {
          headers: authHeader(),
        }
      );

      handleretaileranddistributor(response.data.Index);
    } catch (error) {
      console.log(error);
    }
  }

  const salesOrderDataBasedonRetailerIdandDistributorId = async (retailerData, distributorData) => {
    try {
      const response = await axios.get(
        `${BASE_URL}so/page/byretaileranddistributorlistid?pageno=${pageno}&pagesize=${pageSize}&sortby=${sort}&field=${field}&rid=${retailerData}&did=${distributorData}&search=${search}`,
        {
          headers: authHeader(),
        }
      );

      console.log(response);
      setSalesOrderData(response.data.Index);
      setPages(response.data.Pages);
    } catch (error) {
      console.log(error);
    }
  };

  function handleretaileranddistributor(data) {
    const retailerData = data.filter((data) => data.rolename === "ROLE_RETAILER");
    const distributorData = data.filter((data) => data.rolename === "ROLE_DISTRIBUTOR");

    // salesOrderDataBasedonRetailerIdandDistributorId(retailerData, distributorData);
    let retailerid = "";
    let distributorid = "";

    for (let i = 0; i < retailerData.length; i++) {
      retailerid += retailerData[i].id + ",";
    }

    for (let i = 0; i < distributorData.length; i++) {
      distributorid += distributorData[i].id + ",";
    }

    salesOrderDataBasedonRetailerIdandDistributorId(retailerid, distributorid);
  }

  function changeHandler(e, element) {
    console.log(e);
    setRetailerGroupId({
      ...retailerGroupId,
      [element]: {
        id: e?.value,
      },
    });

    if (element == "group1") {
      setGroup1Value(e);
    }

    if (element == "group2") {
      setGroup2Value(e);
    }

    if (element == "group3") {
      setGroup3Value(e);
    }

    if (element == "group4") {
      setGroup4Value(e);
    }

    if (element == "group5") {
      setGroup5Value(e);
    }

    handleDisabledConditions(element);
  }

  const setDisabledFunctions = [setDisabled1, setDisabled2, setDisabled3, setDisabled4, setDisabled5];

  function handleDisabledConditions(groupValue) {
    if (!groupValue) {
      return;
    }
    console.log(groupValue);

    // Reset all disabled states to false
    setDisabledFunctions.forEach((setDisabled) => setDisabled(false));

    // Determine the index of the selected group (1-indexed)
    const groupIndex = parseInt(groupValue.replace("group", ""));

    // Disable all previous groups and all groups after the next one
    for (let i = 0; i < setDisabledFunctions.length; i++) {
      if (i < groupIndex - 1) {
        setDisabledFunctions[i](true); // Disable previous groups
      } else if (i > groupIndex) {
        setDisabledFunctions[i](true); // Disable subsequent groups
      }
    }

    // Handle specific group logic
    if (groupValue === "group2") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
    }

    if (groupValue === "group3") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2Value || group2Value?.value?.length === 0 || group2Value?.value === "neelam") {
        setDisabled2(true);
      }
    }

    if (groupValue === "group4") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2Value || group2Value?.value?.length === 0 || group2Value?.value === "neelam") {
        setDisabled2(true);
      }
      if (!group3Value || group3Value?.value?.length === 0 || group3Value?.value === "neelam") {
        setDisabled3(true);
      }
    }

    if (groupValue === "group5") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2Value || group2Value?.value?.length === 0 || group2Value?.value === "neelam") {
        setDisabled2(true);
      }
      if (!group3Value || group3Value?.value?.length === 0 || group3Value?.value === "neelam") {
        setDisabled3(true);
      }
      if (!group4Value || group4Value?.value?.length === 0 || group4Value?.value === "neelam") {
        setDisabled4(true);
      }
    }
  }

  function handleRecentPage(i) {
    setPageNo(i - 1);
  }

  function pagination() {
    const totalPages = pages;
    const maxPagesToShow = 5; // Maximum number of page buttons to show
    const siblingsCount = 2; // Number of sibling pages to show
    const firstPage = 1;
    const lastPage = totalPages;

    let pageNumbers = [];

    const startPage = Math.max(firstPage, pageno + 1 - siblingsCount);
    const endPage = Math.min(lastPage, pageno + 1 + siblingsCount);

    // Show first page button and dots if needed
    if (startPage > firstPage + 1) {
      pageNumbers.push(
        <li key={firstPage} className={firstPage === pageno + 1 ? "newway" : "noway"} onClick={() => handleRecentPage(firstPage)}>
          {firstPage}
        </li>
      );
      pageNumbers.push(
        <li key="start-dots" className="dots" onClick={() => handleRecentPage(Math.max(0, startPage - siblingsCount))}>
          ...
        </li>
      );
    } else if (startPage > firstPage) {
      pageNumbers.push(
        <li key={firstPage} className={firstPage === pageno + 1 ? "newway" : "noway"} onClick={() => handleRecentPage(firstPage)}>
          {firstPage}
        </li>
      );
    }

    // Collect page numbers for current range
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={i === pageno + 1 ? "newway" : "noway"} onClick={() => handleRecentPage(i)}>
          {i}
        </li>
      );
    }

    // Show last page button and dots if needed
    if (endPage < lastPage - 1) {
      pageNumbers.push(
        <li key="end-dots" className="dots" onClick={() => handleRecentPage(Math.min(lastPage, endPage + siblingsCount))}>
          ...
        </li>
      );
      pageNumbers.push(
        <li key={lastPage} className={lastPage === pageno + 1 ? "newway" : "noway"} onClick={() => handleRecentPage(lastPage)}>
          {lastPage}
        </li>
      );
    } else if (endPage < lastPage) {
      pageNumbers.push(
        <li key={lastPage} className={lastPage === pageno + 1 ? "newway" : "noway"} onClick={() => handleRecentPage(lastPage)}>
          {lastPage}
        </li>
      );
    }

    return pageNumbers;
  }
  function previousHandler() {
    setPageNo(pageno - 1 < 0 ? 0 : pageno - 1);
  }

  function nextHandler() {
    setPageNo(pageno + 1 > pages - 1 ? pages - 1 : pageno + 1);
  }

  // function sortHandler(value){
  //     // if (sort === "asc") {
  //     //     setSort("desc");
  //     // }else {
  //     //     setSort("asc");
  //     // }
  //     // setField(value);

  //     reportData.sort((a,b) => a[value] - b[value])

  //     setReportData(reportData);
  // }

  function sortHandler(value) {
    // console.log("Sorting by:", value);

    // // Toggle sort order
    // const newSortOrder = sort === "asc" ? "desc" : "asc";
    // setSort(newSortOrder);

    // // Create a copy of the data to avoid mutating the state directly
    // const sortedData = [...retailerData].sort((a, b) => {
    //   console.log(typeof a[value]);

    //   if (typeof a[value] === "string") {
    //     if (newSortOrder === "asc") {
    //       return a[value].localeCompare(b[value]); // Ascending order
    //     } else {
    //       return b[value].localeCompare(a[value]); // Descending order
    //     }
    //   } else {
    //     if (newSortOrder === "asc") {
    //       return a[value] - b[value]; // Ascending order
    //     } else {
    //       return b[value] - a[value]; // Descending order
    //     }
    //   }
    // });

    // console.log("Sorted Data:", sortedData);

    // Update the state with the sorted data
    //setSalesOrderData(sortedData);

    if (value.type === "svg") {
      return;
    }
    setSort(sort === "asc" ? "desc" : "asc");
    setField(value);
  }

  function getSelectOptionData() {
    groupn1();
    groupn2();
    groupn3();
    groupn4();
    groupn5();
  }

  useEffect(() => {
    getSelectOptionData();
  }, []);

  useEffect(() => {
    groupn1();
  }, [group1Search]);

  useEffect(() => {
    groupn2();
  }, [group2Search]);
  useEffect(() => {
    groupn3();
  }, [group3Search]);
  useEffect(() => {
    groupn4();
  }, [group4Search]);
  useEffect(() => {
    groupn5();
  }, [group5Search]);

  useEffect(() => {
    getRetailerData();
  }, [sort, field, pageno, pageSize]);

  console.log(salesOrderData);
  return (
    <div>
      <MDBCard className="company">
        <h2 className="projection-header">Sales Order Group Report</h2>

        <Form className="product1">
          <Row className="mb-1">
            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="label">Group-1</Form.Label>
              <Select value={group1Value} isDisabled={disabled1} options={groupn1Options} onChange={(e) => changeHandler(e, "group1")} onInputChange={(e) => setGroup1Search(e)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="label">Group-2</Form.Label>
              <Select value={group2Value} isDisabled={disabled2} options={groupn2Options} onChange={(e) => changeHandler(e, "group2")} onInputChange={(e) => setGroup2Search(e)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="label">Group-3</Form.Label>
              <Select value={group3Value} isDisabled={disabled3} options={groupn3Options} onChange={(e) => changeHandler(e, "group3")} onInputChange={(e) => setGroup3Search(e)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="label">Group-4</Form.Label>
              <Select value={group4Value} isDisabled={disabled4} options={groupn4Options} onChange={(e) => changeHandler(e, "group4")} onInputChange={(e) => setGroup4Search(e)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="label">Group-5</Form.Label>
              <Select value={group5Value} isDisabled={disabled5} options={groupn5Options} onChange={(e) => changeHandler(e, "group5")} onInputChange={(e) => setGroup5Search(e)} />
            </Form.Group>
          </Row>

          <br></br>
          <div>
            <Button variant="primary" style={{ width: "auto", marginBottom: "30px" }} onClick={(e) => getRetailerData(e)}>
              SUBMIT
            </Button>
          </div>
          <br></br>

          <hr></hr>

          <table className="table thrtable">
            <thead>
              <th onClick={() => sortHandler("id")}>
                <p>ID {sort === "asc" ? <TiArrowSortedDown /> : <TiArrowSortedUp />}</p>
              </th>

              <th onClick={() => sortHandler("productname")}>
                <p>Product Name {sort === "asc" ? <TiArrowSortedDown /> : <TiArrowSortedUp />}</p>
              </th>

              <th onClick={() => sortHandler("createddate")}>
                <p>Sales Order Created Date {sort === "asc" ? <TiArrowSortedDown /> : <TiArrowSortedUp />}</p>
              </th>

              <th onClick={() => sortHandler("soquantityplaced")}>
                <p>Quantity {sort === "asc" ? <TiArrowSortedDown /> : <TiArrowSortedUp />}</p>
              </th>

              <th onClick={() => sortHandler("retailername")}>
                <p>Retailor Name {sort === "asc" ? <TiArrowSortedDown /> : <TiArrowSortedUp />}</p>
              </th>

              <th onClick={() => sortHandler("distributorname")}>
                <p>Distributor Name {sort === "asc" ? <TiArrowSortedDown /> : <TiArrowSortedUp />}</p>
              </th>
            </thead>

            <tbody className="the">
              {salesOrderData?.length > 0 &&
                salesOrderData.map((data, index) => (
                  <tr key={index} style={{ height: "45px" }}>
                    <td>{data.id}</td>
                    <td>{data.productname}</td>
                    <td>{data.createddate}</td>
                    <td>{data.soquantityplaced}</td>
                    <td>{data.retailername}</td>
                    <td>{data.distributorname}</td>
                  </tr>
                ))}
            </tbody>
          </table>

          {salesOrderData.length > 0 && (
            <div className="projection-table-pagination">
              <p>
                Showing 1 to {salesOrderData?.length} Values of {pageno + 1} Page
              </p>
              <div className="prev-next-div">
                <p onClick={previousHandler} style={{ cursor: "pointer" }}>
                  Previous
                </p>
                <div style={{ display: "flex", gap: "10px" }}>{pagination()}</div>
                <p onClick={nextHandler} style={{ cursor: "pointer" }}>
                  Next
                </p>
              </div>
            </div>
          )}
        </Form>
      </MDBCard>
    </div>
  );
};

export default RetailerByGroupReport;
