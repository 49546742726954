import { SUPPLIER_PUT_DATA_TO_LOAD } from "../constant";
import { PUT_NEW_SUPPLIER_ADD_TO_CART } from "../constant";
import { SUPPLIER_EDIT_CHANGE_MEASUREMENT } from "../constant";
import { SUPPLIER_EDIT_CHANGE_CAL_MEASUREMENT } from "../constant";
import { SUPPLIER_EDIT_CHANGE_QTYPCS } from "../constant";
import { SUPPLIER_EDIT_CHANGE_QTYKG } from "../constant";
import { CHANGE_COST_SUPPLIER } from "../constant";
import { CHANGE_SUPPLIER_DISCOUNT } from "../constant";

import { REMOVE_FROM_CART_FROM_SUPPLIER } from "../constant";
import { CHANGE_SUPPLIER_TEXT } from "../constant";
import { EMPTY_SUPPLIER_DATA } from "../constant";


export const supplierdeliverydata = (data = [], action) => {
console.log('====================================');
console.log(action);
console.log('====================================');
    switch (action.type) {
        case SUPPLIER_PUT_DATA_TO_LOAD:
            

            let getcurrentdata = [];
            (action.data).map(user => {
                let data = {
                    poitemid: user.poitemid,
                    unitofmeasurement: user.unitofmeasurement,
                    uomPrimary: user.uomPrimary,
                    uomSecondary: user.uomSecondary,
                    amount: user.amount,
                    calunitofmeasurement: user.calunitofmeasurement,
                    dlp: user.dlp,
                    grossamount: user.grossamount,
                    gstvalue: user.gstvalue,
                    igst: user.igst,
                    sdnquantity: user.poquantity,
                    sdnquantitykgs: user.poquantitykgs,
                    product: { "id": user.product.id },
                    productName: user.productName,
                    productType: user.productType,
                    standardQtyPerBox: user.standardQtyPerBox,
                    total: user.total,
                    tradeDiscount: user.tradeDiscount,
                    hsncode: user.hsncode
                    // rate:user.rate,
                    // mrp:user.mrp,
                    // amount:user.amount,
                    // cgst:user.cgst,
                    // discount:user.discount,
                    // gstvalue:user.gstvalue,
                    // igst:user.igst,
                    // sdn_quantity:user.poquantity,
                    // product:{"id":user.product.id,"productName":user.product.productName},
                    // sgst:user.sgst,
                    // total:user.total,

                }
                getcurrentdata.push(data);
            });


            return [...getcurrentdata];


        case PUT_NEW_SUPPLIER_ADD_TO_CART:
            let currentData = {
                sdn_id: action.data.id,
                product: { "id": action.data.id },
                igst: action.data.igst,
                dlp: "",
                productName: action.data.productName,
                productType: action.data.productType,
                standardQtyPerBox: action.data.standardQtyPerBox,
                uomPrimary: action.data.uomPrimary,
                uomSecondary: action.data.uomSecondary,
                tradeDiscount: "",
                hsncode: action.data.hsnCode,
                unitofmeasurement: action.data.unitofmeasurement == undefined ? action.data.productType !== "kgproduct" ? "box" : '' : action.data.unitofmeasurement,
                calunitofmeasurement: action.data.calunitofmeasurement == undefined ? action.data.productType !== "kgproduct" ? "box" : '' : action.data.calunitofmeasurement
            }
            return [currentData, ...data];


        case SUPPLIER_EDIT_CHANGE_MEASUREMENT:
            let newCart = [...data]
            let itemIndex = newCart.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem = data[itemIndex]

            currItem.unitofmeasurement = action.data
            data[itemIndex] = currItem
            return [...data];

        case SUPPLIER_EDIT_CHANGE_CAL_MEASUREMENT:
            let newCart1 = [...data]
            let itemIndex1 = newCart1.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem1 = data[itemIndex1]

            currItem1.calunitofmeasurement = action.data

            if (currItem1.calunitofmeasurement === "pcs") {
                if (currItem1.tradeDiscount > 0) {
                    let discount1 = Number(currItem1.sdnquantity) * Number(currItem1.dlp) * (Number(currItem1.tradeDiscount) / 100)
                    currItem1.grossamount = (Number(currItem1.sdnquantity) * Number(currItem1.dlp)).toFixed(2);
                    let totaAmount = (Number(currItem1.sdnquantity) * Number(currItem1.dlp)) - Number(discount1)
                    currItem1.total = (Number(totaAmount)).toFixed(2);
                    currItem1.gstvalue = ((Number(currItem1.total) / 100) * Number(currItem1.igst)).toFixed(2);
                    currItem1.amount = (Number(currItem1.gstvalue) + Number(currItem1.total)).toFixed(2);
                    data[itemIndex1] = currItem1
                } else {
                    currItem1.grossamount = (Number(currItem1.sdnquantity) * Number(currItem1.dlp))
                    currItem1.total = (Number(currItem1.grossamount)).toFixed(2);
                    currItem1.gstvalue = ((Number(currItem1.total) / 100) * Number(currItem1.igst)).toFixed(2);
                    currItem1.amount = ((Number(currItem1.gstvalue)) + (Number(currItem1.total))).toFixed(2);
                    data[itemIndex1] = currItem1
                }
            }
            if (currItem1.calunitofmeasurement === "kg") {
                if (currItem1.tradeDiscount > 0) {
                    let discount1 = Number(currItem1.sdnquantitykgs) * Number(currItem1.dlp) * (Number(currItem1.tradeDiscount) / 100)
                    currItem1.grossamount = (Number(currItem1.sdnquantitykgs) * Number(currItem1.dlp)).toFixed(2);
                    let totaAmount = (Number(currItem1.sdnquantitykgs) * Number(currItem1.dlp)) - Number(discount1)
                    currItem1.total = (Number(totaAmount)).toFixed(2);
                    currItem1.gstvalue = ((Number(currItem1.total) / 100) * Number(currItem1.igst)).toFixed(2);
                    currItem1.amount = (Number(currItem1.gstvalue) + Number(currItem1.total)).toFixed(2);
                    data[itemIndex1] = currItem1
                } else {
                    currItem1.grossamount = (Number(currItem1.sdnquantitykgs) * Number(currItem1.dlp)).toFixed(2);
                    currItem1.total = (Number(currItem1.grossamount)).toFixed(2);
                    currItem1.gstvalue = ((Number(currItem1.total) / 100) * Number(currItem1.igst)).toFixed(2);
                    currItem1.amount = ((Number(currItem1.gstvalue)) + (Number(currItem1.total))).toFixed(2);
                    data[itemIndex1] = currItem1
                }
            }
            return [...data];

        case SUPPLIER_EDIT_CHANGE_QTYPCS:
            let newCart2 = [...data]
            let itemIndex2 = newCart2.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem2 = data[itemIndex2]

            if (currItem2.unitofmeasurement === "box") {
                if (currItem2.tradeDiscount > 0) {
                    if (action.data === "") {
                        currItem2.sdnquantity = ""
                    } else {
                        currItem2.sdnquantity = Number(action.data)
                    }
                    let discount1 = Number(currItem2.sdnquantity) * Number(currItem2.dlp) * (Number(currItem2.tradeDiscount) / 100)
                    currItem2.grossamount = (Number(currItem2.sdnquantity) * Number(currItem2.dlp)).toFixed(2);
                    let totaAmount = Number(currItem2.sdnquantity) * Number(currItem2.dlp) - Number(discount1)
                    currItem2.total = (Number(totaAmount)).toFixed(2);
                    currItem2.gstvalue = ((Number(currItem2.total) / 100) * Number(currItem2.igst)).toFixed(2);
                    currItem2.amount = (Number(currItem2.gstvalue) + Number(currItem2.total)).toFixed(2);
                    data[itemIndex2] = currItem2

                } else {
                    if (action.data === "") {
                        currItem2.sdnquantity = "";
                        currItem2.sdnquantitykgs = "";
                    } else {
                        currItem2.sdnquantity = Number(action.data)

                    }

                    currItem2.grossamount = (Number(currItem2.sdnquantity) * Number(currItem2.dlp))
                    currItem2.total = (Number(currItem2.grossamount)).toFixed(2);
                    currItem2.gstvalue = ((Number(currItem2.total) / 100) * Number(currItem2.igst)).toFixed(2);
                    currItem2.amount = ((Number(currItem2.gstvalue)) + (Number(currItem2.total))).toFixed(2);
                    data[itemIndex2] = currItem2
                }
            }
            if (currItem2.unitofmeasurement === "pcs") {

                if (action.data === "") {
                    currItem2.sdnquantity = "";
                    currItem2.sdnquantitykgs = "";
                } else {
                    currItem2.sdnquantity = Number(action.data)
                    let aa = Number(currItem2.sdnquantity)
                    currItem2.sdnquantitykgs = (Number(aa) * Number(currItem2.uomSecondary)).toFixed(3);

                }
            }
            if (currItem2.unitofmeasurement === "kg") {

                if (action.data === "") {
                    currItem2.sdnquantity = "";
                    
                } else {
                    currItem2.sdnquantity = Number(action.data)

                }
            }
            if (currItem2.calunitofmeasurement === "pcs") {
                if(currItem2.unitofmeasurement === 'pcs'){
                    if (currItem2.tradeDiscount > 0) {
                        if (action.data === "") {
                            currItem2.sdnquantity = ""
                        } else {
                            currItem2.sdnquantity = Number(action.data)
                            let aa = Number(currItem2.sdnquantity)
                            currItem2.sdnquantitykgs = (Number(aa) * Number(currItem2.uomSecondary)).toFixed(3);
                        }
                        let discount1 = Number(currItem2.sdnquantity) * Number(currItem2.dlp) * (Number(currItem2.tradeDiscount) / 100)
                        currItem2.grossamount = (Number(currItem2.sdnquantity) * Number(currItem2.dlp)).toFixed(2);
                        let totaAmount = Number(currItem2.sdnquantity) * Number(currItem2.dlp) - Number(discount1)
                        currItem2.total = (Number(totaAmount)).toFixed(2);
                        currItem2.gstvalue = ((Number(currItem2.total) / 100) * Number(currItem2.igst)).toFixed(2);
                        currItem2.amount = (Number(currItem2.gstvalue) + Number(currItem2.total)).toFixed(2);
                        data[itemIndex2] = currItem2
    
                    } else {
                        if (action.data === "") {
                            currItem2.sdnquantity = "";
                            currItem2.sdnquantitykgs = "";
                        } else {
                            currItem2.sdnquantity = Number(action.data)
    
                        }
    
                        currItem2.grossamount = (Number(currItem2.sdnquantity) * Number(currItem2.dlp))
                        currItem2.total = (Number(currItem2.grossamount)).toFixed(2);
                        currItem2.gstvalue = ((Number(currItem2.total) / 100) * Number(currItem2.igst)).toFixed(2);
                        currItem2.amount = ((Number(currItem2.gstvalue)) + (Number(currItem2.total))).toFixed(2);
                        data[itemIndex2] = currItem2
                    }
                } else if(currItem2.unitofmeasurement === 'kg'){
                    if (currItem2.tradeDiscount > 0) {
                        if (action.data === "") {
                            currItem2.sdnquantity = ""
                        } else {
                            currItem2.sdnquantity = Number(action.data)
                        
                        }
                        let discount1 = Number(currItem2.sdnquantity) * Number(currItem2.dlp) * (Number(currItem2.tradeDiscount) / 100)
                        currItem2.grossamount = (Number(currItem2.sdnquantity) * Number(currItem2.dlp)).toFixed(2);
                        let totaAmount = Number(currItem2.sdnquantity) * Number(currItem2.dlp) - Number(discount1)
                        currItem2.total = (Number(totaAmount)).toFixed(2);
                        currItem2.gstvalue = ((Number(currItem2.total) / 100) * Number(currItem2.igst)).toFixed(2);
                        currItem2.amount = (Number(currItem2.gstvalue) + Number(currItem2.total)).toFixed(2);
                        data[itemIndex2] = currItem2
    
                    } else {
                        if (action.data === "") {
                            currItem2.sdnquantity = "";
                            
                        } else {
                            currItem2.sdnquantity = Number(action.data)
    
                        }
    
                        currItem2.grossamount = (Number(currItem2.sdnquantity) * Number(currItem2.dlp))
                        currItem2.total = (Number(currItem2.grossamount)).toFixed(2);
                        currItem2.gstvalue = ((Number(currItem2.total) / 100) * Number(currItem2.igst)).toFixed(2);
                        currItem2.amount = ((Number(currItem2.gstvalue)) + (Number(currItem2.total))).toFixed(2);
                        data[itemIndex2] = currItem2
                    }
                } 
            }

            if (currItem2.calunitofmeasurement === "kg") {
                if(currItem2.unitofmeasurement === 'pcs'){
                if (currItem2.tradeDiscount > 0) {
                    if (action.data === "") {
                        currItem2.sdnquantity = ""
                    } else {
                        currItem2.sdnquantity = Number(action.data)
                        let aa = Number(currItem2.sdnquantity)
                        currItem2.sdnquantitykgs = (Number(aa) * Number(currItem2.uomSecondary)).toFixed(3);
                    }
                    let discount1 = Number(currItem2.sdnquantitykgs) * Number(currItem2.dlp) * (Number(currItem2.tradeDiscount) / 100)
                    currItem2.grossamount = (Number(currItem2.sdnquantitykgs) * Number(currItem2.dlp)).toFixed(2);
                    let totaAmount = Number(currItem2.sdnquantitykgs) * Number(currItem2.dlp) - Number(discount1)
                    currItem2.total = (Number(totaAmount)).toFixed(2);
                    currItem2.gstvalue = ((Number(currItem2.total) / 100) * Number(currItem2.igst)).toFixed(2);
                    currItem2.amount = (Number(currItem2.gstvalue) + Number(currItem2.total)).toFixed(2);
                    data[itemIndex2] = currItem2

                } else {
                    if (action.data === "") {
                        currItem2.sdnquantity = "";
                        currItem2.sdnquantitykgs = "";
                    } else {
                        currItem2.sdnquantity = Number(action.data)
                        let aa = Number(currItem2.sdnquantity)
                        currItem2.sdnquantitykgs = (Number(aa) * Number(currItem2.uomSecondary)).toFixed(3);

                    }

                    currItem2.grossamount = (Number(currItem2.sdnquantitykgs) * Number(currItem2.dlp))
                    currItem2.total = (Number(currItem2.grossamount)).toFixed(2);
                    currItem2.gstvalue = ((Number(currItem2.total) / 100) * Number(currItem2.igst)).toFixed(2);
                    currItem2.amount = ((Number(currItem2.gstvalue)) + (Number(currItem2.total))).toFixed(2);
                    data[itemIndex2] = currItem2
                }
            } else if(currItem2.unitofmeasurement === 'pcs'){
                if (currItem2.tradeDiscount > 0) {
                    if (action.data === "") {
                        currItem2.sdnquantity = ""
                    } else {
                        currItem2.sdnquantity = Number(action.data)
                    
                    }
                    let discount1 = Number(currItem2.sdnquantitykgs) * Number(currItem2.dlp) * (Number(currItem2.tradeDiscount) / 100)
                    currItem2.grossamount = (Number(currItem2.sdnquantitykgs) * Number(currItem2.dlp)).toFixed(2);
                    let totaAmount = Number(currItem2.sdnquantitykgs) * Number(currItem2.dlp) - Number(discount1)
                    currItem2.total = (Number(totaAmount)).toFixed(2);
                    currItem2.gstvalue = ((Number(currItem2.total) / 100) * Number(currItem2.igst)).toFixed(2);
                    currItem2.amount = (Number(currItem2.gstvalue) + Number(currItem2.total)).toFixed(2);
                    data[itemIndex2] = currItem2

                } else {
                    if (action.data === "") {
                        currItem2.sdnquantity = "";
                        
                    } else {
                        currItem2.sdnquantity = Number(action.data)
                        
                    }

                    currItem2.grossamount = (Number(currItem2.sdnquantitykgs) * Number(currItem2.dlp))
                    currItem2.total = (Number(currItem2.grossamount)).toFixed(2);
                    currItem2.gstvalue = ((Number(currItem2.total) / 100) * Number(currItem2.igst)).toFixed(2);
                    currItem2.amount = ((Number(currItem2.gstvalue)) + (Number(currItem2.total))).toFixed(2);
                    data[itemIndex2] = currItem2
                }
            } 
            }
            return [...data];

        case SUPPLIER_EDIT_CHANGE_QTYKG:
            let newCart3 = [...data]
            let itemIndex3 = newCart3.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem3 = data[itemIndex3]

            if (currItem3.unitofmeasurement === "kg") {
                if (action.data === "") {
                    currItem3.sdnquantity = "";
                    currItem3.sdnquantitykgs = "";
                } else {
                    currItem3.sdnquantitykgs = Number(action.data)
                    let aa = Number(currItem3.sdnquantitykgs)
                    let ab = (Number(aa) / Number(currItem3.uomSecondary))
                    currItem3.sdnquantity = Math.round(ab);
                }
            }

            if (currItem3.unitofmeasurement === "pcs") {
                if (action.data === "") {
                    currItem3.sdnquantitykgs = "";
                } else {
                    currItem3.sdnquantitykgs = Number(action.data)
                }
            }

            if (currItem3.calunitofmeasurement === "kg") {
                if(currItem3.unitofmeasurement === 'kg'){
                if (currItem3.tradeDiscount > 0) {
                    if (action.data === "") {
                        currItem3.sdnquantitykgs = "";
                    } else {
                        currItem3.sdnquantitykgs = Number(action.data)
                        let aa = Number(currItem3.sdnquantitykgs)
                        let ab = (Number(aa) / Number(currItem3.uomSecondary))
                        currItem3.sdnquantity = Math.round(ab);
                    }
                    let discount1 = Number(currItem3.sdnquantitykgs) * Number(currItem3.dlp) * (Number(currItem3.tradeDiscount) / 100)
                    currItem3.grossamount = (Number(currItem3.sdnquantitykgs) * Number(currItem3.dlp)).toFixed(2);
                    let totaAmount = ((Number(currItem3.sdnquantitykgs) * Number(currItem3.dlp)) - Number(discount1))
                    currItem3.total = (Number(totaAmount)).toFixed(2);
                    currItem3.gstvalue = ((Number(currItem3.total) / 100) * Number(currItem3.igst)).toFixed(2);
                    currItem3.amount = (Number(currItem3.gstvalue) + Number(currItem3.total)).toFixed(2);
                    data[itemIndex3] = currItem3
                } else {
                    if (action.data === "") {
                        currItem3.sdnquantitykgs = "";
                    } else {
                        currItem3.sdnquantitykgs = Number(action.data)
                        let aa = Number(currItem3.sdnquantitykgs)
                        let ab = (Number(aa) / Number(currItem3.uomSecondary))
                        currItem3.sdnquantity = Math.round(ab);
                    }
                    currItem3.grossamount = (Number(currItem3.sdnquantitykgs) * Number(currItem3.dlp))
                    currItem3.total = (Number(currItem3.grossamount)).toFixed(2);
                    currItem3.gstvalue = ((Number(currItem3.total) / 100) * Number(currItem3.igst)).toFixed(2);
                    currItem3.amount = ((Number(currItem3.gstvalue)) + (Number(currItem3.total))).toFixed(2);
                    data[itemIndex3] = currItem3
                }
            } else if(currItem3.unitofmeasurement === 'pcs'){
                if (currItem3.tradeDiscount > 0) {
                    if (action.data === "") {
                        currItem3.sdnquantitykgs = "";
                    } else {
                        currItem3.sdnquantitykgs = Number(action.data)

                    }
                    let discount1 = Number(currItem3.sdnquantitykgs) * Number(currItem3.dlp) * (Number(currItem3.tradeDiscount) / 100)
                    currItem3.grossamount = (Number(currItem3.sdnquantitykgs) * Number(currItem3.dlp)).toFixed(2);
                    let totaAmount = ((Number(currItem3.sdnquantitykgs) * Number(currItem3.dlp)) - Number(discount1))
                    currItem3.total = (Number(totaAmount)).toFixed(2);
                    currItem3.gstvalue = ((Number(currItem3.total) / 100) * Number(currItem3.igst)).toFixed(2);
                    currItem3.amount = (Number(currItem3.gstvalue) + Number(currItem3.total)).toFixed(2);
                    data[itemIndex3] = currItem3
                } else {
                    if (action.data === "") {
                        currItem3.sdnquantitykgs = "";
                    } else {
                        currItem3.sdnquantitykgs = Number(action.data)
                    }
                    currItem3.grossamount = (Number(currItem3.sdnquantitykgs) * Number(currItem3.dlp))
                    currItem3.total = (Number(currItem3.grossamount)).toFixed(2);
                    currItem3.gstvalue = ((Number(currItem3.total) / 100) * Number(currItem3.igst)).toFixed(2);
                    currItem3.amount = ((Number(currItem3.gstvalue)) + (Number(currItem3.total))).toFixed(2);
                    data[itemIndex3] = currItem3
                }
            } 
            }

            if (currItem3.calunitofmeasurement === "pcs") {
                if(currItem3.unitofmeasurement === 'kg'){
                if (currItem3.tradeDiscount > 0) {
                    if (action.data === "") {
                        currItem3.sdnquantitykgs = "";
                    } else {
                        currItem3.sdnquantitykgs = Number(action.data)
                        let aa = Number(currItem3.sdnquantitykgs)
                        let ab = (Number(aa) / Number(currItem3.uomSecondary))
                        currItem3.sdnquantity = Math.round(ab);
                    }
                    let discount1 = Number(currItem3.sdnquantity) * Number(currItem3.dlp) * (Number(currItem3.tradeDiscount) / 100)
                    currItem3.grossamount = (Number(currItem3.sdnquantity) * Number(currItem3.dlp)).toFixed(2);
                    let totaAmount = ((Number(currItem3.sdnquantity) * Number(currItem3.dlp)) - Number(discount1))
                    currItem3.total = (Number(totaAmount)).toFixed(2);
                    currItem3.gstvalue = ((Number(currItem3.total) / 100) * Number(currItem3.igst)).toFixed(2);
                    currItem3.amount = (Number(currItem3.gstvalue) + Number(currItem3.total)).toFixed(2);
                    data[itemIndex3] = currItem3
                } else {
                    if (action.data === "") {
                        currItem3.sdnquantitykgs = "";
                    } else {
                        currItem3.sdnquantitykgs = Number(action.data)
                        let aa = Number(currItem3.sdnquantitykgs)
                        let ab = (Number(aa) / Number(currItem3.uomSecondary))
                        currItem3.sdnquantity = Math.round(ab);
                    }
                    currItem3.grossamount = (Number(currItem3.sdnquantity) * Number(currItem3.dlp))
                    currItem3.total = (Number(currItem3.grossamount)).toFixed(2);
                    currItem3.gstvalue = ((Number(currItem3.total) / 100) * Number(currItem3.igst)).toFixed(2);
                    currItem3.amount = ((Number(currItem3.gstvalue)) + (Number(currItem3.total))).toFixed(2);
                    data[itemIndex3] = currItem3
                }
            } else if(currItem3.unitofmeasurement === 'pcs'){
                if (currItem3.tradeDiscount > 0) {
                    if (action.data === "") {
                        currItem3.sdnquantitykgs = "";
                    } else {
                        currItem3.sdnquantitykgs = Number(action.data)

                    }
                    let discount1 = Number(currItem3.sdnquantity) * Number(currItem3.dlp) * (Number(currItem3.tradeDiscount) / 100)
                    currItem3.grossamount = (Number(currItem3.sdnquantity) * Number(currItem3.dlp)).toFixed(2);
                    let totaAmount = ((Number(currItem3.sdnquantity) * Number(currItem3.dlp)) - Number(discount1))
                    currItem3.total = (Number(totaAmount)).toFixed(2);
                    currItem3.gstvalue = ((Number(currItem3.total) / 100) * Number(currItem3.igst)).toFixed(2);
                    currItem3.amount = (Number(currItem3.gstvalue) + Number(currItem3.total)).toFixed(2);
                    data[itemIndex3] = currItem3
                } else {
                    if (action.data === "") {
                        currItem3.sdnquantitykgs = "";
                    } else {
                        currItem3.sdnquantitykgs = Number(action.data)

                    }
                    currItem3.grossamount = (Number(currItem3.sdnquantity) * Number(currItem3.dlp))
                    currItem3.total = (Number(currItem3.grossamount)).toFixed(2);
                    currItem3.gstvalue = ((Number(currItem3.total) / 100) * Number(currItem3.igst)).toFixed(2);
                    currItem3.amount = ((Number(currItem3.gstvalue)) + (Number(currItem3.total))).toFixed(2);
                    data[itemIndex3] = currItem3
                }
            }  
            }

            return [...data];

        case CHANGE_COST_SUPPLIER:
            let newCart4 = [...data]
            let itemIndex4 = newCart4.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem4 = data[itemIndex4]

            currItem4.dlp = action.data
            if (currItem4.calunitofmeasurement === "box") {
                if (currItem4.tradeDiscount > 0) {
                    if (action.data === "") {
                        currItem4.dlp = ""
                    } else {
                        currItem4.dlp = Number(action.data)
                    }
                    let discount1 = Number(currItem4.sdnquantity) * Number(currItem4.dlp) * (Number(currItem4.tradeDiscount) / 100)
                    currItem4.grossamount = (Number(currItem4.sdnquantity) * Number(currItem4.dlp)).toFixed(2);
                    let totaAmount = Number(currItem4.sdnquantity) * Number(currItem4.dlp) - Number(discount1)
                    currItem4.total = (Number(totaAmount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.total) / 100) * Number(currItem4.igst)).toFixed(2);
                    currItem4.amount = (Number(currItem4.gstvalue) + Number(currItem4.total)).toFixed(2);
                    data[itemIndex4] = currItem4

                } else {
                    if (action.data === "") {
                        currItem4.dlp = ""
                    } else {
                        currItem4.dlp = Number(action.data)
                    }
                    currItem4.grossamount = (Number(currItem4.sdnquantity) * Number(currItem4.dlp)).toFixed(2);
                    currItem4.total = (Number(currItem4.grossamount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.total) / 100) * Number(currItem4.igst)).toFixed(2);
                    currItem4.amount = ((Number(currItem4.gstvalue)) + (Number(currItem4.total))).toFixed(2);
                    data[itemIndex4] = currItem4
                }
            }
            if (currItem4.calunitofmeasurement === "pcs") {
                if (currItem4.tradeDiscount > 0) {

                    if (action.data === "") {
                        currItem4.dlp = ""
                    } else {
                        currItem4.dlp = Number(action.data)
                    }
                    let discount1 = Number(currItem4.sdnquantity) * Number(currItem4.dlp) * (Number(currItem4.tradeDiscount) / 100)
                    currItem4.grossamount = (Number(currItem4.sdnquantity) * Number(currItem4.dlp)).toFixed(2);
                    let totaAmount = Number(currItem4.sdnquantity) * Number(currItem4.dlp) - Number(discount1)
                    currItem4.total = (Number(totaAmount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.total) / 100) * Number(currItem4.igst)).toFixed(2);
                    currItem4.amount = (Number(currItem4.gstvalue) + Number(currItem4.total)).toFixed(2);
                    data[itemIndex4] = currItem4
                } else {
                    if (action.data === "") {
                        currItem4.dlp = ""
                    } else {
                        currItem4.dlp = Number(action.data)
                    }
                    currItem4.grossamount = (Number(currItem4.sdnquantity) * Number(currItem4.dlp)).toFixed(2);
                    currItem4.total = (Number(currItem4.grossamount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.total) / 100) * Number(currItem4.igst)).toFixed(2);
                    currItem4.amount = ((Number(currItem4.gstvalue)) + (Number(currItem4.total))).toFixed(2);
                    data[itemIndex4] = currItem4

                }
            }
            if (currItem4.calunitofmeasurement === "kg") {
                if (currItem4.tradeDiscount > 0) {

                    if (action.data === "") {
                        currItem4.dlp = ""
                    } else {
                        currItem4.dlp = Number(action.data)
                    }
                    let discount1 = Number(currItem4.sdnquantitykgs) * Number(currItem4.dlp) * (Number(currItem4.tradeDiscount) / 100)
                    currItem4.grossamount = (Number(currItem4.sdnquantitykgs) * Number(currItem4.dlp)).toFixed(2);
                    let totaAmount = Number(currItem4.sdnquantitykgs) * Number(currItem4.dlp) - Number(discount1)
                    currItem4.total = (Number(totaAmount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.total) / 100) * Number(currItem4.igst)).toFixed(2);
                    currItem4.amount = (Number(currItem4.gstvalue) + Number(currItem4.total)).toFixed(2);
                    data[itemIndex4] = currItem4

                } else {
                    if (action.data === "") {
                        currItem4.dlp = ""
                    } else {
                        currItem4.dlp = Number(action.data)
                    }
                    currItem4.grossamount = (Number(currItem4.sdnquantitykgs) * Number(currItem4.dlp)).toFixed(2);
                    currItem4.total = (Number(currItem4.grossamount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.total) / 100) * Number(currItem4.igst)).toFixed(2);
                    currItem4.amount = ((Number(currItem4.gstvalue)) + (Number(currItem4.total))).toFixed(2);
                    data[itemIndex4] = currItem4
                }
            }

            return [...data];

        case CHANGE_SUPPLIER_DISCOUNT:
            let newCart5 = [...data]
            let itemIndex5 = newCart5.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem5 = data[itemIndex5]


            if (currItem5.calunitofmeasurement === "box") {
                if (action.data === "") {
                    currItem5.tradeDiscount = ""
                } else {
                    currItem5.tradeDiscount = Number(action.data)
                }

                let discount1 = Number(currItem5.sdnquantity) * Number(currItem5.dlp) * (Number(currItem5.tradeDiscount) / 100)
                currItem5.grossamount = (Number(currItem5.sdnquantity) * Number(currItem5.dlp)).toFixed(2);
                let totaAmount = Number(currItem5.sdnquantity) * Number(currItem5.dlp) - Number(discount1)
                currItem5.total = (Number(totaAmount)).toFixed(2);
                currItem5.gstvalue = ((Number(currItem5.total) / 100) * Number(currItem5.igst)).toFixed(2);
                currItem5.amount = (Number(currItem5.gstvalue) + Number(currItem5.total)).toFixed(2);
                data[itemIndex5] = currItem5
            }
            if (currItem5.calunitofmeasurement === "pcs") {
                if (action.data === "") {
                    currItem5.tradeDiscount = ""
                } else {
                    currItem5.tradeDiscount = Number(action.data)
                }

                let discount1 = Number(currItem5.sdnquantity) * Number(currItem5.dlp) * (Number(currItem5.tradeDiscount) / 100)
                currItem5.grossamount = (Number(currItem5.sdnquantity) * Number(currItem5.dlp)).toFixed(2);
                let totaAmount = Number(currItem5.sdnquantity) * Number(currItem5.dlp) - Number(discount1)
                currItem5.total = (Number(totaAmount)).toFixed(2);
                currItem5.gstvalue = ((Number(currItem5.total) / 100) * Number(currItem5.igst)).toFixed(2);
                currItem5.amount = (Number(currItem5.gstvalue) + Number(currItem5.total)).toFixed(2);
                data[itemIndex5] = currItem5
            }
            if (currItem5.calunitofmeasurement === "kg") {
                if (action.data === "") {
                    currItem5.tradeDiscount = ""
                } else {
                    currItem5.tradeDiscount = Number(action.data)
                }

                let discount1 = Number(currItem5.sdnquantitykgs) * Number(currItem5.dlp) * (Number(currItem5.tradeDiscount) / 100)
                currItem5.grossamount = (Number(currItem5.sdnquantitykgs) * Number(currItem5.dlp)).toFixed(2);
                let totaAmount = Number(currItem5.sdnquantitykgs) * Number(currItem5.dlp) - Number(discount1)
                currItem5.total = (Number(totaAmount)).toFixed(2);
                currItem5.gstvalue = ((Number(currItem5.total) / 100) * Number(currItem5.igst)).toFixed(2);
                currItem5.amount = (Number(currItem5.gstvalue) + Number(currItem5.total)).toFixed(2);
                data[itemIndex5] = currItem5
            }

            return [...data];


        case REMOVE_FROM_CART_FROM_SUPPLIER:

            const remainingitem = data.filter((item) => item.product.id !== action.data);
            return [...remainingitem];





        case CHANGE_SUPPLIER_TEXT:

            let newCart6 = [...data]
            let itemIndex6 = newCart6.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem6 = data[itemIndex6]

            currItem6.text = action.data
            data[itemIndex6] = currItem6
            return [...data];


        case EMPTY_SUPPLIER_DATA:
            // console.warn("reducer called",action)
            return [];



        default:
            return data;
    }


}
