import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Col, Form, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import Swal from "sweetalert2";
import { Prev } from "react-bootstrap/esm/PageItem";
export default function CancelQuntityDisSales() {
  const { id } = useParams();
  const { pointindex } = useParams();
  const navigate = useNavigate();
  const [salesOrderDataDetails, setsalesOrderDataDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [pendingQuantityPcs, setPendingQuantityPcs] = useState(0);
  const [pendingQuantityPcsbeforevalue, setpendingQuantityPcsbeforevalue] =
    useState(0);
  const [pendingQuantitykgbeforevalue, setpendingQuantitykgbeforevalue] =
    useState(0);
  const [pendingQuantityKg, setPendingQuantityKg] = useState(0);
  const [cancelPcsQty, setCancelPcsQty] = useState("");
  const [cancelKgQty, setCancelKgQty] = useState("");
  const [addCancelQuantity, setAddCancelQuantity] = useState();
  const [addCancelkgAspcs, setAddCancelkgAspcs] = useState();
  const [flagFordiableKG, setflagFordiableKG] = useState(false);
  const [flagFordiablePCS, setflagFordiablePCS] = useState(false);
  async function getByID() {
    setLoadingTableData(true);
    const response = await axios
      .get(`${BASE_URL}so/item1/${id}`, {
        headers: authHeader(),
      })
      .catch((error) => alert(error))
      .finally(() => {
        setLoadingTableData(false);
      });
    console.log(response.data, "response get siddhi");

    const result = {
      productid: response.data[0]?.productid,
      id: response.data[0].salesorderitem_id,
      product_name: response.data[0].product_name,
      product_type: response.data[0].product_type,
      sokgqty: response.data[0].sokgqty,
      sopcsqty: response.data[0].sopcsqty,
      dist_pendingcancelkgqty: response.data[0].dist_pendingcancelkgqty,
      dist_pendingcancelpcsqty: response.data[0].dist_pendingcancelpcsqty,
      dist_addcancelkgqty: Number(response.data[0]. dist_addcancelkgqty),
       dist_addcancelpcsqty: Number(response.data[0]. dist_addcancelpcsqty),
      dist_cancelpcsqty: "",
      dist_cancelkgqty: "",
      uom_primary: response.data[0].uom_primary,
      uom_secondary: response.data[0].uom_secondary,
      error: "",
      cancelstatus: response.data[0].cancelstatus,
    };

    setAddCancelQuantity(Number(response.data[0]. dist_addcancelpcsqty));
    setAddCancelkgAspcs(Number(response.data[0].dist_addcancelkgqty));
    console.log(result, "sajjjjjjjjjjjjjj");

    setsalesOrderDataDetails(result);

    setPendingQuantityPcs(result.dist_pendingcancelpcsqty);
    setPendingQuantityKg(result.dist_pendingcancelkgqty);
    setpendingQuantityPcsbeforevalue(result.dist_pendingcancelpcsqty);
    setpendingQuantitykgbeforevalue(result.dist_pendingcancelkgqty);
    // setsalesOrderDataDetails(
    //   await response.data?.salesOrderItems?.map((item) => item)
    // );
  }

  useEffect(() => {
    getByID();
  }, []);

  const quantityHandlerPcs = (e) => {
    const value = parseInt(e.target.value) || "";
    // salesOrderDataDetails.addcancelpcsqty = 0;
    const row = salesOrderDataDetails;
    row. dist_addcancelpcsqty = addCancelQuantity;

    setflagFordiableKG(true);
    if (value === "") {
      setflagFordiableKG(false);
    }

    if (value > pendingQuantityPcsbeforevalue) {
      Swal.fire({
        icon: "error",
        title: "Exceeded Limit",
        text: " Cannot cancel more than pending quantity.",
      });

      setsalesOrderDataDetails({
        ...salesOrderDataDetails,
        dist_pendingcancelpcsqty: pendingQuantityPcsbeforevalue,
        dist_pendingcancelkgqty: pendingQuantitykgbeforevalue,
        dist_cancelpcsqty: "",
      });
      setflagFordiableKG(false);
      return;
    }

    const newPendingKg = (
      (pendingQuantityPcsbeforevalue - value) *
      salesOrderDataDetails.uom_secondary
    ).toFixed(3);

    if (newPendingKg < 0) {
      Swal.fire({
        icon: "error",
        title: "Exceeded Limit",
        text: " Cannot cancel more than pending quantity.",
      });

      setsalesOrderDataDetails({
        ...salesOrderDataDetails,
        dist_pendingcancelpcsqty: pendingQuantityPcsbeforevalue,
        dist_pendingcancelkgqty: pendingQuantitykgbeforevalue,
        cancelpcsqty: "",
      });
      setflagFordiableKG(false);
      return;
    }
    // alert(salesOrderDataDetails.addcancelpcsqty + salesOrderDataDetails.cancelPcsQty);
 
    row.dist_cancelpcsqty = parseInt(e.target.value) || "";
    row. dist_addcancelpcsqty =
      Number(row. dist_addcancelpcsqty) + Number(row.dist_cancelpcsqty);
    row.dist_pendingcancelpcsqty = pendingQuantityPcsbeforevalue - value;
    row.dist_pendingcancelkgqty = newPendingKg;
    row. dist_addcancelkgqty = Number(row. dist_addcancelpcsqty) * row.uom_secondary;

    setsalesOrderDataDetails(row);

    setPendingQuantityPcs(pendingQuantityPcsbeforevalue - value);
    setPendingQuantityKg(newPendingKg);

    let newStatus = salesOrderDataDetails.cancelstatus;

    if (
      salesOrderDataDetails.dist_pendingcancelpcsqty <= 0 &&
      salesOrderDataDetails.dist_pendingcancelkgqty <= 0
    ) {
      newStatus = "Cancelled";
    } else if (
      salesOrderDataDetails.dist_pendingcancelpcsqty > 0 ||
      salesOrderDataDetails.dist_pendingcancelkgqty > 0
    ) {
      newStatus = "Partial";
    }

    setsalesOrderDataDetails((prev) => ({ ...prev, cancelstatus: newStatus }));
  };

  const quantityHandlerKg = (e) => {
    const value = parseFloat(e.target.value) || "";
    console.log(value);
    const row = salesOrderDataDetails;
    row.dist_addcancelkgqty = addCancelkgAspcs;
    setflagFordiablePCS(true);
    if (value === "") {
      setflagFordiablePCS(false);
    }
    if (value > pendingQuantitykgbeforevalue) {
      Swal.fire({
        icon: "error",
        title: "Exceeded Limit",
        text: "Cannot cancel more than pending quantity.",
      });

      setsalesOrderDataDetails({
        ...salesOrderDataDetails,
        dist_pendingcancelkgqty: pendingQuantitykgbeforevalue,
        dist_cancelkgqty: "",
      });
      setflagFordiablePCS(false);

      return;
    }

    const   newPendingPcs = Math.round(
      (pendingQuantitykgbeforevalue - value) /
        salesOrderDataDetails.uom_secondary
    );

    if (newPendingPcs < 0) {
      Swal.fire({
        icon: "error",
        title: "Exceeded Limit",
        text: " Cannot cancel more than pending quantity.",
      });

      setsalesOrderDataDetails({
        ...salesOrderDataDetails,
        dist_pendingcancelpcsqty: pendingQuantityPcsbeforevalue,
        dist_pendingcancelkgqty: pendingQuantitykgbeforevalue,
        dist_cancelpcsqty: "",
      });
      setflagFordiablePCS(false);
      return;
    }
    // setsalesOrderDataDetails((prev) => ({
    //   ...prev,
    //   cancelkgqty: value,
    //   pendingcancelkgqty: (pendingQuantitykgbeforevalue - value).toFixed(3),
    //   pendingcancelpcsqty: newPendingPcs,
    // }));
    row.dist_cancelkgqty = parseFloat(e.target.value) || "";

    row.dist_pendingcancelpcsqty = newPendingPcs;
    row.dist_pendingcancelkgqty  = (pendingQuantitykgbeforevalue - value).toFixed(3);
    row.dist_addcancelkgqty = Number(row.dist_addcancelkgqty) + Number(row.dist_cancelkgqty);
   row. dist_addcancelpcsqty = Number(row. dist_addcancelkgqty) / row.uom_secondary;

    setsalesOrderDataDetails(row);
    setPendingQuantityKg((pendingQuantitykgbeforevalue - value).toFixed(3));
    setPendingQuantityPcs(newPendingPcs);
  };



  async function handleUpdate() {
    let newStatus = salesOrderDataDetails.cancelstatus;


    setsalesOrderDataDetails((prev) => ({ ...prev, cancelstatus: newStatus }));
    console.log(salesOrderDataDetails, "submit ke time");
  
    {
      setLoading(true);
      const response = await axios
        .put(
          `${BASE_URL}so/cancel1/${id}`,
          {
            soitems: [salesOrderDataDetails],
          },
          {
            headers: authHeader(),
          }
        ).then(()=>{
            navigate(`/DistributorReport`);
        })
        .catch((error) => alert(error))
        .finally(() => {
          setLoading(false);
        });
  
      console.log(response, "responsse after update");
    }
  }

  const handleback = () => {
    console.log("first");
    navigate("/DistributorReport");
  };
  console.log(salesOrderDataDetails, "salesorderdetai");

  return (
    <>
      <MDBCard data-aos="fade">
        <div className="card-header card-title w-100">
          <div className="d-flex justify-content-between">
            <h2 style={{ fontSize: "16px", fontWeight: 700 }}>
              {" "}
              Cancel Order (Distributor)
            </h2>
            <button
              onClick={() => handleback()}
              className="btn btn-primary ml-3"
              style={{
                marginLeft: "47px",
                padding: "9px 12px",
                marginTop: "-1px",
              }}
            >
              Back
            </button>
          </div>
        </div>

        <Table
          striped
          responsive
          hover
          size="sm"
          className="text-center table-bordered"
        >
          <thead className="p-2 mb-2 bg-success text-white ">
            <tr>
              <th
                style={{ width: "7%", border: "1px solid rgba(0, 0, 0, 0.05)" }}
              >
                ID
              </th>
              <th
                style={{
                  width: "14%",
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
              >
                PRODUCT&nbsp;NAME
              </th>
              <th
                style={{
                  width: "10%",
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
              >
                UOM
              </th>
              <th
                style={{
                  width: "10%",
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
              >
                QTY(PCS)
              </th>
              <th
                style={{ width: "0%", border: "1px solid rgba(0, 0, 0, 0.05)" }}
              >
                QTY(KG)
              </th>
              <th
                style={{
                  width: "15%",
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
              >
                Pending (PCS) Quantity
              </th>
              <th
                style={{
                  width: "13%",
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
              >
                Cancel (PCS) Quantity
              </th>

              <th
                style={{
                  width: "14%",
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
              >
                Pending (KG) \ Quantity
              </th>
              <th
                style={{
                  width: "14%",
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
              >
                Cancel (KG) Quantity
              </th>
            </tr>
          </thead>
          <tbody>
            {loadingTableData ? (
              <div className="d-flex justify-content-center align-item-center">
                <p style={{ fontSize: "20px", textAlign: "center" }}>
                  loading...
                </p>
              </div>
            ) : (
              salesOrderDataDetails && (
                <tr>
                  <td>{salesOrderDataDetails.productid}</td>
                  <td>{salesOrderDataDetails.product_name}</td>
                  <td>{salesOrderDataDetails.product_type}</td>
                  <td>{salesOrderDataDetails.sopcsqty}</td>
                  <td>{salesOrderDataDetails.sokgqty}</td>
                  <td>{salesOrderDataDetails.dist_pendingcancelpcsqty}</td>
                  <td className="d-flex justify-content-center">
                    <Form.Group as={Col} md="12" controlId="cancelitempcs">
                      <Form.Control
                        type="text"
                        value={salesOrderDataDetails.dist_cancelpcsqty}
                        onChange={(e) => {
                          let value = e.target.value;
                          const regex = /^[0-9]*\.?[0-9]{0,2}$/;
                          if (regex.test(value)) {
                            quantityHandlerPcs(e);
                          }
                        }}
                
                        autoComplete="off"
                        disabled={flagFordiablePCS ? true : false}
                      />
                    </Form.Group>
                  </td>

                  <td>{salesOrderDataDetails.dist_pendingcancelkgqty}</td>

                  <td className="d-flex justify-content-center">
                    <Form.Group as={Col} md="12" controlId="cancelitemkg">
                      <Form.Control
                        type="number"
                        value={salesOrderDataDetails.dist_cancelkgqty}
                        onChange={(e) => {
                          let value = e.target.value;
                          const regex = /^[0-9]*\.?[0-9]{0,2}$/;
                          if (regex.test(value)) {
                            quantityHandlerKg(e);
                          }
                        }}
                        autoComplete="off"
                        disabled={flagFordiableKG ? true : false}
                      />
                    </Form.Group>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
        <div className="d-flex">
          <button className="btn btn-primary thh" onClick={handleUpdate}>
            {" "}
            {loading ? "Loading..." : "Submit"}
          </button>
        </div>
      </MDBCard>
    </>
  );
}
