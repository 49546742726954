import React from 'react'
import { Mydatatable } from '../Product/Mydatatable';
import { HiOutlineDotsVertical } from "react-icons/hi";
export const Newwo = () => {





    let header = [
        { "label": "Id", "field": "id" },
        { "label": "Order Id    ", "field": "work_Order_Id" },
        { "label": "Order Date    ", "field": "work_Order_Date" },
        { "label": "Retailer Name", "field": "trade_name" },
        { "label": "Distributor Name", "field": "distname" },
        { "label": "Net Amount", "field": "net_Value" },
        // tax_Amount
        { "label": "Tax Amount", "field": "tax_Amount" },
        { "label": "Total", "field": "gross_Total" },
        { "label": "Rsm Staff", "field": "rsmstaffname" },
     { "label": "ASE", "field": "asestaffname" },
     { "label": "State", "field": "state_Name" },
     { "label": "Created Date", "field": "createddate" },
     { "label": "Created Time", "field": "createdtime" },
     { "label": "Created By", "field": "createbyname" },
     { "label": "Updated Date", "field": "updateddate" },
     { "label": "Updated Time", "field": "updatedtime" },
     { "label": "Updated By", "field": "updatedbyname" },
        { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> },
         { "label": <HiOutlineDotsVertical />, "field":'hideOrshow'},
      ]; 


const sumofkeys=[{"first":'net_Value'},{"first":'tax_Amount'},{"first":'gross_Total'}]
 

  return (
    <Mydatatable
    head={header}
    title={"Secondary Order"}
    axiostabledata={'workorder'}
    Add={"Add Secondary"}
    Create={'/workordercreate'}
    Edit={"/workorderview"}
    View={"/workorderview"}
Sumofkeys={sumofkeys}
    />
  )
}
// gross_Total
// : 
// 0
// id
// : 
// 21
// net_Value
// : 
// 0
// remarks
// : 
// null
// tax_Amount
// : 
// 0
// trade_Name
// : 
// "asdf"
// work_Order_Id
// :