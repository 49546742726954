import axios from "axios";
import { MDBCard } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import Swal from "sweetalert2";

export default function Create() {
    const [add, setAdd] = useState([]);
    const [productAll, setProductAll] = useState([]);
    const [productAll1, setProductAll1] = useState([]);
    const [rate, setrate] = useState('');
    const [product, setproduct] = useState('');
    const [disword, setdisword] = useState(encodeURIComponent(' '));
    const [disword1, setdisword1] = useState(encodeURIComponent(' '));
    const [status,setstatus] = useState(false);
    const [description,setdescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    const AddRow = () => {
        if(status == false){
            Swal.fire({
                title: 'Please Select Product',
                text: "You won't be able to revert this!",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
              })
        } else{
        const rowsInput = {
            id: add.length + 1,
            product: '',
            uomqty: '',
            qty: '',
            qtykg: '',
            uomrate: '',
            rate:'',
            amount:'',
            
        }
        setAdd([...add, rowsInput])
    }
    }

    useEffect(() => {
        if(disword){
        axios.get(BASE_URL + `product/page/0/100/asc/id/${disword}`, { headers: authHeader() })
        .then(res => {
      if(res.data.data==undefined){
        setProductAll((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
     }  if(res.data.Index==undefined){
      setProductAll((res.data.data).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
    
     }
        
    })
    
    
}
if(disword1){
    axios.get(BASE_URL + `product/page/0/100/asc/id/${disword1}`, { headers: authHeader() })
    .then(res => {
  if(res.data.data==undefined){
    setProductAll1((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
 }  if(res.data.Index==undefined){
  setProductAll1((res.data.data).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));

 }
    
})


}
    }, [disword,disword1])
    const deleteItem = (e) => {
        setproduct({"id":e});
        if(e){
            setstatus(true);
        }
        axios.get(BASE_URL + 'product/getById/' + e, { headers: authHeader() }).then(res => {
            console.log('mello',res.data)
            setrate(res.data.dlp);
        })


    }

    const deleteItem1 = (index, evnt) => {
        setdisword1(encodeURIComponent(' '))
        const { label, value } = evnt;
        const name = "product";
        const rowsInput = [...add];
        rowsInput[index][name] = {"id":value};
        setAdd(rowsInput);
        axios.get(BASE_URL + 'product/getById/' + evnt.value,{headers:authHeader()}).then(res=>{
            const name = "rate";
            const rowsInput = [...add];
            rowsInput[index][name] = res.data.dlp;
            setAdd(rowsInput);

            const name1 = "uomSecondary";
            const rowsInput1 = [...add];
            rowsInput1[index][name1] = res.data.uomSecondary;
            setAdd(rowsInput1);


            const name11 = "productType";
            const rowsInput11 = [...add];
            rowsInput11[index][name11] = res.data.productType;
            setAdd(rowsInput11);
        })
    }

    const deleteTableRows = (index) => {
        const remainingitem = add.filter(obj => obj.id !== index)
        setAdd(remainingitem);
    }

    const qtychange = (index,e,row) => {
        console.log(row)

        const { name, value } = e.target;
        const rowsInput = [...add];
        rowsInput[index][name] = value;
        setAdd(rowsInput);
        if(e&&row.productType=='kgproduct'){
            let aa = e.target.value;
            let ab = row.uomSecondary;
            let name ="qtykg";
            const rowsInput = [...add];
            rowsInput[index][name] = ((aa*ab).toFixed(2));
            setAdd(rowsInput);
        }
        if(e){
           if(row.uomrate=='pcs'){
            let aa = e.target.value;
            let ab = row.rate;
            let name ="amount";
            const rowsInput = [...add];
            rowsInput[index][name] = (aa*ab);
            setAdd(rowsInput);
           }if(row.uomrate=='kg'&&row.productType=='kgproduct'){
            // qtykg
            let aa = row.qtykg;
            let ab = row.rate;
            let name ="amount";
            const rowsInput = [...add];
            rowsInput[index][name] = (aa*ab);
            setAdd(rowsInput);
            
           }

        }
    }


    // qtykgchange
const qtykgchange=(index,e,row)=>{
    console.log(row)

    const { name, value } = e.target;
    const rowsInput = [...add];
    rowsInput[index][name] = value;
    setAdd(rowsInput);
    if(e){
        let aa = e.target.value;
        let ab = row.uomSecondary;
        let name ="qty";
        const rowsInput = [...add];
        rowsInput[index][name] = Math.round(aa / ab);
        setAdd(rowsInput);

        
    }
    if(e){
        if(row.uomrate=='pcs'){
         let aa =row.qty;
         let ab = row.rate;
         let name ="amount";
         const rowsInput = [...add];
         rowsInput[index][name] = (aa*ab);
         setAdd(rowsInput);
        }
        if(row.uomrate=='kg'&&row.productType=='kgproduct'){
         // qtykg
         let aa = e.target.value;
         let ab = row.rate;
         let name ="amount";
         const rowsInput = [...add];
         rowsInput[index][name] = (aa*ab);
         setAdd(rowsInput);
         
        }

     }
}

    const onChangeuom = (index,e)=>{
        const { name, value } = e.target;
        const rowsInput = [...add];
        rowsInput[index][name] = value;
        setAdd(rowsInput);
    }

const onchangerate=(index,e,row)=>{
  const { name, value } = e.target;
    const rowsInput = [...add];
    rowsInput[index][name] = value;
    setAdd(rowsInput);
   
        if(e){
            if(row.uomrate=='pcs'){
             let aa = e.target.value;
             let ab = row.qty;
             let name ="amount";
             const rowsInput = [...add];
             rowsInput[index][name] = (aa*ab);
             setAdd(rowsInput);
            }if(row.uomrate=='kg'){
             // qtykg
             let aa =  e.target.value;
             let ab = row.qtykg;
             let name ="amount";
             const rowsInput = [...add];
             rowsInput[index][name] = (aa*ab);
             setAdd(rowsInput);
             
            }
 
       
    }
}
    const onChangeuom1 = (index,e,row)=>{
        const { name, value } = e.target;
        const rowsInput = [...add];
        rowsInput[index][name] = value;
        setAdd(rowsInput);

        if(e.target.value=='pcs'){
            let aa = e.target.value;
            let ab = row.qty;
            let rate=row.rate;
            let name ="amount";
            const rowsInput = [...add];
            rowsInput[index][name] =rate*ab;
            setAdd(rowsInput);
        } if(e.target.value=='kg'){
            let aa = e.target.value;
            let ab = row.qtykg;
            let rate=row.rate;
            let name ="amount";
            const rowsInput = [...add];
            rowsInput[index][name] =rate*ab;
            setAdd(rowsInput);
        }
    }



    const navigate = useNavigate();

    const submitform = () => {
        setIsLoading(true); 
console.log(add)
        let bomItems = add.map(user=>(
            {"amount":user.amount,
            "product":user.product,
            "qty":Number(user.qty),
            "kg":Number(user.qtykg),
            "rate":Number(user.rate),
            "uom":user.uomqty,
            "uom2":user.uomrate,
        
        }
        ));
        console.log('mello',{bomItems})
        axios.post(BASE_URL + 'bom/',{product,rate,description,bomItems},{headers:authHeader()}).then(res=>{
            console.log('mello',res.data)
            navigate("/BOM/Table/1")
        }) .catch(error => {
            console.error('Error adding  :', error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
                  <li id="typer">You must be filling something incorrectly or
      </li>
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
      
          })
          .finally(() => {
            setIsLoading(false); 
          });
    }

    return (
        <MDBCard className="company">
            <h1>Create Bill Of Material</h1>
            <Link to='/BOM/Table/1' className="btn btn-primary">Back</Link>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Product Name</Form.Label>
                        <Select options={productAll} onChange={(options) => deleteItem(options.value)} onInputChange={(inputValue) => setdisword(inputValue)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Rate</Form.Label>
                        <Form.Control type="text" value={rate} name="rate" readOnly />
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Description</Form.Label>
                        <Form.Control as="textarea" rows={3} name='description' onChange={(e) => setdescription(e.target.value)} placeholder='Enter' />
                    </Form.Group>
                </Row>
            </Form>
            <table>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Product&nbsp;Name</th>
                        <th>UOM</th>
                        <th>Qty(PCS)</th>
                        <th>Qty(Kg)</th>
                        <th>Rate</th>
                        <th>UOM</th>
                        <th>Amount</th>
                        <th><button className="btn btn-outline-success" onClick={AddRow} >+</button></th>
                    </tr>
                </thead>
                <tbody>
                    {add.map((row, index) => (
                        <tr key={row.id}>
                            <td>{row.id}</td>
                            <td><Form.Group as={Col} md="12" controlId="formBasicName">
                                <Select options={productAll1} onChange={(options) => deleteItem1(index, options)} onInputChange={(inputValue) => setdisword1(inputValue)} />
                            </Form.Group></td>
                            <td>
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Select onChange={(e) => onChangeuom(index,e)} name="uomqty">
                                        <option>Select</option>
                                        <option value={'pcs'}>PCS</option>
                                  {row.productType=='kgproduct'?<option value={'kg'}>KG</option>:""}
                                    </Form.Select>
                                </Form.Group>
                            </td>
                          
                            <td>
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                <Form.Control type="number" disabled={row.uomqty === 'kg'} value={row.qty} min="0" name="qty" size="sm" onChange={(e)=>qtychange(index,e,row)} requied />
                            </Form.Group>
                            </td>
                            <td>
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                <Form.Control type="number" disabled={row.uomqty === 'pcs'} value={row.qtykg} min="0" name="qtykg" size="sm" onChange={(e)=>qtykgchange(index,e,row)} requied  />
                            </Form.Group>
                            </td>
                            <td>
                            <Form.Group as={Col} md="12" controlId="formBasicName">
                                <Form.Control type="number" min="0" value={row.rate} onChange={(e)=>onchangerate(index,e,row)} name="rate" size="sm"   requied />
                            </Form.Group>
                            </td>
                            <td>
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Select onChange={(e) => onChangeuom1(index,e,row)} name="uomrate">
                                        <option>Select</option>
                                        <option value={'pcs'}>PCS</option>
                                        {row.productType=='kgproduct'?<option value={'kg'}>KG</option>:""}
                                        </Form.Select>
                                </Form.Group>
                            </td>
                          
                            <td>
                            <Form.Group as={Col} md="12" controlId="formBasicName">
                                <Form.Control type="number" min="0" value={row.amount} name="amount" size="sm" readOnly requied />
                            </Form.Group>
                            </td>
                            <td><button className="btn btn-outline-danger" onClick={() => (deleteTableRows(row.id))}>x</button></td>
                        </tr>
                    ))}

                </tbody>
            </table>
            {/* <Button onClick={submitform}>Submit</Button> */}
          <div className="row">
            <div className="col">
            <Button variant="primary" style={{width:'auto'}} onClick={submitform} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
            </div>
          </div>
        </MDBCard>
    )
}