import React from "react";
import Datatable from "../../services/DataTable";

function Groupn5IndexTable() {
  let header = [
    { label: "Id", field: "id" },
    { label: "Title", field: "title" },
    {
      label: "Action",
      field: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-eye"
        >
          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
          <circle cx="12" cy="12" r="3" />
        </svg>
      ),
    },
  ];
  return <Datatable header={header} tableTitle={"Group 5"} apiField={"groupn5"} addButton={"Add Group 5"} createRoute={"/create/group5"} updateRoute={"/view/group5"} viewRoute={"/view/group5"} />;
}

export default Groupn5IndexTable;
