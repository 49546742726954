import React from 'react'
import { Mydatatable } from '../Product/Mydatatable';
import { HiOutlineDotsVertical } from "react-icons/hi";
const Newpo = () => {

    let header = [
        { "label": "Id", "field": "id" },
        { "label": "Primary Order Id", "field": "work_Order_Id" },
        // { "label": "Primary Order Date", "field": "work_Order_Date" },
        { "label": "Trade Name", "field": "trade_Name" },
        { "label": "State Name", "field": "state_Name" },
        // state_Name
        { "label": "RSM", "field": "rsmstaffname" },
        // rsmstaffname
        { "label": "Net Amount", "field": "net_Value" },
        { "label": "GST", "field": "tax_Amount" },
        { "label": "Gross Total", "field": "gross_Total" },
     { "label": "Remarks", "field": "remarks" },
     { "label": "Created Date", "field": "createddate" },
     { "label": "Created Time", "field": "createdtime" },
     { "label": "Created By", "field": "createbyname" },
     { "label": "Updated Date", "field": "updateddate" },
     { "label": "Updated Time", "field": "updatedtime" },
     { "label": "Updated By", "field": "updatedbyname" },
        { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> },
         { "label": <HiOutlineDotsVertical />, "field":'hideOrshow'},
      ]; 
      
      const sumofkeys=[{"first":'tax_Amount'},{"first":'net_Value'}, {"first":'gross_Total'}]
      
      
  return (
    <Mydatatable
    head={header}
    title={"Primary Order"}
    axiostabledata={'primaryworkorder'}
    Add={"Add Primary "}
    // Edit={"/pedit"}
    Create={'/PrimaryOrderCreate'}
    Edit={"/PrimaryOrderView"}
    View={"/PrimaryOrderView"}
     Sumofkeys={sumofkeys}
    />


  )
}

export default Newpo