import { CHANGE_COST_PRIMARYORDER, CHANGE_DISCOUNT1_PRIMARYORDER, CHANGE_MEASUREMENT_PRIMARYORDER, CHANGE_PER_PRIMARYORDER, CHANGE_QTY_PRIMARYORDER, CHANGE_SQPB_PRIMARYORDER, CHANGE_TEXT_PRIMARYORDER, EMPTY_DATA_PRIMARYORDER, GET_DATA_PRIMARYORDER, REMOVE_TO_CART_PRIMARYORDER } from "../constant";
import { GET_PRODUCT_PRIMARYORDER } from "../constant";

export const primaryorder = (data = [], action) => {

    switch(action.type){
        case GET_DATA_PRIMARYORDER:
            let allData = action.data
            let newData = action.data2
            // let itemData = allData.map(user=>(user.workOderItems))
          
            let itemValue = []
            let ss = allData.map(user=>{
                user.map(item=>{
                    itemValue.push(item)
                    
                })
            })
            
            let itemData = []
            let discountData = itemValue.forEach(Element=>{
                console.log(Element);
                
                if (Element.productType !== "kgproduct") {
                   itemData.push( {
                            wItemId: Element.wItemId,
                            productId: Element.productId,
                            mrp: Element.mrp,
                            qty: Element.qty,
                            total: Element.total,
                            gstvalue: Element.gstvalue,
                            amount: Element.amount,
                            text: Element.text,
                            cgst: Element.cgst,
                            igst: Element.igst,
                            sgst: Element.sgst,
                            unitofmeasurement: Element.unitofmeasurement,
                            measurement: Element.measurement,
                            dlp: Element.dlp,
                            productType: Element.productType,
                            discount: newData.boxProductDiscount,
                            discount1:  newData.schemeboxProductDiscount,
                            grossamount:Element.grossamount,
                            standardQtyPerBox : Element.standardQtyPerBox,
                            productName:Element.productName,
                            uomSecondary:Element.uomSecondary
                        });
                }
                else if (Element.productType == "kgproduct") {
                    itemData.push( {
                        wItemId: Element.wItemId,
                        productId: Element.productId,
                        mrp: Element.mrp,
                        qty: Element.qty,
                        total: Element.total,
                        gstvalue: Element.gstvalue,
                        amount: Element.amount,
                        text: Element.text,
                        cgst: Element.cgst,
                        igst: Element.igst,
                        sgst: Element.sgst,
                        unitofmeasurement: Element.unitofmeasurement,
                        measurement: Element.measurement,
                        dlp: Element.dlp,
                        productType: Element.productType,
                        discount: newData.kgProductDiscount,
                        discount1:  newData.schemekgProductDiscount,
                        grossamount:Element.grossamount,
                        standardQtyPerBox : Element.standardQtyPerBox,
                        productName:Element.productName,
                        uomSecondary:Element.uomSecondary

                    });
                }
              
              
                
               
            })
            let result = itemData.reduce((a, c) => {
                let filtered = a.filter(el => el.productId === c.productId);
                if(filtered.length > 0){
                    a[a.indexOf(filtered[0])].qty += +c.qty;
                    a[a.indexOf(filtered[0])].total += +c.total;
                    a[a.indexOf(filtered[0])].amount += +c.amount;
                    a[a.indexOf(filtered[0])].measurement += +c.measurement;
                    a[a.indexOf(filtered[0])].grossamount += +c.grossamount;
                    
                }else{
                    a.push(c);
                }
                return a;
            }, []);
               
            // let result = Object.values(itemData.reduce((c, {productId,qty,amount,dlp,total,measurement,discount,text,igst,cgst,sgst,unitofmeasurement,discount1,grossamount,gstvalue}) => {
                 
            //     c[productId] = c[productId] || {productId,qty,amount,dlp,total,gstvalue,discount,text,igst,cgst,sgst,unitofmeasurement,measurement,discount1,grossamount: 0};
            //     c[productId].qty = qty;
            //     c[productId].total += total;
            //     c[productId].gstvalue += gstvalue;
            //     c[productId].amount += amount;
            //     c[productId].discount = discount;
            //     c[productId].text = text;
            //     c[productId].igst = igst;
            //     c[productId].cgst = cgst;
            //     c[productId].sgst = sgst;
            //     c[productId].unitofmeasurement = unitofmeasurement;
            //     c[productId].measurement += measurement;
            //     c[productId].dlp = dlp;
            //     c[productId].discount1 = discount1;
            //     c[productId].grossamount +=grossamount;
               
                
            //    return c
            //   }, {}));
            //    console.log("mello",result)
            //    console.log("mello",itemData)
              let newData2 = []
              
          let newData1 = result.map(user=>{
            if(user.productId){
                
                // if((user.standardQtyPerBox % user.qty) == 0){
                //     console.log("mello",user.standardQtyPerBox,user.qty)
                // }
                if(user.discount > 0 || user.discount1 > 0 ){


                let quantity_placed = "";
                let quantity_placed_kg = "";
                   let a = user.measurement/user.standardQtyPerBox;
                   if( (Number(Math.trunc(a))* Number(user.standardQtyPerBox)) == user.measurement){
                    if(user.unitofmeasurement == "box"){
                        quantity_placed= Number(user.measurement);
                        quantity_placed_kg= '';
                    }else {
                        quantity_placed= Number(user.measurement);
                        quantity_placed_kg= (Number(user.measurement)* Number(user.uomSecondary));
                    }
                    
                   } else{
                    if(user.unitofmeasurement == "box"){
                        quantity_placed = (Number(Math.trunc(a)+1)* Number(user.standardQtyPerBox))
                        quantity_placed_kg = '';
                    } else {
                        quantity_placed = (Number(Math.trunc(a)+1)* Number(user.standardQtyPerBox))
                        quantity_placed_kg = (Number((Number(Math.trunc(a)+1)* Number(user.standardQtyPerBox))) * Number(user.uomSecondary)).toFixed(2)
                    }
                    
                   }
                     
                   let discount2 = "";
                   let totalAmount = '';
                   if( (Number(Math.trunc(a))* Number(user.standardQtyPerBox)) == user.measurement){
                    if(user.unitofmeasurement == "box"){
                         discount2 = ((Number(quantity_placed) * Number(user.dlp) * Number(user.discount))/100)
                     totalAmount = ((Number(quantity_placed) * Number(user.dlp))- discount2).toFixed(2);
                    }else {
                        discount2 = ((Number(quantity_placed_kg) * Number(user.dlp) * Number(user.discount))/100)
                        totalAmount = ((Number(quantity_placed_kg) * Number(user.dlp))- discount2).toFixed(2)
                        
                    }
                    
                   } else{
                    if(user.unitofmeasurement == "box"){
                        discount2 = ((Number(quantity_placed) * Number(user.dlp) * Number(user.discount))/100)
                        totalAmount = ((Number(quantity_placed) * Number(user.dlp))- discount2).toFixed(2);
                    } else {
                        discount2 = ((Number(quantity_placed_kg) * Number(user.dlp) * Number(user.discount))/100)
                        totalAmount = ((Number(quantity_placed_kg) * Number(user.dlp))- discount2).toFixed(2);
                    }
                    
                   }
                  
                let schemediscount = ((Number(totalAmount) * Number(user.discount1))/100)
                let totaldata = (Number(totalAmount) - Number(schemediscount))
                let gst = (((Number(totaldata)/Number(100)) * Number(user.igst))).toFixed(2)
                let NetAmount = (Number(gst) + Number(totaldata)).toFixed(2)
              
                
                newData2.push( {
                    wItemId:user.wItemId,
                    id:user.wItemId,
                    productId: user.productId,/* */
                    mrp: user.mrp,
                    qty: user.qty,/* */
                    netAmount: totaldata,/* */
                    gstvalue: gst,
                    // amount: user.amount,/* */
                    text: user.text,
                    igst: user.igst,
                    unitofmeasurement: user.unitofmeasurement,
                    measurement: user.measurement,/* */
                    dlp: user.dlp,/* */
                    productType: user.productType,
                    discount: user.discount,
                    discount1:  user.discount1,
                    grossamount:totalAmount,
                    total : NetAmount,
                    productName:user.productName,
                    standardQtyPerBox:user.standardQtyPerBox,
                    uomSecondary:user.uomSecondary,
                    quantity_placed : quantity_placed,
                    quantity_placed_kg : quantity_placed_kg
                });
            }
            else{
           
                let quantity_placed = "";
                let quantity_placed_kg = "";
              
                let a = user.measurement/user.standardQtyPerBox;
                if( (Number(Math.trunc(a))* Number(user.standardQtyPerBox)) == user.measurement){
                    if(user.unitofmeasurement == "box"){
                        quantity_placed= Number(user.measurement);
                        quantity_placed_kg= '';
                    }else {
                        quantity_placed= Number(user.measurement);
                        quantity_placed_kg= (Number(user.measurement)* Number(user.uomSecondary));
                    }
                } else{
                    if(user.unitofmeasurement == "box"){
                        quantity_placed = (Number(Math.trunc(a)+1)* Number(user.standardQtyPerBox))
                        quantity_placed_kg = '';
                    } else {
                        quantity_placed = (Number(Math.trunc(a)+1)* Number(user.standardQtyPerBox))
                        quantity_placed_kg = (Number((Number(Math.trunc(a)+1)* Number(user.standardQtyPerBox))) * Number(user.uomSecondary)).toFixed(2)
                    }
                }

                let netamountvalue = '';
                 if( (Number(Math.trunc(a))* Number(user.standardQtyPerBox)) == user.measurement){
                    if(user.unitofmeasurement == "box"){
                        netamountvalue = (Number(quantity_placed) * Number(user.dlp)).toFixed(2);
                    }else {
                         netamountvalue = (Number(quantity_placed_kg) * Number(user.dlp)).toFixed(2);
                    }
                } else{
                    if(user.unitofmeasurement == "box"){
                     netamountvalue = (Number(quantity_placed) * Number(user.dlp)).toFixed(2);
                    } else {
                        netamountvalue = (Number(quantity_placed_kg) * Number(user.dlp)).toFixed(2);
                    }
                }
                
                let gst = (((Number(netamountvalue)/Number(100)) * Number(user.igst))).toFixed(2);
                let NetAmount = (Number(gst) + Number(netamountvalue)).toFixed(2);

                newData2.push( {
                    wItemId:user.wItemId,
                    id:user.wItemId,
                    productId: user.productId,/* */
                    mrp: user.mrp,
                    qty: user.qty,/* */
                    netAmount: netamountvalue,/* */
                    gstvalue: gst,
                    // amount: user.amount,/* */
                    text: user.text,
                    igst: user.igst,
                    unitofmeasurement: user.unitofmeasurement,
                    measurement: user.measurement,/* */
                    dlp: user.dlp,/* */
                    productType: user.productType,
                    discount: user.discount,
                    discount1:  user.discount1,
                    grossamount:netamountvalue,
                    total : NetAmount,
                    productName:user.productName,
                    standardQtyPerBox:user.standardQtyPerBox,
                    uomSecondary:user.uomSecondary,
                    quantity_placed : quantity_placed,
                    quantity_placed_kg : quantity_placed_kg

                });
            }
                // console.log("mello",totaldata,gst,user.igst,user.grossamount)
            }
          })

          return newData2;
        //   console.log("mello",newData2)


        case GET_PRODUCT_PRIMARYORDER:
            let newData3 = action.data2;
            let dataNew = action.data;

            function discountData1() {
                if (dataNew.productType == "boxproduct") {
                    return ({ "ProductDiscount": newData3.boxProductDiscount, "schemeDiscount": newData3.schemeboxProductDiscount });
                }
                else if (dataNew.productType == "kgproduct") {
                    return ({ "ProductDiscount": newData3.kgProductDiscount, "schemeDiscount": newData3.schemekgProductDiscount });
                }
                else if (dataNew.productType == "corporateproduct") {
                    return ({ "ProductDiscount": newData3.corporaetProductDiscount, "schemeDiscount": newData3.schemecorporateProductDiscount });
                }
                else if (dataNew.productType == "cookerproduct") {
                    return ({ "ProductDiscount": newData3.cookerProductDiscount, "schemeDiscount": newData3.schemecookerProductDiscount });
                }
                else if (dataNew.productType == "noshproduct") {
                    return ({ "ProductDiscount": newData3.noshProductDiscount, "schemeDiscount": newData3.schemenoshProductDisocunt });
                }
            }

            let aa = discountData1();
            const item = {
                id: action.data.id,
                brand: action.data.brand,
                capacity: action.data.capacity,
                category: action.data.category,
                cgst: action.data.cgst,
                cgstLedger: action.data.cgstLedger,
                diameter: action.data.diameter,
                eanCode: action.data.eanCode,
                hsnCode: action.data.hsnCode,
                igst: action.data.igst,
                igstLedger: action.data.igstLedger,
                dlp: action.data.dlp,
                productName: action.data.productName,
                productType: action.data.productType,
                standardQtyPerBox : action.data.standardQtyPerBox,
                sgst: action.data.sgst,
                sgstLedger: action.data.sgstLedger,
                shortName: action.data.shortName,
                uomPrimary: action.data.uomPrimary,
                uomSecondary: action.data.uomSecondary,
                discount: aa.ProductDiscount,
                discount1: aa.schemeDiscount,
                unitofmeasurement: action.data.unitofmeasurement == undefined ?action.data.productType !== "kgproduct" ? "box" :'' :action.data.unitofmeasurement
            }
            // console.log("mello",item)
            return [item, ...data];



            case CHANGE_MEASUREMENT_PRIMARYORDER:

            let newCart3 = [...data]
            let itemIndex3 = newCart3.findIndex(obj => obj.id === action.item.id)
            let currItem3 = data[itemIndex3]
            currItem3.unitofmeasurement = action.data
            data[itemIndex3] = currItem3



            return [...data];
            
            case CHANGE_QTY_PRIMARYORDER:

            let newCart4 = [...data]
            let itemIndex4 = newCart4.findIndex(obj => obj.id === action.item.id)
            let currItem4 = data[itemIndex4]
        // currItem4.measurement = Number(action.data)

        if (action.data2 == "kg") {

            if (currItem4.discount > 0 || currItem4.discount1 > 0) {
                if (action.data == "") {
                    currItem4.qty = '';
                    currItem4.measurement = '';
                } else {
             
                    currItem4.qty = (action.data)
                    let aa = Number(currItem4.qty);
                    let ab = (Number(aa) / currItem4.uomSecondary)
                    currItem4.measurement = Math.round(ab);

                    let a = (Number(currItem4.measurement)/Number(currItem4.standardQtyPerBox));
                    if( (Number(Math.trunc(a))* Number(currItem4.standardQtyPerBox)) == Number(currItem4.measurement)){
                     currItem4.quantity_placed= Number(Number(currItem4.measurement))
                    } else{
                        currItem4.quantity_placed = (Number(Math.trunc(a)+1)* Number(currItem4.standardQtyPerBox))
                    }
                    currItem4.quantity_placed_kg = (Number(currItem4.quantity_placed) * Number(currItem4.uomSecondary)).toFixed(2)
                }
                let discount2 = Number(currItem4.quantity_placed_kg) * Number(currItem4.dlp) * (Number(currItem4.discount) / 100)
                    let totaAmount = (Number(currItem4.quantity_placed_kg) * Number(currItem4.dlp) - Number(discount2))
                    currItem4.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem4.discount1)) / 100)
                    let NetAmount = (Number(totaAmount) - Number(schemediscount));
                    currItem4.netAmount = (Number(NetAmount)).toFixed(2)
                    // console.log("mello5",netAmount,currItem4.NetAmount)
                    currItem4.productId = currItem4.id
                    // currItem4.NetAmount = parseInt(currItem4.quantity_placed_kg) * parseInt(currItem4.dlp)
                    currItem4.gstvalue = ((Number(currItem4.netAmount)/Number(100))*(Number(currItem4.igst))).toFixed(2)
                    currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2)
                    data[itemIndex4] = currItem4
            } else {

                if (action.data == "") {
                    currItem4.qty = '';
                    currItem4.measurement = '';
                } else {
                    currItem4.qty = (action.data)
                    let aa = Number(currItem4.qty);
                    let ab = (Number(aa) / currItem4.uomSecondary)
                    currItem4.measurement = Math.round(ab);

                    let a = (Number(currItem4.measurement)/Number(currItem4.standardQtyPerBox));
                    if( (Number(Math.trunc(a))* Number(currItem4.standardQtyPerBox)) == Number(currItem4.measurement)){
                     currItem4.quantity_placed= Number(Number(currItem4.measurement))
                    } else{
                        currItem4.quantity_placed = (Number(Math.trunc(a)+1)* Number(currItem4.standardQtyPerBox))
                    }
                    currItem4.quantity_placed_kg = (Number(currItem4.quantity_placed) * Number(currItem4.uomSecondary)).toFixed(2)

                }
                currItem4.discount = Number(0)
                currItem4.productId = currItem4.id
                currItem4.grossamount = (Number(currItem4.quantity_placed_kg) * Number(currItem4.dlp)).toFixed(2);
                let NetAmount = (Number(currItem4.quantity_placed_kg) * Number(currItem4.dlp))
                currItem4.netAmount = (Number(NetAmount)).toFixed(2)
                currItem4.gstvalue = ((Number(currItem4.netAmount)/Number(100))*(Number(currItem4.igst))).toFixed(2)
                currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2)
                data[itemIndex4] = currItem4


            }
        }



            return [...data];


        case CHANGE_PER_PRIMARYORDER:

            let newCart = [...data]
            let itemIndex = newCart.findIndex(obj => obj.id === action.item.id)
            let currItem = data[itemIndex]

            if (action.data2 == "pcs") {

                if (currItem.discount > 0 || currItem.discount1 > 0) {
                    if (action.data == "") {
                        currItem.measurement = Number(0)
                        currItem.qty = currItem.measurement
                    } else {
                        currItem.measurement = Number(action.data)
                        let aa = Number(currItem.measurement)
                        currItem.qty = (Number(aa) * Number(currItem.uomSecondary))

                        let a = (Number(action.data)/Number(currItem.standardQtyPerBox));
                        if( (Number(Math.trunc(a))* Number(currItem.standardQtyPerBox)) == Number(action.data)){
                         currItem.quantity_placed= Number(Number(currItem.measurement))
                        } else{
                            currItem.quantity_placed = (Number(Math.trunc(a)+1)* Number(currItem.standardQtyPerBox))
                        }
                        currItem.quantity_placed_kg = (Number(currItem.quantity_placed) * Number(currItem.uomSecondary)).toFixed(2)
                    }

                    let discount2 = Number(currItem.quantity_placed_kg) * Number(currItem.dlp) * (Number(currItem.discount) / 100)
                    let totaAmount = Number(currItem.quantity_placed_kg) * Number(currItem.dlp) - Number(discount2);
                    currItem.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem.discount1)) / 100)
                    let NetAmount = (Number(totaAmount) - Number(schemediscount))
                    currItem.netAmount= (Number(NetAmount)).toFixed(2)
                    currItem.productId = currItem.id
                    // currItem.NetAmount = parseInt(currItem.quantity_placed_kg) * parseInt(currItem.dlp)
                    currItem.gstvalue = ((Number(currItem.netAmount)/Number(100))*(Number(currItem.igst))).toFixed(2)
                    currItem.total = (Number(currItem.gstvalue) + Number(currItem.netAmount)).toFixed(2)
                    data[itemIndex] = currItem

                } else {

                    if (action.data == "") {
                        currItem.measurement = Number(0)
                        currItem.qty = currItem.measurement
                    } else {
                        currItem.measurement = Number(action.data)
                        let aa = Number(currItem.measurement)
                        currItem.qty = (Number(aa) * (Number(currItem.uomSecondary)));

                        let a = (Number(action.data)/Number(currItem.standardQtyPerBox));
                        if( (Number(Math.trunc(a))* Number(currItem.standardQtyPerBox)) == Number(action.data)){
                         currItem.quantity_placed= Number(Number(currItem.measurement))
                        } else{
                            currItem.quantity_placed = (Number(Math.trunc(a)+1)* Number(currItem.standardQtyPerBox))
                        }
                        currItem.quantity_placed_kg = (Number(currItem.quantity_placed) * Number(currItem.uomSecondary)).toFixed(2)

                    }
                    currItem.discount = Number(0)
                    currItem.productId = currItem.id
                    currItem.grossamount = (Number(currItem.quantity_placed_kg) * Number(currItem.dlp)).toFixed(2);
                    let NetAmount = (Number(currItem.quantity_placed_kg) * Number(currItem.dlp))
                    currItem.netAmount = (Number(NetAmount)).toFixed(2)
                    currItem.gstvalue = ((Number(currItem.netAmount)/Number(100))*(Number(currItem.igst))).toFixed(2)
                    currItem.total = (Number(currItem.gstvalue) + Number(currItem.netAmount)).toFixed(2)
                    data[itemIndex] = currItem


                }

            }
            if (currItem.unitofmeasurement == "box") {

                if (currItem.discount > 0 || currItem.discount1 > 0) {
                    if (action.data == "") {
                        currItem.measurement = Number(0)
                    } else {
                        
                        currItem.measurement = Number(action.data)

                        let a = (Number(action.data)/Number(currItem.standardQtyPerBox));
                        if( (Number(Math.trunc(a))* Number(currItem.standardQtyPerBox)) == Number(action.data)){
                         currItem.quantity_placed= Number(Number(action.data))
                        } else{
                            currItem.quantity_placed = (Number(Math.trunc(a)+1)* Number(currItem.standardQtyPerBox))
                        }
                        currItem.quantity_placed_kg = '';
                    }
                       
                    let discount2 = Number(currItem.quantity_placed) * Number(currItem.dlp) * (Number(currItem.discount) / 100)
                    let totaAmount = Number(currItem.quantity_placed) * Number(currItem.dlp) - Number(discount2);
                    currItem.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem.discount1)) / 100)
                    let NetAmount = (Number(totaAmount) - Number(schemediscount))
                    currItem.netAmount = (Number(NetAmount)).toFixed(2)
                    // console.log("mello5",currItem.grossamount)
                    currItem.productId = currItem.id
                    // currItem.NetAmount = parseInt(currItem.measurement) * parseInt(currItem.dlp)
                    currItem.gstvalue = ((Number(currItem.netAmount)/Number(100))*(Number(currItem.igst))).toFixed(2)
                    currItem.total = (Number(currItem.gstvalue) + Number(currItem.netAmount)).toFixed(2)
                    data[itemIndex] = currItem

                } else {

                    if (action.data == "") {
                        currItem.measurement = Number(0)
                    } else {
                        currItem.measurement = Number(action.data)

                        let a = (Number(action.data)/Number(currItem.standardQtyPerBox));
                        if( (Number(Math.trunc(a))* Number(currItem.standardQtyPerBox)) == Number(action.data)){
                         currItem.quantity_placed= Number(Number(action.data))
                        } else{
                            currItem.quantity_placed = (Number(Math.trunc(a)+1)* Number(currItem.standardQtyPerBox))
                        }
                        currItem.quantity_placed_kg = '';

                    }
                    currItem.discount = Number(0)
                    currItem.productId = currItem.id
                    currItem.grossamount = (Number(currItem.quantity_placed) * Number(currItem.dlp)).toFixed(2);
                    let NetAmount = (Number(currItem.quantity_placed) * Number(currItem.dlp))
                    currItem.netAmount = (Number(NetAmount)).toFixed(2)
                    currItem.gstvalue = ((Number(currItem.netAmount)/Number(100))*(Number(currItem.igst))).toFixed(2)
                    currItem.total = (Number(currItem.gstvalue) + Number(currItem.netAmount)).toFixed(2)
                    data[itemIndex] = currItem


                }

            }
           

            return [...data];


        case REMOVE_TO_CART_PRIMARYORDER:

            const remainingitem = data.filter((item) => item.id !== action.data);
            return [...remainingitem];


        case CHANGE_COST_PRIMARYORDER:

            let newCart1 = [...data]
            let itemIndex1 = newCart1.findIndex(obj => obj.id === action.item.id)
            let currItem1 = data[itemIndex1]

            if(currItem1.sqpb == null){
            if (currItem1.unitofmeasurement == "box") {
            if (currItem1.discount > 0 || currItem1.discount1 > 0) {

                if (action.data == "") {
                    currItem1.dlp = ""
                } else {
                    currItem1.dlp = Number(action.data)
                }
                // currItem1.dlp = parseInt(action.data)
                
                let a = (Number(currItem1.measurement)/Number(currItem1.standardQtyPerBox));
                        if( (Number(Math.trunc(a))* Number(currItem1.standardQtyPerBox)) == Number(currItem1.measurement)){
                         currItem1.quantity_placed= Number(Number(currItem1.measurement))
                        } else{
                            currItem1.quantity_placed = (Number(Math.trunc(a)+1)* Number(currItem1.standardQtyPerBox))
                        }
                        currItem1.quantity_placed_kg = '';

                let discount3 = Number(currItem1.quantity_placed) * Number(currItem1.dlp) * (Number(currItem1.discount) / 100)
                let totaAmount = Number(currItem1.quantity_placed) * Number(currItem1.dlp) - Number(discount3)
                currItem1.grossamount = (Number(totaAmount)).toFixed(2);
                let schemediscount = ((Number(totaAmount) * Number(currItem1.discount1)) / 100)
                let NetAmount = (Number(totaAmount) - Number(schemediscount))
                currItem1.netAmount = (Number(NetAmount)).toFixed(2)
                // currItem1.NetAmount = parseInt(currItem1.quantity_placed_kg) * parseInt(currItem1.dlp)
                currItem1.gstvalue = ((Number(currItem1.netAmount)/Number(100))*(Number(currItem1.igst))).toFixed(2)
                currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2)
                data[itemIndex1] = currItem1
                return [...data];

            } else {

                if (action.data == "") {
                    currItem1.dlp = ""
                } else {
                    currItem1.dlp = Number(action.data)
                }
                // currItem1.dlp = parseInt(action.data)

                let a = (Number(currItem1.measurement)/Number(currItem1.standardQtyPerBox));
                if( (Number(Math.trunc(a))* Number(currItem1.standardQtyPerBox)) == Number(currItem1.measurement)){
                 currItem1.quantity_placed= Number(Number(currItem1.measurement))
                } else{
                    currItem1.quantity_placed = (Number(Math.trunc(a)+1)* Number(currItem1.standardQtyPerBox))
                }
                currItem1.quantity_placed_kg = '';


                currItem1.discount = Number(0)
                currItem1.grossamount = (Number(currItem1.quantity_placed) * Number(currItem1.dlp)).toFixed(2);
                let NetAmount = (Number(currItem1.quantity_placed) * Number(currItem1.dlp))
                currItem1.netAmount = (Number(NetAmount)).toFixed(2)
                currItem1.gstvalue = ((Number(currItem1.netAmount)/Number(100))*(Number(currItem1.igst))).toFixed(2)
                currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2)
                data[itemIndex1] = currItem1
                return [...data];

            }
        } /*end Box Product */
        else{
            if (currItem1.discount > 0 || currItem1.discount1 > 0) {

                if (action.data == "") {
                    currItem1.dlp = ""
                } else {
                    currItem1.dlp = Number(action.data)
                }
                // currItem1.dlp = parseInt(action.data)

                let a = (Number(currItem1.measurement)/Number(currItem1.standardQtyPerBox));
                    if( (Number(Math.trunc(a))* Number(currItem1.standardQtyPerBox)) == Number(currItem1.measurement)){
                     currItem1.quantity_placed= Number(Number(currItem1.measurement))
                    } else{
                        currItem1.quantity_placed = (Number(Math.trunc(a)+1)* Number(currItem1.standardQtyPerBox))
                    }
                    currItem1.quantity_placed_kg = (Number(currItem1.quantity_placed) * Number(currItem1.uomSecondary)).toFixed(2)

                let discount3 = Number(currItem1.quantity_placed_kg) * Number(currItem1.dlp) * (Number(currItem1.discount) / 100)
                let totaAmount = Number(currItem1.quantity_placed_kg) * Number(currItem1.dlp) - Number(discount3)
                currItem1.grossamount = (Number(totaAmount)).toFixed(2);
                let schemediscount = ((Number(totaAmount) * Number(currItem1.discount1)) / 100)
                let NetAmount = (Number(totaAmount) - Number(schemediscount))
                currItem1.netAmount = (Number(NetAmount)).toFixed(2)
                // currItem1.NetAmount = parseInt(currItem1.quantity_placed_kg) * parseInt(currItem1.dlp)
                currItem1.gstvalue = ((Number(currItem1.netAmount)/Number(100))*(Number(currItem1.igst))).toFixed(2)
                currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2)
                data[itemIndex1] = currItem1
                return [...data];

            } else {

                if (action.data == "") {
                    currItem1.dlp = ""
                } else {
                    currItem1.dlp = Number(action.data)
                }
                // currItem1.dlp = parseInt(action.data)

                let a = (Number(currItem1.measurement)/Number(currItem1.standardQtyPerBox));
                    if( (Number(Math.trunc(a))* Number(currItem1.standardQtyPerBox)) == Number(currItem1.measurement)){
                     currItem1.quantity_placed= Number(Number(currItem1.measurement))
                    } else{
                        currItem1.quantity_placed = (Number(Math.trunc(a)+1)* Number(currItem1.standardQtyPerBox))
                    }
                    currItem1.quantity_placed_kg = (Number(currItem1.quantity_placed) * Number(currItem1.uomSecondary)).toFixed(2)

                currItem1.discount = Number(0)
                currItem1.grossamount = (Number(currItem1.quantity_placed_kg) * Number(currItem1.dlp)).toFixed(2);
                let NetAmount = (Number(currItem1.quantity_placed_kg) * Number(currItem1.dlp))
                currItem1.netAmount = (Number(NetAmount)).toFixed(2)
                currItem1.gstvalue = ((Number(currItem1.netAmount)/Number(100))*(Number(currItem1.igst))).toFixed(2)
                currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2)
                data[itemIndex1] = currItem1
                return [...data];

            }
        } /* end KG Product */
    } else {

        if (currItem1.unitofmeasurement == "box") {
            if (currItem1.discount > 0 || currItem1.discount1 > 0) {

                if (action.data == "") {
                    currItem1.dlp = ""
                } else {
                    currItem1.dlp = Number(action.data)
                }
                // currItem1.dlp = parseInt(action.data)
                let discount3 = Number(currItem1.sqpb) * Number(currItem1.dlp) * (Number(currItem1.discount) / 100)
            currItem1.quantity_placed_kg = '';
                let totaAmount = Number(currItem1.sqpb) * Number(currItem1.dlp) - Number(discount3)
                currItem1.grossamount = (Number(totaAmount)).toFixed(2);
                let schemediscount = ((Number(totaAmount) * Number(currItem1.discount1)) / 100)
                let NetAmount = (Number(totaAmount) - Number(schemediscount))
                currItem1.netAmount = (Number(NetAmount)).toFixed(2)
                // currItem1.NetAmount = parseInt(currItem1.measurement) * parseInt(currItem1.dlp)
                currItem1.gstvalue = ((Number(currItem1.netAmount)/Number(100))*(Number(currItem1.igst))).toFixed(2)
                currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2)
                data[itemIndex1] = currItem1
                return [...data];

            } else {

                if (action.data == "") {
                    currItem1.dlp = ""
                } else {
                    currItem1.dlp = Number(action.data)
                }
                // currItem1.dlp = parseInt(action.data)
                currItem1.discount = Number(0)
                currItem1.grossamount = (Number(currItem1.sqpb) * Number(currItem1.dlp)).toFixed(2);
            currItem1.quantity_placed_kg = '';
                let NetAmount = (Number(currItem1.sqpb) * Number(currItem1.dlp))
                currItem1.netAmount = (Number(NetAmount)).toFixed(2)
                currItem1.gstvalue = ((Number(currItem1.netAmount)/Number(100))*(Number(currItem1.igst)))
                currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount))
                data[itemIndex1] = currItem1
                return [...data];

            }
        } else{
            if (currItem1.discount > 0 || currItem1.discount1 > 0) {

                if (action.data == "") {
                    currItem1.dlp = ""
                } else {
                    currItem1.dlp = Number(action.data)
                }
                // currItem1.dlp = parseInt(action.data)
                let qtydata = (Number(currItem1.sqpb) * Number(currItem1.uomSecondary))
            currItem1.quantity_placed_kg =Number(qtydata).toFixed(2);
                let discount3 = Number(qtydata) * Number(currItem1.dlp) * (Number(currItem1.discount) / 100)
                let totaAmount = Number(qtydata) * Number(currItem1.dlp) - Number(discount3)
                currItem1.grossamount = (Number(totaAmount)).toFixed(2);
                let schemediscount = ((Number(totaAmount) * Number(currItem1.discount1)) / 100)
                let NetAmount = (Number(totaAmount) - Number(schemediscount))
                currItem1.netAmount = (Number(NetAmount)).toFixed(2)
                // currItem1.NetAmount = parseInt(currItem1.qty) * parseInt(currItem1.dlp)
                currItem1.gstvalue = ((Number(currItem1.netAmount)/Number(100))*(Number(currItem1.igst))).toFixed(2)
                currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2)
                data[itemIndex1] = currItem1
                return [...data];

            } else {

                if (action.data == "") {
                    currItem1.dlp = ""
                } else {
                    currItem1.dlp = Number(action.data)
                }
                // currItem1.dlp = parseInt(action.data)
                let qtydata = (Number(currItem1.sqpb) * Number(currItem1.uomSecondary))
            currItem1.quantity_placed_kg =Number(qtydata).toFixed(2);
                currItem1.discount = Number(0)
                currItem1.grossamount = (Number(qtydata) * Number(currItem1.dlp)).toFixed(2);
                let NetAmount = (Number(qtydata) * Number(currItem1.dlp))
                currItem1.netAmount = (Number(NetAmount)).toFixed(2)
                currItem1.gstvalue = ((Number(currItem1.netAmount)/Number(100))*(Number(currItem1.igst))).toFixed(2)
                currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2)
                data[itemIndex1] = currItem1
                return [...data];

            }
        }

    }

        case CHANGE_DISCOUNT1_PRIMARYORDER:

            let newCart2 = [...data]
            let itemIndex2 = newCart2.findIndex(obj => obj.id === action.item.id)
            let currItem2 = data[itemIndex2]

                if(currItem2.sqpb == null){
            if (currItem2.unitofmeasurement == "box") {
            if (action.data == "") {
                currItem2.discount1 = 0
            } else {
                currItem2.discount1 = Number(action.data)
            }

            // currItem1.dlp = parseInt(action.data)

            let a = (Number(currItem2.measurement)/Number(currItem2.standardQtyPerBox));
                        if( (Number(Math.trunc(a))* Number(currItem2.standardQtyPerBox)) == Number(currItem2.measurement)){
                         currItem2.quantity_placed= Number(Number(currItem2.measurement))
                        } else{
                            currItem2.quantity_placed = (Number(Math.trunc(a)+1)* Number(currItem2.standardQtyPerBox))
                        }
                        currItem2.quantity_placed_kg = '';

            let discount1 = Number(currItem2.quantity_placed) * Number(currItem2.dlp) * (Number(currItem2.discount) / 100)
            let totaAmount = Number(currItem2.quantity_placed) * Number(currItem2.dlp) - Number(discount1)
            currItem2.grossamount = (Number(totaAmount)).toFixed(2);
                let schemediscount = ((Number(totaAmount) * Number(currItem2.discount1)) / 100)
                let NetAmount = (Number(totaAmount) - Number(schemediscount))
                currItem2.netAmount = (Number(NetAmount)).toFixed(2)
                // currItem1.NetAmount = parseInt(currItem1.quantity_placed_kg) * parseInt(currItem1.dlp)
                currItem2.gstvalue = ((Number(currItem2.netAmount)/Number(100))*(Number(currItem2.igst))).toFixed(2)
            currItem2.total = (Number(currItem2.gstvalue) + Number(currItem2.netAmount)).toFixed(2)
            data[itemIndex2] = currItem2

        } /* BOX Product End */
        else{
            if (action.data == "") {
                currItem2.discount1 = 0
            } else {
                currItem2.discount1 = Number(action.data)
            }

            // currItem1.dlp = parseInt(action.data)

            let a = (Number(currItem2.measurement)/Number(currItem2.standardQtyPerBox));
                    if( (Number(Math.trunc(a))* Number(currItem2.standardQtyPerBox)) == Number(currItem2.measurement)){
                     currItem2.quantity_placed= Number(Number(currItem2.measurement))
                    } else{
                        currItem2.quantity_placed = (Number(Math.trunc(a)+1)* Number(currItem2.standardQtyPerBox))
                    }
                    currItem2.quantity_placed_kg = (Number(currItem2.quantity_placed) * Number(currItem2.uomSecondary)).toFixed(2)

            let discount1 = Number(currItem2.quantity_placed_kg) * Number(currItem2.dlp) * (Number(currItem2.discount) / 100)
            let totaAmount = Number(currItem2.quantity_placed_kg) * Number(currItem2.dlp) - Number(discount1)
            currItem2.grossamount = (Number(totaAmount)).toFixed(2);
                let schemediscount = ((Number(totaAmount) * Number(currItem2.discount1)) / 100)
                let NetAmount = (Number(totaAmount) - Number(schemediscount))
                currItem2.netAmount = (Number(NetAmount)).toFixed(2)
                // currItem1.NetAmount = parseInt(currItem1.quantity_placed_kg) * parseInt(currItem1.dlp)
                currItem2.gstvalue = ((Number(currItem2.netAmount)/Number(100))*(Number(currItem2.igst))).toFixed(2)
            currItem2.total = (Number(currItem2.gstvalue) + Number(currItem2.netAmount)).toFixed(2)
            data[itemIndex2] = currItem2
        }
    } else {

        if (currItem2.unitofmeasurement == "box") {
            if (action.data == "") {
                currItem2.discount1 = 0
            } else {
                currItem2.discount1 = Number(action.data)
            }

            // currItem1.dlp = parseInt(action.data)
            
            let discount1 = Number(currItem2.sqpb) * Number(currItem2.dlp) * (Number(currItem2.discount) / 100);
            currItem2.quantity_placed_kg = '';
            let totaAmount = Number(currItem2.sqpb) * Number(currItem2.dlp) - Number(discount1)
            currItem2.grossamount = (Number(totaAmount)).toFixed(2) ;
                let schemediscount = ((Number(totaAmount) * Number(currItem2.discount1)) / 100)
                let NetAmount = (Number(totaAmount) - Number(schemediscount))
                currItem2.netAmount = (Number(NetAmount)).toFixed(2)
                // currItem1.NetAmount = parseInt(currItem1.measurement) * parseInt(currItem1.dlp)
                currItem2.gstvalue = ((Number(currItem2.netAmount)/Number(100))*(Number(currItem2.igst))).toFixed(2)
            currItem2.total = Number(currItem2.gstvalue) + Number(currItem2.netAmount)
            data[itemIndex2] = currItem2

        } else{
            if (action.data == "") {
                currItem2.discount1 = 0
            } else {
                currItem2.discount1 = Number(action.data)
            }

            // currItem1.dlp = parseInt(action.data)
            // console.log("mello",currItem2.uomSecondary)
            let qtydata = (Number(currItem2.sqpb) * Number(currItem2.uomSecondary))
            currItem2.quantity_placed_kg =Number(qtydata).toFixed(2);
            let discount1 = Number(qtydata) * Number(currItem2.dlp) * (Number(currItem2.discount) / 100)
            let totaAmount = Number(qtydata) * Number(currItem2.dlp) - Number(discount1)
            currItem2.grossamount = (Number(totaAmount)).toFixed(2) ;
                let schemediscount = ((Number(totaAmount) * Number(currItem2.discount1)) / 100)
                let NetAmount = (Number(totaAmount) - Number(schemediscount))
                currItem2.netAmount = (Number(NetAmount)).toFixed(2)
                // currItem1.NetAmount = parseInt(currItem1.qtydata) * parseInt(currItem1.dlp)
                currItem2.gstvalue = ((Number(currItem2.netAmount)/Number(100))*(Number(currItem2.igst))).toFixed(2)
            currItem2.total = Number(currItem2.gstvalue) + Number(currItem2.netAmount)
            data[itemIndex2] = currItem2
        }

    }

            return [...data];


        case CHANGE_TEXT_PRIMARYORDER:

            let newCart5 = [...data]
            let itemIndex5 = newCart5.findIndex(obj => obj.id === action.item.id)
            let currItem5 = data[itemIndex5]

            currItem5.text = action.data
            data[itemIndex5] = currItem5
            return [...data];


        case EMPTY_DATA_PRIMARYORDER:
            // console.warn("reducer called",action)
            return [];

            case CHANGE_SQPB_PRIMARYORDER:
                let newCart6 = [...data]
            let itemIndex6 = newCart6.findIndex(obj => obj.wItemId === action.item.wItemId)
            let currItem6 = data[itemIndex6]
            currItem6.sqpb = action.data
            
            if(currItem6.unitofmeasurement == "box"){
             if(currItem6.quantity_placed){

               currItem6.quantity_placed = action.data
            if( currItem6.uomSecondary == "" || currItem6.uomSecondary == "0"){

                if (currItem6.discount > 0 || currItem6.discount1 > 0) {

                    let discount2 = Number(currItem6.sqpb) * Number(currItem6.dlp) * (Number(currItem6.discount) / 100);
                    currItem6.quantity_placed_kg = '';
                    let totaAmount = Number(currItem6.sqpb) * Number(currItem6.dlp) - Number(discount2);
                    currItem6.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem6.discount1)) / 100);
                    let NetAmount = (Number(totaAmount) - Number(schemediscount));
                    currItem6.netAmount= (Number(NetAmount)).toFixed(2)
                    currItem6.gstvalue = ((Number(currItem6.netAmount)/Number(100))*(Number(currItem6.igst))).toFixed(2)
                    currItem6.total = (Number(currItem6.gstvalue) + Number(currItem6.netAmount)).toFixed(2)
                    data[itemIndex6] = currItem6
                    
                } else {
                    currItem6.discount = Number(0);
                    currItem6.quantity_placed_kg = '';
                    currItem6.grossamount = (Number(currItem6.sqpb) * Number(currItem6.dlp)).toFixed(2);
                    let NetAmount = (Number(currItem6.sqpb) * Number(currItem6.dlp));
                    currItem6.netAmount = (Number(NetAmount)).toFixed(2);
                    currItem6.gstvalue = ((Number(currItem6.netAmount)/Number(100))*(Number(currItem6.igst))).toFixed(2)
                    currItem6.total = (Number(currItem6.gstvalue) + Number(currItem6.netAmount)).toFixed(2)
                    data[itemIndex6] = currItem6
                    // console.log("mello",totaAmount,NetAmount)
                }
            }
            else{
                if (currItem6.discount > 0 || currItem6.discount1 > 0) {
                    let qtydata = (Number(currItem6.sqpb) * Number(currItem6.uomSecondary))
                    currItem6.quantity_placed_kg ='';
                    let discount2 = Number(qtydata) * Number(currItem6.dlp) * (Number(currItem6.discount) / 100);
                    let totaAmount = Number(qtydata) * Number(currItem6.dlp) - Number(discount2);
                    currItem6.grossamount = (Number(totaAmount)).toFixed(2) ;
                    let schemediscount = ((Number(totaAmount) * Number(currItem6.discount1)) / 100);
                    let NetAmount = (Number(totaAmount) - Number(schemediscount));
                    currItem6.netAmount= (Number(NetAmount)).toFixed(2);
                    currItem6.gstvalue = ((Number(currItem6.netAmount)/Number(100))*(Number(currItem6.igst))).toFixed(2)
                    currItem6.total = (Number(currItem6.gstvalue) + Number(currItem6.netAmount)).toFixed(2)
                    data[itemIndex6] = currItem6
                    
                }  else {
                    let qtydata = (Number(currItem6.sqpb) * Number(currItem6.uomSecondary))
                    currItem6.quantity_placed_kg ='';
                    currItem6.discount = Number(0);
                    currItem6.grossamount = (Number(qtydata) * Number(currItem6.dlp)).toFixed(2);
                    let NetAmount = (Number(qtydata) * Number(currItem6.dlp));
                    currItem6.netAmount = (Number(NetAmount)).toFixed(2)
                    currItem6.gstvalue = ((Number(currItem6.netAmount)/Number(100))*(Number(currItem6.igst))).toFixed(2) 
                    currItem6.total = (Number(currItem6.gstvalue) + Number(currItem6.netAmount)).toFixed(2)
                    data[itemIndex6] = currItem6
                    // console.log("mello",totaAmount,NetAmount)
                }
            }
        }
    } /* Box Product End */
    else {
        if(currItem6.quantity_placed){

            currItem6.quantity_placed = action.data
         if( currItem6.uomSecondary == "" || currItem6.uomSecondary == "0"){

             if (currItem6.discount > 0 || currItem6.discount1 > 0) {

                 let discount2 = Number(currItem6.sqpb) * Number(currItem6.dlp) * (Number(currItem6.discount) / 100);
                 currItem6.quantity_placed_kg = currItem6.sqpb;
                 let totaAmount = Number(currItem6.sqpb) * Number(currItem6.dlp) - Number(discount2);
                 currItem6.grossamount = (Number(totaAmount)).toFixed(2) ;
                 let schemediscount = ((Number(totaAmount) * Number(currItem6.discount1)) / 100);
                 let NetAmount = (Number(totaAmount) - Number(schemediscount));
                 currItem6.netAmount= (Number(NetAmount)).toFixed(2)
                 currItem6.gstvalue = ((Number(currItem6.netAmount)/Number(100))*(Number(currItem6.igst))).toFixed(2)
                 currItem6.total = (Number(currItem6.gstvalue) + Number(currItem6.netAmount)).toFixed(2)
                 data[itemIndex6] = currItem6
                 
             } else {
                 currItem6.discount = Number(0);
                 currItem6.quantity_placed_kg = currItem6.sqpb;
                 currItem6.grossamount = (Number(currItem6.sqpb) * Number(currItem6.dlp)).toFixed(2);
                 let NetAmount = (Number(currItem6.sqpb) * Number(currItem6.dlp));
                 currItem6.netAmount = (Number(NetAmount)).toFixed(2);
                 currItem6.gstvalue = ((Number(currItem6.netAmount)/Number(100))*(Number(currItem6.igst))).toFixed(2)
                 currItem6.total = (Number(currItem6.gstvalue) + Number(currItem6.netAmount)).toFixed(2)
                 data[itemIndex6] = currItem6
                 // console.log("mello",totaAmount,NetAmount)
             }
         }
         else{
             if (currItem6.discount > 0 || currItem6.discount1 > 0) {
                 let qtydata = (Number(currItem6.sqpb) * Number(currItem6.uomSecondary))
                 currItem6.quantity_placed_kg =Number(qtydata).toFixed(2);
                 let discount2 = Number(qtydata) * Number(currItem6.dlp) * (Number(currItem6.discount) / 100);
                 let totaAmount = Number(qtydata) * Number(currItem6.dlp) - Number(discount2);
                 currItem6.grossamount = (Number(totaAmount)).toFixed(2) ;
                 let schemediscount = ((Number(totaAmount) * Number(currItem6.discount1)) / 100);
                 let NetAmount = (Number(totaAmount) - Number(schemediscount));
                 currItem6.netAmount= (Number(NetAmount)).toFixed(2);
                 currItem6.gstvalue = ((Number(currItem6.netAmount)/Number(100))*(Number(currItem6.igst))).toFixed(2)
                 currItem6.total = (Number(currItem6.gstvalue) + Number(currItem6.netAmount)).toFixed(2)
                 data[itemIndex6] = currItem6
                 
             }  else {
                 let qtydata = (Number(currItem6.sqpb) * Number(currItem6.uomSecondary))
                 currItem6.quantity_placed_kg =Number(qtydata).toFixed(2);
                 currItem6.discount = Number(0);
                 currItem6.grossamount = (Number(qtydata) * Number(currItem6.dlp)).toFixed(2);
                 let NetAmount = (Number(qtydata) * Number(currItem6.dlp));
                 currItem6.netAmount = (Number(NetAmount)).toFixed(2)
                 currItem6.gstvalue = ((Number(currItem6.netAmount)/Number(100))*(Number(currItem6.igst))).toFixed(2) 
                 currItem6.total = (Number(currItem6.gstvalue) + Number(currItem6.netAmount)).toFixed(2)
                 data[itemIndex6] = currItem6
                 // console.log("mello",totaAmount,NetAmount)
             }
         }
     }
    }


            return[...data]


            default:
        return data;
    }
}