export const EMPTY_CART = "EMPTY_CART";
export const PRODUCT_LIST = "PRODUCT_LIST";
export const SET_PRODUCT_LIST = "SET_PRODUCT_LIST";
export const SEARCH_PRODUCT = "SEARCH_PRODUCT";

export const ALL_PURCHASE_DATA = "ALL_PURCHASE_DATA";
export const SET_PURCHASE_DATA = "SET_PURCHASE_DATA";
export const ALL_SALES_DATA = "ALL_SALES_DATA";
export const SET_SALES_DATA = "SET_SALES_DATA";
export const ALL_AVAILABLE_REPORT_DATA = "ALL_AVAILABLE_REPORT_DATA";
export const SET_AVAILABLE_REPORT_DATA = "SET_AVAILABLE_REPORT_DATA";
export const PURCHASE_EDIT = "PURCHASE_EDIT";
export const SET_PURCHASE_EDIT_DATA = "SET_PURCHASE_EDIT_DATA";
export const PURCHASE_CHANGE_QTY = "PURCHASE_CHANGE_QTY";
// export const PURCHASE_ADD_TO_CART = "PURCHASE_ADD_TO_CART";
export const PURCHASE_SET_CART = "PURCHASE_SET_CART";
export const PURCHASE_REMOVE_CART = "PURCHASE_REMOVE_CART";


/* OPENING STOCK */

export const OPENINGSTOCK_WAREHOUSE_CHECK = "OPENINGSTOCK_WAREHOUSE_CHECK";
export const PUT_WAREDATA_DROPDOWN = "PUT_WAREDATA_DROPDOWN";
export const EMPTY_OPEN_WARE_DATA = "EMPTY_OPEN_WARE_DATA";

/* Purchase Create */
export const PURCHASE_GET_DATA_TO_LOAD = "PURCHASE_GET_DATA_TO_LOAD";
export const PURCHASE_PUT_DATA_TO_LOAD = "PURCHASE_PUT_DATA_TO_LOAD";
export const PURCHASE_ADD_TO_CART = "PURCHASE_ADD_TO_CART";
export const PUT_NEW_PURCHASE_ADD_TO_CART = "PUT_NEW_PURCHASE_ADD_TO_CART";
export const PURCHASE_CHANGE_MEASUREMENT = "PURCHASE_CHANGE_MEASUREMENT";
export const PURCHASE_CHANGE_QTYPCS = "PURCHASE_CHANGE_QTYPCS";
export const PURCHASE_CHANGE_QTYKG = "PURCHASE_CHANGE_QTYKG";
export const PURCHASE_CHANGE_CAL_MEASUREMENT = "PURCHASE_CHANGE_CAL_MEASUREMENT";
export const REMOVE_FROM_CART_FROM_PURCHASE = "REMOVE_FROM_CART_FROM_PURCHASE";
export const CHANGE_COST_PURCHASE = "CHANGE_COST_PURCHASE";
export const CHANGE_PURCHASE_DISCOUNT = "CHANGE_PURCHASE_DISCOUNT";
export const CHANGE_PURCHASE_TEXT = "CHANGE_PURCHASE_TEXT";
export const EMPTY_PURCHASE_DATA = "EMPTY_PURCHASE_DATA";


/* Purchase Update */
export const PURCHASE_EDIT_GET_DATA_TO_LOAD = "PURCHASE_EDIT_GET_DATA_TO_LOAD";
export const PURCHASE_EDIT_PUT_DATA_TO_LOAD = "PURCHASE_EDIT_PUT_DATA_TO_LOAD";
export const PURCHASE_EDIT_ADD_TO_CART = "PURCHASE_EDIT_ADD_TO_CART";
export const PUT_NEW_PURCHASE_EDIT_ADD_TO_CART = "PUT_NEW_PURCHASE_EDIT_ADD_TO_CART";
export const PURCHASE_EDIT_CHANGE_MEASUREMENT = "PURCHASE_EDIT_CHANGE_MEASUREMENT";
export const PURCHASE_EDIT_CHANGE_QTYPCS = "PURCHASE_EDIT_CHANGE_QTYPCS";
export const PURCHASE_EDIT_CHANGE_QTYKG = "PURCHASE_EDIT_CHANGE_QTYKG";
export const PURCHASE_EDIT_CHANGE_CAL_MEASUREMENT = "PURCHASE_EDIT_CHANGE_CAL_MEASUREMENT";
export const CHANGE_COST_PURCHASE_EDIT = "CHANGE_COST_PURCHASE_EDIT";
export const CHANGE_PURCHASE_EDIT_DISCOUNT = "CHANGE_PURCHASE_EDIT_DISCOUNT";
export const REMOVE_FROM_CART_FROM_PURCHASE_EDIT = "REMOVE_FROM_CART_FROM_PURCHASE_EDIT";
export const CHANGE_PURCHASE_EDIT_TEXT = "CHANGE_PURCHASE_EDIT_TEXT";
export const EMPTY_PURCHASE_EDIT_DATA = "EMPTY_PURCHASE_EDIT_DATA";


/* Purchase Return */
export const PURCHASE_RETURN_ADD_TO_CART = "PURCHASE_RETURN_ADD_TO_CART";
export const PUT_NEW_PURCHASE_RETURN_ADD_TO_CART = "PUT_NEW_PURCHASE_RETURN_ADD_TO_CART";
export const PURCHASE_RETURN_CHANGE_MEASUREMENT = "PURCHASE_RETURN_CHANGE_MEASUREMENT";
export const PURCHASE_RETURN_CHANGE_QTYPCS = "PURCHASE_RETURN_CHANGE_QTYPCS";
export const PURCHASE_RETURN_CHANGE_QTYKG = "PURCHASE_RETURN_CHANGE_QTYKG";
export const PURCHASE_RETURN_CHANGE_CAL_MEASUREMENT = "PURCHASE_RETURN_CHANGE_CAL_MEASUREMENT";
export const CHANGE_COST_PURCHASE_RETURN = "CHANGE_COST_PURCHASE_RETURN";
export const CHANGE_PURCHASE_RETURN_DISCOUNT = "CHANGE_PURCHASE_RETURN_DISCOUNT";
export const REMOVE_FROM_CART_FROM_PURCHASE_RETURN = "REMOVE_FROM_CART_FROM_PURCHASE_RETURN";
export const CHANGE_PURCHASE_RETURN_TEXT = "CHANGE_PURCHASE_RETURN_TEXT";
export const EMPTY_PURCHASE_RETURN_DATA = "EMPTY_PURCHASE_RETURN_DATA";

/* Purchase Return Update */
 export const PR_UPDATE_GET_DATA = "PR_UPDATE_GET_DATA";
 export const PR_UPDATE_PUT_DATA = "PR_UPDATE_PUT_DATA";
 export const PR_UPDATE_ADD_TO_CART = "PR_UPDATE_ADD_TO_CART";
 export const PUT_NEW_PR_UPDATE_ADD_TO_CART = "PUT_NEW_PR_UPDATE_ADD_TO_CART";
export const PR_UPDATE_CHANGE_MEASUREMENT = "PR_UPDATE_CHANGE_MEASUREMENT";
export const PR_UPDATE_CHANGE_QTYPCS = "PR_UPDATE_CHANGE_QTYPCS";
export const PR_UPDATE_CHANGE_QTYKG = "PR_UPDATE_CHANGE_QTYKG";
export const PR_UPDATE_CHANGE_CAL_MEASUREMENT = "PR_UPDATE_CHANGE_CAL_MEASUREMENT";
export const CHANGE_COST_PR_UPDATE = "CHANGE_COST_PR_UPDATE";
export const CHANGE_PR_UPDATE_DISCOUNT = "CHANGE_PR_UPDATE_DISCOUNT";
export const REMOVE_FROM_CART_FROM_PR_UPDATE = "REMOVE_FROM_CART_FROM_PR_UPDATE";
export const CHANGE_PR_UPDATE_TEXT = "CHANGE_PR_UPDATE_TEXT";



/* PO Create */
export const PO_ADD_TO_CART = "PO_ADD_TO_CART";
export const SET_PO_LIST = "SET_PO_LIST";
export const PO_CHANGE_MEASUREMENT = "PO_CHANGE_MEASUREMENT";
export const PO_CHANGE_QTYPCS = "PO_CHANGE_QTYPCS";
export const PO_CHANGE_QTYKG = "PO_CHANGE_QTYKG";
export const PO_CHANGE_CAL_MEASUREMENT = "PO_CHANGE_CAL_MEASUREMENT";
export const PO_CHANGE_COST = "PO_CHANGE_COST";
export const PO_CHANGE_DISCOUNT1 = "PO_CHANGE_DISCOUNT1";
export const PO_CHANGE_TEXT = "PO_CHANGE_TEXT";
export const PO_REMOVE_TO_CART = "PO_REMOVE_TO_CART";
export const PO_EMPTY_DATA = "PO_EMPTY_DATA";

/* PO Update */

export const PO_GET_EDIT_DATA_TO_LOAD = "PO_GET_EDIT_DATA_TO_LOAD";
export const PO_PUT_EDIT_DATA_TO_LOAD = "PO_PUT_EDIT_DATA_TO_LOAD";
export const PO_EDIT_ADD_TO_CART = "PO_EDIT_ADD_TO_CART";
export const PUT_NEW_PO_EDIT_ADD_TO_CART = "PUT_NEW_PO_EDIT_ADD_TO_CART";
export const PO_EDIT_CHANGE_MEASUREMENT = "PO_EDIT_CHANGE_MEASUREMENT";
export const PO_EDIT_CHANGE_QTYPCS = "PO_EDIT_CHANGE_QTYPCS";
export const PO_EDIT_CHANGE_QTYKG = "PO_EDIT_CHANGE_QTYKG";
export const PO_EDIT_CHANGE_CAL_MEASUREMENT = "PO_EDIT_CHANGE_CAL_MEASUREMENT";
export const CHANGE_COST_PO_EDIT = "CHANGE_COST_PO_EDIT";
export const CHANGE_PO_EDIT_DISCOUNT1 = "CHANGE_PO_EDIT_DISCOUNT1";
export const CHANGE_PO_EDIT_TEXT = "CHANGE_PO_EDIT_TEXT";
export const REMOVE_FROM_CART_FROM_PO_EDIT = "REMOVE_FROM_CART_FROM_PO_EDIT";
export const EMPTY_PO_EDIT_DATA = "EMPTY_PO_EDIT_DATA";

/* Supplier Delivery Create */

export const SUPPLIER_GET_DATA_TO_LOAD = "SUPPLIER_GET_DATA_TO_LOAD";
export const SUPPLIER_PUT_DATA_TO_LOAD = "SUPPLIER_PUT_DATA_TO_LOAD";
export const SUPPLIER_ADD_TO_CART = "SUPPLIER_ADD_TO_CART";
export const PUT_NEW_SUPPLIER_ADD_TO_CART = "PUT_NEW_SUPPLIER_ADD_TO_CART";
export const SUPPLIER_EDIT_CHANGE_MEASUREMENT = "SUPPLIER_EDIT_CHANGE_MEASUREMENT";
export const SUPPLIER_EDIT_CHANGE_QTYPCS = "SUPPLIER_EDIT_CHANGE_QTYPCS";
export const SUPPLIER_EDIT_CHANGE_QTYKG = "SUPPLIER_EDIT_CHANGE_QTYKG";
export const SUPPLIER_EDIT_CHANGE_CAL_MEASUREMENT = "SUPPLIER_EDIT_CHANGE_CAL_MEASUREMENT";
export const CHANGE_COST_SUPPLIER = "CHANGE_COST_SUPPLIER";
export const CHANGE_SUPPLIER_DISCOUNT = "CHANGE_SUPPLIER_DISCOUNT";
export const CHANGE_SUPPLIER_TEXT = "CHANGE_SUPPLIER_TEXT";
export const REMOVE_FROM_CART_FROM_SUPPLIER = "REMOVE_FROM_CART_FROM_SUPPLIER";
export const EMPTY_SUPPLIER_DATA = "EMPTY_SUPPLIER_DATA";



/* Supplier Delivery Update */
export const SUPPLIER_EDIT_CHANGE_QTYPCSs = "SUPPLIER_EDIT_CHANGE_QTYPCS";
export const SUPPLIER_ADD_TO_CART_EDIT='SUPPLIER_ADD_TO_CART_EDIT';
export const PUT_NEW_SUPPLIER_ADD_TO_CART_EDIT='PUT_NEW_SUPPLIER_ADD_TO_CART_EDIT';
export const SUPPLIER_EDIT_CHANGE_QTYPCS_EDIT = "SUPPLIER_EDIT_CHANGE_QTYPCS_NewEDIT";
export const SUPPLIER_EDIT_CHANGE_QTYKG_EDIT='SUPPLIER_EDIT_CHANGE_QTYKG_EDIT';
export const SUPPLIER_EDIT_CHANGE_CAL_MEASUREMENT_EDIT='SUPPLIER_EDIT_CHANGE_CAL_MEASUREMENT_EDIT';
export const CHANGE_SUPPLIER_DISCOUNT_EDIT='CHANGE_SUPPLIER_DISCOUNT_EDIT';
export const SUPPLIER_EDIT_CHANGE_QTYKGs = "SUPPLIER_EDIT_CHANGE_QTYKG";
export const SUPPLIER_EDIT_GET_DATA_TO_LOAD = "SUPPLIER_EDIT_GET_DATA_TO_LOAD";
export const SUPPLIER_EDIT_PUT_DATA_TO_LOAD = "SUPPLIER_EDIT_PUT_DATA_TO_LOAD";
export const SUPPLIER_EDIT_ADD_TO_CART = "SUPPLIER_EDIT_ADD_TO_CART";
export const PUT_NEW_SUPPLIER_EDIT_ADD_TO_CART = "PUT_NEW_SUPPLIER_EDIT_ADD_TO_CART";
export const CHANGE_SUPPLIER_EDIT_QTY = "CHANGE_SUPPLIER_EDIT_QTY";
export const REMOVE_FROM_CART_FROM_SUPPLIER_EDIT = "REMOVE_FROM_CART_FROM_SUPPLIER_EDIT";
export const REMOVE_FROM_CART_FROM_SUPPLIER_eDIT ='REMOVE_FROM_CART_FROM_SUPPLIER_eDIT';
export const CHANGE_COST_SUPPLIER_EDIT = "CHANGE_COST_SUPPLIER_EDIT";
export const CHANGE_SUPPLIER_EDIT_DISCOUNT = "CHANGE_SUPPLIER_EDIT_DISCOUNT";
export const CHANGE_SUPPLIER_EDIT_TEXT = "CHANGE_SUPPLIER_EDIT_TEXT";
export const EMPTY_SUPPLIER_EDIT_DATA = "EMPTY_SUPPLIER_EDIT_DATA";

export const SUPPLIER_EDIT_CHANGE_MEASUREMENTtn='SUPPLIER_EDIT_CHANGE_MEASUREMENTtn'
/* Material Re Create */

export const MATERIAL_GET_DATA_TO_LOAD = "MATERIAL_GET_DATA_TO_LOAD";
export const MATERIAL_PUT_DATA_TO_LOAD = "MATERIAL_PUT_DATA_TO_LOAD";
export const MATERIAL_ADD_TO_CART = "MATERIAL_ADD_TO_CART";
export const PUT_NEW_MATERIAL_ADD_TO_CART = "PUT_NEW_MATERIAL_ADD_TO_CART";
export const MATERIAL_EDIT_CHANGE_MEASUREMENT = "MATERIAL_EDIT_CHANGE_MEASUREMENT";
export const MATERIAL_EDIT_CHANGE_QTYPCS = "MATERIAL_EDIT_CHANGE_QTYPCS";
export const MATERIAL_EDIT_CHANGE_QTYKG = "MATERIAL_EDIT_CHANGE_QTYKG";
export const MATERIAL_EDIT_CHANGE_CAL_MEASUREMENT = "MATERIAL_EDIT_CHANGE_CAL_MEASUREMENT";
export const CHANGE_COST_MATERIAL = "CHANGE_COST_MATERIAL";
export const CHANGE_MATERIAL_DISCOUNT = "CHANGE_MATERIAL_DISCOUNT";
export const CHANGE_MATERIAL_TEXT = "CHANGE_MATERIAL_TEXT";
export const REMOVE_FROM_CART_FROM_MATERIAL = "REMOVE_FROM_CART_FROM_MATERIAL";
export const EMPTY_MATERIAL_DATA = "EMPTY_MATERIAL_DATA";

/* Material Delivery Note Update */

export const MATERIAL_EDIT_GET_DATA_TO_LOAD = "MATERIAL_EDIT_GET_DATA_TO_LOAD";
export const MATERIAL_EDIT_PUT_DATA_TO_LOAD = "MATERIAL_EDIT_PUT_DATA_TO_LOAD";
export const MATERIAL_EDIT_ADD_TO_CART = "MATERIAL_EDIT_ADD_TO_CART";
export const PUT_NEW_MATERIAL_EDIT_ADD_TO_CART = "PUT_NEW_MATERIAL_EDIT_ADD_TO_CART";
export const MATERIAL_CHANGE_MEASUREMENT = "MATERIAL_CHANGE_MEASUREMENT";
export const MATERIAL_CHANGE_QTYPCS = "MATERIAL_CHANGE_QTYPCS";
export const MATERIAL_CHANGE_QTYKG = "MATERIAL_CHANGE_QTYKG";
export const MATERIAL_CHANGE_CAL_MEASUREMENT = "MATERIAL_CHANGE_CAL_MEASUREMENT";
export const CHANGE_COST_MATERIAL_EDIT = "CHANGE_COST_MATERIAL_EDIT";
export const CHANGE_MATERIAL_EDIT_DISCOUNT = "CHANGE_MATERIAL_EDIT_DISCOUNT";
export const CHANGE_MATERIAL_EDIT_TEXT = "CHANGE_MATERIAL_EDIT_TEXT";
export const REMOVE_FROM_CART_FROM_MATERIAL_EDIT = "REMOVE_FROM_CART_FROM_MATERIAL_EDIT";
export const EMPTY_MATERIAL_EDIT_DATA = "EMPTY_MATERIAL_EDIT_DATA";

/* WorkOrder Create */

export const ADD_TO_CART_WORKORDER = "ADD_TO_CART_WORKORDER";
export const SET_PRODUCT_LIST_WORKORDER = "SET_PRODUCT_LIST_WORKORDER";
export const REMOVE_TO_CART_WORKORDER = "REMOVE_TO_CART_WORKORDER";
export const CHANGE_QTY_WORKORDER = "CHANGE_QTY_WORKORDER";
export const CHANGE_MEASUREMENT_WORKORDER = "CHANGE_MEASUREMENT_WORKORDER";
export const CHANGE_PER_WORKORDER = "CHANGE_PER_WORKORDER";
export const CHANGE_COST_WORKORDER = "CHANGE_COST_WORKORDER";
export const CHANGE_DISCOUNT_WORKORDER = "CHANGE_DISCOUNT_WORKORDER";
export const CHANGE_DISCOUNT1_WORKORDER = "CHANGE_DISCOUNT1_WORKORDER";
export const CHANGE_TEXT_WORKORDER = "CHANGE_TEXT_WORKORDER";
export const EMPTY_DATA_WORKORDER = "EMPTY_DATA_WORKORDER";

/* WorkOrder Update */

export const GET_EDIT_DATA_TO_LOAD_WORKORDER = "GET_EDIT_DATA_TO_LOAD_WORKORDER";
export const PUT_EDIT_DATA_TO_LOAD_WORKORDER = "PUT_EDIT_DATA_TO_LOAD_WORKORDER";
export const EDIT_ADD_TO_CART_WORKORDER = "EDIT_ADD_TO_CART_WORKORDER";
export const PUT_NEW_EDIT_ADD_TO_CART_WORKORDER = "PUT_NEW_EDIT_ADD_TO_CART_WORKORDER";
export const CHANGE_EDIT_QTY_WORKORDER = "CHANGE_EDIT_QTY_WORKORDER";
export const CHANGE_EDIT_MEASUREMENT_WORKORDER = "CHANGE_EDIT_MEASUREMENT_WORKORDER";
export const EDIT_CHANGE_PER_WORKORDER = "EDIT_CHANGE_PER_WORKORDER";
export const REMOVE_FROM_CART_FROM_EDIT_WORKORDER = "REMOVE_FROM_CART_FROM_EDIT_WORKORDER";
export const CHANGE_COST_EDIT_WORKORDER = "CHANGE_COST_EDIT_WORKORDER";
export const CHANGE_EDIT_DISCOUNT_WORKORDER = "CHANGE_EDIT_DISCOUNT_WORKORDER";
export const CHANGE_EDIT_DISCOUNT1_WORKORDER = "CHANGE_EDIT_DISCOUNT1_WORKORDER";
export const CHANGE_EDIT_TEXT_WORKORDER = "CHANGE_EDIT_TEXT_WORKORDER";
export const EMPTY_EDIT_DATA_WORKORDER = "EMPTY_EDIT_DATA_WORKORDER";
export const CHANGE_STAFFCANCLEQTY = "CHANGE_STAFFCANCLEQTY";
export const CHANGE_RETAILERCANCLEQTY = "CHANGE_RETAILERCANCLEQTY";

/* PrimaryOrder Create */
export const GET_DATA_PRIMARYORDER = "GET_DATA_PRIMARYORDER";
export const GET_PRODUTDATA_PRIMARYORDER = "GET_PRODUTDATA_PRIMARYORDER";
export const GET_PRODUCT_PRIMARYORDER = "GET_PRODUCT_PRIMARYORDER";
export const REMOVE_TO_CART_PRIMARYORDER = "REMOVE_TO_CART_PRIMARYORDER";
export const CHANGE_QTY_PRIMARYORDER = "CHANGE_QTY_PRIMARYORDER";
export const CHANGE_SQPB_PRIMARYORDER = "CHANGE_SQPB_PRIMARYORDER";
export const CHANGE_MEASUREMENT_PRIMARYORDER = "CHANGE_MEASUREMENT_PRIMARYORDER";
export const CHANGE_PER_PRIMARYORDER = "CHANGE_PER_PRIMARYORDER";
export const CHANGE_COST_PRIMARYORDER = "CHANGE_COST_PRIMARYORDER";
export const CHANGE_DISCOUNT_PRIMARYORDER = "CHANGE_DISCOUNT_PRIMARYORDER";
export const CHANGE_DISCOUNT1_PRIMARYORDER = "CHANGE_DISCOUNT1_PRIMARYORDER";
export const CHANGE_TEXT_PRIMARYORDER = "CHANGE_TEXT_PRIMARYORDER";
export const EMPTY_DATA_PRIMARYORDER = "EMPTY_DATA_PRIMARYORDER";

/* PrimaryOrder Update */
export const GET_DATA_PRIMARYORDER_UPDATE = "GET_DATA_PRIMARYORDER_UPDATE";
export const GET_PRIMARYORDER_UPDATE = "GET_PRIMARYORDER_UPDATE";
export const GET_PRODUCT_ADD_PRIMARYORDER_UPDATE = "GET_PRODUCT_ADD_PRIMARYORDER_UPDATE";
export const GET_PRODUCTDATA_ADD_PRIMARYORDER_UPDATE = "GET_PRODUCTDATA_ADD_PRIMARYORDER_UPDATE";
export const REMOVE_TO_CART_PRIMARYORDER_UPDATE = "REMOVE_TO_CART_PRIMARYORDER_UPDATE";
export const CHANGE_UOM_PRIMARYORDER_UPDATE = "CHANGE_UOM_PRIMARYORDER_UPDATE";
export const CHANGE_QTYPCS_PRIMARYORDER_UPDATE = "CHANGE_QTYPCS_PRIMARYORDER_UPDATE";
export const CHANGE_QTYKG_PRIMARYORDER_UPDATE = "CHANGE_QTYKG_PRIMARYORDER_UPDATE";
export const CHANGE_SQPBPCS_PRIMARYORDER_UPDATE = "CHANGE_SQPBPCS_PRIMARYORDER_UPDATE";
export const CHANGE_COST_PRIMARYORDER_UPDATE = "CHANGE_COST_PRIMARYORDER_UPDATE";
export const CHANGE_DISCOUNT1_PRIMARYORDER_UPDATE = "CHANGE_DISCOUNT1_PRIMARYORDER_UPDATE";
export const CHANGE_TEXT_PRIMARYORDER_UPDATE = "CHANGE_TEXT_PRIMARYORDER_UPDATE";
export const EMPTY_DATA_PRIMARYORDER_UPDATE = "EMPTY_DATA_PRIMARYORDER_UPDATE";

/* PrimaryOrder View */
export const ESTIMATED_DELIVERY = "ESTIMATED_DELIVERY";
export const ESTIMATED_DELIVERY_DATA = "ESTIMATED_DELIVERY_DATA";
export const ESTIMATED_DELIVERY_VALUE = "ESTIMATED_DELIVERY_VAlUE";

/* Sales Order Create */

export const SALESORDER_GET_DATA_TO_LOAD = "SALESORDER_GET_DATA_TO_LOAD";
export const SALESORDER_PUT_DATA_TO_LOAD = "SALESORDER_PUT_DATA_TO_LOAD";
export const WORKORDERBYSALESORDER_GET_DATA_TO_LOAD = "WORKORDERBYSALESORDER_GET_DATA_TO_LOAD";
export const WORKORDERBYSALESORDER_PUT_DATA_TO_LOAD = "WORKORDERBYSALESORDER_PUT_DATA_TO_LOAD";
export const SALESORDER_ADD_TO_CART = "SALESORDER_ADD_TO_CART";
export const PUT_NEW_SALESORDER_ADD_TO_CART = "PUT_NEW_SALESORDER_ADD_TO_CART";
export const CHANGE_SALESORDER_MEASUREMENT = "CHANGE_SALESORDER_MEASUREMENT";
export const CHANGE_SALESORDER_SQPB_MEASUREMENT = "CHANGE_SALESORDER_SQPB_MEASUREMENT";
export const CHANGE_SALESORDER_QTY = "CHANGE_SALESORDER_QTY";
export const CHANGE_MEASUREMENT_SALESORDER = "CHANGE_MEASUREMENT_SALESORDER";
export const REMOVE_FROM_CART_FROM_SALESORDER = "REMOVE_FROM_CART_FROM_SALESORDER";
export const CHANGE_COST_SALESORDER = "CHANGE_COST_SALESORDER";
export const CHANGE_SALESORDER_DISCOUNT = "CHANGE_SALESORDER_DISCOUNT";
export const CHANGE_SALESORDER_TEXT = "CHANGE_SALESORDER_TEXT";
export const EMPTY_SALESORDER_DATA = "EMPTY_SALESORDER_DATA";

/* Sales Order Update */

export const SALESORDER_EDIT_GET_DATA_TO_LOAD = "SALESORDER_EDIT_GET_DATA_TO_LOAD";
export const SALESORDER_EDIT_PUT_DATA_TO_LOAD = "SALESORDER_EDIT_PUT_DATA_TO_LOAD";
export const SALESORDER_EDIT_ADD_TO_CART = "SALESORDER_EDIT_ADD_TO_CART";
export const PUT_NEW_SALESORDER_EDIT_ADD_TO_CART = "PUT_NEW_SALESORDER_EDIT_ADD_TO_CART";
export const CHANGE_SALESORDER_EDIT_MEASUREMENT = "CHANGE_SALESORDER_EDIT_MEASUREMENT";
export const CHANGE_SALESORDER_SQPB_EDIT_MEASUREMENT = "CHANGE_SALESORDER_SQPB_EDIT_MEASUREMENT";
export const CHANGE_SALESORDER_EDIT_QTY = "CHANGE_SALESORDER_EDIT_QTY";
export const CHANGE_MEASUREMENT_EDIT_SALESORDER = "CHANGE_MEASUREMENT_EDIT_SALESORDER";
export const REMOVE_FROM_CART_FROM_SALESORDER_EDIT = "REMOVE_FROM_CART_FROM_SALESORDER_EDIT";
export const CHANGE_COST_SALESORDER_EDIT = "CHANGE_COST_SALESORDER_EDIT";
export const CHANGE_SALESORDER_EDIT_DISCOUNT = "CHANGE_SALESORDER_EDIT_DISCOUNT";
export const CHANGE_SALESORDER_EDIT_TEXT = "CHANGE_SALESORDER_EDIT_TEXT";
export const EMPTY_SALESORDER_EDIT_DATA = "EMPTY_SALESORDER_EDIT_DATA";

/* Delivery Challan Create */

export const DELIVERYCHALLAN_GET_DATA_TO_LOAD = "DELIVERYCHALLAN_GET_DATA_TO_LOAD";
export const DELIVERYCHALLAN_PUT_DATA_TO_LOAD = "DELIVERYCHALLAN_PUT_DATA_TO_LOAD";
export const DELIVERYCHALLAN_ADD_TO_CART = "DELIVERYCHALLAN_ADD_TO_CART";
export const PUT_NEW_DELIVERYCHALLAN_ADD_TO_CART = "PUT_NEW_DELIVERYCHALLAN_ADD_TO_CART";
export const CHANGE_DELIVERYCHALLAN_MEASUREMENT = "CHANGE_DELIVERYCHALLAN_MEASUREMENT";
export const DELIVERYCHALLAN_SQPB_MEASUREMENT = "DELIVERYCHALLAN_SQPB_MEASUREMENT";
export const DELIVERYCHALLAN_SQPBKG_MEASUREMENT = "DELIVERYCHALLAN_SQPBKG_MEASUREMENT";
export const DELIVERYCHALLAN_CHANGE_CAL_MEASUREMENT = "DELIVERYCHALLAN_CHANGE_CAL_MEASUREMENT";
export const REMOVE_FROM_CART_FROM_DELIVERYCHALLAN = "REMOVE_FROM_CART_FROM_DELIVERYCHALLAN";
export const CHANGE_COST_DELIVERYCHALLAN = "CHANGE_COST_DELIVERYCHALLAN";
export const CHANGE_DELIVERYCHALLAN_DISCOUNT = "CHANGE_DELIVERYCHALLAN_DISCOUNT";
export const CHANGE_DELIVERYCHALLAN_TEXT = "CHANGE_DELIVERYCHALLAN_TEXT";
export const EMPTY_DELIVERYCHALLAN_DATA = "EMPTY_DELIVERYCHALLAN_DATA";

/* So Pending Dc */
export const DC_ADD_TO_CART = "DC_ADD_TO_CART";
export const DC_PUT_NEW_ADD_TO_CART = "DC_PUT_NEW_ADD_TO_CART";
export const DC_CHANGE_MEASUREMENT = "DC_CHANGE_MEASUREMENT";
export const DC_SQPB_MEASUREMENT =  "DC_SQPB_MEASUREMENT";
export const DC_SQPBKG_MEASUREMENT =  "DC_SQPBKG_MEASUREMENT";
export const DC_CHANGE_CAL_MEASUREMENT = "DC_CHANGE_CAL_MEASUREMENT";
export const DC_REMOVE_FROM_CART = "DC_REMOVE_FROM_CART";
export const DC_CHANGE_COST = "DC_CHANGE_COST";
export const DC_CHANGE_DISCOUNT = "DC_CHANGE_DISCOUNT";
export const DC_CHANGE_TEXT = "DC_CHANGE_TEXT";
export const DC_EMPTY_DATA = "DC_EMPTY_DATA"; 

/* Sales Invoice Create */

export const SALES_GET_DATA_TO_LOAD = "SALES_GET_DATA_TO_LOAD";
export const SALES_PUT_DATA_TO_LOAD = "SALES_PUT_DATA_TO_LOAD";
export const SALES_ADD_TO_CART = "SALES_ADD_TO_CART";
export const PUT_NEW_SALES_ADD_TO_CART = "PUT_NEW_SALES_ADD_TO_CART";
export const CHANGE_SALES_MEASUREMENT = "CHANGE_SALES_MEASUREMENT";
export const CHANGE_SALES_SQPB_MEASUREMENT = "CHANGE_SALES_SQPB_MEASUREMENT";
export const CHANGE_SALES_SQPBKG_MEASUREMENT = "CHANGE_SALES_SQPBKG_MEASUREMENT";
export const CHANGE_SALES_QTY = "CHANGE_SALES_QTY";
export const CHANGE_MEASUREMENT_SALES = "CHANGE_MEASUREMENT_SALES";
export const REMOVE_FROM_CART_FROM_SALES = "REMOVE_FROM_CART_FROM_SALES";
export const CHANGE_COST_SALES = "CHANGE_COST_SALES";
export const CHANGE_SALES_DISCOUNT = "CHANGE_SALES_DISCOUNT";
export const CHANGE_SALES_TEXT = "CHANGE_SALES_TEXT";
export const EMPTY_SALES_DATA = "EMPTY_SALES_DATA";


/* Sales Invoice Update */
export const SALES_EDIT_GET_DATA_TO_LOAD = "SALES_EDIT_GET_DATA_TO_LOAD";
export const SALES_EDIT_PUT_DATA_TO_LOAD = "SALES_EDIT_PUT_DATA_TO_LOAD";
export const SALES_EDIT_ADD_TO_CART = "SALES_EDIT_ADD_TO_CART";
export const PUT_NEW_SALES_EDIT_ADD_TO_CART = "PUT_NEW_SALES_EDIT_ADD_TO_CART";
export const CHANGE_SALES_EDIT_MEASUREMENT = "CHANGE_SALES_EDIT_MEASUREMENT";
export const CHANGE_SALES_EDIT_SQPB_MEASUREMENT = "CHANGE_SALES_EDIT_SQPB_MEASUREMENT";
export const CHANGE_SALES_EDIT_SQPBKG_MEASUREMENT = "CHANGE_SALES_EDIT_SQPBKG_MEASUREMENT";
export const CHANGE_SALES_EDIT_CAL_MEASUREMENT = "CHANGE_SALES_EDIT_CAL_MEASUREMENT";
export const REMOVE_FROM_CART_FROM_SALES_EDIT = "REMOVE_FROM_CART_FROM_SALES_EDIT";
export const CHANGE_COST_SALES_EDIT = "CHANGE_COST_SALES_EDIT";
export const CHANGE_SALES_EDIT_DISCOUNT = "CHANGE_SALES_EDIT_DISCOUNT";
export const CHANGE_SALES_EDIT_TEXT = "CHANGE_SALES_EDIT_TEXT";
export const EMPTY_SALES_EDIT_DATA = "EMPTY_SALES_EDIT_DATA";

/* Sales Return Create */
export const SALES_RETURN_AAD_TO_CART = "SALES_RETURN_AAD_TO_CART";
export const SALES_RETURN_PUT_ADD_TO_CART = "SALES_RETURN_PUT_ADD_TO_CART";
export const SALES_RETURN_MEASUREMENT = "SALES_RETURN_MEASUREMENT";
export const SALES_RETURN_CALMEASUREMENT = "SALES_RETURN_CALMEASUREMENT";
export const SALES_RETURN_PCS = "SALES_RETURN_PCS";
export const SALES_RETURN_QTY = "SALES_RETURN_QTY";
export const SALES_RETURN_COST = "SALES_RETURN_COST";
export const SALES_RETURN_DISCOUNT = "SALES_RETURN_DISCOUNT";
export const SALES_RETURN_REMOVE_FROM_CART = "SALES_RETURN_REMOVE_FROM_CART";
export const SALES_RETURN_TEXT = "SALES_RETURN_TEXT";
export const SALES_RETURN_EMPTY_DATA = "SALES_RETURN_EMPTY_DATA";

/* Sales Return Update */
export const SALES_RETURN_UPDATE_DATA_LOAD = "SALES_RETURN_UPDATE_DATA_LOAD";
export const SALES_RETURN_UPDATE_GET_DATA_LOAD = "SALES_RETURN_UPDATE_GET_DATA_LOAD";
export const SALES_RETURN_UPDATE_AAD_TO_CART = "SALES_RETURN_UPDATE_AAD_TO_CART";
export const SALES_RETURN_UPDATE_PUT_ADD_TO_CART = "SALES_RETURN_UPDATE_PUT_ADD_TO_CART";
export const SALES_RETURN_UPDATE_MEASUREMENT = "SALES_RETURN_UPDATE_MEASUREMENT";
export const SALES_RETURN_UPDATE_CALMEASUREMENT = "SALES_RETURN_UPDATE_CALMEASUREMENT";
export const SALES_RETURN_UPDATE_PCS = "SALES_RETURN_UPDATE_PCS";
export const SALES_RETURN_UPDATE_QTY = "SALES_RETURN_UPDATE_QTY";
export const SALES_RETURN_UPDATE_COST = "SALES_RETURN_UPDATE_COST";
export const SALES_RETURN_UPDATE_DISCOUNT = "SALES_RETURN_UPDATE_DISCOUNT";
export const SALES_RETURN_UPDATE_REMOVE_FROM_CART = "SALES_RETURN_UPDATE_REMOVE_FROM_CART";
export const SALES_RETURN_UPDATE_TEXT = "SALES_RETURN_UPDATE_TEXT";
export const SALES_RETURN_UPDATE_EMPTY_DATA = "SALES_RETURN_UPDATE_EMPTY_DATA";