import axios from 'axios';
import React, { useEffect, useState } from 'react'
import AuthService from '../../services/auth.service';
import authHeader from '../../services/auth-header';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";
import { CSVLink } from 'react-csv';
import Select from "react-select";// import  from '../Retailer/Edit';
import { getroles } from '../../Login/Login';

export const Mydatatable = ({ head, axiostabledata, Edit, View, Add, title, Create, Sumofkeys, warehousehdata }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading22, setLoading22] = useState(false);

  const currentuser = AuthService.getCurrentUser();

  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(encodeURIComponent(' '));
  const [number, setnumber] = useState([])
  const [total, setotal] = useState([])
  const [size, setsize] = useState([])
  const [getid, setgetid] = useState(0)
  const [limit, setlimit] = useState(10)
  const [sort1, setsort1] = useState(false)
  const [sort, setsort] = useState("desc")
  const [fieldnme, setfeilds] = useState("id")
  const [data, setdata] = useState([])
  const [superid, setsuperid] = useState(0)
  const [exportpage, setexport] = useState([])
  const [data1, setdata1] = useState(10)
  const [entries, setentries] = useState([])
  const { pointindex } = useParams();
  const [warehousehvalue, setwarehousehvalue] = useState([])
  const [warehousehvaluedb, setwarehousehvaluedb] = useState([])
  const [hide, setHide] = useState(false);
  const pagination = (msg) => {
    //  console.log(msg);

    setgetid(msg)
  }
  const handlechange = (entry) => {
    setdata1(entry)
    setlimit(entry)
  }
  const handleToggleColumns = () => {
    setHide(!hide);
  };

  const count = [{ "value": 10 }, { "value": 20 }, { "value": 50 }, { "value": 100 }, { "value": 200 }]

  const searchhandle = (search) => {
    if (search.length == 0) {
      setError(encodeURIComponent(' '))
    } else {
      setError(search)


    }
  }

  //   useEffect(() => {
  //     setCurrentPage(Number(pointindex))
  //     handlePageChange(Number(pointindex))
  //   }, [   ])


  // console.log(head)



  // const sotreddata=(value)=>{
  //   setsort(value.sort);
  //   setfeilds(value.field);
  // console.log(value.field);
  // }

  const onChangewarehouse = (e) => {
    console.log(e.value)
    setwarehousehvaluedb(Number(e.value))
    setwarehousehvalue({ value: e.value, label: e.label })

  }
  const handleexport = () => {
    setLoading22(true)
    axios.get(`http://43.225.54.138:8080/scm/api/${axiostabledata}/export`, { headers: authHeader() })
      .then(response => {
        setexport(response.data)
        // console.log(`http://43.225.54.138:8080/scm/api/product/page/${Number(getid)}/${Number(limit)}/${sort}/${fieldnme}`);
        console.log(response.data);

        // if(response.status >= 200 && response.status < 300){
        // setError(response.data)
        // return(<CSVLink data={response.data}></CSVLink>)
        // }

      })
      .catch(error => {
        console.error(error);
      })




    //   .finally(() => {


    // });
  }

  useEffect(() => {
    console.log(exportpage)

    if (exportpage.length !== 0) {
      setLoading22(false);
      document.getElementById("button").click()
    }

  }

    , [exportpage])

  const handlesort = (field) => {
    setsort1(!sort1)
    // console.log(sort?{"sort":"asc","field":field}:{"sort":"dsc","field":field})
    //  sotreddata(sort1?{"sort":"asc","field":field}:{"sort":"desc","field":field})
    //  console.log(first)

    if (sort1) {
      setsort("asc")
      setfeilds(field)
    } else {
      setsort("desc")
      setfeilds(field)
    }

    // console.log(field,sort);

  }

  // const sotreddata=(value)=>{
  //   setsort(value.sort);
  //   setfeilds(value.field);
  // console.log(value.field);
  // }


  useEffect(() => {




    const user = AuthService.getCurrentUser();

    const headers = {
      Authorization: 'Bearer your_token_here',
    };

    const startTime = new Date().getTime();
    axios.get(`http://43.225.54.138:8080/scm/api/${axiostabledata}/page${axiostabledata === 'mininmumstock' ? "/" + Number(warehousehvaluedb) + "/" : "" + '/'}${axiostabledata === 'mininmumstock' ? Number(0) + "/" : Number(currentPage - 1) + '/'}${Number(limit)}/${sort}/${axiostabledata == 'distributor/openingstock' ? "id" : fieldnme}/${error}`, { headers: authHeader() })
      .then(response => {
        const endTime = new Date().getTime();
        const elapsedTime = endTime - startTime;
        console.log(`Request took ${elapsedTime} milliseconds`);
        setentries(response.data.Enteries)
        console.log(response)
        if (response.data.Index == undefined) {
          setsize(0);
          setnumber(0);
          console.log(response)
          setdata(response.data.data);
          // setdata(response.data);
          // setdata(response.data.data);
          // if(axiostabledata=="staff"){
          //   setdata(response.data.data);
          // }else{
          //   setdata(response.data.data);
          // }
        }
        else {
          setsize(response.data.Pages);
          setnumber(response.data.Pages);
          setdata(response.data.Index);
        }
        // setdata(response.data.data);

      })
      .catch(error => {
        console.error(error);
      });



    // if (exportpage.length == 0) {
    //   axios.get(`http://43.225.54.138:8080/scm/api/${axiostabledata}/export`, { headers: authHeader() })
    //     .then(response => {
    //       setexport(response.data)
    //       // console.log(`http://43.225.54.138:8080/scm/api/product/page/${Number(getid)}/${Number(limit)}/${sort}/${fieldnme}`);
    //       console.log(response.data);

    //       // if(response.status >= 200 && response.status < 300){
    //       //   setError(response.data)
    //       // }
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });
    // }

  }, [getid, limit, fieldnme, sort, warehousehvaluedb, currentPage, error]);


  const data5 = Object.assign(...Sumofkeys.map(({ first }) => ({
    [first]: data.reduce((acc, curr) => acc + (parseFloat(curr[first]) || 0), 0)
  })));

  // console.log(data5);
  // c 


  const handle = (id) => {
    // console.log("axiostabledata cm/report")

    if (axiostabledata !== "cm/report" && axiostabledata !== "repacking" && axiostabledata !== "loosepacking" && axiostabledata !== "transporter" && axiostabledata !== "invoice" && axiostabledata !== "warehouseupload" && axiostabledata !== "setbarcode" && axiostabledata !== "productbarcode" && (getroles() == 'ROLE_RETAILER' || getroles() == "ROLE_DISTRIBUTOR" && axiostabledata == "meeting" ? true : true)) {
      if (id >= 0) {
        if (Number(pointindex) == 0) {
          navigate(`${Edit}/${id}/${Number(currentPage)}`)
        } else {
          navigate(`${Edit}/${id}/${currentPage}`)
        }
      } else {
        if (Number(pointindex) == 0) {
          navigate(`${View}/${id.view}/${Number(currentPage)}`)
        } else {
          navigate(`${View}/${id.view}/${currentPage}`)
        }




        // navigate(`${View}/${id.view}/${currentPage}`)
      }
    } if (axiostabledata == "invoice") {
      //  setsort("desc");

      console.log(id.view)

      // axios.delete(`http://43.225.54.138:8080/scm/api/invoice/${id.view}`, { headers: authHeader() })
      // .then(response => {
      //   setsort("asc")

      //     // console.log(`http://43.225.54.138:8080/scm/api/product/page/${Number(getid)}/${Number(limit)}/${sort}/${fieldnme}`);
      //   console.log(response.data);

      //   // if(response.status >= 200 && response.status < 300){
      //     // setError(response.data)
      //     // return(<CSVLink data={response.data}></CSVLink>)
      //   // }

      // })
      // .catch(error => {
      //   console.error(error);
      // })
      axios.delete(`http://43.225.54.138:8080/scm/api/invoice/${id.view}`, { headers: authHeader() })
        .then(response => {
          console.log(response.data);
          setdata(data.filter((item) => item.id !== id.view))
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {

        });



    }
    if (axiostabledata == "transporter") {
      axios.delete(`http://43.225.54.138:8080/scm/api/transporter/${id.view}`, { headers: authHeader() })
        .then(response => {
          console.log(response.data);
          setdata(data.filter((item) => item.id !== id.view))
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {

        });
    }
  }

  const bruce = data.map((item, rowIndex) => (

    // <tr className="the"  >
    //   {head.map((item2) =>
    //     // <td className="datatabletd" id={item2.field=="action"?"":item.id}  onClick={()=>handle(item2.field=="action"?"":item.id)}>{item[item2.field]}</td>
    //     <td className="datatabletd" style={{ display: item2.field == "action" ? axiostabledata == "mininmumstock" ? "none" : "block" : "" }} onClick={() => { handle(item2.field == "action" ? { "view": item.id } : item.id); }}>{item2.subaction1 !== "Download" ?
    //       (item2.field == "action" ? item2.subaction1 : item[item2.field]) :
    //       (
    //         (item2.field == "imglocation" || item2.field == "pdflocation") &&
    //         <button className='downloadidea' onClick={() => window.open(`http://43.225.54.138:8080/scm/${item[item2.field]}`, '_blank')}>
    //           Download
    //         </button>
    //       )
    //     }
    //     </td>
    //     //     {if(item.action){
    //     //  return   <td className="datatabletd"     >{item[item2.field]}  </td>
    //     //   }
    //     // else{
    //     //   return   <td className="datatabletd"  id={item.id}   >{item[item2.field]}  </td>
    //     // }



    //   )}
    // </tr>
    <tr className="the">
    {head.map(
      (item2) => {
   
        if (
          (item2.field === "createddate" ||
            item2.field === "createdtime" ||
            item2.field === "createbyname" ||
            item2.field === "updateddate" ||
            item2.field === "updatedtime" ||
            item2.field==='remarks'||
            item2.field === "updatedbyname") &&
          !hide
        ) {
          return null; 
        }

        return (
          // <td className="datatabletd" id={item2.field=="action"?"":item.id}  onClick={()=>handle(item2.field=="action"?"":item.id)}>{item[item2.field]}</td>
          <td
            className={`datatabletd ${
              item2?.field === "status"
              ? item?.status === "pending"
                ? "opens" : item?.status==='converted'?'converted':''
                
              : ""
            } 
          `}
            style={{
              display:
                item2.field == "action"
                  ? axiostabledata == "mininmumstock"
                    ? "none"
                    : ""
                  : "",
            }}
            onClick={() => {
              handle(item2.field == "action" ? { view: item.id } : item.id);
            }}
          >
            {item2.subaction1 !== "Download"
              ? item2.field == "action"
                ? item2.subaction1
                : item[item2.field] === "pending"
                ? "open"
                : item[item2.field]
              : (item2.field == "imglocation" ||
                  item2.field == "pdflocation") && (
                  <button
                    className="downloadidea"
                    onClick={() =>
                      window.open(
                        `http://43.225.54.138:8080/scm/${item[item2.field]}`,
                        "_blank"
                      )
                    }
                  >
                    Download
                  </button>
                )}
          </td>
        );
      }
      //     {if(item.action){
      //  return   <td className="datatabletd"     >{item[item2.field]}  </td>
      //   }
      // else{
      //   return   <td className="datatabletd"  id={item.id}   >{item[item2.field]}  </td>
      // }
    )}
  </tr>
  ));
  useEffect(() => {
    console.log(currentPage)
     

    setCurrentPage(Number(pointindex))


    setgetid(Number(pointindex))

  }, [])

  const handlePageChange = (page) => {


    setCurrentPage(page);
    // console.log(page-1);
    setsuperid(page - 1);
    pagination(page - 1);
  };
  const renderPageNumbers = () => {
    const pages = [];
    const maxPagesToShow = 3;

    for (let i = Math.max(0, currentPage - 2); i <= Math.min(number - 1, currentPage + 10); i++) {
      pages.push(
        <li key={i} className={i === Number(currentPage) ? 'newway' : ''} onClick={() => handlePageChange(i)}>
          {i}
        </li>
      );
    }
    // pages+1
    return pages;
  };
  const handleretailermap = () => {
    navigate(`/Staffmap`)
  }
  const handledis = () => {
    navigate(`/Staffmapdis`)

  }
  return (
    // data.length == null ?
    // 1!==1?(<div className="pilt">
    //     <div class  Distributor="loader"></div>
    //   </div>) : 
    <>



      <MDBCard data-aos="fade" >
        <div className="card-header flex">
          <h1>{title}</h1>
          <div className="flex">
            {axiostabledata == 'distributor' && getroles() == "ROLE_ADMIN" ? <button className="btn btn-primary thh" style={{ width: '240px' }} onClick={() => handledis()}>Find your Distributor</button> : ""}
            {getroles() == "ROLE_SUPPLIER" && axiostabledata == 'sdn' ? (Create.length !== 0 ? <Link to={Create}> <button className="btn btn-primary thh"> <span className="add">{Add}</span>  </button></Link> : "") : ""}

            {axiostabledata == 'retailer' && getroles() == "ROLE_ADMIN" ? <button className="btn btn-primary thh" style={{ width: '240px' }} onClick={() => handleretailermap()}>Find your retailer</button> : ""}
            {getroles() == "ROLE_RETAILER" && axiostabledata == 'workorder' ? (Create.length !== 0 ? <Link to={Create}> <button className="btn btn-primary thh"> <span className="add">{Add}</span>  </button></Link> : "") : ""}
            {getroles() == "ROLE_DISTRIBUTOR" && (axiostabledata == 'workorder' || axiostabledata == 'retailer' || axiostabledata == 'primaryworkorder') ? (Create.length !== 0 ? <Link to={Create}> <button className="btn btn-primary thh"> <span className="add">{Add}</span>  </button></Link> : "") : ""}
            {getroles() == "ROLE_ASE" || getroles() == "ROLE_ASM" || getroles() == "ROLE_RSM" || getroles() == "ROLE_NSM" &&
              (axiostabledata == 'retailer' || axiostabledata == 'distributor' || axiostabledata == 'staff' || axiostabledata == 'workorder' || axiostabledata == 'assetreqform') ?
              (Create.length !== 0 ? <Link to={Create}> <button className="btn btn-primary thh"> <span className="add">{Add}</span>  </button></Link> : "") : ""}
            {getroles() == "ROLE_ADMIN" && (axiostabledata == 'product' || axiostabledata == 'repacking' || axiostabledata == 'loosepacking' || axiostabledata == "meeting" || axiostabledata == "cm" || axiostabledata == 'retailer' || axiostabledata == 'distributor' || axiostabledata == 'indiststock' || axiostabledata == "outdiststock" || axiostabledata === 'jobworkinward' || axiostabledata === 'jobworkoutward' || axiostabledata == 'staff' || axiostabledata == 'primaryworkorder' || axiostabledata == 'assetreqform' || axiostabledata === 'assetassigntostaff'
              || axiostabledata == 'workorder' || axiostabledata == 'po' || axiostabledata == 'dms' || axiostabledata == 'brand' || axiostabledata == "invoice" || axiostabledata == 'branch' || axiostabledata == 'warehouse' || axiostabledata === 'productbarcode' || axiostabledata === 'setbarcode' || axiostabledata === 'cartonbarcode' || axiostabledata === 'mastercartoon' || axiostabledata === 'bom' || axiostabledata === 'holiday' || axiostabledata === 'galaprefix'
              || axiostabledata === 'group1' || axiostabledata == 'distributor/openingstock' || axiostabledata === 'group2' || axiostabledata === 'group3' || axiostabledata == 'transporter' || axiostabledata === 'group4' || axiostabledata === 'group5' || axiostabledata === 'group6' || axiostabledata === 'group7' || axiostabledata === 'group8' || axiostabledata === 'group9' || axiostabledata === 'group10' || axiostabledata === 'voucher'
              || axiostabledata == 'supplier' || axiostabledata == 'mrn' || axiostabledata == 'purchase' || axiostabledata == 'pr' || axiostabledata == 'so' || axiostabledata == 'dc' || axiostabledata == 'sales' || axiostabledata == 'sr' || axiostabledata == 'po' || axiostabledata == 'asset'||axiostabledata == 'sdn') ?
              (Create.length !== 0 ? <Link to={Create}> <button className="btn btn-primary thh"> <span className="add">{Add}</span>  </button></Link> : "") : ""}
            {getroles() == "ROLE_ADMIN" ? (loading22 == false ? (axiostabledata == 'product' || axiostabledata == 'retailer' || axiostabledata == 'distributor' || axiostabledata == 'staff' || axiostabledata == 'primaryworkorder' || axiostabledata == 'workorder' ? <div className="whitefont pk " onClick={() => handleexport()}>{loading22 == true ? <div class="loader12"></div> : 'Export'}</div> : "") : <div class="loader12"></div>) : ""}
            <CSVLink id="button" data={exportpage}></CSVLink>

          </div>

        </div>

        <MDBCardBody >
          {warehousehdata !== undefined ? (
            <div className='row'>
              <div className='col-6'>
                <label className='form-label'>Select warehouse</label>
                <Select value={warehousehvalue} options={warehousehdata} onChange={(e) => onChangewarehouse(e)} />
              </div>
            </div>
          ) : ""}

          <br></br>
          <div className="forentriesandaddbutton">
            <div className="bottole">
              <p>Show entries</p>
              <select className="newselect" onChange={(e) => handlechange(e.target.value)}>
                {count.map((item) => { return <option>{item.value}</option> })}
              </select>

            </div>
            <div className=" ">
              <input type="text" className="form-control" placeholder='Enter Search . . .' onChange={(e) => searchhandle(e.target.value)} />

            </div>

          </div>
          {data.length == 0 ?
            (<div className="pilt">
              <div class="loader"></div>
            </div>) : ""}
            <div className={`${hide===true ?'table-responsive-2':''}`}>
          <table className="table thrtable ">

            {/* <thead>

                <tr>


                  {head.map((head, index) => (
                    <th style={{display:head.field=="action"?axiostabledata=="mininmumstock" ? "none" : "block":""}} onClick={() => { handlesort(head.field == "action" ? "id" : head.field) }} key={index}>{head.label}{sort1 ? <TiArrowSortedUp /> : <TiArrowSortedDown className="" />}</th>
                  ))}
                </tr>
              </thead> */}
            <thead>
              <tr>
                {head.map((head, index) => {
                  if (
                    (head.field === "createddate" ||
                      head.field === "createdtime" ||
                      head.field === "createbyname" ||
                      head.field === "updateddate" ||
                      head.field === "updatedtime" ||
                      head.field === 'remarks' ||
                      head.field === "updatedbyname") &&
                    !hide
                  ) {
                    return null;
                  }

                  return (
                    <th
                      style={{
                        display:
                          head.field == "action"
                            ? axiostabledata == "mininmumstock"
                              ? "none"
                              : "block"
                            : "",
                         width:   head.field==='hideOrshow'?'12%':''
                      }}
                      onClick={() => {
                        handlesort(head.field == "action" ? "id" : head.field);
                      }}
                      key={index}
                    >
                      {head.field === "hideOrshow" ? (
                        <button
                          onClick={handleToggleColumns}
                          className="showOrHide"
                        >
                          {hide ? "Show Less" : "Show More"}
                        </button>
                      ) : (
                        head.label
                      )}
                      {head.field === "hideOrshow" ? (
                        ""
                      ) : sort1 ? (
                        <TiArrowSortedUp />
                      ) : (
                        <TiArrowSortedDown className="" />
                      )}
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>



              {bruce}
            </tbody>
            <tfoot>
              <tr>
                {axiostabledata === 'mrn' || axiostabledata === 'po' || axiostabledata === 'sdn' || axiostabledata === 'purchase' || axiostabledata === 'pr' || axiostabledata === 'workorder' || axiostabledata === 'primaryworkorder' || axiostabledata === 'so' || axiostabledata === 'dc' || axiostabledata === 'sales' || axiostabledata === 'holiday' || axiostabledata === 'sr' || axiostabledata === 'productbarcode' || axiostabledata === 'setbarcode' || axiostabledata === 'cartonbarcode' || axiostabledata === 'mastercartoon' || axiostabledata === 'bom' || axiostabledata == 'asset' || axiostabledata == 'assetreqform' ?
                  head.map((item, index) => (
                    <th key={index}>
                      {data5.hasOwnProperty(item.field) ? data5[item.field].toFixed(2) : ''}
                    </th>
                  ))
                  : null
                }

              </tr>
            </tfoot>
          </table>
          </div>
          <div className="flex">
            <div className="showentri">


              {data1 == 10 ? <p>Showing  {superid * data1 + 1} to {superid * data1 + 10} of {entries} entries</p> : ""}
              {data1 == 20 ? <p>Showing {superid * data1 + 1} to {superid * data1 + 20} of {entries} entries</p> : ""}
              {/* {data1 == 30 ? <p>Showing {superid * data1 + 1} to {superid * data1 + 30} of {entries} entries</p> : ""} */}
              {data1 == 50 ? <p>Showing {superid * data1 + 1} to {superid * data1 + 50} of {entries} entries</p> : ""}
              {data1 == 100 ? <p>Showing {superid * data1 + 1} to {superid * data1 + 100} of {entries} entries</p> : ""}
              {data1 == 200 ? <p>Showing {superid * data1 + 1} to {superid * data1 + 200} of {entries} entries</p> : ""}
            </div>
            <div className="pagination">
              <ul className="pagination">



                <li onClick={() => handlePageChange(currentPage > 1 ? currentPage - 1 : 1)}>
                  {'Previous'}
                </li>
                {renderPageNumbers()}
                {number}
                <li onClick={() => handlePageChange(currentPage == number ? number : currentPage + 1)}>{'Next'}</li>

              </ul>

            </div>
          </div>

        </MDBCardBody>
      </MDBCard>
    </>
  )
}