import { MDBCard } from "mdbreact";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Form, Row, Col, Table } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import Select from "react-select";
import Swal from "sweetalert2";

const ViewProjectionEntry = () => {
  const navigate = useNavigate();
  const { id, pointindex } = useParams();

  const [projectionEntryData, setProjectionEntryData] = useState({
    projectionentryitem: [],
  });
  const [loading, setLoading] = useState(false);

  var group1 = "";
  var group2 = "";
  var group3 = "";
  var group4 = "";
  var group5 = "";

  const [groupn1Options, setGroupN1Options] = useState();
  const [groupn2Options, setGroupN2Options] = useState();
  const [groupn3Options, setGroupN3Options] = useState();
  const [groupn4Options, setGroupN4Options] = useState();
  const [groupn5Options, setGroupN5Options] = useState();
  const [productOptions, setProductOptions] = useState();

  const [group1Value, setGroup1Value] = useState();
  const [group2Value, setGroup2Value] = useState();
  const [group3Value, setGroup3Value] = useState();
  const [group4Value, setGroup4Value] = useState();
  const [group5Value, setGroup5Value] = useState();

  const [group1Search, setGroup1Search] = useState("");
  const [group2Search, setGroup2Search] = useState("");
  const [group3Search, setGroup3Search] = useState("");
  const [group4Search, setGroup4Search] = useState("");
  const [group5Search, setGroup5Search] = useState("");
  const [productSearch, setProductSearch] = useState(encodeURIComponent(" "));

  const [disabled1, setDisabled1] = useState(false);
  const [disabled2, setDisabled2] = useState(false);
  const [disabled3, setDisabled3] = useState(false);
  const [disabled4, setDisabled4] = useState(false);
  const [disabled5, setDisabled5] = useState(false);

  async function getProjectionEntryData(params) {
    try {
      const response = await axios.get(`${BASE_URL}v1/projectionentry/${id}`, {
        headers: authHeader(),
      });

      const data = response.data;

      const row = data.projectionentryitem.map((data) => ({
        product: {
          id: data?.product?.id,
        },
        product_name: data?.product?.productName + "-" + data?.product?.eanCode + "-" + data?.product?.shortName,
        qty: data?.qty,
        kgqty: data?.kgqty,
      }));

      setProjectionEntryData({
        ...projectionEntryData,
        projectionDate: data.projectionDate,
        projectionentryitem: row,
        ...(data?.groupn1?.id ? { groupn1: { id: data?.groupn1?.id } } : {}),
        ...(data?.groupn2?.id ? { groupn2: { id: data?.groupn2?.id } } : {}),
        ...(data?.groupn3?.id ? { groupn3: { id: data?.groupn3?.id } } : {}),
        ...(data?.groupn4?.id ? { groupn4: { id: data?.groupn4?.id } } : {}),
        ...(data?.groupn5?.id ? { groupn5: { id: data?.groupn5?.id } } : {}),
      });
      setGroup1Value({ value: data?.groupn1?.id, label: data?.groupn1?.title });
      setGroup2Value({ value: data?.groupn2?.id, label: data?.groupn2?.title });
      setGroup3Value({ value: data?.groupn3?.id, label: data?.groupn3?.title });
      setGroup4Value({ value: data?.groupn4?.id, label: data?.groupn4?.title });
      setGroup5Value({ value: data?.groupn5?.id, label: data?.groupn5?.title });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    group1 = group1Value;
    group2 = group2Value;
    group3 = group3Value;
    group4 = group4Value;
    group5 = group5Value;

    handleInitialDisabledConditions();
  }, [group1Value, group2Value, group3Value, group4Value, group5Value]);
  // groupn1: {
  //     id: data?.groupn1?.id,
  //   },
  //   groupn2: {
  //     id: data?.groupn2?.id,
  //   },
  //   groupn3: {
  //     id: data?.groupn3?.id,
  //   },
  //   groupn4: {
  //     id: data?.groupn4?.id,
  //   },
  //   groupn5: {
  //     id: data?.groupn5?.id,
  //   },

  async function groupn1() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn1`, {
        headers: authHeader(),
      });

      setGroupN1Options(response.data.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn2() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn2/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group2Search}`, {
        headers: authHeader(),
      });

      setGroupN2Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn3() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn3/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group3Search}`, {
        headers: authHeader(),
      });

      setGroupN3Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn4() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn4/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group4Search}`, {
        headers: authHeader(),
      });

      setGroupN4Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn5() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn5/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group5Search}`, {
        headers: authHeader(),
      });

      setGroupN5Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function getProductData() {
    try {
      const response = await axios
        .get(`${BASE_URL}product/page/0/30/asc/id/${productSearch ? productSearch : encodeURIComponent(" ")}`, {
          headers: authHeader(),
        })
        .then((res) => {
          setProductOptions(res.data.Index.map((use) => ({ value: use.id, label: use.product_Name + " - " + use.ean_Code + " - " + use.short_Name })));
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }

  async function submitData(e) {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.put(`${BASE_URL}v1/projectionentry/${id}`, projectionEntryData, {
        headers: authHeader(),
      });

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Data has been successfully updated!",
      });
      navigate(`/projectionentrytable/${pointindex}`);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function handleProductChange(e) {
    try {
      const response = await axios.get(`${BASE_URL}product/getById/${e.value}`, {
        headers: authHeader(),
      });

      let row = {
        product: {
          id: response.data.id,
        },
        product_name: response.data.productName + "-" + response.data.eanCode + "-" + response.data.shortName,
        qty: "",
      };

      setProjectionEntryData({
        ...projectionEntryData,
        projectionentryitem: [...projectionEntryData.projectionentryitem, row],
      });
    } catch (error) {
      console.log(error);
    }
  }

  const setDisabledFunctions = [setDisabled1, setDisabled2, setDisabled3, setDisabled4, setDisabled5];

  function removeRow(id) {
    const filteredData = projectionEntryData.projectionentryitem.filter((_, index) => index !== id);
    console.log(filteredData);

    setProjectionEntryData({
      ...projectionEntryData,
      projectionentryitem: filteredData,
    });
  }

  function handleInitialDisabledConditions() {
    setDisabledFunctions.forEach((setDisabled) => setDisabled(false));

    // Determine the index of the selected group (1-indexed)
    //const groupIndex = parseInt(groupValue.replace("groupn", ""));

    // Disable all previous groups and all groups after the next one
    // for (let i = 0; i < setDisabledFunctions.length; i++) {
    //   if (i < groupIndex - 1) {
    //     setDisabledFunctions[i](true); // Disable previous groups
    //   } else if (i > groupIndex) {
    //     setDisabledFunctions[i](true); // Disable subsequent groups
    //   }
    // }

    if (group1) {
      if (!group3 || group3?.value?.length === 0 || group3?.value === "neelam") {
        setDisabled3(true);
      }
      if (!group4 || group4?.value?.length === 0 || group4?.value === "neelam") {
        setDisabled4(true);
      }
      if (!group5 || group5?.value?.length === 0 || group5?.value === "neelam") {
        setDisabled5(true);
      }

      setDisabled2(false);
    }

    // Handle specific group logic
    if (group2) {
      if (!group1 || group1?.value?.length === 0 || group1?.value === "neelam") {
        setDisabled1(true);
      }

      if (!group4 || group4?.value?.length === 0 || group4?.value === "neelam") {
        setDisabled4(true);
      }
      if (!group5 || group5?.value?.length === 0 || group5?.value === "neelam") {
        setDisabled5(true);
      }

      setDisabled3(false);
    }

    if (group3) {
      if (!group1 || group1?.value?.length === 0 || group1?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2 || group2?.value?.length === 0 || group2?.value === "neelam") {
        setDisabled2(true);
      }

      if (!group5 || group5?.value?.length === 0 || group5?.value === "neelam") {
        setDisabled5(true);
      }

      setDisabled4(false);
    }

    if (group4) {
      if (!group1 || group1?.value?.length === 0 || group1?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2 || group2?.value?.length === 0 || group2?.value === "neelam") {
        setDisabled2(true);
      }
      if (!group3 || group3?.value?.length === 0 || group3?.value === "neelam") {
        setDisabled3(true);
      }

      setDisabled5(false);
    }

    if (group5) {
      if (!group1 || group1?.value?.length === 0 || group1?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2 || group2?.value?.length === 0 || group2?.value === "neelam") {
        setDisabled2(true);
      }
      if (!group3 || group3?.value?.length === 0 || group3?.value === "neelam") {
        setDisabled3(true);
      }
      if (!group4 || group4?.value?.length === 0 || group4?.value === "neelam") {
        setDisabled4(true);
      }
    }
  }

  function handleDisabledConditions(groupValue) {
    if (!groupValue) {
      return;
    }
    console.log(groupValue);

    // Reset all disabled states to false
    setDisabledFunctions.forEach((setDisabled) => setDisabled(false));

    // Determine the index of the selected group (1-indexed)
    const groupIndex = parseInt(groupValue.replace("groupn", ""));

    // Disable all previous groups and all groups after the next one
    // for (let i = 0; i < setDisabledFunctions.length; i++) {
    //   if (i < groupIndex - 1) {
    //     setDisabledFunctions[i](true); // Disable previous groups
    //   } else if (i > groupIndex) {
    //     setDisabledFunctions[i](true); // Disable subsequent groups
    //   }
    // }

    if (groupValue === "groupn1") {
      if (!group3Value || group3Value?.value?.length === 0 || group3Value?.value === "neelam") {
        setDisabled3(true);
      }
      if (!group4Value || group4Value?.value?.length === 0 || group4Value?.value === "neelam") {
        setDisabled4(true);
      }
      if (!group5Value || group5Value?.value?.length === 0 || group5Value?.value === "neelam") {
        setDisabled5(true);
      }
    }

    // Handle specific group logic
    if (groupValue === "groupn2") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }

      if (!group4Value || group4Value?.value?.length === 0 || group4Value?.value === "neelam") {
        setDisabled4(true);
      }
      if (!group5Value || group5Value?.value?.length === 0 || group5Value?.value === "neelam") {
        setDisabled5(true);
      }
    }

    if (groupValue === "groupn3") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2Value || group2Value?.value?.length === 0 || group2Value?.value === "neelam") {
        setDisabled2(true);
      }

      if (!group5Value || group5Value?.value?.length === 0 || group5Value?.value === "neelam") {
        setDisabled5(true);
      }
    }

    if (groupValue === "groupn4") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2Value || group2Value?.value?.length === 0 || group2Value?.value === "neelam") {
        setDisabled2(true);
      }
      if (!group3Value || group3Value?.value?.length === 0 || group3Value?.value === "neelam") {
        setDisabled3(true);
      }
    }

    if (groupValue === "groupn5") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2Value || group2Value?.value?.length === 0 || group2Value?.value === "neelam") {
        setDisabled2(true);
      }
      if (!group3Value || group3Value?.value?.length === 0 || group3Value?.value === "neelam") {
        setDisabled3(true);
      }
      if (!group4Value || group4Value?.value?.length === 0 || group4Value?.value === "neelam") {
        setDisabled4(true);
      }
    }
  }

  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = "font-size 2s";
    element.style.fontSize = "30px";
    element.style.fontWeight = "600";
    // font-weight: 600;
    setTimeout(() => {
      document.getElementById("typer1").classList.add("promote");
      element.style.fontSize = "24px";
    }, 1000);
  };

  const handledelete = (id) => {
    console.log(id);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${BASE_URL}projectionentry/${id}`, {
            headers: authHeader(),
          })
          .then((res) => {
            // window.location.reload();
            console.log(res.data);
            if (1 == 1) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              navigate(`/product/${pointindex}`);
            }
          })
          .catch((error) => {
            console.error("Error adding  :", error);
            if (error.message.includes("Network Error") || error.message.includes("Failed to fetch")) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `
              <div>
                <p id="issueText">Why do I have this issue?</p>
                <ul>
            
                <li id="typer1">You might have an internet issue</li>
                </ul>
              </div>
            `,
                didOpen: () => {
                  applyFontSizeTransition("issueText");
                },
              });
            }
          });
      }
    });
  };

  function changeHandler(e, element) {
    console.log(e);

    if (element) {
      setProjectionEntryData({
        ...projectionEntryData,
        [element]: {
          id: e.value,
        },
      });
    } else {
      const { name, value } = e.target;

      setProjectionEntryData({
        ...projectionEntryData,
        [name]: value,
      });
    }

    if (element === "groupn1") {
      setGroup1Value(e);
    } else if (element === "groupn2") {
      setGroup2Value(e);
    } else if (element === "groupn3") {
      setGroup3Value(e);
    } else if (element === "groupn4") {
      setGroup4Value(e);
    } else if (element === "groupn5") {
      setGroup5Value(e);
    }

    handleDisabledConditions(element);
  }

  function pcsHandler(e, index) {
    console.log(e.target.value);

    const newRow = projectionEntryData.projectionentryitem;

    newRow[index].qty = e.target.value;

    if (newRow[index].producttype === "kgproduct") {
      newRow[index].kgqty = Number((newRow[index].qty * newRow[index].uomsecondary).toFixed(3));
    }

    setProjectionEntryData({
      ...projectionEntryData,
      projectionentryitem: newRow,
    });
  }

  function kgHandler(e, index) {
    const newRow = projectionEntryData.projectionentryitem;

    newRow[index].kgqty = e.target.value;
    newRow[index].qty = Number((newRow[index].kgqty / newRow[index].uomsecondary).toFixed(1));

    setProjectionEntryData({
      ...projectionEntryData,
      projectionentryitem: newRow,
    });
  }

  function handleUom(e, index) {
    let rows = projectionEntryData.projectionentryitem;

    rows[index].uom = e.target.value;

    setProjectionEntryData({
      ...projectionEntryData,
      projectionentryitem: rows,
    });
  }

  function disableKgHandler(index) {
    console.log(projectionEntryData.projectionentryitem[index].uom);
    if (
      projectionEntryData.projectionentryitem[index].producttype === "kgproduct" &&
      (projectionEntryData.projectionentryitem[index].uom === "kg" || projectionEntryData.projectionentryitem[index].uom === "")
    ) {
      return false;
    }

    return true;
  }

  function disablePcsHandler(index) {
    if (projectionEntryData.projectionentryitem[index].uom === "pcs") {
      return false;
    }
    if (projectionEntryData.projectionentryitem[index].producttype !== "kgproduct") {
      return false;
    }
    return true;
  }

  useEffect(() => {
    groupn1();
    groupn2();
    groupn3();
    groupn4();
    groupn5();
    getProductData();
    getProjectionEntryData();
  }, []);

  useEffect(() => {
    getProductData();
  }, [productSearch]);

  console.log(projectionEntryData);

  return (
    <div>
      <MDBCard className="company">
        <div className="d-flex bd-highlight">
          <div className="card-header card-title w-100">VIEW PRODUCTS</div>
          {/* <button className='prox btn' onClick={()=>handleChange12()} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"/><polyline points="12 19 5 12 12 5"/></svg></button> */}
          {/* <a className=' delete btn' onClick={()=>handledelete(id)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg></a> */}

          <Link className="p-2 w-200 card-header card-title bd-highlight" variant="primary" style={{ width: "auto" }} onClick={() => handledelete(id)}>
            <Button className="btn-sm">Delete</Button>
          </Link>

          <Link className="p-2 w-200 card-header card-title bd-highlight" to={`/projectionentry/update/${id}/${pointindex}`}>
            <Button className="btn-sm">Edit</Button>
          </Link>

          <Link className="p-2 w-200 card-header card-title bd-highlight" to={`/projectionentrytable/${pointindex}`}>
            <Button className="btn-sm">Back</Button>
          </Link>
        </div>

        <Form className="product1">
          <Row>
            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="label">
                Starting Date<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control type="date" autoComplete="off" value={projectionEntryData.projectionDate} name="projectionDate" onChange={(e) => changeHandler(e)} disabled />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="cname2">Group-1</Form.Label>
              <Select value={group1Value} isDisabled={true} options={groupn1Options} onChange={(e) => changeHandler(e, "groupn1")} onInputChange={(e) => setGroup1Search(e)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="cname2">Group-2</Form.Label>
              <Select value={group2Value} isDisabled={true} options={groupn2Options} onChange={(e) => changeHandler(e, "groupn2")} onInputChange={(e) => setGroup2Search(e)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="label">Group-3</Form.Label>
              <Select value={group3Value} isDisabled={true} options={groupn3Options} onChange={(e) => changeHandler(e, "groupn3")} onInputChange={(e) => setGroup3Search(e)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="cname2">Group-4</Form.Label>
              <Select value={group4Value} isDisabled={true} options={groupn4Options} onChange={(e) => changeHandler(e, "groupn4")} onInputChange={(e) => setGroup4Search(e)} />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="cname2">Group-5</Form.Label>
              <Select value={group5Value} isDisabled={true} options={groupn5Options} onChange={(e) => changeHandler(e, "groupn5")} onInputChange={(e) => setGroup5Search(e)} />
            </Form.Group>
          </Row>

          <br></br>

          <Table striped responsive hover size="sm">
            <thead className="p-3 mb-2 bg-success text-white">
              <tr>
                <th>PRODUCT&nbsp;NAME</th>
                <th>UOM</th>
                <th>Quantity (PCS)</th>
                <th>Quantity (KG)</th>
              </tr>
            </thead>
            <tbody>
              {projectionEntryData.projectionentryitem &&
                projectionEntryData.projectionentryitem.map((data, index) => (
                  <tr>
                    <td>{data.product_name}</td>
                    <td>
                      <Form.Group as={Col} md="12">
                        <Form.Select name="uom" disabled={data.producttype !== "kgproduct" ? true : false} size="sm" onChange={(e) => handleUom(e, index)}>
                          <option>{data.producttype !== "kgproduct" ? "PCS" : "Select"}</option>
                          <option value="pcs">PCS</option>
                          <option value="kg">KG</option>
                        </Form.Select>
                      </Form.Group>
                    </td>

                    <td>
                      <Form.Group as={Col} md="12">
                        <Form.Control type="number" name="qty" onChange={(e) => pcsHandler(e, index)} min="0" className="addprojection-productname" value={data.qty} size="sm" disabled={true} />
                      </Form.Group>
                    </td>

                    <td>
                      <Form.Group as={Col} md="12">
                        <Form.Control type="number" name="kgqty" onChange={(e) => kgHandler(e, index)} className="addprojection-productname" min="0" size="sm" value={data.kgqty} disabled={true} />
                      </Form.Group>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Form>
      </MDBCard>
    </div>
  );
};

export default ViewProjectionEntry;
