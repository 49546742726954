import { MDBCard } from "mdbreact";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Row, Col, Table } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import Select from "react-select";
import { CSVLink } from "react-csv";

const ProjectionEntryReport = () => {
  const count = [{ value: 10 }, { value: 20 }, { value: 50 }, { value: 100 }, { value: 200 }];
  const [loading, setLoading] = useState(false);

  const [finalData, setFinalData] = useState();
  const [projectionEntryData, setProjectionEntryData] = useState([]);
  const [projectionEntryDataLoading, setProjectionEntryDataLoading] = useState(false);
  const [salesOrderData, setSalesOrderData] = useState([]);
  const [salesOrderDataLoading, setSalesOrderDataLoading] = useState(false);
  const [salesData, setSalesData] = useState();
  const [salesDataLoading, setSalesDataLoading] = useState(false);
  const [closingData, setClosingData] = useState();
  const [closingDataLoading, setClosingDataLoading] = useState(false);

  var finaldata = [];
  var projectionentrydata = [];
  var salesorderdata = [];
  var salesdata = [];
  var closingdata = [];

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [groupn1Options, setGroupN1Options] = useState();
  const [groupn2Options, setGroupN2Options] = useState();
  const [groupn3Options, setGroupN3Options] = useState();
  const [groupn4Options, setGroupN4Options] = useState();
  const [groupn5Options, setGroupN5Options] = useState();
  const [productOptions, setProductOptions] = useState();
  const [warehouseOptions, setWarehouseOptions] = useState();

  const [group1Value, setGroup1Value] = useState();
  const [group2Value, setGroup2Value] = useState();
  const [group3Value, setGroup3Value] = useState();
  const [group4Value, setGroup4Value] = useState();
  const [group5Value, setGroup5Value] = useState();
  const [warehouseValue, setWarehouseValue] = useState();

  const [group1Search, setGroup1Search] = useState("");
  const [group2Search, setGroup2Search] = useState("");
  const [group3Search, setGroup3Search] = useState("");
  const [group4Search, setGroup4Search] = useState("");
  const [group5Search, setGroup5Search] = useState("");
  const [productSearch, setProductSearch] = useState(encodeURIComponent(" "));
  const [warehouseSearch, setWarehouseSearch] = useState(encodeURIComponent(" "));

  const [disabled1, setDisabled1] = useState(false);
  const [disabled2, setDisabled2] = useState(false);
  const [disabled3, setDisabled3] = useState(false);
  const [disabled4, setDisabled4] = useState(false);
  const [disabled5, setDisabled5] = useState(false);

  const [currentPosts, setCurrentPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const [pageno, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState("asc");
  const [field, setField] = useState("id");
  const [pages, setPages] = useState();
  const [search, setSearch] = useState(encodeURIComponent(" "));

  const [retailerGroupId, setRetailerGroupId] = useState({
    groupn1: {
      id: "",
    },
    groupn2: {
      id: "",
    },
    groupn3: {
      id: "",
    },
    groupn4: {
      id: "",
    },
    groupn5: {
      id: "",
    },
  });

  async function groupn1() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn1`, {
        headers: authHeader(),
      });

      setGroupN1Options(response.data.map((data) => ({ value: data.id, label: data.title })));
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn2() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn2/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group2Search}`, {
        headers: authHeader(),
      });

      setGroupN2Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn3() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn3/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group3Search}`, {
        headers: authHeader(),
      });

      setGroupN3Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn4() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn4/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group4Search}`, {
        headers: authHeader(),
      });

      setGroupN4Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn5() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn5/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group5Search}`, {
        headers: authHeader(),
      });

      setGroupN5Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
    } catch (error) {
      console.log(error);
    }
  }

  async function getWarehouseData() {
    try {
      const response = await axios.get(`${BASE_URL}warehouse/page/0/10/asc/id/${warehouseSearch}`, {
        headers: authHeader(),
      });

      setWarehouseOptions(
        response.data.Index ? response.data.Index.map((data) => ({ label: data.name, value: data.id })) : response.data.data.map((data) => ({ label: data.warehousename, value: data.id }))
      );
    } catch (error) {}
  }

  async function getProductData() {
    try {
      const response = await axios
        .get(`${BASE_URL}product/page/0/30/asc/id/${productSearch ? productSearch : encodeURIComponent(" ")}`, {
          headers: authHeader(),
        })
        .then((res) => {
          setProductOptions(res.data.Index.map((use) => ({ value: use.id, label: use.product_Name + " - " + use.ean_Code + " - " + use.short_Name })));
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }

  async function getRetailerData() {
    setLoading(true);
    setSalesDataLoading(true);
    setSalesOrderDataLoading(true);
    getProjectionEntryData();
    let count = 0;
    let cnt = 0;
    let obj = retailerGroupId;

    for (const key of Object.keys(retailerGroupId)) {
      if (obj[key]?.id?.length === 0 || obj[key].id === "neelam") {
        obj[key].id = "neelam";
      } else if (obj[key]?.id?.length !== 0 && obj[key].id !== "neelam") {
        if (obj.groupn1?.id?.length !== 0 && obj?.groupn1?.id !== "neelam") {
          cnt++;
        } else {
          cnt = 0;
        }
      }
    }

    if (cnt === 0 || cnt === 1) {
      count = 0;
    } else if (cnt > 1) {
      count = cnt;
    }

    try {
      const response = await axios.get(
        `${BASE_URL}retailer/groupn/page/retaileranddistributorwithgroupn/0/20/asc/id/${retailerGroupId.groupn1.id}/${retailerGroupId.groupn2.id}/${retailerGroupId.groupn3.id}/${retailerGroupId?.groupn4?.id}/${retailerGroupId?.groupn5?.id}/${count}`,
        {
          headers: authHeader(),
        }
      );

      handleretaileranddistributor(response.data.Index, count);
    } catch (error) {
      console.log(error);
    }
  }

  async function getProjectionEntryData() {
    setProjectionEntryDataLoading(true);
    let count = 0;
    let cnt = 0;
    let obj = retailerGroupId;

    for (const key of Object.keys(retailerGroupId)) {
      if (obj[key]?.id?.length === 0 || obj[key].id === "neelam") {
        obj[key].id = "neelam";
      } else if (obj[key]?.id?.length !== 0 && obj[key].id !== "neelam") {
        if (obj.groupn1?.id?.length !== 0 && obj?.groupn1?.id !== "neelam") {
          cnt++;
        } else {
          cnt = 0;
        }
      }
    }

    if (cnt === 0 || cnt === 1) {
      count = 0;
    } else if (cnt > 1) {
      count = cnt;
    }

    try {
      const response = await axios.get(
        `${BASE_URL}v1/projectionentry/projection/report?g1=${retailerGroupId.groupn1.id}&g2=${retailerGroupId.groupn2.id}&g3=${retailerGroupId.groupn3.id}&g4=${retailerGroupId?.groupn4?.id}&g5=${retailerGroupId?.groupn5?.id}&fromdate=${fromDate}&todate=${toDate}&count=${count}`,
        {
          headers: authHeader(),
        }
      );

      handleProjectionEntryData(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function getSalesOrderData(retailerid, distributorid) {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}so/projectionreport?rid=${retailerid}&did=${distributorid}&fromdate=${fromDate}&todate=${toDate}`, {
        headers: authHeader(),
      });

      setSalesOrderData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setSalesOrderDataLoading(false);
    }
  }

  async function getSalesData(retailerData, distributorData) {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}sales/projectionreport?rid=${retailerData}&did=${distributorData}&fromdate=${fromDate}&todate=${toDate}&wid=${warehouseValue}`, {
        headers: authHeader(),
      });

      setSalesData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setSalesDataLoading(false);
    }
  }

  async function handleClosingData(data) {
    setClosingDataLoading(true);
    try {
      const productIds = data
        .filter((d) => d.productid !== null) // Filter out entries where productid is null
        .map((d) => d.productid);

      const requests = productIds.map((pid) => {
        return axios.get(`http://43.225.54.138:8080/scm/api/sales/closingprojectionreport?fromdate=${fromDate}&todate=${toDate}&wid=${warehouseValue}&pid=${pid}`, { headers: authHeader() });
      });

      // Wait for all requests to resolve
      const responses = await Promise.all(requests);

      // Extract the data from each response and flatten the arrays
      const allClosingData = responses.map((response) => response.data).flat();

      // This will now be a single flattened array

      // Optionally, set this data to state (if using React, for example)
      setClosingData(allClosingData);
    } catch (error) {
      console.log(error);
    } finally {
      setClosingDataLoading(false);
      setLoading(false);
    }
  }

  //-----------------------------Function----------------------------------

  const handleFinalDataForPagination = (finalCompleteData) => {
    const finalDataForPagination = finalCompleteData ? finalCompleteData : finalData;
    const lastIndex = currentPage * postsPerPage;
    const firstIndex = lastIndex - postsPerPage;

    const data = finalDataForPagination?.filter((d) => d.productid !== null);

    setCurrentPosts(data?.slice(firstIndex, lastIndex));
  };

  const handleFinalData = () => {
    const finalCompleteData = finalData?.map((data) => {
      let finalObj = closingData?.find((closingdata) => closingdata.productid === data.productid);

      if (finalObj) {
        return { ...data, ...finalObj };
      }
      return data;
    });

    const additionalProducts = closingData?.filter((data) => !finalData?.some((data1) => data1.productid === data.productid));

    if (additionalProducts) {
      finalCompleteData?.push(...additionalProducts);
    }

    setFinalData(finalCompleteData);
    handleFinalDataForPagination(finalCompleteData);
  };

  const handleProjectionEntrySOSalesData = () => {
    const finalObj = projectionEntryData.map((data, index) => {
      let ans = salesOrderData?.find((data1) => data1.productid === data.productid);

      if (ans) {
        // If the product exists in both arrays, merge data
        return { ...data, ...ans };
      }
      return data;
    });

    // Step 2: Check for products in 'product' but not in 'projectionEntryData'
    const additionalProducts = salesOrderData?.filter((data1) => !projectionEntryData.some((data2) => data2.productid === data1.productid));

    // Step 3: Add these products to the final object

    if (additionalProducts) {
      finalObj.push(...additionalProducts);
    }

    const salesOrderObj = finalObj.map((data) => {
      let salesCommonData = salesData?.find((salesdata) => salesdata.productid === data.productid);

      if (salesCommonData) {
        return { ...data, ...salesCommonData };
      }

      return data;
    });

    const additionalSalesOrderProducts = salesData?.filter((data1) => !salesOrderObj.some((data2) => data2.productid === data1.productid));

    // Step 3: Add these products to the final object

    if (additionalSalesOrderProducts) {
      salesOrderObj.push(...additionalSalesOrderProducts);
    }

    setFinalData(salesOrderObj);
    handleClosingData(salesOrderObj);
  };

  const handleProjectionEntryData = (products) => {
    const aggregatedProducts = () => {
      const aggregated = products.reduce((acc, product) => {
        // Check if the product with the same productid already exists

        const existingProduct = acc.find((item) => item.productid === product.productid);

        if (existingProduct) {
          // If product exists, sum the pcsqty and kgqty
          existingProduct.pcsqty += product.pcsqty;
          existingProduct.kgqty += product.kgqty;
          existingProduct.sokgqty = 0;
          existingProduct.sopcsqty = 0;
          existingProduct.skgqty = 0;
          existingProduct.spcsqty = 0;
          existingProduct.cancelkgqty = 0;
          existingProduct.cancelpcsqty = 0;
          existingProduct.closingkgqty = 0;
          existingProduct.closingpcsqty = 0;
        } else {
          // If product doesn't exist, add it to the array
          product.sokgqty = 0;
          product.sopcsqty = 0;
          product.skgqty = 0;
          product.spcsqty = 0;
          product.cancelkgqty = 0;
          product.cancelpcsqty = 0;
          product.closingkgqty = 0;
          product.closingpcsqty = 0;
          acc.push({ ...product });
        }

        return acc;
      }, []);

      return aggregated;
    };

    setProjectionEntryData(aggregatedProducts());
    setProjectionEntryDataLoading(false);
  };

  function handleretaileranddistributor(data, count) {
    const retailerData = data.filter((data) => data.rolename === "ROLE_RETAILER");
    const distributorData = data.filter((data) => data.rolename === "ROLE_DISTRIBUTOR");

    // salesOrderDataBasedonRetailerIdandDistributorId(retailerData, distributorData);
    let retailerid = "";
    let distributorid = "";

    for (let i = 0; i < retailerData.length; i++) {
      retailerid += retailerData[i].id + ",";
    }

    for (let i = 0; i < distributorData.length; i++) {
      distributorid += distributorData[i].id + ",";
    }

    getSalesOrderData(retailerid, distributorid, count);
    getSalesData(retailerid, distributorid);
  }

  const setDisabledFunctions = [setDisabled1, setDisabled2, setDisabled3, setDisabled4, setDisabled5];

  function handleDisabledConditions(groupValue) {
    if (!groupValue) {
      return;
    }

    // Reset all disabled states to false
    setDisabledFunctions.forEach((setDisabled) => setDisabled(false));

    // Determine the index of the selected group (1-indexed)
    const groupIndex = parseInt(groupValue.replace("groupn", ""));

    // Disable all previous groups and all groups after the next one
    // for (let i = 0; i < setDisabledFunctions.length; i++) {
    //   if (i < groupIndex - 1) {
    //     setDisabledFunctions[i](true); // Disable previous groups
    //   } else if (i > groupIndex) {
    //     setDisabledFunctions[i](true); // Disable subsequent groups
    //   }
    // }

    if (groupValue === "groupn1") {
      if (!group3Value || group3Value?.value?.length === 0 || group3Value?.value === "neelam") {
        setDisabled3(true);
      }
      if (!group4Value || group4Value?.value?.length === 0 || group4Value?.value === "neelam") {
        setDisabled4(true);
      }
      if (!group5Value || group5Value?.value?.length === 0 || group5Value?.value === "neelam") {
        setDisabled5(true);
      }
    }

    // Handle specific group logic
    if (groupValue === "groupn2") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }

      if (!group4Value || group4Value?.value?.length === 0 || group4Value?.value === "neelam") {
        setDisabled4(true);
      }
      if (!group5Value || group5Value?.value?.length === 0 || group5Value?.value === "neelam") {
        setDisabled5(true);
      }
    }

    if (groupValue === "groupn3") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2Value || group2Value?.value?.length === 0 || group2Value?.value === "neelam") {
        setDisabled2(true);
      }

      if (!group5Value || group5Value?.value?.length === 0 || group5Value?.value === "neelam") {
        setDisabled5(true);
      }
    }

    if (groupValue === "groupn4") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2Value || group2Value?.value?.length === 0 || group2Value?.value === "neelam") {
        setDisabled2(true);
      }
      if (!group3Value || group3Value?.value?.length === 0 || group3Value?.value === "neelam") {
        setDisabled3(true);
      }
    }

    if (groupValue === "groupn5") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2Value || group2Value?.value?.length === 0 || group2Value?.value === "neelam") {
        setDisabled2(true);
      }
      if (!group3Value || group3Value?.value?.length === 0 || group3Value?.value === "neelam") {
        setDisabled3(true);
      }
      if (!group4Value || group4Value?.value?.length === 0 || group4Value?.value === "neelam") {
        setDisabled4(true);
      }
    }
  }

  function changeHandler(e, element) {
    // if (element) {
    //   setProjectionEntryData({
    //     ...projectionEntryData,
    //     [element]: {
    //       id: e.value,
    //     },
    //   });
    // } else {
    //   const { name, value } = e.target;

    //   setProjectionEntryData({
    //     ...projectionEntryData,
    //     [name]: value,
    //   });
    // }
    setRetailerGroupId({
      ...retailerGroupId,
      [element]: {
        id: e?.value,
      },
    });

    if (element === "groupn1") {
      setGroup1Value(e);
    } else if (element === "groupn2") {
      setGroup2Value(e);
    } else if (element === "groupn3") {
      setGroup3Value(e);
    } else if (element === "groupn4") {
      setGroup4Value(e);
    } else if (element === "groupn5") {
      setGroup5Value(e);
    } else if (element === "warehouse") {
      setWarehouseValue(e.value);
    }

    handleDisabledConditions(element);
  }

  function handleQuantityChange(e, index) {
    // let rows = projectionEntryData.projectionentryitem;
    // rows[index].quantity = e.target.value;
    // setProjectionEntryData({
    //   ...projectionEntryData,
    //   projectionentryitem: rows,
    // });
  }

  function handleRecentPage(i) {
    setCurrentPage(i);
  }

  function pagination() {
    const totalPages = Math.ceil(finalData?.length / postsPerPage);

    const maxPagesToShow = 5; // Maximum number of page buttons to show
    const siblingsCount = 2; // Number of sibling pages to show
    const firstPage = 1;
    const lastPage = totalPages;

    let pageNumbers = [];

    const startPage = Math.max(firstPage, pageno + 1 - siblingsCount);
    const endPage = Math.min(lastPage, pageno + 1 + siblingsCount);

    // Show first page button and dots if needed
    if (startPage > firstPage + 1) {
      pageNumbers.push(
        <li key={firstPage} className={firstPage === currentPage ? "newway" : "noway"} onClick={() => handleRecentPage(firstPage)}>
          {firstPage}
        </li>
      );
      pageNumbers.push(
        <li key="start-dots" className="dots" onClick={() => handleRecentPage(Math.max(0, startPage - siblingsCount))}>
          ...
        </li>
      );
    } else if (startPage > firstPage) {
      pageNumbers.push(
        <li key={firstPage} className={firstPage === currentPage ? "newway" : "noway"} onClick={() => handleRecentPage(firstPage)}>
          {firstPage}
        </li>
      );
    }

    // Collect page numbers for current range
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={i === currentPage ? "newway" : "noway"} onClick={() => handleRecentPage(i)}>
          {i}
        </li>
      );
    }

    // Show last page button and dots if needed
    if (endPage < lastPage - 1) {
      pageNumbers.push(
        <li key="end-dots" className="dots" onClick={() => handleRecentPage(Math.min(lastPage, endPage + siblingsCount))}>
          ...
        </li>
      );
      pageNumbers.push(
        <li key={lastPage} className={lastPage === currentPage ? "newway" : "noway"} onClick={() => handleRecentPage(lastPage)}>
          {lastPage}
        </li>
      );
    } else if (endPage < lastPage) {
      pageNumbers.push(
        <li key={lastPage} className={lastPage === currentPage ? "newway" : "noway"} onClick={() => handleRecentPage(lastPage)}>
          {lastPage}
        </li>
      );
    }

    return pageNumbers;
  }
  function previousHandler() {
    setCurrentPage(currentPage - 1 < 1 ? 1 : currentPage - 1);
  }

  function nextHandler() {
    setCurrentPage(currentPage + 1 > Math.ceil(finalData?.length / postsPerPage) ? Math.ceil(finalData?.length / postsPerPage) : currentPage + 1);
  }

  // function sortHandler(value){
  //     // if (sort === "asc") {
  //     //     setSort("desc");
  //     // }else {
  //     //     setSort("asc");
  //     // }
  //     // setField(value);

  //     reportData.sort((a,b) => a[value] - b[value])

  //     setReportData(reportData);
  // }

  function sortHandler(value) {
    // console.log("Sorting by:", value);

    // // Toggle sort order
    // const newSortOrder = sort === "asc" ? "desc" : "asc";
    // setSort(newSortOrder);

    // // Create a copy of the data to avoid mutating the state directly
    // const sortedData = [...retailerData].sort((a, b) => {
    //   console.log(typeof a[value]);

    //   if (typeof a[value] === "string") {
    //     if (newSortOrder === "asc") {
    //       return a[value].localeCompare(b[value]); // Ascending order
    //     } else {
    //       return b[value].localeCompare(a[value]); // Descending order
    //     }
    //   } else {
    //     if (newSortOrder === "asc") {
    //       return a[value] - b[value]; // Ascending order
    //     } else {
    //       return b[value] - a[value]; // Descending order
    //     }
    //   }
    // });

    // console.log("Sorted Data:", sortedData);

    // Update the state with the sorted data
    //setSalesOrderData(sortedData);

    if (value.type === "svg") {
      return;
    }
    setSort(sort === "asc" ? "desc" : "asc");
    setField(value);
  }

  useEffect(() => {
    groupn1();
    groupn2();
    groupn3();
    groupn4();
    groupn5();
    getProductData();
    getWarehouseData();
  }, []);

  useEffect(() => {
    groupn1();
  }, [group1Search]);

  useEffect(() => {
    groupn2();
  }, [group2Search]);

  useEffect(() => {
    groupn3();
  }, [group3Search]);

  useEffect(() => {
    groupn4();
  }, [group4Search]);

  useEffect(() => {
    groupn5();
  }, [group5Search]);

  useEffect(() => {
    getWarehouseData();
  }, [warehouseSearch]);

  useEffect(() => {
    handleProjectionEntrySOSalesData();
  }, [projectionEntryData, salesOrderData, salesData]);

  useEffect(() => {
    handleFinalData();
  }, [closingData]);

  useEffect(() => {
    handleFinalDataForPagination();
  }, [currentPage, postsPerPage]);

  //currentPosts && salesData && salesOrderData && projectionEntryData && finalData && closingData && closingData?.length !== 0 && currentPosts?.length !== 0
  return (
    <div>
      <MDBCard className="company">
        <h2 className="projection-header">Projection Entry Report</h2>

        <Form className="product1">
          <Row>
            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="label">
                From Date<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="label">
                To Date<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="label">
                Warehouse<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Select options={warehouseOptions} onChange={(e) => setWarehouseValue(e.value)} onInputChange={(e) => setWarehouseSearch(e)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="cname2">Group-1</Form.Label>
              <Select value={group1Value} isDisabled={disabled1} options={groupn1Options} onChange={(e) => changeHandler(e, "groupn1")} onInputChange={(e) => setGroup1Search(e)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="cname2">Group-2</Form.Label>
              <Select value={group2Value} isDisabled={disabled2} options={groupn2Options} onChange={(e) => changeHandler(e, "groupn2")} onInputChange={(e) => setGroup2Search(e)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="label">Group-3</Form.Label>
              <Select value={group3Value} isDisabled={disabled3} options={groupn3Options} onChange={(e) => changeHandler(e, "groupn3")} onInputChange={(e) => setGroup3Search(e)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="cname2">Group-4</Form.Label>
              <Select value={group4Value} isDisabled={disabled4} options={groupn4Options} onChange={(e) => changeHandler(e, "groupn4")} onInputChange={(e) => setGroup4Search(e)} />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="cname2">Group-5</Form.Label>
              <Select value={group5Value} isDisabled={disabled5} options={groupn5Options} onChange={(e) => changeHandler(e, "groupn5")} onInputChange={(e) => setGroup5Search(e)} />
            </Form.Group>
          </Row>

          <br></br>
          <Button variant="primary" style={{ width: "auto", marginBottom: "30px", marginLeft: "0px" }} onClick={getRetailerData}>
            SUBMIT
          </Button>

          <CSVLink id="button-export" data={Array.isArray(currentPosts) && currentPosts.length > 0 ? currentPosts : []}>
            <Button variant="primary" style={{ width: "auto", marginBottom: "30px", color: "white" }}>
              Export
            </Button>
          </CSVLink>

          <div>
            <div className="bottole">
              <p>Show entries</p>
              <select
                className="newselect"
                onChange={(e) => {
                  setCurrentPage(1);
                  setPostsPerPage(e.target.value);
                }}
              >
                {count.map((item) => (
                  <option value={item.value}>{item.value}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="table-responsive-2">
            <table className="table thrtable projection-entry-report-table">
              <thead style={{ border: "2px solid black" }}>
                <tr>
                  <th style={{ border: "2px solid black !important" }} rowSpan="2">
                    Product Id
                  </th>
                  <th style={{ border: "2px solid black !important" }} rowSpan="2">
                    Item Name
                  </th>
                  <th style={{ border: "2px solid black !important" }} rowSpan="2">
                    Date
                  </th>
                  <th style={{ border: "2px solid black !important" }} rowSpan="2">
                    EAN Code
                  </th>
                  <th style={{ border: "2px solid black !important" }} colSpan="2">
                    Projection Quantity
                  </th>
                  <th style={{ border: "2px solid black !important" }} colSpan="2">
                    Order Received
                  </th>
                  <th style={{ border: "2px solid black !important" }} colSpan="2">
                    Dispatch Quantity
                  </th>
                  <th style={{ border: "2px solid black !important" }} colSpan="2">
                    Pre Close Quantity
                  </th>
                  <th style={{ border: "2px solid black !important" }} colSpan="2">
                    Pending Quantity
                  </th>
                  <th style={{ border: "2px solid black !important" }} colSpan="2">
                    Stock
                  </th>
                  <th style={{ border: "2px solid black !important" }} colSpan="2">
                    Order To Give
                  </th>
                </tr>
                <tr>
                  <th style={{ border: "0.5px solid black" }}>KGS</th>
                  <th style={{ borderLeft: "0.5px solid black", borderRight: "2px solid black !important" }}>PCS</th>
                  <th style={{ border: "0.5px solid black" }}>KGS</th>
                  <th style={{ borderLeft: "0.5px solid black", borderRight: "2px solid black !important" }}>PCS</th>
                  <th style={{ border: "0.5px solid black" }}>KGS</th>
                  <th style={{ borderLeft: "0.5px solid black", borderRight: "2px solid black !important" }}>PCS</th>
                  <th style={{ border: "0.5px solid black" }}>KGS</th>
                  <th style={{ borderLeft: "0.5px solid black", borderRight: "2px solid black !important" }}>PCS</th>
                  <th style={{ border: "0.5px solid black" }}>KGS</th>
                  <th style={{ borderLeft: "0.5px solid black", borderRight: "2px solid black !important" }}>PCS</th>
                  <th style={{ border: "0.5px solid black" }}>KGS</th>
                  <th style={{ borderLeft: "0.5px solid black", borderRight: "2px solid black !important" }}>PCS</th>
                  <th style={{ border: "0.5px solid black" }}>KGS</th>
                  <th style={{ borderLeft: "0.5px solid black", borderRight: "2px solid black !important" }}>PCS</th>
                </tr>
              </thead>
              <tbody style={{ border: "2px solid black", position: "relative" }}>
                {loading && projectionEntryDataLoading && salesDataLoading && salesOrderDataLoading && closingDataLoading ? (
                  <div className="spinner-container">
                    <div className="spinner"></div>
                  </div>
                ) : (
                  currentPosts?.map((data) => (
                    <tr key={data.productid}>
                      {/* Add your table data rows here */}
                      <td>{data.productid}</td>
                      <td>{data.productname}</td>
                      <td>{data.pedate}</td>
                      <td>{data.eancode}</td>
                      <td style={{ border: "0.5px solid black", borderBottom: "2px solid black" }}>{data.kgqty?.toFixed(3) || 0}</td>
                      <td style={{ borderLeft: "0.5px solid black", borderRight: "2px solid black" }}>{data.pcsqty || 0}</td>
                      <td style={{ border: "0.5px solid black", borderBottom: "2px solid black" }}>{data.sokgqty?.toFixed(3) || 0}</td>
                      <td style={{ borderLeft: "0.5px solid black", borderRight: "2px solid black" }}>{data.sopcsqty || 0}</td>
                      <td style={{ border: "0.5px solid black", borderBottom: "2px solid black" }}>{data.skgqty?.toFixed(3) || 0}</td>
                      <td style={{ borderLeft: "0.5px solid black", borderRight: "2px solid black" }}>{data.spcsqty || 0}</td>
                      <td style={{ border: "0.5px solid black", borderBottom: "2px solid black" }}>{data.cancelkgqty?.toFixed(3) || 0}</td>
                      <td style={{ borderLeft: "0.5px solid black", borderRight: "2px solid black !important" }}>{data.cancelpcsqty || 0}</td>
                      <td style={{ border: "0.5px solid black", borderBottom: "2px solid black" }}>
                        {(() => {
                          const sokgqty = Number(data.sokgqty || 0);
                          const skgqty = Number(data.skgqty || 0);
                          const cancelkgqty = Number(data.cancelkgqty || 0);
                          const result = sokgqty - skgqty - cancelkgqty;
                          return result > 0 ? result.toFixed(3) : "0.000";
                        })()}
                      </td>
                      <td style={{ borderLeft: "0.5px solid black", borderRight: "2px solid black" }}>
                        {(() => {
                          const sopcsqty = Number(data.sopcsqty || 0);
                          const spcsqty = Number(data.spcsqty || 0);
                          const cancelpcsqty = Number(data.cancelpcsqty || 0);
                          const result = sopcsqty - spcsqty - cancelpcsqty;
                          return result > 0 ? result.toFixed(3) : "0.000";
                        })()}
                      </td>
                      <td style={{ border: "0.5px solid black", borderBottom: "2px solid black" }}>{data.closingkgqty?.toFixed(3) || 0}</td>
                      <td style={{ borderLeft: "0.5px solid black", borderRight: "2px solid black" }}>{data.closingpcsqty || 0}</td>
                      <td style={{ border: "0.5px solid black", borderBottom: "2px solid black" }}>
                        {(() => {
                          const sokgqty = Number(data.sokgqty || 0);
                          const skgqty = Number(data.skgqty || 0);
                          const cancelkgqty = Number(data.cancelkgqty || 0);
                          const closingkgqty = Number(data.closingkgqty || 0);
                          const result = sokgqty - skgqty - cancelkgqty;
                          const finalResult = result > 0 ? result - closingkgqty : 0 - closingkgqty;

                          return finalResult.toFixed(3);
                        })()}
                      </td>
                      <td style={{ borderLeft: "0.5px solid black", borderRight: "2px solid black" }}>
                        {(() => {
                          const sopcsqty = Number(data.sopcsqty || 0);
                          const spcsqty = Number(data.spcsqty || 0);
                          const cancelpcsqty = Number(data.cancelpcsqty || 0);
                          const closingpcsqty = Number(data.closingpcsqty || 0);
                          const result = sopcsqty - spcsqty - cancelpcsqty;
                          const finalResult = result > 0 ? result - closingpcsqty : 0 - closingpcsqty;

                          return finalResult.toFixed(3);
                        })()}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          {currentPosts && (
            <div className="projection-table-pagination">
              <p>
                Showing 1 to {currentPosts?.length} Values of {currentPage} Page
              </p>
              <div className="prev-next-div">
                <p onClick={previousHandler} style={{ cursor: "pointer" }}>
                  Previous
                </p>
                <div style={{ display: "flex", gap: "10px" }}>{pagination()}</div>
                <p onClick={nextHandler} style={{ cursor: "pointer" }}>
                  Next
                </p>
              </div>
            </div>
          )}
        </Form>
      </MDBCard>
    </div>
  );
};

export default ProjectionEntryReport;
