import React, { useEffect, useState } from "react";
import { BASE_URL } from "../services/Api";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { MDBCard, MDBCardBody } from "mdbreact";
import authHeader from "../services/auth-header";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const Datatable = ({ header, tableTitle, apiField, addButton, createRoute, updateRoute, viewRoute }) => {
  console.log({ header, tableTitle, apiField, addButton, createRoute, updateRoute, viewRoute });
  const { pointIndex } = useParams();
  console.log(pointIndex);

  const count = [{ value: 10 }, { value: 20 }, { value: 50 }, { value: 100 }, { value: 200 }];
  const navigate = useNavigate();

  //useStates
  const [projectionData, setProjectionData] = useState([]);
  const [search, setSearch] = useState(encodeURIComponent(" "));
  const [pages, setPages] = useState();
  const [currentPage, setCurrentPage] = useState(pointIndex ? pointIndex - 1 : 0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("desc");
  const [currentValue, setCurrentValue] = useState("id");
  const [totalEnteries, setTotalEnteries] = useState();

  async function getProjectionData() {
    const response = await axios
      .get(`${BASE_URL}v1/${apiField}/page?pageNumber=${currentPage}&pageSize=${limit}&field=${currentValue}&direction=${sort}&search=${search}`, {
        headers: authHeader(),
      })
      .then((response) => {
        console.log(`${BASE_URL}projection/page/${currentPage}/${limit}/${sort}/${currentValue}/${search}`);
        setProjectionData(response.data.content);
        setPages(response.data.totalPages);
        setTotalEnteries(response.data.totalElements);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //functions
  function limitHandler(e) {
    setLimit(e.target.value);
    setCurrentPage(0);
  }

  function sortHandler(value) {
    if (value.type === "svg") {
      return;
    }
    setSort(sort === "asc" ? "desc" : "asc");
    setCurrentValue(value);
  }

  function addButtonHandler() {
    navigate(createRoute);
  }

  function handleRecentPage(i) {
    setCurrentPage(i - 1);
  }

  function updateHandler(id) {
    navigate(`${updateRoute}/${id}/${currentPage + 1}`);
  }

  function viewHandler(label, id) {
    if (label === "Action") {
      navigate(`${viewRoute}/${id}/${currentPage + 1}`);
      return;
    } else {
      updateHandler(id);
    }
  }

  function pagination() {
    const totalPages = pages;
    // const maxPagesToShow = 5; Maximum number of page buttons to show
    const siblingsCount = 2; // Number of sibling pages to show
    const firstPage = 1;
    const lastPage = totalPages;

    let pageNumbers = [];

    const startPage = Math.max(firstPage, currentPage + 1 - siblingsCount);
    const endPage = Math.min(lastPage, currentPage + 1 + siblingsCount);

    console.log("-------------------------------------------------");
    console.log("Total Pages :", totalPages);
    console.log("siblingsCount :", siblingsCount);
    console.log("firstPage :", firstPage);
    console.log("lastPage :", lastPage);
    console.log("startPage :", startPage);
    console.log("endPage :", endPage);
    console.log("currentPage", currentPage);
    console.log("Start page calculations", currentPage + 1 - siblingsCount);
    console.log("End page Calculations", currentPage + 1 + siblingsCount);
    console.log("--------------------------------------------------");

    // Show first page button and dots if needed
    if (startPage > firstPage + 1) {
      pageNumbers.push(
        <li key={firstPage} className={firstPage === currentPage + 1 ? "newway" : "noway"} onClick={() => handleRecentPage(firstPage)}>
          {firstPage}
        </li>
      );
      pageNumbers.push(
        <li key="start-dots" className="dots" onClick={() => handleRecentPage(Math.max(0, startPage - siblingsCount))}>
          ...
        </li>
      );
    } else if (startPage > firstPage) {
      pageNumbers.push(
        <li key={firstPage} className={firstPage === currentPage + 1 ? "newway" : "noway"} onClick={() => handleRecentPage(firstPage)}>
          {firstPage}
        </li>
      );
    }

    // Collect page numbers for current range
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={i === currentPage + 1 ? "newway" : "noway"} onClick={() => handleRecentPage(i)}>
          {i}
        </li>
      );
    }

    // Show last page button and dots if needed
    if (endPage < lastPage - 1) {
      pageNumbers.push(
        <li key="end-dots" className="dots" onClick={() => handleRecentPage(Math.min(lastPage, endPage + siblingsCount))}>
          ...
        </li>
      );
      pageNumbers.push(
        <li key={lastPage} className={lastPage === currentPage + 1 ? "newway" : "noway"} onClick={() => handleRecentPage(lastPage)}>
          {lastPage}
        </li>
      );
    } else if (endPage < lastPage) {
      pageNumbers.push(
        <li key={lastPage} className={lastPage === currentPage + 1 ? "newway" : "noway"} onClick={() => handleRecentPage(lastPage)}>
          {lastPage}
        </li>
      );
    }

    return pageNumbers;
  }

  //useEffect
  useEffect(() => {
    getProjectionData();
  }, []);

  useEffect(() => {
    getProjectionData();
  }, [limit, sort, currentValue, search, currentPage]);

  function previousHandler() {
    setCurrentPage(currentPage - 1 < 0 ? 0 : currentPage - 1);
  }

  function nextHandler() {
    setCurrentPage(currentPage + 1 > pages - 1 ? pages - 1 : currentPage + 1);
  }

  console.log(projectionData);

  return (
    <div>
      <MDBCard data-aos="fade">
        <div className="card-header flex">
          <h2 className="projection-header">{tableTitle}</h2>
          <button onClick={() => addButtonHandler()} className="btn btn-primary thh">
            {addButton}
          </button>
        </div>

        <MDBCardBody>
          <div className="entryandsearch">
            <div className="bottole">
              <p>Show entries</p>
              <select className="newselect" onChange={(e) => limitHandler(e)}>
                {count.map((item) => (
                  <option>{item.value}</option>
                ))}
              </select>
            </div>

            <div className=" ">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Search . . ."
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
          </div>

          <table className="table thrtable table-animation">
            <thead>
              <tr>
                {header.map((head) => (
                  <th onClick={() => sortHandler(head.field)}>
                    {head.label} {head.label !== "Action" ? sort === "asc" ? <TiArrowSortedDown /> : <TiArrowSortedUp /> : ""}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className="the">
              {projectionData &&
                projectionData.map((data) => (
                  <tr key={data.projectionid}>
                    {header.map((head) => (
                      <td onClick={() => viewHandler(head.label, data.id)} className={head.label === "Action" ? "zindex" : ""}>
                        {head.label !== "Action" ? data[head.field] : head.field}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>

          <div className="projection-table-pagination">
            <p>
              Showing 1 to {projectionData?.length} of {currentPage + 1} Page
            </p>
            <div className="prev-next-div">
              <div onClick={previousHandler} style={{ cursor: "pointer" }}>
                Prev
              </div>
              <div style={{ display: "flex", gap: "10px" }}>{pagination()}</div>

              <div onClick={nextHandler} style={{ cursor: "pointer" }}>
                Next
              </div>
            </div>
          </div>
        </MDBCardBody>
      </MDBCard>
    </div>
  );
};

export default Datatable;
