// import axios from 'axios';
// import React from 'react'
// import { useEffect } from 'react';
// import { useState } from 'react';
// import { Button, Col, Container, Form, Row } from 'react-bootstrap';
// import { Link, useNavigate, useParams } from 'react-router-dom'
// import Select from 'react-select';
// import authHeader from '../../services/auth-header';
// import ProductService from '../../services/ProductService';
// import BrandService from '../../services/Brand';
// import { MDBCard } from 'mdbreact';
// import Swal from 'sweetalert2';

// export default function PEdit() {
//   const [isLoading, setIsLoading] = useState(false);
//   const applyFontSizeTransition = (elementId) => {
//     const element = document.getElementById(elementId);
//     element.style.transition = 'font-size 2s';
//     element.style.fontSize = '30px';
//     element.style.fontWeight = '600';
//     // font-weight: 600;
//     setTimeout(() => {

//       document.getElementById("typer1").classList.add('promote');
//       element.style.fontSize = '24px';
//     }, 1000);
//   };
//   /* Product Id */
//   let Toast = Swal.mixin({
//     toast: true,
//     position: 'top-end',
//     showConfirmButton: false,
//     timer: 3000,
//     timerProgressBar: true,
//     didOpen: (toast) => {
//       toast.addEventListener('mouseenter', Swal.stopTimer)
//       toast.addEventListener('mouseleave', Swal.resumeTimer)
//     }
//   })
//   const { id } = useParams();

//   /* Navigator */
//   const navigate = useNavigate();

//   const [cgst, setCgst] = useState("");
//   const [sgst, setSgst] = useState("");
//   const [name, setName] = useState("");
//   const [category, setcategory] = useState("");
//   const [standardQtyPerBox, setstandardQtyPerBox] = useState("");
//   const [shortName, setshortName] = useState("");
//   const [eanCode, seteanCode] = useState("");
//   const [uomPrimary, setuomPrimary] = useState('');
//   const [uomSecondary, setuomSecondary] = useState('');
//   const [mrp, setmrp] = useState('');
//   const [capacity, setcapacity] = useState('');
//   const [diameter, setdiameter] = useState('');
//   const [hsnCode, sethsnCode] = useState('');
//   const [brand, setbrand] = useState('');
//   const [igst, setIgst] = useState("");
//   const [igstLedger, setIgstLedger] = useState("");
//   const [cgstLedger, setCgstLedger] = useState("");
//   const [sgstLedger, setSgstLedger] = useState("");
//   const [igstdata1, setIgstdata1] = useState([]);
//   const [igstdata, setIgstdata] = useState([]);
//   const [igst1, setigst1] = useState(null);
//   const [productType, setproductType] = useState('');
//   const [productGroup, setproductGroup] = useState('');
//   const [dlp, setdlp] = useState('');
//   const [branddata, setBrandData] = useState([]);
//   const [brandName, setBrandName] = useState([]);
//   const [error, setError] = useState(false);
//   const [files, setFiles] = useState('');
//   const [checked, setChecked] = useState(false);
//   const [productKind,setproductKind]=useState('');
//   const [imagedisplay, setdiplay] = useState()
//   const [costprice, setcostprice] = useState("");
//   useEffect(() => {
//     /* Product Data */

//     ProductService.getProductEdit(id).then((res) => {
//       console.log("mello", res.data);
//       setCgst(res.data.cgst);
//       setSgst(res.data.sgst);
//       setName(res.data.productName);
//       setcategory(res.data.category);
//       setstandardQtyPerBox(res.data.standardQtyPerBox);
//       setshortName(res.data.shortName);
//       seteanCode(res.data.eanCode);
//       setIgst(res.data.igst);
//       setIgstLedger(res.data.igstLedger);
//       setCgstLedger(res.data.cgstLedger);
//       setSgstLedger(res.data.sgstLedger);
//       setuomPrimary(res.data.uomPrimary);
//       setuomSecondary(res.data.uomSecondary);
//       setcapacity(res.data.capacity);
//       setdiameter(res.data.diameter);
//       setmrp(res.data.mrp);
//       sethsnCode(res.data.hsnCode);
//       setbrand(res.data.brand);
//       setproductType(res.data.productType);
//       setproductKind(res.data.productKind);
//       setdlp(res.data.dlp);
//       setproductGroup(res.data.productGroup);

//       const aa = res.data.igstLedger;
//       ProductService.getProductIgst(1).then(res1 => {
//         (res1.data).map(user => {
//           if (user.id == aa) {
//             setigst1({ value: user.id, label: user.per })
//           }
//         })

//       })
//       setdiplay(res.data.productImages);
//       setFiles(res.data.productImages)

//       /* Brand Data */
//       let cid = res.data.brand;

//       BrandService.getBrandEdit(cid).then(respo => {
//         setBrandName({ value: (respo.data).id, label: (respo.data).brandName })
//       });

//     });

//     /* IGST Data */
//     ProductService.getigstByIdonedata().then(response => {
//       setIgstdata((response.data).map(user => ({ value: user.id, label: user.per })));
//     })

//     /* Brand All DATA */
//     BrandService.getBrand().then(res => {
//       let aa = res.data;
//       setBrandData(aa.map(user => ({ value: user.id, label: user.brandName })))
//     });

//     const timeoutId = setTimeout(() => {
//       console.log('Delayed message after 5  seconds!');
//     }, 5000);

//      return () => clearTimeout(timeoutId);
//   }, []);

//   // useEffect(() => {
//   //   setIgstdata(igstdata1.map(user => ({ value: user.id, label: user.per })))
//   // }, [igstdata1]);

//   const onSelectIgst = (e) => {
//     // console.log("Selected: ", e);
//     setigst1(e);
//   };

//   const handleChangeigst = (e) => {
//     const value = e.value;
//     setIgstLedger(value)
//   }

//   const gstcal = (e) => {
//     const value = e.value;
//     ProductService.getgetIgstByIdonedata(value).then((response) => {
//       setIgst(response.data.per);
//       var igstper = response.data.per;
//       var cgstper = parseInt(igstper / 2);
//       var sgstper = parseInt(igstper / 2);

//       setCgst(cgstper);
//       setSgst(sgstper);

//       ProductService.getcgstByIdonedata(cgstper).then((response1) => {
//         setCgstLedger(response1.data.id)
//       })
//       ProductService.getsgstByIdonedata(sgstper).then((response2) => {
//         setSgstLedger(response2.data.id)
//       })
//     })
//   }

//   const handleChange = (e) => {
//     const name = e.target.name;
//     const value = e.target.value;
//     if (name == "name") {
//       setName(value);
//     }
//     else if (name == "shortName") {
//       setshortName(value)
//     } else if (name == "eanCode") {
//       seteanCode(value)
//     } else if (name == "standardQtyPerBox") {
//       setstandardQtyPerBox(value)
//     } else if (name == "category") {
//       setcategory(value)
//     } else if (name == "uomPrimary") {
//       setuomPrimary(value)
//     } else if (name == "uomSecondary") {
//       setuomSecondary(value)
//     } else if (name == "mrp") {
//       setmrp(value)
//     } else if (name == "capacity") {
//       setcapacity(value)
//     } else if (name == "diameter") {
//       setdiameter(value)
//     } else if (name == "hsnCode") {
//       sethsnCode(value)
//     } else if (name == "productGroup") {
//       setproductGroup(value)
//     } else if (name == "dlp") {
//       setdlp(value)
//     }

//   };

//   /* Change Brand */
//   const onSelectBrand = (e) => {
//     setbrand(e.value);
//     let cid = e.value;

//     BrandService.getBrandEdit(cid).then(respo => {
//       setBrandName({ value: (respo.data).id, label: (respo.data).brandName })
//     })
//   };

//   const handleFileChange = (event) => {
//     const selectedFile = event.target.files[0];
//     const maxSize = 100 * 1024;
//     if (selectedFile && selectedFile.size < maxSize) {

//       if (selectedFile && selectedFile.type === 'image/jpeg' || selectedFile && selectedFile.type === 'image/jpg' || selectedFile && selectedFile.type === 'image/png' && selectedFile.size <= maxSize) {
//         // File is a valid JPG image
//         setFiles([...files, selectedFile]);
//         // setdiplay([...files, selectedFile]);
//         // You can upload the file or perform other actions here
//       } else {
//         if (selectedFile && selectedFile.type !== 'image/jpeg' || selectedFile && selectedFile.type !== 'image/jpg' || selectedFile && selectedFile.type !== 'image/png') {

//           Swal.fire({
//             title: 'Please select a valid JPG,PNG,JPEG image.',
//             icon: 'warning',
//             button: {
//               ok: 'Ok'
//             },
//           }).then((ok) => {
//             if (ok) {
//               window.location.reload();
//             }

//           })
//           // alert('Only Accept JPG,PNG,JPEG image.');
//         }
//       }
//     } else {
//       Swal.fire({
//         title: 'Please select an image that is no larger than 100KB.',
//         icon: 'warning',
//         button: {
//           ok: 'Ok'
//         },
//       }).then((ok) => {
//         if (ok) {
//           window.location.reload();
//         }

//       })
//     }
//   };

//   // const handleRemoveFile = (index) => {
//   //   const newFiles = [...files];
//   //   newFiles[index] = null;
//   //   setFiles(newFiles);
//   // };

//   const handleRemoveFile = (index) => {
//     console.log(index);
//     const newFiles = [...files];
//     newFiles.splice(index, 1);
//      setFiles(newFiles);
//      setdiplay(newFiles)
// };

// console.log(files)
//  console.log(imagedisplay)

//   const submitform = () => {

//     const igst = igst1.label;
//     let productName = name;

//     let formdata = new FormData();
//     let imageData = new FormData();

//     formdata.append('productName', productName);
//     formdata.append('shortName', shortName);
//     formdata.append('eanCode', eanCode);
//     formdata.append('standardQtyPerBox', standardQtyPerBox);
//     formdata.append('category', category);
//     formdata.append('uomPrimary', uomPrimary);
//     formdata.append('uomSecondary', uomSecondary);
//     formdata.append('mrp', mrp);
//     formdata.append('capacity', capacity);
//     formdata.append('diameter', diameter);
//     formdata.append('hsnCode', hsnCode);
//     formdata.append('brand', brand);
//     formdata.append('productGroup', productGroup);
//     formdata.append('dlp', dlp);
//     formdata.append('igst', igst);
//     formdata.append('cgst', cgst);
//     formdata.append('sgst', sgst);
//     formdata.append('igstLedger', igstLedger);
//     formdata.append('cgstLedger', cgstLedger);
//     formdata.append('sgstLedger', sgstLedger);
//     formdata.append('productType', productType);
//     formdata.append('productKind',productKind);
//     // let productImageArray =
//     // imagedisplay.map(img => {
//     //   formdata.append('productStringImage', JSON.stringify(img));
//     //   console.log(img)
//     // })

//     // imagedisplay.map(img=>{

//     //   productImageArray.push(img);

//     //   console.log(img)
//     // });
//     // imageData['productImages'] = productImageArray;
//     formdata.append("productStringImage",JSON.stringify(imagedisplay));
//     // formdata.append("productImages", productImageArray)

//     for (let i = 0; i < files.length; i++) {
//       formdata.append('files', files[i].id == null ? files[i] : files[i].location);
//     }
//     console.log(imageData);
//     console.log(formdata);
//     if (productType.length !== 0 && productKind.length !==0 && sgstLedger.length !== 0 && cgstLedger.length !== 0 && igstLedger.length !== 0 && sgst.length !== 0 && cgst.length !== 0 && igst.length !== 0 && brand.length !== 0 && productGroup.length !== 0 && dlp.length !== 0 && productName.length !== 0 && shortName.length !== 0 && eanCode.length !== 0 && standardQtyPerBox.length !== 0 && category.length !== 0 && uomPrimary.length !== 0 && uomSecondary.length !== 0 && mrp.length !== 0) {
//       setIsLoading(true);

//       setError(false)
//       axios.put(`http://43.225.54.138:8080/scm/api/product/update/` + id, formdata, {
//         headers: authHeader()
//       }).then((response) => {
//         navigate('/product/1');
//         if (response.status == 201 || response.status == 200) {
//           // alert("ee")
//           Toast.fire({
//             icon: 'success',
//             title: 'Successfully Updated'
//           })
//         }
//       }).catch(error => {
//         console.error('Error adding  :', error);
//         if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: "Something went wrong!",
//           footer: `
//             <div>
//               <p id="issueText">Why do I have this issue?</p>
//               <ul>

//               <li id="typer1">You might have an internet issue</li>
//               </ul>
//             </div>
//           `,
//           didOpen: () => {
//             applyFontSizeTransition('issueText');
//           }
//         });
//       }
//       })
//       .finally(() => {
//         setIsLoading(false);
//       });
//     } else {
//       setError(true)
//     }
//   }
//   return (
//     <MDBCard>
//       <div className='d-flex bd-highlight'>
//         <div className="card-header card-title w-100">EDIT PRODUCTS</div>
//         <Link className='p-2 w-200 card-header card-title bd-highlight' to={'/product/1'}><Button>Back</Button></Link>
//       </div>
//       <Form className='product2'>
//         <Row className="mb-1">
//           <Form.Group as={Col} md="12" controlId="formBasicName">
//             <Form.Label className="cname2">Product Name*</Form.Label>
//             <Form.Control type="text" autoComplete="off" style={{ borderColor: error && name.length == 0 ? "red" : "" }} name='name' value={name} onChange={handleChange} placeholder="Enter Product Name" />
//             {error && name.length === 0 ? <p style={{ color: error && name.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

//           </Form.Group>

//           <Form.Group as={Col} md="6" controlId="formBasicName">
//             <Form.Label className="cname2">Short Name*</Form.Label>
//             <Form.Control type="text" style={{ borderColor: error && shortName.length == 0 ? "red" : "" }} autoComplete="off" name='shortName' value={shortName} onChange={handleChange} placeholder="Enter Product Name" />
//             {error && shortName.length === 0 ? <p style={{ color: error && shortName.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

//           </Form.Group>
//           <Form.Group as={Col} md="6" controlId="formBasicName">
//             <Form.Label className="cname2">Ean Code No*</Form.Label>
//             <Form.Control type="text" style={{ borderColor: error && eanCode.length == 0 ? "red" : "" }} autoComplete="off" name='eanCode' value={eanCode} onChange={handleChange} placeholder="Enter Product Name" />
//             {error && eanCode.length === 0 ? <p style={{ color: error && eanCode.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

//           </Form.Group>

//           <Form.Group as={Col} md="6" controlId="formBasicName">
//             <Form.Label className="cname2">Standard Qty Per Box*</Form.Label>
//             <Form.Control type="number" style={{ borderColor: error && standardQtyPerBox.length == 0 ? "red" : "" }} autoComplete="off" name='standardQtyPerBox' value={standardQtyPerBox} onChange={handleChange} placeholder="Enter Product Name" />
//             {error && standardQtyPerBox.length === 0 ? <p style={{ color: error && standardQtyPerBox.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

//           </Form.Group>
//           <Form.Group as={Col} md="6" controlId="formBasicName">
//             <Form.Label className="cname2">Group*</Form.Label>
//             <Form.Control type="text" style={{ borderColor: error && productGroup.length == 0 ? "red" : "" }} autoComplete="off" name='productGroup' value={productGroup} onChange={handleChange} placeholder="Enter Product Name" />
//             {error && productGroup.length === 0 ? <p style={{ color: error && productGroup.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

//           </Form.Group>
//           <Form.Group as={Col} md="6" controlId="formBasicName">
//             <Form.Label className="cname2">Category*</Form.Label>
//             <Form.Control type="text" style={{ borderColor: error && category.length == 0 ? "red" : "" }} autoComplete="off" name='category' value={category} onChange={handleChange} placeholder="Enter Product Name" />
//             {error && category.length === 0 ? <p style={{ color: error && category.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

//           </Form.Group>
//           <Form.Group as={Col} md="6" controlId="formBasicName">
//             <Form.Label className="cname2">UOM(PCS)*</Form.Label>
//             <Form.Control type="text" autoComplete="off" style={{ borderColor: error && uomPrimary.length == 0 ? "red" : "" }} name='uomPrimary' value={uomPrimary} onChange={handleChange} placeholder="Enter Product Name" />
//             {error && uomPrimary.length === 0 ? <p style={{ color: error && uomPrimary.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

//           </Form.Group>
//           <Form.Group as={Col} md="6" controlId="formBasicName">
//             <Form.Label className="cname2">UOM(KG)*</Form.Label>
//             <Form.Control type="text" autoComplete="off" style={{ borderColor: error && uomSecondary.length == 0 ? "red" : "" }} name='uomSecondary' value={uomSecondary} onChange={handleChange} placeholder="Enter Product Name" />
//             {error && uomSecondary.length === 0 ? <p style={{ color: error && uomSecondary.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

//           </Form.Group>
//           <Form.Group as={Col} md="6" controlId="formBasicName">
//             <Form.Label className="cname2">DLP(₹)*</Form.Label>
//             <Form.Control type="number" autoComplete="off" style={{ borderColor: error && dlp.length == 0 ? "red" : "" }} name='dlp' value={dlp} onChange={handleChange} placeholder="Enter Product Name" />
//             {error && dlp.length === 0 ? <p style={{ color: error && dlp.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

//           </Form.Group>
//           <Form.Group as={Col} md="6" controlId="formBasicName">
//             <Form.Label className="cname2">MRP(₹)*</Form.Label>
//             <Form.Control type="number" autoComplete="off" style={{ borderColor: error && mrp.length == 0 ? "red" : "" }} name='mrp' value={mrp} onChange={handleChange} placeholder="Enter Product Name" />
//             {error && mrp.length === 0 ? <p style={{ color: error && mrp.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

//           </Form.Group>
//           <Form.Group as={Col} md="6" controlId="formBasicName">
//             <Form.Label className="cname2">Capacity(ml)</Form.Label>
//             <Form.Control type="number" autoComplete="off" name='capacity' value={capacity} onChange={handleChange} placeholder="Enter Product Name" />
//           </Form.Group>

//           <Form.Group as={Col} md="6" controlId="formBasicName">
//             <Form.Label className="cname2">Diameter(cm)</Form.Label>
//             <Form.Control type="text" autoComplete="off" name='diameter' value={diameter} onChange={handleChange} placeholder="Enter Product Name" />
//           </Form.Group>
//           <Form.Group as={Col} md="6" controlId="formBasicName">
//             <Form.Label className="cname2">HSN Code*</Form.Label>
//             <Form.Control type="text" style={{ borderColor: error && hsnCode.length == 0 ? "red" : "" }} autoComplete="off" name='hsnCode' value={hsnCode} onChange={handleChange} placeholder="Enter Product Name" />
//             {error && hsnCode.length === 0 ? <p style={{ color: error && hsnCode.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

//           </Form.Group>

//           <Form.Group as={Col} md="6" controlId="formBasicName">
//             <Form.Label className="cname2">Brand Name*</Form.Label>
//             <Select value={brandName} style={{ borderColor: error && hsnCode.length == 0 ? "red" : "" }} options={branddata} name='brand' onChange={(e) => { onSelectBrand(e) }} />
//             {error && brand.length === 0 ? <p style={{ color: error && brand.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

//           </Form.Group>
//           <Form.Group as={Col} md="6" controlId="formBasicName">
//             <Form.Label className="cname2">Product Type*</Form.Label>
//             <Form.Select name='productType' style={{ borderColor: error && productType.length == 0 ? "red" : "" }} value={productType} onChange={(e) => setproductType(e.target.value)}>
//               <option >Select</option>
//               <option value="boxproduct">Box Product</option>
//               <option value="kgproduct">KG Product</option>
//               <option value="corporateproduct">Corporate Product</option>
//               <option value="cookerproduct">Cooker Product</option>
//               <option value="noshproduct">Nosh Product</option>
//             </Form.Select>
//             {error && productType.length === 0 ? <p style={{ color: error && productType.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

//           </Form.Group>
//         </Row>
//         <Row className="mb-1">
//         <Form.Group as={Col} md="6" controlId="formBasicName">
//             <Form.Label className="cname2">Product Kind*</Form.Label>
//             <Form.Select name='productKind' style={{ borderColor: error && productKind.length == 0 ? "red" : "" }} value={productKind} onChange={(e) => setproductKind(e.target.value)}>
//               <option >Select</option>
//               <option value="Raw Material">Raw Material</option>
//               <option value="Semi-Finished">Semi-Finished</option>
//               <option value="Finished">Finished</option>
//             </Form.Select>
//             {error && productKind.length === 0 ? <p style={{ color: error && productKind.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

//           </Form.Group>
//           <Form.Group as={Col} md="6" controlId="formBasicName">
//             <Form.Label className="cname2">IGST%*</Form.Label>
//             <Select name='igst' style={{ borderColor: error && igstLedger.length == 0 ? "red" : "" }} value={igst1} options={igstdata} onChange={(e) => { handleChangeigst(e); gstcal(e); onSelectIgst(e) }} />
//           </Form.Group>
//         </Row>
//         <Row className="mb-1">

//           <Form.Group as={Col} md="6" controlId="formBasicName">
//             <Form.Label className="cname2">CGST%</Form.Label>
//             <Form.Control type="text" name='cgst' value={cgst} onChange={handleChange} placeholder="Enter Product Name" />
//           </Form.Group>
//           <Form.Group as={Col} md="6" controlId="formBasicName">
//             <Form.Label className="cname2">SGST%</Form.Label>
//             <Form.Control type="text" name='sgst' value={sgst} onChange={handleChange} placeholder="Enter Product Name" />
//             {error && igstLedger.length === 0 ? <p style={{ color: error && igstLedger.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

//           </Form.Group>
//           {[...Array(5)].map((_, index) => (
//             <div key={index} style={{ height: "120px", width: '20%', alignItems: "center", justifyContent: "center", textAlign: 'center', borderWidth: "2px", borderRadius: "1rem", borderStyle: "dashed", borderColor: "#cbd5e1", backgroundColor: "#f8fafc" }}>
//               <Form.Group as={Col} md="3" controlId="formBasicName">

//                 <Form.Control type="file" id="fileInput" multiple onChange={handleFileChange} style={{ display: 'none' }} />
//               </Form.Group>
//               {files[index] ? (
//                 <div>
//                   <img key={index} src={files[index].id == null ? URL.createObjectURL(files[index]) : "http://43.225.54.138:8080/scm/" + files[index].location} alt={`Image ${index}`} style={{ width: '100px', height: '100px', margin: '5px' }} />
//                   <button onClick={() => handleRemoveFile(index)}>X</button>
//                 </div>
//               ) : <Form.Label htmlFor="fileInput" className="cname2">Image</Form.Label>}
//             </div>
//           ))}

//         </Row>

//         <Button variant="primary" style={{width:'auto'}} onClick={submitform} disabled={isLoading}>
//           {isLoading ? 'Loading...' : 'Submit'}
//         </Button>
//       </Form>

//     </MDBCard>
//   )
// }

import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import authHeader from "../../services/auth-header";
import ProductService from "../../services/ProductService";
import BrandService from "../../services/Brand";
import { MDBCard } from "mdbreact";
import Swal from "sweetalert2";

export default function PEdit() {
  const [isLoading, setIsLoading] = useState(false);
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = "font-size 2s";
    element.style.fontSize = "30px";
    element.style.fontWeight = "600";
    // font-weight: 600;
    setTimeout(() => {
      document.getElementById("typer1").classList.add("promote");
      element.style.fontSize = "24px";
    }, 1000);
  };
  /* Product Id */
  let Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const { id } = useParams();

  /* Navigator */
  const navigate = useNavigate();

  const [cgst, setCgst] = useState("");
  const [sgst, setSgst] = useState("");
  const [name, setName] = useState("");
  const [category, setcategory] = useState("");
  const [standardQtyPerBox, setstandardQtyPerBox] = useState("");
  const [shortName, setshortName] = useState("");
  const [eanCode, seteanCode] = useState("");
  const [uomPrimary, setuomPrimary] = useState("");
  const [uomSecondary, setuomSecondary] = useState("");
  const [mrp, setmrp] = useState("");
  const [capacity, setcapacity] = useState("");
  const [diameter, setdiameter] = useState("");
  const [hsnCode, sethsnCode] = useState("");
  const [brand, setbrand] = useState("");
  const [igst, setIgst] = useState("");
  const [igstLedger, setIgstLedger] = useState("");
  const [cgstLedger, setCgstLedger] = useState("");
  const [sgstLedger, setSgstLedger] = useState("");
  const [igstdata1, setIgstdata1] = useState([]);
  const [igstdata, setIgstdata] = useState([]);
  const [igst1, setigst1] = useState(null);
  const [productType, setproductType] = useState("");
  const [productGroup, setproductGroup] = useState("");
  const [dlp, setdlp] = useState("");
  const [branddata, setBrandData] = useState([]);
  const [brandName, setBrandName] = useState([]);
  const [error, setError] = useState(false);
  const [files, setFiles] = useState("");
  const [checked, setChecked] = useState(false);
  const [productKind, setproductKind] = useState("");
  const [imagedisplay, setdiplay] = useState();
  const [costprice, setcostprice] = useState("");
  const [uom, setuom] = useState("");
  useEffect(() => {
    /* Product Data */

    ProductService.getProductEdit(id).then((res) => {
      console.log("mello", res.data);
      setCgst(res.data.cgst);
      setSgst(res.data.sgst);
      setName(res.data.productName);
      setcategory(res.data.category);
      setstandardQtyPerBox(res.data.standardQtyPerBox);
      setshortName(res.data.shortName);
      seteanCode(res.data.eanCode);
      setIgst(res.data.igst);
      setIgstLedger(res.data.igstLedger);
      setCgstLedger(res.data.cgstLedger);
      setSgstLedger(res.data.sgstLedger);
      setuomPrimary(res.data.uomPrimary);
      setuomSecondary(res.data.uomSecondary);
      setcapacity(res.data.capacity);
      setdiameter(res.data.diameter);
      setmrp(res.data.mrp);
      sethsnCode(res.data.hsnCode);
      setbrand(res.data.brand);
      setproductType(res.data.productType);
      setproductKind(res.data.productKind);
      setdlp(res.data.dlp);
      setproductGroup(res.data.productGroup);
      setcostprice(res.data.costprice);
      setuom(res.data.uom);
      const aa = res.data.igstLedger;
      ProductService.getProductIgst(1).then((res1) => {
        res1.data.map((user) => {
          if (user.id == aa) {
            setigst1({ value: user.id, label: user.per });
          }
        });
      });
      setdiplay(res.data.productImages);
      setFiles(res.data.productImages);

      /* Brand Data */
      let cid = res.data.brand;

      BrandService.getBrandEdit(cid).then((respo) => {
        setBrandName({ value: respo.data.id, label: respo.data.brandName });
      });
    });

    /* IGST Data */
    ProductService.getigstByIdonedata().then((response) => {
      setIgstdata(response.data.map((user) => ({ value: user.id, label: user.per })));
    });

    /* Brand All DATA */
    BrandService.getBrand().then((res) => {
      let aa = res.data;
      setBrandData(aa.map((user) => ({ value: user.id, label: user.brandName })));
    });

    const timeoutId = setTimeout(() => {
      console.log("Delayed message after 5  seconds!");
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  // useEffect(() => {
  //   setIgstdata(igstdata1.map(user => ({ value: user.id, label: user.per })))
  // }, [igstdata1]);

  const onSelectIgst = (e) => {
    // console.log("Selected: ", e);
    setigst1(e);
  };

  const handleChangeigst = (e) => {
    const value = e.value;
    setIgstLedger(value);
  };

  const gstcal = (e) => {
    const value = e.value;
    ProductService.getgetIgstByIdonedata(value).then((response) => {
      setIgst(response.data.per);
      var igstper = response.data.per;
      var cgstper = parseInt(igstper / 2);
      var sgstper = parseInt(igstper / 2);

      setCgst(cgstper);
      setSgst(sgstper);

      ProductService.getcgstByIdonedata(cgstper).then((response1) => {
        setCgstLedger(response1.data.id);
      });
      ProductService.getsgstByIdonedata(sgstper).then((response2) => {
        setSgstLedger(response2.data.id);
      });
    });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name == "name") {
      setName(value);
    } else if (name == "shortName") {
      setshortName(value);
    } else if (name == "eanCode") {
      seteanCode(value);
    } else if (name == "standardQtyPerBox") {
      setstandardQtyPerBox(value);
    } else if (name == "category") {
      setcategory(value);
    } else if (name == "uomPrimary") {
      setuomPrimary(value);
    } else if (name == "uomSecondary") {
      setuomSecondary(value);
    } else if (name == "mrp") {
      setmrp(value);
    } else if (name == "capacity") {
      setcapacity(value);
    } else if (name == "diameter") {
      setdiameter(value);
    } else if (name == "hsnCode") {
      sethsnCode(value);
    } else if (name == "productGroup") {
      setproductGroup(value);
    } else if (name == "dlp") {
      setdlp(value);
    } else if (name == "costprice") {
      const regex = /^[0-9]*\.?[0-9]{0,2}$/;

      if (regex.test(value)) {
        setcostprice(value);
      }
    } else if (name == "uom") {
      setuom(value);
    }
  };

  /* Change Brand */
  const onSelectBrand = (e) => {
    setbrand(e.value);
    let cid = e.value;

    BrandService.getBrandEdit(cid).then((respo) => {
      setBrandName({ value: respo.data.id, label: respo.data.brandName });
    });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const maxSize = 100 * 1024;
    if (selectedFile && selectedFile.size < maxSize) {
      if (
        (selectedFile && selectedFile.type === "image/jpeg") ||
        (selectedFile && selectedFile.type === "image/jpg") ||
        (selectedFile && selectedFile.type === "image/png" && selectedFile.size <= maxSize)
      ) {
        // File is a valid JPG image
        setFiles([...files, selectedFile]);
        // setdiplay([...files, selectedFile]);
        // You can upload the file or perform other actions here
      } else {
        if ((selectedFile && selectedFile.type !== "image/jpeg") || (selectedFile && selectedFile.type !== "image/jpg") || (selectedFile && selectedFile.type !== "image/png")) {
          Swal.fire({
            title: "Please select a valid JPG,PNG,JPEG image.",
            icon: "warning",
            button: {
              ok: "Ok",
            },
          }).then((ok) => {
            if (ok) {
              window.location.reload();
            }
          });
          // alert('Only Accept JPG,PNG,JPEG image.');
        }
      }
    } else {
      Swal.fire({
        title: "Please select an image that is no larger than 100KB.",
        icon: "warning",
        button: {
          ok: "Ok",
        },
      }).then((ok) => {
        if (ok) {
          window.location.reload();
        }
      });
    }
  };

  // const handleRemoveFile = (index) => {
  //   const newFiles = [...files];
  //   newFiles[index] = null;
  //   setFiles(newFiles);
  // };

  const handleRemoveFile = (index) => {
    console.log(index);
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    setdiplay(newFiles);
  };

  console.log(files);
  console.log(imagedisplay);

  const submitform = () => {
    const igst = igst1.label;
    let productName = name;

    let formdata = new FormData();
    let imageData = new FormData();

    formdata.append("productName", productName);
    formdata.append("shortName", shortName);
    formdata.append("eanCode", eanCode);
    formdata.append("standardQtyPerBox", standardQtyPerBox);
    formdata.append("category", category);
    formdata.append("uomPrimary", uomPrimary);
    formdata.append("uomSecondary", uomSecondary);
    formdata.append("mrp", mrp);
    formdata.append("capacity", capacity);
    formdata.append("diameter", diameter);
    formdata.append("hsnCode", hsnCode);
    formdata.append("brand", brand);
    formdata.append("productGroup", productGroup);
    formdata.append("dlp", dlp);
    formdata.append("igst", igst);
    formdata.append("cgst", cgst);
    formdata.append("sgst", sgst);
    formdata.append("igstLedger", igstLedger);
    formdata.append("cgstLedger", cgstLedger);
    formdata.append("sgstLedger", sgstLedger);
    formdata.append("productType", productType);
    formdata.append("productKind", productKind);
    formdata.append("costprice", costprice);
    formdata.append("uom", uom);
    // let productImageArray =
    // imagedisplay.map(img => {
    //   formdata.append('productStringImage', JSON.stringify(img));
    //   console.log(img)
    // })

    // imagedisplay.map(img=>{

    //   productImageArray.push(img);

    //   console.log(img)
    // });
    // imageData['productImages'] = productImageArray;
    formdata.append("productStringImage", JSON.stringify(imagedisplay));
    // formdata.append("productImages", productImageArray)

    for (let i = 0; i < files.length; i++) {
      formdata.append("files", files[i].id == null ? files[i] : files[i].location);
    }
    console.log(imageData);
    console.log(formdata);
    if (
      productType.length !== 0 &&
      productKind.length !== 0 &&
      sgstLedger.length !== 0 &&
      cgstLedger.length !== 0 &&
      igstLedger.length !== 0 &&
      sgst.length !== 0 &&
      cgst.length !== 0 &&
      igst.length !== 0 &&
      brand.length !== 0 &&
      productGroup.length !== 0 &&
      dlp.length !== 0 &&
      productName.length !== 0 &&
      shortName.length !== 0 &&
      eanCode.length !== 0 &&
      standardQtyPerBox.length !== 0 &&
      category.length !== 0 &&
      uomPrimary.length !== 0 &&
      uomSecondary.length !== 0 &&
      mrp.length !== 0 &&
      costprice.length !== 0 &&
      uom.length !== 0
    ) {
      setIsLoading(true);

      setError(false);
      axios
        .put(`http://43.225.54.138:8080/scm/api/product/update/` + id, formdata, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.status == 201 || response.status == 200) {
            // alert("ee")
            Toast.fire({
              icon: "success",
              title: "Successfully Updated",
            });
            console.log(response);
          }
          //navigate("/product/1");
        })
        .catch((error) => {
          console.error("Error adding  :", error);
          if (error.message.includes("Network Error") || error.message.includes("Failed to fetch")) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
          
              <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
              didOpen: () => {
                applyFontSizeTransition("issueText");
              },
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setError(true);
    }
  };
  return (
    <MDBCard>
      <div className="d-flex bd-highlight">
        <div className="card-header card-title w-100">EDIT PRODUCTS</div>
        <Link className="p-2 w-200 card-header card-title bd-highlight" to={"/product/1"}>
          <Button>Back</Button>
        </Link>
      </div>
      <Form className="product2">
        <Row className="mb-1">
          <Form.Group as={Col} md="12" controlId="formBasicName">
            <Form.Label className="cname2">Product Name*</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              style={{ borderColor: error && name.length == 0 ? "red" : "" }}
              name="name"
              value={name}
              onChange={handleChange}
              placeholder="Enter Product Name"
            />
            {error && name.length === 0 ? <p style={{ color: error && name.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Short Name*</Form.Label>
            <Form.Control
              type="text"
              style={{ borderColor: error && shortName.length == 0 ? "red" : "" }}
              autoComplete="off"
              name="shortName"
              value={shortName}
              onChange={handleChange}
              placeholder="Enter Product Name"
            />
            {error && shortName.length === 0 ? <p style={{ color: error && shortName.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Ean Code No*</Form.Label>
            <Form.Control
              type="text"
              style={{ borderColor: error && eanCode.length == 0 ? "red" : "" }}
              autoComplete="off"
              name="eanCode"
              value={eanCode}
              onChange={handleChange}
              placeholder="Enter Product Name"
            />
            {error && eanCode.length === 0 ? <p style={{ color: error && eanCode.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Standard Qty Per Box*</Form.Label>
            <Form.Control
              type="number"
              style={{ borderColor: error && standardQtyPerBox.length == 0 ? "red" : "" }}
              autoComplete="off"
              name="standardQtyPerBox"
              value={standardQtyPerBox}
              onChange={handleChange}
              placeholder="Enter Product Name"
            />
            {error && standardQtyPerBox.length === 0 ? <p style={{ color: error && standardQtyPerBox.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Group*</Form.Label>
            <Form.Control
              type="text"
              style={{ borderColor: error && productGroup.length == 0 ? "red" : "" }}
              autoComplete="off"
              name="productGroup"
              value={productGroup}
              onChange={handleChange}
              placeholder="Enter Product Name"
            />
            {error && productGroup.length === 0 ? <p style={{ color: error && productGroup.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Category*</Form.Label>
            <Form.Control
              type="text"
              style={{ borderColor: error && category.length == 0 ? "red" : "" }}
              autoComplete="off"
              name="category"
              value={category}
              onChange={handleChange}
              placeholder="Enter Product Name"
            />
            {error && category.length === 0 ? <p style={{ color: error && category.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">UOM*</Form.Label>
            <Form.Select name="productType" style={{ borderColor: error && uom.length == 0 ? "red" : "" }} value={uom} onChange={(e) => setuom(e.target.value)}>
              <option>Select</option>
              <option value="bag">Bags (BAG)</option>
              <option value="bale">Bale (BAL)</option>
              <option value="bundle">Bundles (BDL)</option>
              <option value="buckle">Buckles (BKL)</option>
              <option value="box">Box (BOX)</option>
              <option value="bottle">Bottles (BTL)</option>
              <option value="can">Cans (CAN)</option>
              <option value="carton">Cartons (CTN)</option>
              <option value="ccm">Cubic centimeters (CCM)</option>
              <option value="cbm">Cubic meters (CBM)</option>
              <option value="cm">Centimeters (CMS)</option>
              <option value="drum">Drums (DRM)</option>
              <option value="dozen">Dozens (DOZ)</option>
              <option value="gg">Great gross (GGK)</option>
              <option value="gross">Gross (GRS)</option>
              <option value="grams">Grams (GMS)</option>
              <option value="km">Kilometre (KME)</option>
              <option value="kg">Kilograms (KGS)</option>
              <option value="kl">Kilo litre (KLR)</option>
              <option value="metricTon">Metric ton (MTS)</option>
              <option value="ml">Milliliter (MLT)</option>
              <option value="meter">Meters (MTR)</option>
              <option value="nos">Numbers (NOS)</option>
              <option value="pack">Packs (PAC)</option>
              <option value="pcs">Pieces (PCS)</option>
              <option value="pair">Pairs (PRS)</option>
              <option value="quintal">Quintal (QTL)</option>
              <option value="roll">Rolls (ROL)</option>
              <option value="squareYard">Square Yards (SQY)</option>
              <option value="set">Sets (SET)</option>
              <option value="squareFeet">Square feet (SQF)</option>
              <option value="squareMeter">Square meters (SQM)</option>
              <option value="tablet">Tablets (TBS)</option>
              <option value="tube">Tubes (TUB)</option>
              <option value="tenGross">Ten Gross (TGM)</option>
              <option value="thousand">Thousands (THD)</option>
              <option value="ton">Tonnes (TON)</option>
              <option value="unit">Units (UNT)</option>
              <option value="usGallon">US Gallons (UGS)</option>
              <option value="yard">Yards (YDS)</option>
            </Form.Select>
            {error && uom.length === 0 ? <p style={{ color: error && uom.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">UOM(PCS)*</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              style={{ borderColor: error && uomPrimary.length == 0 ? "red" : "" }}
              name="uomPrimary"
              value={uomPrimary}
              onChange={handleChange}
              placeholder="Enter Product Name"
            />
            {error && uomPrimary.length === 0 ? <p style={{ color: error && uomPrimary.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">UOM(KG)*</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              style={{ borderColor: error && uomSecondary.length == 0 ? "red" : "" }}
              name="uomSecondary"
              value={uomSecondary}
              onChange={handleChange}
              placeholder="Enter Product Name"
            />
            {error && uomSecondary.length === 0 ? <p style={{ color: error && uomSecondary.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">DLP(₹)*</Form.Label>
            <Form.Control
              type="number"
              autoComplete="off"
              style={{ borderColor: error && dlp.length == 0 ? "red" : "" }}
              name="dlp"
              value={dlp}
              onChange={handleChange}
              placeholder="Enter Product Name"
            />
            {error && dlp.length === 0 ? <p style={{ color: error && dlp.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">MRP(₹)*</Form.Label>
            <Form.Control
              type="number"
              autoComplete="off"
              style={{ borderColor: error && mrp.length == 0 ? "red" : "" }}
              name="mrp"
              value={mrp}
              onChange={handleChange}
              placeholder="Enter Product Name"
            />
            {error && mrp.length === 0 ? <p style={{ color: error && mrp.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Capacity(ml)</Form.Label>
            <Form.Control type="number" autoComplete="off" name="capacity" value={capacity} onChange={handleChange} placeholder="Enter Product Name" />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Diameter(cm)</Form.Label>
            <Form.Control type="text" autoComplete="off" name="diameter" value={diameter} onChange={handleChange} placeholder="Enter Product Name" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">HSN Code*</Form.Label>
            <Form.Control
              type="text"
              style={{ borderColor: error && hsnCode.length == 0 ? "red" : "" }}
              autoComplete="off"
              name="hsnCode"
              value={hsnCode}
              onChange={handleChange}
              placeholder="Enter Product Name"
            />
            {error && hsnCode.length === 0 ? <p style={{ color: error && hsnCode.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Brand Name*</Form.Label>
            <Select
              value={brandName}
              style={{ borderColor: error && hsnCode.length == 0 ? "red" : "" }}
              options={branddata}
              name="brand"
              onChange={(e) => {
                onSelectBrand(e);
              }}
            />
            {error && brand.length === 0 ? <p style={{ color: error && brand.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Product Type*</Form.Label>
            <Form.Select name="productType" style={{ borderColor: error && productType.length == 0 ? "red" : "" }} value={productType} onChange={(e) => setproductType(e.target.value)}>
              <option>Select</option>
              <option value="boxproduct">Box Product</option>
              <option value="kgproduct">KG Product</option>
              <option value="corporateproduct">Corporate Product</option>
              <option value="cookerproduct">Cooker Product</option>
              <option value="noshproduct">Nosh Product</option>
            </Form.Select>
            {error && productType.length === 0 ? <p style={{ color: error && productType.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
        </Row>
        <Row className="mb-1">
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Product Kind*</Form.Label>
            <Form.Select name="productKind" style={{ borderColor: error && productKind.length == 0 ? "red" : "" }} value={productKind} onChange={(e) => setproductKind(e.target.value)}>
              <option>Select</option>
              <option>Select</option>
              <option value="Raw material">Raw Material</option>
              <option value="Packing material">Packing Material</option>
              <option value="Semi finished">Semi-Finished</option>
              <option value="finished">Finished</option>
            </Form.Select>
            {error && productKind.length === 0 ? <p style={{ color: error && productKind.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Cost Price</Form.Label>
            <Form.Control
              type="text"
              style={{ borderColor: error && costprice.length == 0 ? "red" : "" }}
              autoComplete="off"
              name="costprice"
              value={costprice}
              onChange={handleChange}
              placeholder="Enter Cost Name"
            />
            {error && shortName.length === 0 ? <p style={{ color: error && shortName.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">IGST%*</Form.Label>
            <Select
              name="igst"
              style={{ borderColor: error && igstLedger.length == 0 ? "red" : "" }}
              value={igst1}
              options={igstdata}
              onChange={(e) => {
                handleChangeigst(e);
                gstcal(e);
                onSelectIgst(e);
              }}
            />
          </Form.Group>
        </Row>
        <Row className="mb-1">
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">CGST%</Form.Label>
            <Form.Control type="text" name="cgst" value={cgst} onChange={handleChange} placeholder="Enter Product Name" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">SGST%</Form.Label>
            <Form.Control type="text" name="sgst" value={sgst} onChange={handleChange} placeholder="Enter Product Name" />
            {error && igstLedger.length === 0 ? <p style={{ color: error && igstLedger.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          {[...Array(5)].map((_, index) => (
            <div
              key={index}
              style={{
                height: "120px",
                width: "20%",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                borderWidth: "2px",
                borderRadius: "1rem",
                borderStyle: "dashed",
                borderColor: "#cbd5e1",
                backgroundColor: "#f8fafc",
              }}
            >
              <Form.Group as={Col} md="3" controlId="formBasicName">
                <Form.Control type="file" id="fileInput" multiple onChange={handleFileChange} style={{ display: "none" }} />
              </Form.Group>
              {files[index] ? (
                <div>
                  <img
                    key={index}
                    src={files[index].id == null ? URL.createObjectURL(files[index]) : "http://43.225.54.138:8080/scm/" + files[index].location}
                    alt={`Image ${index}`}
                    style={{ width: "100px", height: "100px", margin: "5px" }}
                  />
                  <button onClick={() => handleRemoveFile(index)}>X</button>
                </div>
              ) : (
                <Form.Label htmlFor="fileInput" className="cname2">
                  Image
                </Form.Label>
              )}
            </div>
          ))}
        </Row>

        <Button variant="primary" style={{ width: "auto" }} onClick={submitform} disabled={isLoading}>
          {isLoading ? "Loading..." : "Submit"}
        </Button>
      </Form>
    </MDBCard>
  );
}
