import { MDBCard } from "mdbreact";
import React from "react";
import { Mydatatable } from "../../Master/Product/Mydatatable";
import AuthService from "../../services/auth.service";

export default function Table(){

    let header = [
        { "label": "Id", "field": "id" },
        { "label": "Staff Name", "field": "staff_name" },
        { "label": "Asset Name", "field": "assetsname" },
        { "label": "Company Name    ", "field": "companyname" },

        { "label": "Supplier Name    ", "field": "suppliername" },
        { "label": "Insurance provider name", "field":"insuranceprovidername" },
        // { "label": "Serial No  ", "field": "grandtotal" },
        // { "label": "State", "field": "state_name" },
        // { "label": "To Date", "field": "net_Amount" },
     { "label": "Expiry Date", "field": "expiry_date" },
     { "label": "Created Date", "field": "createddate" },
     { "label": "Created Time", "field": "createdtime" },
     { "label": "Created By", "field": "createbyname" },

     { "label": "Updated Date", "field": "updateddate" },
     { "label": "Updated Time", "field": "updatedtime" },
     { "label": "Updated By", "field": "updatedbyname" },
    //  { "label": "Updated By", "field": "updatedbyname" },
        { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> }
      ]; 

      
  const currentuser = AuthService.getCurrentUser();
console.log(currentuser.roles[0])
      const sumofkeys=[{"first":'grandtotal'},{'first':'net_Amount'} ]
    return(
        <Mydatatable
        head={header}
        title={"Assign To Staff"}
        axiostabledata={'assetassigntostaff'}
        Add={"Add"}
        Create={currentuser.roles[0]=='ROLE_ADMIN'?`/AssignToStaffCreate`:''}
        Edit={"/AssignToStaffView"}
        View={"/AssignToStaffView"}
        Sumofkeys={sumofkeys}
        />
    )
}