import {
    CHANGE_SUPPLIER_DISCOUNT_EDIT,
    PUT_NEW_SUPPLIER_ADD_TO_CART_EDIT,
    REMOVE_FROM_CART_FROM_SUPPLIER_eDIT,
    SUPPLIER_ADD_TO_CART_EDIT,
    SUPPLIER_EDIT_CHANGE_CAL_MEASUREMENT_EDIT,
  SUPPLIER_EDIT_CHANGE_MEASUREMENTtn,
  SUPPLIER_EDIT_CHANGE_QTYKG_EDIT,
  SUPPLIER_EDIT_CHANGE_QTYPCS_EDIT,
  SUPPLIER_EDIT_PUT_DATA_TO_LOAD,
} from "../constant";
import { PUT_NEW_SUPPLIER_EDIT_ADD_TO_CART } from "../constant";
import { CHANGE_SUPPLIER_EDIT_QTY } from "../constant";
import { REMOVE_FROM_CART_FROM_SUPPLIER_EDIT } from "../constant";
import { CHANGE_COST_SUPPLIER_EDIT } from "../constant";
import { CHANGE_SUPPLIER_EDIT_DISCOUNT } from "../constant";
import { CHANGE_SUPPLIER_EDIT_TEXT } from "../constant";
import { EMPTY_SUPPLIER_EDIT_DATA } from "../constant";

export const supplierdeliveryeditdata = (data = [], action) => {
  switch (action.type) {
    case SUPPLIER_EDIT_PUT_DATA_TO_LOAD:
      return [...action.data];

    case PUT_NEW_SUPPLIER_EDIT_ADD_TO_CART:
      let currentData = {
        product: { id: action.data.id },
        igst: action.data.igst,
        mrp: action.data.mrp,
        productName: action.data.productName,
      };
      return [currentData, ...data];

    case CHANGE_SUPPLIER_EDIT_QTY:
      let newCart = [...data];
      let itemIndex = newCart.findIndex(
        (obj) => obj.product.id === action.item.product.id
      );
      let currItem = data[itemIndex];

      if (action.data2 == "withtax") {
        if (currItem.discount > 0) {
          if (action.data == "") {
            currItem.sdn_quantity = Number(0);
          } else {
            currItem.sdn_quantity = Number(action.data);
          }

          let discount2 =
            Number(currItem.sdn_quantity) *
            Number(currItem.mrp) *
            (Number(currItem.discount) / 100);
          currItem.total = (
            Number(currItem.sdn_quantity) * Number(currItem.mrp) -
            Number(discount2)
          ).toFixed(2);
          currItem.productId = currItem.id;
          // currItem.total = parseInt(currItem.sdn_quantity) * parseInt(currItem.mrp)
          currItem.gstvalue = (
            Number(currItem.total) *
            (Number(currItem.igst) / 100)
          ).toFixed(2);
          currItem.amount = (
            Number(currItem.gstvalue) + Number(currItem.total)
          ).toFixed(2);
          data[itemIndex] = currItem;
        } else {
          if (action.data == "") {
            currItem.sdn_quantity = Number(0);
          } else {
            currItem.sdn_quantity = Number(action.data);
          }
          currItem.discount = Number(0);
          currItem.productId = currItem.id;
          currItem.total = (
            Number(currItem.sdn_quantity) * Number(currItem.mrp)
          ).toFixed(2);
          currItem.gstvalue = (
            Number(currItem.total) *
            (Number(currItem.igst) / 100)
          ).toFixed(2);
          currItem.amount = (
            Number(currItem.gstvalue) + Number(currItem.total)
          ).toFixed(2);
          data[itemIndex] = currItem;
        }
      } else {
        if (currItem.discount > 0) {
          if (action.data == "") {
            currItem.sdn_quantity = Number(0);
          } else {
            currItem.sdn_quantity = Number(action.data);
          }

          let discount2 =
            Number(currItem.sdn_quantity) *
            Number(currItem.mrp) *
            (Number(currItem.discount) / 100);
          currItem.total = (
            Number(currItem.sdn_quantity) * Number(currItem.mrp) -
            Number(discount2)
          ).toFixed(2);
          currItem.productId = currItem.id;
          // currItem.total = parseInt(currItem.sdn_quantity) * parseInt(currItem.mrp)
          currItem.gstvalue = Number(0);
          currItem.amount = (
            Number(currItem.gstvalue) + Number(currItem.total)
          ).toFixed(2);
          data[itemIndex] = currItem;
        } else {
          if (action.data == "") {
            currItem.sdn_quantity = Number(0);
          } else {
            currItem.sdn_quantity = Number(action.data);
          }
          currItem.discount = Number(0);
          currItem.productId = currItem.id;
          currItem.total = (
            Number(currItem.sdn_quantity) * Number(currItem.mrp)
          ).toFixed(2);
          currItem.gstvalue = Number(0);
          currItem.amount = (
            Number(currItem.gstvalue) + Number(currItem.total)
          ).toFixed(2);
          data[itemIndex] = currItem;
        }
      }

      return [...data];


         case CHANGE_SUPPLIER_DISCOUNT_EDIT:
                  let newCartEditDic = [...data]
                  let itemIndexEditDic = newCartEditDic.findIndex(obj => obj.product.id === action.item.product.id)
                  let currItemEditDic = data[itemIndexEditDic]
      
      
                  if (currItemEditDic.calunitofmeasurement === "box") {
                      if (action.data === "") {
                          currItemEditDic.tradeDiscount = ""
                      } else {
                          currItemEditDic.tradeDiscount = Number(action.data)
                      }
      
                      let discount1 = Number(currItemEditDic.sdnquantity) * Number(currItemEditDic.dlp) * (Number(currItemEditDic.tradeDiscount) / 100)
                      currItemEditDic.grossamount = (Number(currItemEditDic.sdnquantity) * Number(currItemEditDic.dlp)).toFixed(2);
                      let totaAmount = Number(currItemEditDic.sdnquantity) * Number(currItemEditDic.dlp) - Number(discount1)
                      currItemEditDic.total = (Number(totaAmount)).toFixed(2);
                      currItemEditDic.gstvalue = ((Number(currItemEditDic.total) / 100) * Number(currItemEditDic.igst)).toFixed(2);
                      currItemEditDic.amount = (Number(currItemEditDic.gstvalue) + Number(currItemEditDic.total)).toFixed(2);
                      data[itemIndexEditDic] = currItemEditDic
                  }
                  if (currItemEditDic.calunitofmeasurement === "pcs") {
                      if (action.data === "") {
                          currItemEditDic.tradeDiscount = ""
                      } else {
                          currItemEditDic.tradeDiscount = Number(action.data)
                      }
      
                      let discount1 = Number(currItemEditDic.sdnquantity) * Number(currItemEditDic.dlp) * (Number(currItemEditDic.tradeDiscount) / 100)
                      currItemEditDic.grossamount = (Number(currItemEditDic.sdnquantity) * Number(currItemEditDic.dlp)).toFixed(2);
                      let totaAmount = Number(currItemEditDic.sdnquantity) * Number(currItemEditDic.dlp) - Number(discount1)
                      currItemEditDic.total = (Number(totaAmount)).toFixed(2);
                      currItemEditDic.gstvalue = ((Number(currItemEditDic.total) / 100) * Number(currItemEditDic.igst)).toFixed(2);
                      currItemEditDic.amount = (Number(currItemEditDic.gstvalue) + Number(currItemEditDic.total)).toFixed(2);
                      data[itemIndexEditDic] = currItemEditDic
                  }
                  if (currItemEditDic.calunitofmeasurement === "kg") {
                      if (action.data === "") {
                          currItemEditDic.tradeDiscount = ""
                      } else {
                          currItemEditDic.tradeDiscount = Number(action.data)
                      }
      
                      let discount1 = Number(currItemEditDic.sdnquantitykgs) * Number(currItemEditDic.dlp) * (Number(currItemEditDic.tradeDiscount) / 100)
                      currItemEditDic.grossamount = (Number(currItemEditDic.sdnquantitykgs) * Number(currItemEditDic.dlp)).toFixed(2);
                      let totaAmount = Number(currItemEditDic.sdnquantitykgs) * Number(currItemEditDic.dlp) - Number(discount1)
                      currItemEditDic.total = (Number(totaAmount)).toFixed(2);
                      currItemEditDic.gstvalue = ((Number(currItemEditDic.total) / 100) * Number(currItemEditDic.igst)).toFixed(2);
                      currItemEditDic.amount = (Number(currItemEditDic.gstvalue) + Number(currItemEditDic.total)).toFixed(2);
                      data[itemIndexEditDic] = currItemEditDic
                  }
      
                  return [...data];
      
      
         

  case SUPPLIER_EDIT_CHANGE_CAL_MEASUREMENT_EDIT:
            let newCart1 = [...data]
            let itemIndex1 = newCart1.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem1 = data[itemIndex1]

            currItem1.calunitofmeasurement = action.data

            if (currItem1.calunitofmeasurement === "pcs") {
                if (currItem1.tradeDiscount > 0) {
                    let discount1 = Number(currItem1.sdnquantity) * Number(currItem1.dlp) * (Number(currItem1.tradeDiscount) / 100)
                    currItem1.grossamount = (Number(currItem1.sdnquantity) * Number(currItem1.dlp)).toFixed(2);
                    let totaAmount = (Number(currItem1.sdnquantity) * Number(currItem1.dlp)) - Number(discount1)
                    currItem1.total = (Number(totaAmount)).toFixed(2);
                    currItem1.gstvalue = ((Number(currItem1.total) / 100) * Number(currItem1.igst)).toFixed(2);
                    currItem1.amount = (Number(currItem1.gstvalue) + Number(currItem1.total)).toFixed(2);
                    data[itemIndex1] = currItem1
                } else {
                    currItem1.grossamount = (Number(currItem1.sdnquantity) * Number(currItem1.dlp))
                    currItem1.total = (Number(currItem1.grossamount)).toFixed(2);
                    currItem1.gstvalue = ((Number(currItem1.total) / 100) * Number(currItem1.igst)).toFixed(2);
                    currItem1.amount = ((Number(currItem1.gstvalue)) + (Number(currItem1.total))).toFixed(2);
                    data[itemIndex1] = currItem1
                }
            }
            if (currItem1.calunitofmeasurement === "kg") {
                if (currItem1.tradeDiscount > 0) {
                    let discount1 = Number(currItem1.sdnquantitykgs) * Number(currItem1.dlp) * (Number(currItem1.tradeDiscount) / 100)
                    currItem1.grossamount = (Number(currItem1.sdnquantitykgs) * Number(currItem1.dlp)).toFixed(2);
                    let totaAmount = (Number(currItem1.sdnquantitykgs) * Number(currItem1.dlp)) - Number(discount1)
                    currItem1.total = (Number(totaAmount)).toFixed(2);
                    currItem1.gstvalue = ((Number(currItem1.total) / 100) * Number(currItem1.igst)).toFixed(2);
                    currItem1.amount = (Number(currItem1.gstvalue) + Number(currItem1.total)).toFixed(2);
                    data[itemIndex1] = currItem1
                } else {
                    currItem1.grossamount = (Number(currItem1.sdnquantitykgs) * Number(currItem1.dlp)).toFixed(2);
                    currItem1.total = (Number(currItem1.grossamount)).toFixed(2);
                    currItem1.gstvalue = ((Number(currItem1.total) / 100) * Number(currItem1.igst)).toFixed(2);
                    currItem1.amount = ((Number(currItem1.gstvalue)) + (Number(currItem1.total))).toFixed(2);
                    data[itemIndex1] = currItem1
                }
            }
            return [...data];

    

   case REMOVE_FROM_CART_FROM_SUPPLIER_eDIT:

            const remainingitems = data.filter((item) => item.product.id !== action.data);
            return [...remainingitems];

    case REMOVE_FROM_CART_FROM_SUPPLIER_EDIT:
      const remainingitem = data.filter(
        (item) => item.product.id !== action.data
      );
      return [...remainingitem];
case CHANGE_COST_SUPPLIER_EDIT:
            let newCart4 = [...data]
            let itemIndex4 = newCart4.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem4 = data[itemIndex4]

            currItem4.dlp = action.data
            if (currItem4.calunitofmeasurement === "box") {
                if (currItem4.tradeDiscount > 0) {
                    if (action.data === "") {
                        currItem4.dlp = ""
                    } else {
                        currItem4.dlp = Number(action.data)
                    }
                    let discount1 = Number(currItem4.sdnquantity) * Number(currItem4.dlp) * (Number(currItem4.tradeDiscount) / 100)
                    currItem4.grossamount = (Number(currItem4.sdnquantity) * Number(currItem4.dlp)).toFixed(2);
                    let totaAmount = Number(currItem4.sdnquantity) * Number(currItem4.dlp) - Number(discount1)
                    currItem4.total = (Number(totaAmount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.total) / 100) * Number(currItem4.igst)).toFixed(2);
                    currItem4.amount = (Number(currItem4.gstvalue) + Number(currItem4.total)).toFixed(2);
                    data[itemIndex4] = currItem4

                } else {
                    if (action.data === "") {
                        currItem4.dlp = ""
                    } else {
                        currItem4.dlp = Number(action.data)
                    }
                    currItem4.grossamount = (Number(currItem4.sdnquantity) * Number(currItem4.dlp)).toFixed(2);
                    currItem4.total = (Number(currItem4.grossamount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.total) / 100) * Number(currItem4.igst)).toFixed(2);
                    currItem4.amount = ((Number(currItem4.gstvalue)) + (Number(currItem4.total))).toFixed(2);
                    data[itemIndex4] = currItem4
                }
            }
            if (currItem4.calunitofmeasurement === "pcs") {
                if (currItem4.tradeDiscount > 0) {

                    if (action.data === "") {
                        currItem4.dlp = ""
                    } else {
                        currItem4.dlp = Number(action.data)
                    }
                    let discount1 = Number(currItem4.sdnquantity) * Number(currItem4.dlp) * (Number(currItem4.tradeDiscount) / 100)
                    currItem4.grossamount = (Number(currItem4.sdnquantity) * Number(currItem4.dlp)).toFixed(2);
                    let totaAmount = Number(currItem4.sdnquantity) * Number(currItem4.dlp) - Number(discount1)
                    currItem4.total = (Number(totaAmount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.total) / 100) * Number(currItem4.igst)).toFixed(2);
                    currItem4.amount = (Number(currItem4.gstvalue) + Number(currItem4.total)).toFixed(2);
                    data[itemIndex4] = currItem4
                } else {
                    if (action.data === "") {
                        currItem4.dlp = ""
                    } else {
                        currItem4.dlp = Number(action.data)
                    }
                    currItem4.grossamount = (Number(currItem4.sdnquantity) * Number(currItem4.dlp)).toFixed(2);
                    currItem4.total = (Number(currItem4.grossamount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.total) / 100) * Number(currItem4.igst)).toFixed(2);
                    currItem4.amount = ((Number(currItem4.gstvalue)) + (Number(currItem4.total))).toFixed(2);
                    data[itemIndex4] = currItem4

                }
            }
            if (currItem4.calunitofmeasurement === "kg") {
                if (currItem4.tradeDiscount > 0) {

                    if (action.data === "") {
                        currItem4.dlp = ""
                    } else {
                        currItem4.dlp = Number(action.data)
                    }
                    let discount1 = Number(currItem4.sdnquantitykgs) * Number(currItem4.dlp) * (Number(currItem4.tradeDiscount) / 100)
                    currItem4.grossamount = (Number(currItem4.sdnquantitykgs) * Number(currItem4.dlp)).toFixed(2);
                    let totaAmount = Number(currItem4.sdnquantitykgs) * Number(currItem4.dlp) - Number(discount1)
                    currItem4.total = (Number(totaAmount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.total) / 100) * Number(currItem4.igst)).toFixed(2);
                    currItem4.amount = (Number(currItem4.gstvalue) + Number(currItem4.total)).toFixed(2);
                    data[itemIndex4] = currItem4

                } else {
                    if (action.data === "") {
                        currItem4.dlp = ""
                    } else {
                        currItem4.dlp = Number(action.data)
                    }
                    currItem4.grossamount = (Number(currItem4.sdnquantitykgs) * Number(currItem4.dlp)).toFixed(2);
                    currItem4.total = (Number(currItem4.grossamount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.total) / 100) * Number(currItem4.igst)).toFixed(2);
                    currItem4.amount = ((Number(currItem4.gstvalue)) + (Number(currItem4.total))).toFixed(2);
                    data[itemIndex4] = currItem4
                }
            }

            return [...data];

 
    // case CHANGE_COST_SUPPLIER_EDIT:
    //   let newCart1 = [...data];
    //   let itemIndex1 = newCart1.findIndex(
    //     (obj) => obj.product.id === action.item.product.id
    //   );
    //   let currItem1 = data[itemIndex1];

    //   if (action.data2 == "withtax") {
    //     if (currItem1.discount > 0) {
    //       if (action.data == "") {
    //         currItem1.mrp = "";
    //       } else {
    //         currItem1.mrp = Number(action.data);
    //       }
    //       // currItem1.mrp = parseInt(action.data)
    //       let discount3 =
    //         Number(currItem1.sdn_quantity) *
    //         Number(currItem1.mrp) *
    //         (Number(currItem1.discount) / 100);
    //       currItem1.total = (
    //         Number(currItem1.sdn_quantity) * Number(currItem1.mrp) -
    //         Number(discount3)
    //       ).toFixed(2);
    //       // currItem1.total = parseInt(currItem1.sdn_quantity) * parseInt(currItem1.mrp)
    //       currItem1.gstvalue = (
    //         Number(currItem1.total) *
    //         (Number(currItem1.igst) / 100)
    //       ).toFixed(2);
    //       currItem1.amount = (
    //         Number(currItem1.gstvalue) + Number(currItem1.total)
    //       ).toFixed(2);
    //       data[itemIndex1] = currItem1;
    //       return [...data];
    //     } else {
    //       if (action.data == "") {
    //         currItem1.mrp = "";
    //       } else {
    //         currItem1.mrp = Number(action.data);
    //       }
    //       // currItem1.mrp = parseInt(action.data)
    //       currItem1.discount = Number(0);
    //       currItem1.total = (
    //         Number(currItem1.sdn_quantity) * Number(currItem1.mrp)
    //       ).toFixed(2);
    //       currItem1.gstvalue = (
    //         Number(currItem1.total) *
    //         (Number(currItem1.igst) / 100)
    //       ).toFixed(2);
    //       currItem1.amount = (
    //         Number(currItem1.gstvalue) + Number(currItem1.total)
    //       ).toFixed(2);
    //       data[itemIndex1] = currItem1;
    //       return [...data];
    //     }
    //   } else {
    //     if (currItem1.discount > 0) {
    //       if (action.data == "") {
    //         currItem1.mrp = "";
    //       } else {
    //         currItem1.mrp = Number(action.data);
    //       }
    //       // currItem1.mrp = parseInt(action.data)
    //       let discount3 =
    //         Number(currItem1.sdn_quantity) *
    //         Number(currItem1.mrp) *
    //         (Number(currItem1.discount) / 100);
    //       currItem1.total = (
    //         Number(currItem1.sdn_quantity) * Number(currItem1.mrp) -
    //         Number(discount3)
    //       ).toFixed(2);
    //       // currItem1.total = parseInt(currItem1.sdn_quantity) * parseInt(currItem1.mrp)
    //       currItem1.gstvalue = Number(0);
    //       currItem1.amount = (
    //         Number(currItem1.gstvalue) + Number(currItem1.total)
    //       ).toFixed(2);
    //       data[itemIndex1] = currItem1;
    //       return [...data];
    //     } else {
    //       if (action.data == "") {
    //         currItem1.mrp = "";
    //       } else {
    //         currItem1.mrp = Number(action.data);
    //       }
    //       // currItem1.mrp = parseInt(action.data)
    //       currItem1.discount = Number(0);
    //       currItem1.total = (
    //         Number(currItem1.sdn_quantity) * Number(currItem1.mrp)
    //       ).toFixed(2);
    //       currItem1.gstvalue = Number(0);
    //       currItem1.amount = (
    //         Number(currItem1.gstvalue) + Number(currItem1.total)
    //       ).toFixed(2);
    //       data[itemIndex1] = currItem1;
    //       return [...data];
    //     }
    //   }

    case SUPPLIER_EDIT_CHANGE_MEASUREMENTtn:
      let newCart11 = [...data];
      let itemIndex11 = newCart11.findIndex(
        (obj) => obj.product.id === action.item.product.id
      );
      let currItem11 = data[itemIndex11];
      console.log(data);
      currItem11.unitofmeasurement = action.data;
      data[itemIndex11] = currItem11;
      console.log(action);
      return [...data];

    case SUPPLIER_EDIT_CHANGE_QTYPCS_EDIT:
      let newCartEdit = [...data];
      let itemIndexEdit = newCartEdit.findIndex(
        (obj) => obj.product.id === action.item.product.id
      );
      let currItemEdit = data[itemIndexEdit];

      if (currItemEdit.unitofmeasurement === "box") {
        if (currItemEdit.tradeDiscount > 0) {
          if (action.data === "") {
            currItemEdit.sdnquantity = "";
          } else {
            currItemEdit.sdnquantity = Number(action.data);
          }
          let discount1 =
            Number(currItemEdit.sdnquantity) *
            Number(currItemEdit.dlp) *
            (Number(currItemEdit.tradeDiscount) / 100);
          currItemEdit.grossamount = (
            Number(currItemEdit.sdnquantity) * Number(currItemEdit.dlp)
          ).toFixed(2);
          let totaAmount =
            Number(currItemEdit.sdnquantity) * Number(currItemEdit.dlp) -
            Number(discount1);
          currItemEdit.total = Number(totaAmount).toFixed(2);
          currItemEdit.gstvalue = (
            (Number(currItemEdit.total) / 100) *
            Number(currItemEdit.igst)
          ).toFixed(2);
          currItemEdit.amount = (
            Number(currItemEdit.gstvalue) + Number(currItemEdit.total)
          ).toFixed(2);
          data[itemIndexEdit] = currItemEdit;
        } else {
          if (action.data === "") {
            currItemEdit.sdnquantity = "";
            currItemEdit.sdnquantitykgs = "";
          } else {
            currItemEdit.sdnquantity = Number(action.data);
          }

          currItemEdit.grossamount =
            Number(currItemEdit.sdnquantity) * Number(currItemEdit.dlp);
          currItemEdit.total = Number(currItemEdit.grossamount).toFixed(2);
          currItemEdit.gstvalue = (
            (Number(currItem2.total) / 100) *
            Number(currItemEdit.igst)
          ).toFixed(2);
          currItemEdit.amount = (
            Number(currItemEdit.gstvalue) + Number(currItemEdit.total)
          ).toFixed(2);
          data[itemIndexEdit] = currItemEdit;
        }
      }
      if (currItemEdit.unitofmeasurement === "pcs") {
        if (action.data === "") {
          currItemEdit.sdnquantity = "";
          currItemEdit.sdnquantitykgs = "";
        } else {
          currItemEdit.sdnquantity = Number(action.data);
          let aa = Number(currItemEdit.sdnquantity);
          currItemEdit.sdnquantitykgs = (
            Number(aa) * Number(currItemEdit.uomSecondary)
          ).toFixed(3);
        }
      }
      if (currItemEdit.unitofmeasurement === "kg") {
        if (action.data === "") {
          currItemEdit.sdnquantity = "";
        } else {
          currItemEdit.sdnquantity = Number(action.data);
        }
      }
      if (currItemEdit.calunitofmeasurement === "pcs") {
        if (currItemEdit.unitofmeasurement === "pcs") {
          if (currItemEdit.tradeDiscount > 0) {
            if (action.data === "") {
              currItemEdit.sdnquantity = "";
            } else {
              currItemEdit.sdnquantity = Number(action.data);
              let aa = Number(currItemEdit.sdnquantity);
              currItemEdit.sdnquantitykgs = (
                Number(aa) * Number(currItemEdit.uomSecondary)
              ).toFixed(3);
            }
            let discount1 =
              Number(currItemEdit.sdnquantity) *
              Number(currItemEdit.dlp) *
              (Number(currItemEdit.tradeDiscount) / 100);
            currItemEdit.grossamount = (
              Number(currItemEdit.sdnquantity) * Number(currItemEdit.dlp)
            ).toFixed(2);
            let totaAmount =
              Number(currItemEdit.sdnquantity) * Number(currItemEdit.dlp) -
              Number(discount1);
            currItemEdit.total = Number(totaAmount).toFixed(2);
            currItemEdit.gstvalue = (
              (Number(currItemEdit.total) / 100) *
              Number(currItemEdit.igst)
            ).toFixed(2);
            currItemEdit.amount = (
              Number(currItemEdit.gstvalue) + Number(currItemEdit.total)
            ).toFixed(2);
            data[itemIndexEdit] = currItemEdit;
          } else {
            if (action.data === "") {
              currItemEdit.sdnquantity = "";
              currItemEdit.sdnquantitykgs = "";
            } else {
              currItemEdit.sdnquantity = Number(action.data);
            }

            currItemEdit.grossamount =
              Number(currItemEdit.sdnquantity) * Number(currItemEdit.dlp);
            currItemEdit.total = Number(currItemEdit.grossamount).toFixed(2);
            currItemEdit.gstvalue = (
              (Number(currItemEdit.total) / 100) *
              Number(currItemEdit.igst)
            ).toFixed(2);
            currItemEdit.amount = (
              Number(currItemEdit.gstvalue) + Number(currItemEdit.total)
            ).toFixed(2);
            data[itemIndexEdit] = currItemEdit;
          }
        } else if (currItemEdit.unitofmeasurement === "kg") {
          if (currItemEdit.tradeDiscount > 0) {
            if (action.data === "") {
              currItemEdit.sdnquantity = "";
            } else {
              currItemEdit.sdnquantity = Number(action.data);
            }
            let discount1 =
              Number(currItemEdit.sdnquantity) *
              Number(currItemEdit.dlp) *
              (Number(currItemEdit.tradeDiscount) / 100);
            currItemEdit.grossamount = (
              Number(currItemEdit.sdnquantity) * Number(currItemEdit.dlp)
            ).toFixed(2);
            let totaAmount =
              Number(currItemEdit.sdnquantity) * Number(currItemEdit.dlp) -
              Number(discount1);
            currItemEdit.total = Number(totaAmount).toFixed(2);
            currItemEdit.gstvalue = (
              (Number(currItemEdit.total) / 100) *
              Number(currItemEdit.igst)
            ).toFixed(2);
            currItemEdit.amount = (
              Number(currItemEdit.gstvalue) + Number(currItemEdit.total)
            ).toFixed(2);
            data[itemIndexEdit] = currItemEdit;
          } else {
            if (action.data === "") {
              currItemEdit.sdnquantity = "";
            } else {
              currItemEdit.sdnquantity = Number(action.data);
            }

            currItemEdit.grossamount =
              Number(currItemEdit.sdnquantity) * Number(currItemEdit.dlp);
            currItemEdit.total = Number(currItemEdit.grossamount).toFixed(2);
            currItemEdit.gstvalue = (
              (Number(currItemEdit.total) / 100) *
              Number(currItemEdit.igst)
            ).toFixed(2);
            currItemEdit.amount = (
              Number(currItemEdit.gstvalue) + Number(currItemEdit.total)
            ).toFixed(2);
            data[itemIndexEdit] = currItemEdit;
          }
        }
      }

      if (currItemEdit.calunitofmeasurement === "kg") {
        if (currItemEdit.unitofmeasurement === "pcs") {
          if (currItemEdit.tradeDiscount > 0) {
            if (action.data === "") {
              currItemEdit.sdnquantity = "";
            } else {
              currItemEdit.sdnquantity = Number(action.data);
              let aa = Number(currItemEdit.sdnquantity);
              currItemEdit.sdnquantitykgs = (
                Number(aa) * Number(currItemEdit.uomSecondary)
              ).toFixed(3);
            }
            let discount1 =
              Number(currItemEdit.sdnquantitykgs) *
              Number(currItemEdit.dlp) *
              (Number(currItemEdit.tradeDiscount) / 100);
            currItemEdit.grossamount = (
              Number(currItemEdit.sdnquantitykgs) * Number(currItemEdit.dlp)
            ).toFixed(2);
            let totaAmount =
              Number(currItemEdit.sdnquantitykgs) * Number(currItemEdit.dlp) -
              Number(discount1);
            currItemEdit.total = Number(totaAmount).toFixed(2);
            currItemEdit.gstvalue = (
              (Number(currItemEdit.total) / 100) *
              Number(currItemEdit.igst)
            ).toFixed(2);
            currItemEdit.amount = (
              Number(currItemEdit.gstvalue) + Number(currItemEdit.total)
            ).toFixed(2);
            data[itemIndexEdit] = currItemEdit;
          } else {
            if (action.data === "") {
              currItemEdit.sdnquantity = "";
              currItemEdit.sdnquantitykgs = "";
            } else {
              currItemEdit.sdnquantity = Number(action.data);
              let aa = Number(currItemEdit.sdnquantity);
              currItemEdit.sdnquantitykgs = (
                Number(aa) * Number(currItemEdit.uomSecondary)
              ).toFixed(3);
            }

            currItemEdit.grossamount =
              Number(currItemEdit.sdnquantitykgs) * Number(currItemEdit.dlp);
            currItemEdit.total = Number(currItemEdit.grossamount).toFixed(2);
            currItemEdit.gstvalue = (
              (Number(currItemEdit.total) / 100) *
              Number(currItemEdit.igst)
            ).toFixed(2);
            currItemEdit.amount = (
              Number(currItemEdit.gstvalue) + Number(currItemEdit.total)
            ).toFixed(2);
            data[itemIndexEdit] = currItemEdit;
          }
        } else if (currItemEdit.unitofmeasurement === "pcs") {
          if (currItemEdit.tradeDiscount > 0) {
            if (action.data === "") {
              currItemEdit.sdnquantity = "";
            } else {
              currItemEdit.sdnquantity = Number(action.data);
            }
            let discount1 =
              Number(currItemEdit.sdnquantitykgs) *
              Number(currItemEdit.dlp) *
              (Number(currItemEdit.tradeDiscount) / 100);
            currItemEdit.grossamount = (
              Number(currItemEdit.sdnquantitykgs) * Number(currItemEdit.dlp)
            ).toFixed(2);
            let totaAmount =
              Number(currItemEdit.sdnquantitykgs) * Number(currItemEdit.dlp) -
              Number(discount1);
            currItemEdit.total = Number(totaAmount).toFixed(2);
            currItemEdit.gstvalue = (
              (Number(currItemEdit.total) / 100) *
              Number(currItemEdit.igst)
            ).toFixed(2);
            currItemEdit.amount = (
              Number(currItemEdit.gstvalue) + Number(currItemEdit.total)
            ).toFixed(2);
            data[itemIndexEdit] = currItemEdit;
          } else {
            if (action.data === "") {
              currItemEdit.sdnquantity = "";
            } else {
              currItemEdit.sdnquantity = Number(action.data);
            }

            currItemEdit.grossamount =
              Number(currItemEdit.sdnquantitykgs) * Number(currItemEdit.dlp);
            currItemEdit.total = Number(currItemEdit.grossamount).toFixed(2);
            currItemEdit.gstvalue = (
              (Number(currItemEdit.total) / 100) *
              Number(currItemEdit.igst)
            ).toFixed(2);
            currItemEdit.amount = (
              Number(currItemEdit.gstvalue) + Number(currItemEdit.total)
            ).toFixed(2);
            data[itemIndexEdit] = currItemEdit;
          }
        }
      }
      return [...data];

    case SUPPLIER_EDIT_CHANGE_QTYKG_EDIT:
      let newCart3 = [...data];

      let itemIndex3 = newCart3.findIndex(
        (obj) => obj.product.id === action.item.product.id
      );
      let currItem3 = data[itemIndex3];

      if (currItem3.unitofmeasurement === "kg") {
        if (action.data === "") {
          currItem3.sdnquantity = "";
          currItem3.sdnquantitykgs = "";
        } else {
          currItem3.sdnquantitykgs = Number(action.data);
          let aa = Number(currItem3.sdnquantitykgs);
          let ab = Number(aa) / Number(currItem3.uomSecondary);
          currItem3.sdnquantity = Math.round(ab);
        }
      }

      if (currItem3.unitofmeasurement === "pcs") {
        if (action.data === "") {
          currItem3.sdnquantitykgs = "";
        } else {
          currItem3.sdnquantitykgs = Number(action.data);
        }
      }

      if (currItem3.calunitofmeasurement === "kg") {
        if (currItem3.unitofmeasurement === "kg") {
          if (currItem3.tradeDiscount > 0) {
            if (action.data === "") {
              currItem3.sdnquantitykgs = "";
            } else {
              currItem3.sdnquantitykgs = Number(action.data);
              let aa = Number(currItem3.sdnquantitykgs);
              let ab = Number(aa) / Number(currItem3.uomSecondary);
              currItem3.sdnquantity = Math.round(ab);
            }
            let discount1 =
              Number(currItem3.sdnquantitykgs) *
              Number(currItem3.dlp) *
              (Number(currItem3.tradeDiscount) / 100);
            currItem3.grossamount = (
              Number(currItem3.sdnquantitykgs) * Number(currItem3.dlp)
            ).toFixed(2);
            let totaAmount =
              Number(currItem3.sdnquantitykgs) * Number(currItem3.dlp) -
              Number(discount1);
            currItem3.total = Number(totaAmount).toFixed(2);
            currItem3.gstvalue = (
              (Number(currItem3.total) / 100) *
              Number(currItem3.igst)
            ).toFixed(2);
            currItem3.amount = (
              Number(currItem3.gstvalue) + Number(currItem3.total)
            ).toFixed(2);
            data[itemIndex3] = currItem3;
          } else {
            if (action.data === "") {
              currItem3.sdnquantitykgs = "";
            } else {
              currItem3.sdnquantitykgs = Number(action.data);
              let aa = Number(currItem3.sdnquantitykgs);
              let ab = Number(aa) / Number(currItem3.uomSecondary);
              currItem3.sdnquantity = Math.round(ab);
            }
            currItem3.grossamount =
              Number(currItem3.sdnquantitykgs) * Number(currItem3.dlp);
            currItem3.total = Number(currItem3.grossamount).toFixed(2);
            currItem3.gstvalue = (
              (Number(currItem3.total) / 100) *
              Number(currItem3.igst)
            ).toFixed(2);
            currItem3.amount = (
              Number(currItem3.gstvalue) + Number(currItem3.total)
            ).toFixed(2);
            data[itemIndex3] = currItem3;
          }
        } else if (currItem3.unitofmeasurement === "pcs") {
          if (currItem3.tradeDiscount > 0) {
            if (action.data === "") {
              currItem3.sdnquantitykgs = "";
            } else {
              currItem3.sdnquantitykgs = Number(action.data);
            }
            let discount1 =
              Number(currItem3.sdnquantitykgs) *
              Number(currItem3.dlp) *
              (Number(currItem3.tradeDiscount) / 100);
            currItem3.grossamount = (
              Number(currItem3.sdnquantitykgs) * Number(currItem3.dlp)
            ).toFixed(2);
            let totaAmount =
              Number(currItem3.sdnquantitykgs) * Number(currItem3.dlp) -
              Number(discount1);
            currItem3.total = Number(totaAmount).toFixed(2);
            currItem3.gstvalue = (
              (Number(currItem3.total) / 100) *
              Number(currItem3.igst)
            ).toFixed(2);
            currItem3.amount = (
              Number(currItem3.gstvalue) + Number(currItem3.total)
            ).toFixed(2);
            data[itemIndex3] = currItem3;
          } else {
            if (action.data === "") {
              currItem3.sdnquantitykgs = "";
            } else {
              currItem3.sdnquantitykgs = Number(action.data);
            }
            currItem3.grossamount =
              Number(currItem3.sdnquantitykgs) * Number(currItem3.dlp);
            currItem3.total = Number(currItem3.grossamount).toFixed(2);
            currItem3.gstvalue = (
              (Number(currItem3.total) / 100) *
              Number(currItem3.igst)
            ).toFixed(2);
            currItem3.amount = (
              Number(currItem3.gstvalue) + Number(currItem3.total)
            ).toFixed(2);
            data[itemIndex3] = currItem3;
          }
        }
      }

      if (currItem3.calunitofmeasurement === "pcs") {
        if (currItem3.unitofmeasurement === "kg") {
          if (currItem3.tradeDiscount > 0) {
            if (action.data === "") {
              currItem3.sdnquantitykgs = "";
            } else {
              currItem3.sdnquantitykgs = Number(action.data);
              let aa = Number(currItem3.sdnquantitykgs);
              let ab = Number(aa) / Number(currItem3.uomSecondary);
              currItem3.sdnquantity = Math.round(ab);
            }
            let discount1 =
              Number(currItem3.sdnquantity) *
              Number(currItem3.dlp) *
              (Number(currItem3.tradeDiscount) / 100);
            currItem3.grossamount = (
              Number(currItem3.sdnquantity) * Number(currItem3.dlp)
            ).toFixed(2);
            let totaAmount =
              Number(currItem3.sdnquantity) * Number(currItem3.dlp) -
              Number(discount1);
            currItem3.total = Number(totaAmount).toFixed(2);
            currItem3.gstvalue = (
              (Number(currItem3.total) / 100) *
              Number(currItem3.igst)
            ).toFixed(2);
            currItem3.amount = (
              Number(currItem3.gstvalue) + Number(currItem3.total)
            ).toFixed(2);
            data[itemIndex3] = currItem3;
          } else {
            if (action.data === "") {
              currItem3.sdnquantitykgs = "";
            } else {
              currItem3.sdnquantitykgs = Number(action.data);
              let aa = Number(currItem3.sdnquantitykgs);
              let ab = Number(aa) / Number(currItem3.uomSecondary);
              currItem3.sdnquantity = Math.round(ab);
            }
            currItem3.grossamount =
              Number(currItem3.sdnquantity) * Number(currItem3.dlp);
            currItem3.total = Number(currItem3.grossamount).toFixed(2);
            currItem3.gstvalue = (
              (Number(currItem3.total) / 100) *
              Number(currItem3.igst)
            ).toFixed(2);
            currItem3.amount = (
              Number(currItem3.gstvalue) + Number(currItem3.total)
            ).toFixed(2);
            data[itemIndex3] = currItem3;
          }
        } else if (currItem3.unitofmeasurement === "pcs") {
          if (currItem3.tradeDiscount > 0) {
            if (action.data === "") {
              currItem3.sdnquantitykgs = "";
            } else {
              currItem3.sdnquantitykgs = Number(action.data);
            }
            let discount1 =
              Number(currItem3.sdnquantity) *
              Number(currItem3.dlp) *
              (Number(currItem3.tradeDiscount) / 100);
            currItem3.grossamount = (
              Number(currItem3.sdnquantity) * Number(currItem3.dlp)
            ).toFixed(2);
            let totaAmount =
              Number(currItem3.sdnquantity) * Number(currItem3.dlp) -
              Number(discount1);
            currItem3.total = Number(totaAmount).toFixed(2);
            currItem3.gstvalue = (
              (Number(currItem3.total) / 100) *
              Number(currItem3.igst)
            ).toFixed(2);
            currItem3.amount = (
              Number(currItem3.gstvalue) + Number(currItem3.total)
            ).toFixed(2);
            data[itemIndex3] = currItem3;
          } else {
            if (action.data === "") {
              currItem3.sdnquantitykgs = "";
            } else {
              currItem3.sdnquantitykgs = Number(action.data);
            }
            currItem3.grossamount =
              Number(currItem3.sdnquantity) * Number(currItem3.dlp);
            currItem3.total = Number(currItem3.grossamount).toFixed(2);
            currItem3.gstvalue = (
              (Number(currItem3.total) / 100) *
              Number(currItem3.igst)
            ).toFixed(2);
            currItem3.amount = (
              Number(currItem3.gstvalue) + Number(currItem3.total)
            ).toFixed(2);
            data[itemIndex3] = currItem3;
          }
        }
      }

      return [...data];

    case CHANGE_SUPPLIER_EDIT_DISCOUNT:
      let newCart2 = [...data];
      let itemIndex2 = newCart2.findIndex(
        (obj) => obj.product.id === action.item.product.id
      );
      let currItem2 = data[itemIndex2];

      if (action.data2 == "withtax") {
        if (action.data == "") {
          currItem2.discount = 0;
        } else {
          currItem2.discount = Number(action.data);
        }

        // currItem1.mrp = parseInt(action.data)
        let discount1 =
          Number(currItem2.sdn_quantity) *
          Number(currItem2.mrp) *
          (Number(currItem2.discount) / 100);
        currItem2.total = (
          Number(currItem2.sdn_quantity) * Number(currItem2.mrp) -
          Number(discount1)
        ).toFixed(2);
        // currItem2.total = parseInt(currItem2.sdn_quantity) * parseInt(currItem2.mrp)
        currItem2.gstvalue = (
          Number(currItem2.total) *
          (Number(currItem2.igst) / 100)
        ).toFixed(2);
        currItem2.amount = (
          Number(currItem2.gstvalue) + Number(currItem2.total)
        ).toFixed(2);
        data[itemIndex2] = currItem2;
      } else {
        if (action.data == "") {
          currItem2.discount = 0;
        } else {
          currItem2.discount = Number(action.data);
        }

        // currItem1.mrp = parseInt(action.data)
        let discount1 =
          Number(currItem2.sdn_quantity) *
          Number(currItem2.mrp) *
          (Number(currItem2.discount) / 100);
        currItem2.total = (
          Number(currItem2.sdn_quantity) * Number(currItem2.mrp) -
          Number(discount1)
        ).toFixed(2);
        // currItem2.total = parseInt(currItem2.sdn_quantity) * parseInt(currItem2.mrp)
        currItem2.gstvalue = Number(0);
        currItem2.amount = (
          Number(currItem2.gstvalue) + Number(currItem2.total)
        ).toFixed(2);
        data[itemIndex2] = currItem2;
      }

      return [...data];

    case CHANGE_SUPPLIER_EDIT_TEXT:
      let newCart5 = [...data];
      let itemIndex5 = newCart5.findIndex(
        (obj) => obj.product.id === action.item.product.id
      );
      let currItem5 = data[itemIndex5];

      currItem5.text = action.data;
      data[itemIndex5] = currItem5;
      return [...data];


    case PUT_NEW_SUPPLIER_ADD_TO_CART_EDIT:
            let currentDatas = {
              sdnitemid: action.data.id,
                product: { "id": action.data.id },
                igst: action.data.igst,
                dlp: "",
                productName: action.data.productName,
                productType: action.data.productType,
                standardQtyPerBox: action.data.standardQtyPerBox,
                uomPrimary: action.data.uomPrimary,
                uomSecondary: action.data.uomSecondary,
                tradeDiscount: "",
                hsncode: action.data.hsnCode,
                unitofmeasurement: action.data.unitofmeasurement == undefined ? action.data.productType !== "kgproduct" ? "box" : '' : action.data.unitofmeasurement,
                calunitofmeasurement: action.data.calunitofmeasurement == undefined ? action.data.productType !== "kgproduct" ? "box" : '' : action.data.calunitofmeasurement
            }
            return [currentDatas, ...data];


    



    case EMPTY_SUPPLIER_EDIT_DATA:
      // console.warn("reducer called",action)
      return [];

    default:
      return data;
  }
};
