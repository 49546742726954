import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import ProductService from "../../Component/services/ProductService";
import axios from "axios";
import { BASE_URL } from "../../Component/services/Api";
import authHeader from "../../Component/services/auth-header";
import { changecostmaterialreceiptedit, changematerialreceipteditdiscount, changematerialreceipteditqty, changematerialreceiptedittext, getmaterialreceipteditdatatoload, materialeditcalmeasurement, materialeditmeasurement, materialeditqtykgs, materialeditqtypcs, materialreceipteditaddtocart, removefromcartfrommaterialreceiptedit } from "../../redux/Action/materialreceiptnoteedit";
import Swal from "sweetalert2";

export default function Edit() {
    const { id } = useParams();
const {pointindex}=useParams();
    const [warehousedata, setwarehousedata] = useState('');
    const [supplierdata, setsupplierdata] = useState('');
    const [supplierSubContactsdata, setsupplierSubContactsdata] = useState('');
    const [supplierSubContacts_data, setsupplierSubContacts_data] = useState([]);
    const [supplier_SubContactsdata, setsupplier_SubContactsdata] = useState([]);
    const [mrndate, setmrndate] = useState('');
    const [type, settype] = useState('');
    const [status, setstatus] = useState('');
    const [shippingaddress, setshippingaddress] = useState('');
    const [remarks, setremarks] = useState('');
    const [productdata, setproductdata] = useState([]);
    const [taxtype, settaxtype] = useState("");
    const [supplierdeliverynote_id, setsupplierdeliverynote_id] = useState('');
    const [receiptnoteno, setreceiptnoteno] = useState('');
    const [refernceno, setrefernceno] = useState('');
    const [receiptdate, setreceiptdate] = useState('');
    const [otherreference, setotherreference] = useState('');
    const [branch, setbranch] = useState('');
    const [branchdata, setbranchdata] = useState('');
    const [shippingcharge, setShipping] = useState(0);
    const [round, setRound] = useState(0);
    const [bstateid, setbstateid] = useState('');
    const [sstateid, setsstateid] = useState('');
    const [paymentTerms, setpaymentTerms] = useState('');
    const [voucherid, setvoucherid] = useState('');
    const [einvoiceno, seteinvoiceno] = useState('');
    const [ewaybillno, setewaybillno] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    
    const dispatch = useDispatch();
    const materialReceiptNoteItems = useSelector((state) => state.materialreceipteditdata);
    let gross_Amount = materialReceiptNoteItems.length && materialReceiptNoteItems.map(item => Math.round(Number(item.total))).reduce((prev, next) => Math.round(prev - (-next)));
    let gst = materialReceiptNoteItems.length && materialReceiptNoteItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
    //  console.log("mello",gst);
    let aa = [];
    console.log(materialReceiptNoteItems)
    materialReceiptNoteItems.map(user=>{
        let igst_value = user.igst;
        let total_value = user.total;
        let total_amou = ((Number(shippingcharge)/Number(gross_Amount))* Number(total_value));
        let add_amou = Number(total_amou) - (-Number(total_value));
        let final_amou = ((Number(add_amou)/100)*Number(igst_value)).toFixed(2); 
        aa.push(final_amou)

        // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
    })
    let total_per = aa.length && aa.map(user=>user).reduce((prev, next) => (prev - (-next)));

    let grossAmount = Number(gross_Amount) + Number(shippingcharge);
    let grandTotal = Number(gross_Amount)  + Number(shippingcharge) + Number(round) +  Number(total_per);

    let igstTax = Number(total_per);
    let cgstTax = (Number(total_per)/2);
    let sgstTax = (Number(total_per)/2);



    const navigate = useNavigate();

    function roundData(e){
        if(e){
            let gross_Amount = materialReceiptNoteItems.length && materialReceiptNoteItems.map(item => Math.round(Number(item.total))).reduce((prev, next) => Math.round(prev - (-next)));
            let gst = materialReceiptNoteItems.length && materialReceiptNoteItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
            let aa = [];
            materialReceiptNoteItems.map(user=>{
                let igst_value = user.igst;
                let total_value = user.total;
                let total_amou = ((Number(shippingcharge)/Number(gross_Amount))* Number(total_value));
                let add_amou = Number(total_amou) - (-Number(total_value));
                let final_amou = ((Number(add_amou)/100)*Number(igst_value)).toFixed(2); 
                aa.push(final_amou)
        
                // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
            })
            let total_per = aa.length && aa.map(user=>user).reduce((prev, next) => (prev - (-next)));
            
            let grossAmount = Number(gross_Amount) + Number(shippingcharge);
            let grandTotal = Number(gross_Amount)  + Number(shippingcharge) + Number(total_per);
      
             let grand_Total = Math.ceil(grandTotal);
        
             if(grand_Total > grandTotal){
                 let rou_nd = (Number(grand_Total) - Number(grandTotal)).toFixed(2);
                 setRound(rou_nd);
             } else{
                 setRound(0)
             }
               
              
        } else if(e==''){
            setRound(0)
        }
    }

    const getmaterialreceiptydata = () => {

        axios.get(BASE_URL + 'mrn/' + id, {
            headers: authHeader()
        }).then(res => {
            console.log("mello", res.data);
            setwarehousedata(res.data.warehouse);
            setpaymentTerms(res.data.paymentTerms);
            setsupplierdata(res.data.supplier);
            setsupplierSubContactsdata(res.data.supplierSubContacts == null?null:res.data.supplierSubContacts.id);
            setsupplierSubContacts_data(res.data.supplierSubContacts == null?null:{value:res.data.supplierSubContacts.id,label:res.data.supplierSubContacts.contactname})
            setsupplier_SubContactsdata((res.data.supplier.supplierSubContacts).map(user=>({value:user.id,label:user.contactname})))
            settype(res.data.type);
            setstatus(res.data.status);
            setvoucherid(res.data.voucherseries);
            setshippingaddress(res.data.shippingAddress);
            setremarks(res.data.remarks);
            settaxtype(res.data.taxtype);
            setmrndate(res.data.mrndate);
            setsupplierdeliverynote_id(res.data.supplierdeliverynote_id);
            setbranch({ "id": res.data.branch.id });
            setbranchdata(res.data.branch.branchname);
            setShipping(res.data.shippingcharge);
            setRound(res.data.roundofvalue);
            seteinvoiceno(res.data.einvoiceno);
setewaybillno(res.data.ewaybillno);
            setbstateid(res.data.branch.states.id);
            setsstateid(res.data.supplier.states.id);
            setreceiptnoteno(res.data.receiptnoteno);
            setrefernceno(res.data.refernceno);
            setreceiptdate(res.data.receiptdate);
            setotherreference(res.data.otherreference);
        })
    };

    let date_value = new Date(mrndate);
    const defaultValue = date_value.toLocaleDateString('en-CA');
    let date_value1 = new Date(receiptdate);
    const receiptdate_1 = date_value1.toLocaleDateString('en-CA');

    /* get Product All */
    const getproductdata = () => {
        axios.get( BASE_URL + 'product/page/0/100/asc/id/%20',{
            headers:authHeader()
        }).then(res=>{

            setproductdata((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
        })
    };

    const customFilter = (e) => {
        let name = e;
        axios.get( BASE_URL + 'product/page/0/100/asc/id/'+name,{
            headers:authHeader()
        }).then(res=>{
         setproductdata((res.data.data).map(use=>({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })))
        })
    };

    useEffect(() => {
        getmaterialreceiptydata();
        getproductdata();
        dispatch(getmaterialreceipteditdatatoload(id))
    }, []);

    const addData = (e) => {
        const filteredItems = materialReceiptNoteItems.filter(item => item.product.id === e.value);
        if (filteredItems.length == "0") { 
        dispatch(materialreceipteditaddtocart(e.value))
        setShipping("0");
        } else{
            Swal.fire({
                title: 'This Product is Already Added',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showConfirmButton:false,
                showCancelButton:true,
                cancelButtonColor:'red'
            })
        }

    }

    /* get Shipping Charges */
    const settingship = (e) => {

        if (e == "") {

            setShipping("");
        } else {


            setShipping(e);
        }

    }

    /* get Round Value */
    const settinground = (e) => {
        if (e == "") {

            setRound("");
        } else {

            setRound(e);
        }

    }

    const sgstcheck = () => {

        let aa = 0

        if (taxtype == "withtax") {

            if (bstateid === sstateid) {

                aa = sgstTax;

            } else {

                aa = 0;

            }

        } else {

            aa = 0;

        }
        return aa;
    };

    const cgstcheck = () => {

        let bb = 0
        if (taxtype == "withtax") {

            if (bstateid === sstateid) {

                bb = cgstTax;

            } else {

                bb = 0;

            }
        } else {

            bb = 0;

        }
        return bb;
    }

    const igstcheck = () => {

        let cc = 0
        if (taxtype == "withtax") {

            if (bstateid === sstateid) {

                cc = 0;

            } else {

                cc = igstTax;

            }
        } else {

            cc = 0;

        }
        return cc;
    };

    const onChangesubcontact = ( e ) => {
        setsupplierSubContactsdata(e.value);
        setsupplierSubContacts_data({value:e.value,label:e.label})

    }

    const submitdata = () => {
        setIsLoading(true); 
        let warehouse = { "id": Number(warehousedata.id) };
        let supplier = { "id": Number(supplierdata.id) };

        let supplierSubContacts = {id:supplierSubContactsdata};
        let igst = igstcheck();
        let cgst = cgstcheck();
        let sgst = sgstcheck();
        let grandtotal = grandTotal;
        let roundingofvalue = round;
        let grossamount = grossAmount;
        let shippingAddress=shippingaddress;
        const materialreceiptsubmitdata = {
            warehouse, supplier, receiptnoteno, refernceno,ewaybillno,einvoiceno, receiptdate, otherreference, mrndate,  status, shippingaddress, remarks, supplierSubContacts, materialReceiptNoteItems, taxtype, supplierdeliverynote_id,
            igst, cgst, sgst, grandtotal, roundingofvalue, shippingAddress, grossamount, branch,paymentTerms
        };
    console.log('====================================');
    console.log(materialreceiptsubmitdata);
    console.log('====================================');
        axios.put(BASE_URL + 'mrn/' + id, materialreceiptsubmitdata, {
            headers: authHeader()
        }).then(res => {
            navigate(`/MaterialReceiptNoteTable/${pointindex}`)
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon:"error",
              title:"Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          }).finally(() => {
            setIsLoading(false); 
          });
    }

    return (
        <MDBCard className="company">
          
            <div className='d-flex bd-highlight'>
        <div className="card-header card-title w-100">   <h1>Edit Material Receipt Note</h1></div>
        {/* <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/MaterialReceiptNoteEdit/${id}/${pointindex}`}><Button>Edit</Button></Link> */}
        {/* <a className=' delete  btn' onClick={()=>getItem(id)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg></a> */}

        <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/MaterialReceiptNoteTable/${pointindex}`}><Button>Back</Button></Link>

   
      </div>
            <Form className="product1">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Material Receipt Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                            <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Form.Control type="text" value={branchdata} disabled />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">WareHouse Name</Form.Label>
                                    <Form.Control type="text" value={warehousedata.name} disabled />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Company</Form.Label>
                                    <Form.Control type="text" value={supplierdata.companyname} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Contact Name</Form.Label>
                                    <Select options={supplier_SubContactsdata} value={supplierSubContacts_data == null ?"":supplierSubContacts_data} onChange={(e) => onChangesubcontact(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Payment</Form.Label>
                                    <Form.Control type='text' value={paymentTerms} name="paymentTerms" onChange={(e) => setpaymentTerms(e.target.value)} autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">E-invoice No  </Form.Label>
                                    <Form.Control type="text" value={einvoiceno} onChange={(e) => seteinvoiceno(e.target.value)}/>
                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">E-way bill No  </Form.Label>
                                    <Form.Control type="text" value={ewaybillno} onChange={(e) => setewaybillno(e.target.value)}/>
                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Receipt Note No</Form.Label>
                                    <Form.Control type="text" value={receiptnoteno} name="receiptnoteno" onChange={(e) => setreceiptnoteno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Reference No</Form.Label>
                                    <Form.Control type="text" value={refernceno} name="refernceno" onChange={(e) => setrefernceno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Receipt Date</Form.Label>
                                    <Form.Control type="date" value={receiptdate_1} name="receiptdate" onChange={(e) => setreceiptdate(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Other Reference</Form.Label>
                                    <Form.Control type="text" value={otherreference} name="otherreference" onChange={(e) => setotherreference(e.target.value)} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Type</Form.Label>
                                    <Form.Select value={type} disabled>
                                        <option value="b2b">B2B</option>
                                        <option value="b2c">B2C</option>
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher</Form.Label>
                                    <Form.Control type='text' value={voucherid} name="voucherid" readOnly autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher Name</Form.Label>
                                    <Form.Control type='text' value={voucherid} name="voucherid" readOnly autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Status</Form.Label>
                                    <Form.Select value={status} disabled>
                                        <option value='true'>Open</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Select value={taxtype} disabled>
                                        <option>--Select--</option>
                                        <option value='withtax'>With Tax</option>
                                        <option value='withouttax'>Without Tax</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>Billing Address</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Address</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Details</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address : -</Form.Label>
                                    <span>&nbsp;{supplierdata.address}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name : -</Form.Label>
                                    <span>&nbsp;{supplierSubContactsdata == null ? "" : supplierSubContactsdata.contactname}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">City : -</Form.Label>
                                    <span>&nbsp;{supplierdata.cities}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{supplierSubContactsdata == null ? "" : supplierSubContactsdata.phoneno}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Country : -</Form.Label>
                                    <span>{"India"}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span>{supplierSubContactsdata == null ? "" : supplierSubContactsdata.email}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State : -</Form.Label>
                                    <span>{supplierstate}</span>
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Shipping Address</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Label className="label">Address</Form.Label>
                                    <Form.Control as="textarea" value={shippingaddress} name='shippingaddress' onChange={(e) => setshippingaddress(e.target.value)} rows={3}  placeholder='Enter Shipping Address' />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Label className="label">Remarks</Form.Label>
                                    <Form.Control as="textarea" value={remarks} onChange={(e) =>setremarks(e.target.value)} rows={3} name='address' placeholder='Enter Remarks' />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br></br>
                <Row className="mb-1">
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Product</Form.Label>
                        <Select options={productdata} onChange={(e) => addData(e)} onInputChange={(e) => customFilter(e) }  />
                        
                    </Form.Group>
                </Row>
                <br></br>
                <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th style={{ width: '20%' }}>PRODUCT&nbsp;NAME</th>
                            <th style={{ width: '9%' }}>HSN&nbsp;Code</th>
                            <th style={{ width: '8%' }}>GST</th>
                            <th style={{ width: '8%' }}>UOM</th>
                            <th style={{ width: '8%' }}>QTY(PCS)</th>
                            <th style={{ width: '8%' }}>QTY(KG)</th>
                            <th style={{ width: '8%' }}>RATE (₹)</th>
                            <th style={{ width: '8%' }}>UOM</th>
                            <th style={{ width: '8%' }}>GROSS AMOUNT(₹)</th>
                            <th style={{ width: '8%' }}>DISCOUNT %</th>
                            <th style={{ width: '8%' }}>NET AMOUNT (₹)</th>
                            {/* <th>Per&nbsp;Unit&nbsp;Rate</th> */}
                            <th style={{ width: '8%' }}>GST VALUE&nbsp;(₹)</th>
                            <th style={{ width: '8%' }}>TOTAL&nbsp;(₹)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            {/* <th>View</th> */}
                            <th>Action&nbsp;&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {materialReceiptNoteItems.map(item =>
                        item.mrnitemid == null ?<tr key={item.product.id}>
                             <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={item.productName} size="sm" /> */}
                                        <textarea type="text" value={item.productName} className="form-control textcontrol" size="sm"></textarea>
                                    </Form.Group>
                                    <Form.Control as="textarea" onChange={(e) => { dispatch(changematerialreceiptedittext(e.target.value, item)) }} value={item.text} rows={2} className='form-control' />
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number"  value={item.hsncode} size="sm" readOnly/>
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number"  value={item.igst} size="sm" readOnly/>
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                    <Form.Select disabled={item.productType !== "kgproduct" ? true : false} onChange={(e) => { dispatch(materialeditmeasurement(e.target.value, item, item.unitofmeasurement)); roundData(e.target.value)}} size="sm">
                                        <option>{item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                        <option value="pcs">PCS</option>
                                        <option value="kg">KG</option>
                                    </Form.Select>
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" onChange={(e) => { dispatch(materialeditqtypcs(e.target.value, item, item.unitofmeasurement)); roundData(e.target.value)}} disabled={item.unitofmeasurement == "kg" ? true : false} value={item.mrnquantity} size="sm" />
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" onChange={(e) => { dispatch(materialeditqtykgs(e.target.value, item, item.unitofmeasurement)); roundData(e.target.value)}} disabled={item.unitofmeasurement == "pcs" ? true : false || item.unitofmeasurement == "" ? true : false} value={item.unitofmeasurement == "box" ? "" : item.mrnquantitykgs} size="sm" />
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changecostmaterialreceiptedit(e.target.value, item, taxtype));roundData(e.target.value) }} value={item.dlp} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Select disabled={item.productType !== "kgproduct" ? true : false} onChange={(e) => { dispatch(materialeditcalmeasurement(e.target.value, item, item.calunitofmeasurement));roundData(e.target.value) }} size="sm">
                                            <option>{item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                            <option value="pcs">PCS</option>
                                            <option value="kg">KG</option>
                                        </Form.Select>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.grossamount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" onChange={(e) => { dispatch(changematerialreceipteditdiscount(e.target.value, item, taxtype));roundData(e.target.value) }} value={item.tradeDiscount || ''} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.total)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.gstvalue)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.amount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type='button' className='btn btn-danger' onClick={() => {dispatch(removefromcartfrommaterialreceiptedit(item.mrn_id));roundData('')}} value="X" size="sm" />
                                    </Form.Group>
                                </td>

                        </tr>:
                            <tr key={item.mrnitemid}>
                                <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={item.productName} size="sm" /> */}
                                        <textarea type="text" value={item.productName} className="form-control textcontrol" size="sm"></textarea>
                                    </Form.Group>
                                    <Form.Control as="textarea" onChange={(e) => { dispatch(changematerialreceiptedittext(e.target.value, item)) }} value={item.text} rows={2} className='form-control' />
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number"  value={item.hsncode} size="sm" readOnly/>
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number"  value={item.igst} size="sm" readOnly/>
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Select disabled={item.unitofmeasurement == "box" ? true:item.productType !== "kgproduct" ? true:false } value={item.unitofmeasurement} onChange={(e) => { dispatch(materialeditmeasurement(e.target.value, item, item.unitofmeasurement)); }} size="sm">
                                        <option>{item.unitofmeasurement == "box" ? "PCS":item.productType !== "kgproduct" ? 'PCS':"Select"}</option>
                                        <option value="pcs">PCS</option>
                                        <option value="kg">KG</option>
                                    </Form.Select>
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" onChange={(e) => { dispatch(materialeditqtypcs(e.target.value, item, item.unitofmeasurement)); roundData(e.target.value)}} disabled={item.unitofmeasurement == "" ? true : false} value={item.mrnquantity} size="sm" />
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" onChange={(e) => { dispatch(materialeditqtykgs(e.target.value, item, item.unitofmeasurement));roundData(e.target.value) }} disabled={item.unitofmeasurement == "box" ? true:item.productType == "boxproduct" ? true:false || item.unitofmeasurement==""?true:false}  value={item.unitofmeasurement == "box" ? "" : item.mrnquantitykgs} size="sm" />
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changecostmaterialreceiptedit(e.target.value, item, taxtype));roundData(e.target.value) }} value={item.dlp} />
                                    </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Select disabled={item.calunitofmeasurement == "box" ? true:item.productType !== "kgproduct" ? true:false } value={item.calunitofmeasurement} onChange={(e) => { dispatch(materialeditcalmeasurement(e.target.value, item, item.calunitofmeasurement)); roundData(e.target.value)}} size="sm">
                                        <option>{item.calunitofmeasurement == "box" ? "PCS":item.productType !== "kgproduct" ? 'PCS':"Select"}</option>
                                        <option value="pcs">PCS</option>
                                        <option value="kg">KG</option>
                                    </Form.Select>
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.grossamount)} size="sm" readOnly/>
                                    </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" onChange={(e) => { dispatch(changematerialreceipteditdiscount(e.target.value, item, taxtype));roundData(e.target.value) }} value={item.tradeDiscount || ''} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.total)} size="sm" readOnly/>
                                    </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.gstvalue)} size="sm" readOnly/>
                                    </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.amount)} size="sm" readOnly/>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                    {/* <Form.Control type='button' className='btn btn-danger' onClick={() => {dispatch(removefromcartfrommaterial(item.product.id));roundData('')}} value="X" size="sm" /> */}
                                    <Form.Control type='button' className='btn btn-danger' onClick={() => {dispatch(removefromcartfrommaterialreceiptedit(item.product.id));roundData('')}} value="X" size="sm" />

                                        {/* <Form.Control type='button' className='btn btn-danger' onClick={() => dispatch(removefromcartfromsupplierdelivery(item.id))} value="X" size="sm" /> */}
                                    </Form.Group>
                                </td>
                            </tr>
                        )}
                    </tbody>
                    <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                        <td><Form.Group as={Col} md="12">
                                        <Form.Control type="number" value={shippingcharge} onChange={(e) => settingship(e.target.value)}   style={{height:30}} />
                                    </Form.Group></td>
                        <td></td>
                        
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                        <td>
                            <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={grossAmount?((grossAmount).toFixed(2)):"0"} style={{ height: 30 }} readOnly />
                            </Form.Group>
                            </td>
                            <td></td>
                       
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ fontSize: 13 }}>IGST</td>
                        <td><Form.Group as={Col} md="12">
                            <Form.Control type="number" value={igstcheck()} requied style={{ height: 30 }}/>
                            </Form.Group></td>
                            <td></td>
                        
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ fontSize: 13 }}>SGST</td>
                        <td><Form.Group as={Col} md="12">
                            <Form.Control type="number" value={sgstcheck()} requied style={{ height: 30 }}/>
                            </Form.Group></td>
                            <td></td>
                       
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ fontSize: 13 }}>CGST</td>
                        <td><Form.Group as={Col} md="12">
                            <Form.Control type="number" value={cgstcheck()} requied style={{ height: 30 }}/>
                            </Form.Group></td>
                            <td></td>
                        
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                        <td><Form.Group as={Col} md="12">
                                        <Form.Control type="number" value={round} onChange={(e) => settinground(e.target.value)}  style={{height:30}} />
                                    </Form.Group></td>
                                    <td></td>
                       
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                        <td> <Form.Group as={Col} md="12">
                            <Form.Control type="number" value={grandTotal?((grandTotal).toFixed(2)):"0"} readOnly style={{ height: 30 }}/>
                            </Form.Group></td>
                            <td></td>
                        
                    </tr>
                </tbody>
                </Table>
                <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
            </Form>
        </MDBCard>
    )
}