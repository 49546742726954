import React, { useEffect, useState } from "react";
import { MDBCard } from "mdbreact";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import authHeader from "../../services/auth-header";
import { BASE_URL } from "../../services/Api";
import { useParams, Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import Swal from "sweetalert2";
const UpdateGroupn3 = () => {
  const [title, setTitle] = useState("");
  const { id, pointindex } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const user = AuthService.getCurrentUser();
  const navigate = useNavigate();

  const getTitleData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn3/${id}`, {
        headers: authHeader(),
      });

      setTitle(response.data.title);
    } catch (error) {
      console.log(error);
    }
  };

  const submitdata = async () => {
    try {
      const response = await axios.put(
        `${BASE_URL}v1/groupn3/${id}`,
        {
          title: title,
        },
        {
          headers: authHeader(),
        }
      );

      navigate(`/group3indextable/${pointindex}`);
      Swal.fire({
        title: "Success!",
        text: "Title has been successfully updated.",
        icon: "success",
        confirmButtonText: "Okay",
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTitleData();
  }, []);

  return (
    <MDBCard className="company">
      <div className="d-flex bd-highlight">
        <div className="card-header card-title w-100">Update Group3</div>

        <Link className="p-2 w-200 card-header card-title bd-highlight" to={`/group3indextable/${pointindex}`}>
          <Button className="btn-sm">Back</Button>
        </Link>
      </div>
      <Form className="product1">
        <Row className="mb-1">
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="label">Title</Form.Label>
            <Form.Control type="text" name="title" value={title} onChange={(e) => setTitle(e.target.value)} />
          </Form.Group>
        </Row>

        <Button variant="primary" style={{ width: "auto" }} onClick={submitdata} disabled={isLoading}>
          {isLoading ? "Loading..." : "Submit"}
        </Button>
      </Form>
    </MDBCard>
  );
};

export default UpdateGroupn3;
