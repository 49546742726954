export default function getCroppedImg(imageSrc, crop) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = imageSrc;
        image.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            canvas.width = crop.width;
            canvas.height = crop.height;

            ctx.drawImage(
                image,
                crop.x,
                crop.y,
                crop.width,
                crop.height,
                0,
                0,
                crop.width,
                crop.height
            );

            // canvas.toBlob((blob) => {
            //     resolve(new File([blob], "croppedImage.png", { type: "image/png" }));
            // }, "image/png");
            canvas.toBlob((blob) => {
                resolve(new File([blob], "croppedImage.jpg", { type: "image/jpeg" }));
            }, "image/jpeg", 0.8); 
        };
        image.onerror = reject;
    });
}
