import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlinePullRequest } from 'react-icons/ai';
import { FaLocationArrow } from 'react-icons/fa';
import authHeader from '../../services/auth-header';
import { useParams } from 'react-router-dom';

const Componentmap = ({onDateChange1,handleLocationDetails}) => {
  const [isOpenhh, setIsOpenhh] = useState(true);
const [data, setdata] = useState([])
const[date2,setdate2]=useState(new Date())
const[date,setdate]=useState(0)
  const menuIconClick = () => {
    setIsOpenhh(!isOpenhh);
  };
  const { id } = useParams();
  const formatDate1 = (dateString) => {
    // const options = {  year: 'numeric',  month: 'numeric', day: 'numeric' };
    // const formattedDate1 = new Date(dateString).toLocaleString('en-US', options);
    const date = dateString;
    const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return formattedDate;
  };
  const [logindata, setLogindata] = useState('')
 useEffect(() => {
   
    if (date!==0) {
      // const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
       const timeout = setTimeout(() => {

        axios.get(`http://43.225.54.138:8080/scm/api/location/staff2/${id}/${date}`, { headers: authHeader() })
        .then(response => {
          console.log(response.data);
          setLogindata(response?.data[0]);
           axios.get(`http://43.225.54.138:8080/scm/api/location/staff/${id}/${date}`, { headers: authHeader() })
          .then(response1 => {
            console.log(response1.data);
            setdata(response1.data);
            onDateChange1(response1.data,response.data);
           })
          .catch(error => {
            console.error(error);
          });
          })
        .catch(error => {
          console.error(error);
        });
  }, 1000);
     return () => clearTimeout(timeout);
    }
    else{
      const timeout = setTimeout(() => {
const date=new Date()

        const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        axios.get(`http://43.225.54.138:8080/scm/api/location/staff2/${id}/${formattedDate}`, { headers: authHeader() })
        .then(response => {
          console.log(response.data);
          setLogindata(response?.data[0]);
        axios.get(`http://43.225.54.138:8080/scm/api/location/staff/${id}/${formattedDate}`, { headers: authHeader() })
          .then(response1 => {
            console.log(response1.data);
            const updatedData = response1.data.map(item => ({
              ...item,
              timediff: getTimeDifference(item.checkinLocationDate, item.checkoutLocationDate)
            }));
                 
            // setdata(updatedData);
            setdata(updatedData);
            onDateChange1(updatedData,response.data);
// console.log(updatedData)
               
            // onDateChange1(response1.data);
          })
          .catch(error => {
            console.error(error);
          });
        })
        .catch(error => {
          console.error(error);
        });

      }, 1000);
      
     
      return () => clearTimeout(timeout); 
    }
    alert(date)
  }, [date]); 
  
function formatDateTimeTo12Hour(dateTimeString) {
  if (!dateTimeString) return "No Time Available";
  try {
    const [date, time] = dateTimeString.split(" ");
    if (!time) return "Invalid Time Format";
    
    return new Date(`${date.replace(/-/g, "/")}T${time}`).toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  } catch {
    return "Invalid Time Format";
  }
}

  
function formatDate(dateString) {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).replace(/\//g, '-'); 
  // onDateChange1(newDate);  
  return formattedDate;
}

//  alert()

// alert(date2)
const today = new Date().toISOString().split('T')[0];
const moment = require('moment');

// const getTimeDifference = (startDateStr, endDateStr) => {
//   const startDate = moment(startDateStr);
//   const endDate = endDateStr ? moment(endDateStr) : null;

//   if (endDate && endDate.isSameOrAfter(startDate)) {
//     const duration = moment.duration(endDate.diff(startDate));
//     const days = duration.days();
//     const hours = duration.hours();
//     const minutes = duration.minutes();
//     const seconds = duration.seconds();

//     const formattedStartDate = startDate.format("DD-MM-YYYY HH:mm:ss");
//     const formattedEndDate = endDate.format("DD-MM-YYYY HH:mm:ss");

//     return `  ${hours} hours ${minutes} min ${seconds} sec`;
//   } else {
//     return '-';
//   }
// };
// const getTimeDifference = (startDateStr, endDateStr) => {


//   const startDate = new Date(startDateStr);
//   const endDate = endDateStr ? new Date(endDateStr) : null;

  
// // console.log(startDate)
//   if (endDate && endDate >= startDate) {
//     const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());

//     const hours = Math.floor(timeDifference / (1000 * 60 * 60));
//     const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
//     const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

//     return `${hours} hours ${minutes} min ${seconds} sec`;
//   } else {
//     return '-';
//   }
// };
const formatDate11 = (inputDate) => {
   if (!inputDate) {
    return null;  
  }

   const [datePart, timePart] = inputDate.split(' ');

   const [day, month, year] = datePart.split('-');

   const formattedDate = `${year}-${month}-${day}T${timePart}`;

  return formattedDate;
};

 
const getTimeDifference = (startDateStr, endDateStr) => {
   const formattedStartDate = formatDate11(startDateStr);
  const formattedEndDate = formatDate11(endDateStr);

   const startDate = new Date(formattedStartDate);
  const endDate = new Date(formattedEndDate);

  if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime()) && endDate >= startDate) {
    const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());

    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return `${hours} hours ${minutes} min ${seconds} sec`;
  } else {
    return '-';
  }
};
 
 
const handlelocationdetails=(datalat,datalong)=>{
  console.log(datalat,datalong)
}



  return (
    <div>
       <div style={{ fontSize: '25px',position:'fixed',top:'18px', marginLeft: isOpenhh ? "181px" : "-17px", transition: 'all 0.5s ease 0s' }} onClick={menuIconClick}>
            {isOpenhh ? (
              <AiOutlinePullRequest className='sbgicn' />
            ) : (
              <AiOutlineDoubleRight className='sbgicn' />
            )}
          </div>


    <div style={{display:'flex',justifyContent:'center'}}>
      
      <div className='sidebar22'  style={{ width: isOpenhh ? "227px" : "0px", left: '11px',zIndex:999, transition: 'all 0.5s ease 0s' }}>
      <input
    type='date'
    className='earphones'
    defaultValue={today}
    max={today}  
    onChange={(e) => {setdate(e.target.value); setdate2(e.target.value)}}
  />

  <div className='top_section'>
      
          <h3 className='logo' style={{ display: "block" }}></h3>
      
        </div>
     
      
        <ul className="timeline">
      {logindata?(<li className="timeline-item" >
    <div className="timeline-dot"></div>
    <div className="timeline-content">
      <h6 className='retailer'>Login</h6>
      <c className='staffin'>
  {logindata.dayintime
    ? new Date(`1970-01-01T${logindata.dayintime}`).toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
    : "No Time Available"}
</c>     
   


    </div>
  </li>):""}
  {data.map((item, index) => {
    const isLastItem = index === data.length - 1;
   
    if (item.distributor !== null) {
      return (

        <li className="timeline-item" key={index} onClick={()=>handleLocationDetails(item.checkinLocationLatitude,item.checkinLocationLongitude)}>
          <div className="timeline-dot"></div>
          <div className="timeline-content">
            <h6 className='retailer'>{item.distributor.tradeName}</h6>
            <c className='staffin'>Distributor</c>
            <c className='staffin'>{item.distributor.deliveryAddress ? 
              item.distributor.deliveryAddress.map((address, index) => (
                <span key={index}>{address.delivery_address} {address.state} {address.pincode}</span>
              )) : null}
            </c>
            <p className='staffin1'>
  <span style={{ color: '#b0ffb0' }}>{item.checkinLocationDate}</span>
 
  {/* <span style={{ color: '#b0ffb0' }}>{item.checkoutLocationDate}</span> */}
<br></br>
<span style={{ color: '#ffb9bf' }}>
    
    {  item.checkoutLocationDate} 
  </span><br></br>
  <span style={{ color: '#ffb9bf' }}>
    {getTimeDifference(item.checkinLocationDate, item.checkoutLocationDate)}
  </span>

</p>


          </div>
        </li>

      );
    } else if (item.retailer !== null) {
      return (
        <li className="timeline-item" key={index} onClick={()=>handleLocationDetails(item.checkinLocationLatitude,item.checkinLocationLongitude)}>
          <div className="timeline-dot"></div>
          <div className="timeline-content">
            <h6 className='retailer'>{item.retailer.tradeName}</h6>
            <c className='staffin'>Retailer</c>
            <c className='staffin'>{item.retailer.deliveryAddress ? 
              item.retailer.deliveryAddress.map((address, index) => (
                <span key={index}>{address.delivery_address} {address.state} {address.pincode}</span>
              )) : null}
            </c>
            <p className='staffin1'>
  <span style={{ color: '#b0ffb0' }}>{item.checkinLocationDate}</span>
  <br />

  <span style={{ color: '#ffb9bf' }}>
    
    {  item.checkoutLocationDate} 
  </span><br></br>
  <span style={{ color: '#ffb9bf' }}>
    
    {getTimeDifference(item.checkinLocationDate, item.checkoutLocationDate)}
  </span>
</p>

          </div>
        </li>
      );
    } else {
      return (

        <li className="timeline-item" key={index} onClick={()=>handleLocationDetails(item.checkinLocationLatitude,item.checkinLocationLongitude)}>
          <div className="timeline-dot"></div>
          <div className="timeline-content">
            <h6 className='retailer'>Unknown</h6>
            <c className='staffin'>Unknown</c>
            <p className='staffin1'>
  <span style={{ color: '#b0ffb0' }}>{item.checkinLocationDate}</span>
  <br />

  <span style={{ color: '#ffb9bf' }}>
    
    {  item.checkoutLocationDate} 
  </span><br></br>
  <span style={{ color: '#ffb9bf' }}>
    
    {getTimeDifference(item.checkinLocationDate, item.checkoutLocationDate)}
  </span>
</p>
          </div>
        </li>
        
      );
    }
  })}
{logindata ? (
  <li className="timeline-item">
    <div className="timeline-dot"></div>
    <div className="timeline-content">
      <h6 className='retailer'>Logout</h6>
      <c className='staffin'>
        {logindata.dayouttime
          ? new Date(`1970-01-01T${logindata.dayouttime}`).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })
          : "No Time Available"}
      </c>
    </div>
  </li>
) : ""}


</ul>

      </div>
     
  <ss className="tytttt"><FaLocationArrow className="popo"/> {data.length}</ss>
    </div>
    
    </div>
  );
};

export default Componentmap;
