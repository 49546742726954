import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Row, Table, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import DistrbutorService from "../../services/Distrbutor";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { addtocartprimaryorder, changecostprimaryorder, changediscount1primaryorder, changeqtyprimaryorder, changestandardQtyPerBoxprimaryorder, changetextprimaryorder, changeunitofMeasurementprimaryorder, emptydataprimaryorder, getDataPrimaryOrder, perprimaryorder, removefromcartprimaryorder } from "../../../redux/Action/PrimaryOrder";
import WorkOrderService from "../../services/WorkOrderService";
import ProductService from "../../services/ProductService";
import UserService from "../../services/user.service";
import { primaryorder } from "../../../redux/Reducer/PrimaryOrder";
import { Link, useNavigate } from "react-router-dom";
import { MDBCard } from "mdbreact";
import ReatilerService from "../../services/Reatiler";
import Swal from 'sweetalert2';
import { BsFillEyeFill } from "react-icons/bs";
import AuthService from "../../services/auth.service";
import axios from "axios";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
export default function Create() {
  let Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  const [remarks, setremarks] = useState('')
  const [formDate, setformDate] = useState('');
  const [ToDate, setToDate] = useState('');
  const [DistributorName, setDistributorName] = useState([]);
  const [DistributorName1, setDistributorName1] = useState([]);
  const [DistributorId, setDistributorId] = useState([]);
  const [posts, setPosts] = useState([]);
  const [boxProductDiscount, setboxProductDiscount] = useState('');
  const [schemeboxProductDiscount, setschemeboxProductDiscount] = useState('');
  const [kgProductDiscount, setkgProductDiscount] = useState('');
  const [schemekgProductDiscount, setschemekgProductDiscount] = useState('');
  const [corporaetProductDiscount, setcorporaetProductDiscount] = useState('');
  const [schemecorporateProductDiscount, setschemecorporateProductDiscount] = useState('');
  const [cookerProductDiscount, setcookerProductDiscount] = useState('');
  const [schemecookerProductDiscount, setschemecookerProductDiscount] = useState('');
  const [noshProductDiscount, setnoshProductDiscount] = useState('');
  const [schemenoshProductDisocunt, setschemenoshProductDisocunt] = useState('');
  const [distributordata, setdistributordata] = useState([]);
  const [product, setproduct] = useState([]);
  const [product1, setproduct1] = useState([]);
  const [retailer, setretailer] = useState([]);
  const [dateCreated, setdateCreated] = useState(new Date());
  const [deliveryAddress, setDeliveryAddress] = useState([]);
  const [distributorAddress, setdistributorAddress] = useState('');
  const [error, setError] = useState(encodeURIComponent(' '))
  ///////////////////////////

  const [totalPcsQuantity, setTotalPcsQuantity] = useState(0);
  const [totalnet, settotalnet] = useState();
  const [grossfull, setgrossfull] = useState();
  const [gstvalue, setgstvalue] = useState();
  const [amount, setamount] = useState();
  const [kg, setkg] = useState();
  //////////////////
  const [sumbox, setsumbox] = useState(0);
  const [sumofcorp, setcorp] = useState(0);
  const [sumofcorpcs, setcorpc] = useState(0);
  const [boxamt, setboxsmt] = useState(0);
  const [amt, setamt] = useState(0);
  const [kg1, setkg1] = useState(0);
  const [kg2, setkg2] = useState(0);
  const [kg3, setkg3] = useState(0);

  const [c1, setc1] = useState(0);
  const [c2, setc2] = useState(0);
  const [c3, setc3] = useState(0);

  const [n1, setn1] = useState(0);
  const [n2, setn2] = useState(0);
  const [n3, setn3] = useState(0);
  const [currentUser, setCurrentUser] = useState('');
  const [disword, setdisword] = useState(encodeURIComponent(' '))
  const [dis,setdistributordata1]=useState([]);
  const[imagedisplay,setdiplay]=useState([])

  const [isLoading, setIsLoading] = useState(false);
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = 'font-size 2s';
    element.style.fontSize = '30px';
    element.style.fontWeight = '600';
    // font-weight: 600;
    setTimeout(() => {
       
      document.getElementById("typer1").classList.add('promote');
      element.style.fontSize = '24px';
    }, 1000);
  };
  

  ///////////////////////////////
  const datevalue = new Date();
  const defaultValue = datevalue.toLocaleDateString('en-CA');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [secondaryIds, setScondaryIds] = useState([]);
  const primaryOrderItems = useSelector((state) => state.primaryorder);
   console.log("mello",primaryOrderItems)
  const stdkgs = primaryOrderItems.length && primaryOrderItems.map(user => (Number(user.quantity_placed_kg))).reduce((prev, next) => (prev - (-next)));
  const stdpcs = primaryOrderItems.length && primaryOrderItems.map(user => (Number(user.quantity_placed))).reduce((prev, next) => (prev - (-next)));
  // console.log("mello",stdkgs)
  //  console.log("Product:",product);

  const distributorgetdata = () => {
    /* All Distributor */
    // DistrbutorService.getReatilerTable().then(res => {
    //   let distributor = res.data;
    //   setDistributorName(distributor.map(user => ({ value: user.id, label: user.tradeName })));
    //   setdistributordata(res.data);
    // });
  }

  const logindistributor = () => {
    const user = AuthService.getCurrentUser();
    setCurrentUser(user.roles);

    // console.log("mello",user)
    /* Login Distributor */
    if ((user.roles) == "ROLE_DISTRIBUTOR") {
      DistrbutorService.getdistributorget(user.id).then(res => {
        // console.log("mello",res.data);
        setDistributorName1(res.data.tradeName);
        setDistributorId(res.data.id);
        setboxProductDiscount(res.data.boxProductDiscount);
        setschemeboxProductDiscount(res.data.schemeboxProductDiscount);
        setkgProductDiscount(res.data.kgProductDiscount);
        setschemekgProductDiscount(res.data.schemekgProductDiscount);
        setcorporaetProductDiscount(res.data.corporaetProductDiscount);
        setschemecorporateProductDiscount(res.data.schemecorporateProductDiscount);
        setcookerProductDiscount(res.data.cookerProductDiscount);
        setschemecookerProductDiscount(res.data.schemecookerProductDiscount);
        setnoshProductDiscount(res.data.noshProductDiscount);
        setschemenoshProductDisocunt(res.data.schemenoshProductDisocunt);
        if ((res.data).deliveryAddress.length == 0) {
          setDeliveryAddress([]);
        } else {
          setDeliveryAddress((res.data).deliveryAddress.map(use => ({ value: use.id, label: use.delivery_address })));
        }
      })
    };
  }
  useEffect(() => {
    logindistributor();
    distributorgetdata();
    setformDate(defaultValue);
    dispatch(emptydataprimaryorder());
  }, [])
 
 
  useEffect(() => {
  
    axios.get(`http://43.225.54.138:8080/scm/api/distributor/page/0/10/asc/id/${disword.length?disword:encodeURIComponent(' ')}`, { headers: authHeader() })
    .then(response => {
  console.log(response)
  if(response.data.data==undefined){
    setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.trade_Name})));
 }  if(response.data.Index==undefined){
  setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.trade_Name})));

 }
    
 })



}, [ disword])








  useEffect(() => {
    calculateSum();
  }, [primaryOrderItems])
  function PrimaryOrder(e) {
    const name = e.value;
    setDistributorId(name);
    axios.get(BASE_URL + 'distributor/getById/' + name, {
      headers:authHeader()
  }).then(res=>{
     let user = res.data;
     setboxProductDiscount(user.boxProductDiscount);
     setschemeboxProductDiscount(user.schemeboxProductDiscount);
     setkgProductDiscount(user.kgProductDiscount);
     setschemekgProductDiscount(user.schemekgProductDiscount);
     setcorporaetProductDiscount(user.corporaetProductDiscount);
     setschemecorporateProductDiscount(user.schemecorporateProductDiscount);
     setcookerProductDiscount(user.cookerProductDiscount);
     setschemecookerProductDiscount(user.schemecookerProductDiscount);
     setnoshProductDiscount(user.noshProductDiscount);
     setschemenoshProductDisocunt(user.schemenoshProductDisocunt);
     if (user.deliveryAddress.length == 0) {
       setDeliveryAddress([]);
     } else {
       setDeliveryAddress(user.deliveryAddress.map(use => ({ value: use.id, label: use.delivery_address })));
     }
    })
    
  }
  // Distributor Address OnChange
  let getDistributorAddressChange = (e) => {
    setdistributorAddress(e.value)
  }
  const submitform = () => {
    let d_id = DistributorId;
    let from_date = formDate;
    let to_date = ToDate;
    let product1 = [];
    let id = 1;
    // console.log("mello", d_id, from_date, to_date)

    WorkOrderService.getPrimaryOrder(d_id, from_date, to_date).then(res => {
      // console.log("mello",res.data)

      axios.get(BASE_URL + 'product/getAll',{header:authHeader()}).then(res2=>{

        let aa = [];
        (res2.data).map(user => {
          aa.push(user)
        });
        let ac = res.data;
        let ad = []
        // console.log("mello",aa);
        ac.map(user => {
          (user.workOderItems).map(item => {
            if (item !== "") {
              ad.push(item)
            }
          })
        })

        const result = aa.filter((elem) => !ad.find(({ productId }) => elem.id === productId));
        // console.log("mello",result);

        setproduct(result.map(use => ({ value: use.id, label: use.productName + ' - ' + use.eanCode + ' - ' + use.shortName })));

      })
      setPosts(res.data);
      // console.log("mello" + res.data);
      ReatilerService.getReatilerTable().then(res => {
        setretailer(res.data);
      })
    })
  }
  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      let tempUser = posts.map((user) => {
        // console.log(e.target);
        return { ...user, isChecked: checked };
      });
      // console.log(tempUser);
      setPosts(tempUser);
    } else {
      let tempUser = posts.map((user) =>
        user.id == name ? { ...user, isChecked: checked } : user
      );
      setPosts(tempUser);
    }
  };

  // const customFilter = (option, input) => {
  //   const escapedInput = input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape symbols
  //   const regex = new RegExp(escapedInput.replace(/\s+/g, '\\s*'), 'i');
  //   return (regex.test(option.label) ||
  //     regex.test(option.label.replace(/\s+/g, '')) ||
  //     regex.test(option.label.replace(/[^a-zA-Z0-9]/g, ''))
  //   )
  // }
  
  const customFilter = (e) => {
    
      
    let name = e;
    axios.get( BASE_URL + `product/page1/0/100/asc/id/${name.length > 0 ? name:encodeURIComponent(" ")}`,{
        headers:authHeader()
    }).then(res=>{
  
     setproduct(res.data.Index ? (res.data.Index).map(use=>({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })) : (res.data.data).map(use=>({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })))
    })
   }


  /* Product View */
  const [show, setShow] = useState(false);
  const [productdata, setProductdata] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const productdetails = (e) => {
    ProductService.getProductEdit(e).then(res => {
      setProductdata(res.data);
      setdiplay(res.data.productImages);

    })
  }

  const sendDataToAPI = () => {

    const temporaryItemList = [];
    let secondary = [];
    const aa = posts.map(item => {
      if (item.isChecked === true) {
        temporaryItemList.push(item.workOderItems)
        secondary.push(item.id)
      }

    })

    setScondaryIds(secondary)
    dispatch(getDataPrimaryOrder(temporaryItemList, ({
      "schemeboxProductDiscount": schemeboxProductDiscount,
      "schemekgProductDiscount": schemekgProductDiscount,
      "schemecorporateProductDiscount": schemecorporateProductDiscount,
      "schemecookerProductDiscount": schemecookerProductDiscount,
      "schemenoshProductDisocunt": schemenoshProductDisocunt,
      "kgProductDiscount": kgProductDiscount,
      "boxProductDiscount": boxProductDiscount,
      "corporaetProductDiscount": corporaetProductDiscount,
      "cookerProductDiscount": cookerProductDiscount,
      "noshProductDiscount": noshProductDiscount
    })))


  }

  function deleteItem(e,value) {
    setError(value)
    const filteredItems = primaryOrderItems.filter(item => item.id === e);
    if (filteredItems.length == "0") {
      dispatch(addtocartprimaryorder(e, ({
        "schemeboxProductDiscount": schemeboxProductDiscount,
        "schemekgProductDiscount": schemekgProductDiscount,
        "schemecorporateProductDiscount": schemecorporateProductDiscount,
        "schemecookerProductDiscount": schemecookerProductDiscount,
        "schemenoshProductDisocunt": schemenoshProductDisocunt,
        "kgProductDiscount": kgProductDiscount,
        "boxProductDiscount": boxProductDiscount,
        "corporaetProductDiscount": corporaetProductDiscount,
        "cookerProductDiscount": cookerProductDiscount,
        "noshProductDiscount": noshProductDiscount
      })));
    }
  }


  const finaldataSubmit = () => {
    setIsLoading(true); 
    // let d_id = DistributorId;
    let from_date = formDate;
    let to_Date = ToDate;
    let distributor = { "id": DistributorId };

    let kgProductTotalQtyKg = kg1;
    let kgProductTotalQtyPcs = kg2;
    let kgProductTotalprice = kg3;
    let boxProductTotalQtyPcs = sumbox;
    let boxProductTotalprice = boxamt;
    let corporateProductTotalQtyPcs = sumofcorp;
    let corporateProductTotalprice = amt;
    let cookerProductTotalQtyPcs = c1;
    let cookerProductTotalprice = c3;
    let noshProductTotalQtyPcs = n1;
    let noshProductTotalprice = n2;
    let totalQtyKg = kg;
    let totalQtyPcs = totalPcsQuantity;
    let grossTotal = amount;
    let netValue = totalnet;
    let taxAmount = gstvalue;
    let deliveryAddress=distributorAddress;
    if (primaryOrderItems == "") {

    } else {
      let primaryorder = {
        distributor, from_date, to_Date, primaryOrderItems, secondaryIds, dateCreated, remarks, kgProductTotalQtyKg,
        kgProductTotalQtyPcs,
        kgProductTotalprice,
        boxProductTotalQtyPcs,
        boxProductTotalprice,
        corporateProductTotalQtyPcs,
        corporateProductTotalprice,
        cookerProductTotalQtyPcs,
        cookerProductTotalprice,
        noshProductTotalQtyPcs,
        noshProductTotalprice,
        totalQtyKg,
        totalQtyPcs,
        grossTotal,
        netValue,
        taxAmount,
        deliveryAddress
      }
      // console.log("mello",primaryorder)
      UserService.getprimaryorder(primaryorder).then(res => {
        navigate("/Newpo/1");
        if (res.status == 201 || res.status == 200) {
          // alert("ee")
          Toast.fire({
            icon: 'success',
            title: 'Successfully added'
          })
        }
      }).catch(error => {
        console.error('Error adding  :', error);
        if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
          
              <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
          didOpen: () => {
            applyFontSizeTransition('issueText');
          }
        });
      }
      }).finally(() => {
        setIsLoading(false); 
      });
      dispatch(emptydataprimaryorder());
    }


  }
  const calculateSum = () => {

    // console.log(primaryOrderItems)
    let prmy = primaryOrderItems.map((order) => {
      const measurement = parseFloat(order.measurement);
      return isNaN(measurement) || !isFinite(measurement) ? 0 : measurement;
    }).reduce((prev, curr) => prev + curr, 0);

    // console.log(prmy);

    prmy = parseFloat(prmy.toFixed(2));
    setTotalPcsQuantity(prmy);
    ///////////////
    let totalnet = primaryOrderItems.map((order) => parseFloat(order.netAmount))
      .reduce((prev, curr) => prev + curr, 0);
    totalnet = parseFloat(totalnet.toFixed(2));
    settotalnet(totalnet)
    /////////////////////////////////
    let grossnet = primaryOrderItems.map((order) => parseFloat(order.grossamount))
      .reduce((prev, curr) => prev + curr, 0);

    grossnet = parseFloat(grossnet.toFixed(2));
    setgrossfull(grossnet)
    //////////////////////////////////////
    let gst = primaryOrderItems.map((order) => parseFloat(order.gstvalue))
      .reduce((prev, curr) => prev + curr, 0);
    let gt = parseFloat(gst.toFixed(2))
    setgstvalue(gt)
    ///////////////////////////////////////////
    let amt = primaryOrderItems.map((order) => parseFloat(order.total))
      .reduce((prev, curr) => prev + curr, 0);
    const tt = parseFloat(amt.toFixed(2))
    setamount(tt)
    /////////////////////////////////////
    let kg1 = primaryOrderItems
      .map((order) => {
        const parsedQty = parseFloat(order.qty);
        return isNaN(parsedQty) || !isFinite(parsedQty) ? 0 : parsedQty;
      })
      .reduce((prev, curr) => prev + curr, 0);

    const tt1 = parseFloat(kg1.toFixed(2));
    setkg(tt1);
    ///////////////////////////
    let amt12 = primaryOrderItems
      .map((order) => parseFloat(order.netAmount))
      .reduce((prev, curr) => prev + curr, 0);




    ///////box//////
    const kgCorporateProducts = primaryOrderItems.filter((order) => order.productType === "boxproduct")
    setsumbox(kgCorporateProducts.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0))

    setboxsmt(primaryOrderItems.filter((order) => order.productType === "boxproduct").map((order) => parseFloat(order.total))
      .reduce((prev, curr) => prev + curr, 0))
    ////////corp/////////////////
    const sumkgCor = primaryOrderItems.filter((order) => order.productType === "corporateproduct")
    setcorp(sumkgCor.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0))

    const sumpccor = primaryOrderItems.filter((order) => order.productType === "corporateproduct")
    setcorpc(sumpccor.map((order) => parseFloat(order.qty))
      .reduce((prev, curr) => prev + curr, 0))

    setamt(sumpccor.map((order) => parseFloat(order.total))
      .reduce((prev, curr) => prev + curr, 0))

    //////////kg/////////////

    const kgproductpc = primaryOrderItems.filter((order) => order.productType === "kgproduct")
    const time = kgproductpc.map((order) => parseFloat(order.qty))
      .reduce((prev, curr) => prev + curr, 0)
    const time2 = kgproductpc.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0)
    const totalkgg = kgproductpc.map((order) => parseFloat(order.total))
      .reduce((prev, curr) => prev + curr, 0)
    setkg1(parseFloat(time).toFixed(2))
    setkg2(time2)
    setkg3(totalkgg)
    ////////////////cooker/////////////////
    const cooker = primaryOrderItems.filter((order) => order.productType === "cookerproduct")
    const pcs = cooker.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0)
    setc1(pcs)
    const c3total = cooker.map((order) => parseFloat(order.total))
      .reduce((prev, curr) => prev + curr, 0)
    setc3(c3total)
    //////////////////nosh//////////////////////
    const noshing = primaryOrderItems.filter((order) => order.productType === "noshproduct")
    const nospcs = noshing.map((order) => {
      const measurement = parseFloat(order.measurement);
      return isNaN(measurement) || !isFinite(measurement) ? 0 : measurement;
    }).reduce((prev, curr) => prev + curr, 0);
    setn1(nospcs)
    const nospcs1 = noshing.map((order) => {
      const measurement1 = parseFloat(order.qty);
      return isNaN(measurement1) || !isFinite(measurement1) ? 0 : measurement1;
    }).reduce((prev, curr) => prev + curr, 0);

    setn3(parseFloat(nospcs1).toFixed(2))

    const nospcs11 = noshing.map((order) => {
      const measurement1 = parseFloat(order.total);
      return isNaN(measurement1) || !isFinite(measurement1) ? 0 : measurement1;
    }).reduce((prev, curr) => prev + curr, 0);
    setn2(nospcs11)

    // const nostotal=noshing.map((order) => {parseFloat(order.total)})
    // .reduce((prev, curr) => prev + curr, 0)
    // setn2(nostotal)
    // console.log(nostotal)



  }

  
 useEffect(() => {
 
  axios.get(BASE_URL + `product/page1/0/10/asc/id/${error.length ? error : encodeURIComponent(' ')}`, { headers: authHeader() })
    .then(response => {
      if (response.data.data == undefined) {
        setproduct(response.data.Index.map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code })));

      }
      if (response.data.Index == undefined) {
        setproduct(response.data.data.map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code })));

      }
      
    })
    .catch(error => {
      console.error(error);
    });



}, [error]);
const productid = (val) => {
  setproduct1(val)
}
  return (

    <MDBCard style={{ overflow: "scroll" }}>
      <div className='d-flex bd-highlight'>
        <div className="card-header card-title w-100">Primary Order Create</div>
        <Link className='p-2 w-200 card-header card-title bd-highlight' to={'/Newpo/1'}><Button>Back</Button></Link>
      </div>
      {/* <div class="card" style={{width:"1051px"}}> */}
      {/* <div class="card-title card-header">
          Primary Order Create
        </div> */}
      <div className='card-body'>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Body>
              <Row md={2}  >
                {currentUser == 'ROLE_DISTRIBUTOR' ? (
                  <Col>
                    <label class="form-label">Distributor Name:</label>
                    <Form.Control type="text" value={DistributorName1} readOnly />
                  </Col>
                )
                  : (
                    <Col>
                      <label class="form-label">Distributor Name:</label>
                      {/* <Select options={DistributorName} onChange={(e) => { PrimaryOrder(e) }} /> */}
                      <Select options={dis}  onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => PrimaryOrder(options)}/>

                    </Col>
                  )}
                {currentUser == 'ROLE_DISTRIBUTOR' ? (
                  <Col>
                    <label class="form-label">Distributor Address:</label>
                    <Select options={deliveryAddress} onChange={(e) => { getDistributorAddressChange(e) }} />
                  </Col>
                )
                  : (
                    <Col>
                      <label class="form-label">Distributor Address:</label>
                      <Select options={deliveryAddress} onChange={(e) => { getDistributorAddressChange(e) }} />
                    </Col>
                  )}
                <Col>
                  <label class="form-label">From Date: </label>
                  <input type="date" class="form-control" value={formDate} onChange={(e) => setformDate(e.target.value)} />
                </Col>
              </Row>
              <Row md={2}>
                <Col>
                  <Col>
                    <label class="form-label">To Date: </label>
                    <input type="date" class="form-control" value={ToDate} onChange={(e) => setToDate(e.target.value)} />
                  </Col>
                </Col>
                {/* <Col>
                    <label className='form-label'>Sales id</label>
                    <input type="number" className='form-control' />
                  </Col> */}

                {/* <Col>
                    <label className='form-label'>Created by</label>
                    <input type="text" className='form-control' />
                  </Col> */}
              </Row>
              <Row >
                <Col>
                  <Col>
                    <label class="form-label">Remarks </label>
                    <textarea class="form-control" value={remarks} onChange={(e) => setremarks(e.target.value)}></textarea>
                  </Col>
                </Col>
                {/* <Col>
                    <label className='form-label'>Sales id</label>
                    <input type="number" className='form-control' />
                  </Col> */}

                {/* <Col>
                    <label className='form-label'>Created by</label>
                    <input type="text" className='form-control' />
                  </Col> */}
              </Row>
              <br></br>
              <Button variant="primary" type="button" onClick={submitform}>Submit</Button>

            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <br></br>

        <div className='d-flex bd-highlight rere'>
          {/* <div className='p-2 flex-shrink-1 bd-highlight'><CSVLink data={posts} class="btn btn-sm btn-primary">Export</CSVLink></div> */}
          <div class="btn btn-sm btn-primary  timer" onClick={sendDataToAPI}>+</div>
        </div>
        <table class="styled-table">
          <thead>
            <Accordion defaultActiveKey="0" className="rog">
              <Accordion.Item eventKey="0">
                <div style={{ background: '#164194' }}>
                  <tr>
                    <th> <input
                      type="checkbox"
                      className="form-check-input"
                      name="allSelect"
                      checked={!posts.some((user) => user?.isChecked !== true)}
                      onChange={handleChange}
                    /></th>
                    <th>Id</th>
                    <th>Work Order Date</th>
                    {/* <th>OrderStatus</th> */}
                    <th>Created By</th>
                  </tr>
                </div>
              </Accordion.Item>
            </Accordion>
          </thead>

          {posts.map((user, index) => (
            <Accordion defaultActiveKey="0">
              <Accordion.Item >
                <Accordion.Header>
                  <tr key={index}>
                    <td> <input
                      type="checkbox"
                      className="form-check-input"
                      name={user.id}
                      checked={user?.isChecked || false}
                      onChange={handleChange}
                    /></td>
                    <td>{user.id}</td>
                    <td>{user.workOrderDate}</td>
                    {/* <td>{user.orderStatus == "true" ? "Active" : "UnActive"}</td> */}
                    <td>{retailer.map(item => {
                      if (item.id == user.ret_id) {
                        return (item.tradeName)
                      }
                    })}</td>
                  </tr> </Accordion.Header>
                <Accordion.Body>
                  <thead >
                    <tr>
                      <th>Item Id</th>
                      <th>Product Id</th>
                      <th>Product Name</th>
                      <th>Product Type</th>
                      <th>QTY(kg)</th>
                      <th>Measurement(pcs)</th>
                      <th>DLP</th>
                    </tr>
                  </thead>
                  {user.workOderItems.map(item => (
                    <tr>
                      <td>{item.wItemId}</td>
                      <td>{item.productId}</td>
                      {/* <td>{user.orderStatus == "true"?"Active":"UnActive"}</td> */}
                      <td>{item.productName}</td>
                      <td>{item.productType}</td>
                      <td>{item.qty}</td>
                      <td>{item.measurement}</td>
                      <td>{item.dlp}</td>
                    </tr>
                  ))}

                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}

        </table><br></br>
        <label class="form-label"><b>Add&nbsp;Product</b> </label>
        {/* <Select options={product} onInputChange={(inputValue) => setError(inputValue)} onChange={(options) => productid(options.value)} /> */}
        <Select options={product} onChange={(options) => deleteItem(options.value,options)} onInputChange={(e) => customFilter(e) } /> 
        <br></br>
        <Table striped responsive hover size="sm">
          <thead className='p-3 mb-2 bg-success text-white'>
            <tr>
              <th style={{ width: "20%" }}>PRODUCT NAME</th>
              <th style={{ width: "6%" }}>UOM</th>
              <th style={{ width: "6%" }}>QTY (PCS)</th>
              <th style={{ width: "6%" }}>QTY (KG)</th>
              <th style={{ width: "8%" }}>SQ</th>
              <th style={{ width: "6%" }}>SQ Data</th>
              <th style={{ width: "8%" }}>RATE (₹)</th>
              <th style={{ width: "2%" }}>TRADE DISCOUNT %</th>
              <th style={{ width: "8%" }}>GROSS AMOUNT (₹)</th>
              <th style={{ width: "2%" }}>SCHEME DISCOUNT %</th>
              <th style={{ width: "10%" }}>NET AMOUNT (₹)</th>
              {/* <th>Per&nbsp;Unit&nbsp;Rate</th> */}
              <th style={{ width: "10%" }}>GST VALUE (₹)</th>
              <th style={{ width: "10%" }}>TOTAL (₹)</th>
              <th style={{ width: "1%" }}>View</th>
              <th style={{ width: "1%" }}>Action</th>
            </tr>
          </thead>
          <tbody style={{ overflow: "scroll" }}>
            {primaryOrderItems.map(item =>
              item.wItemId == null ? <tr key={item.id} >

                <td>
                  <Form.Group as={Col} md="12" >
                    <textarea type="text" className="form-control" value={item.productName} size="sm" ></textarea>
                  </Form.Group>
                  <Form.Control as="textarea" rows={2} onChange={(e) => dispatch(changetextprimaryorder(e.target.value, item))} className='form-control' />
                </td>
                <td>
                  <Form.Group as={Col} md="12">
                    <Form.Select disabled={item.productType !== "kgproduct" ? true : false} onChange={(e) => { dispatch(changeunitofMeasurementprimaryorder(e.target.value, item, item.unitofmeasurement)); }} size="sm" >
                      <option>{item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                      <option value="pcs">PCS</option>
                      <option value="kg">KG</option>
                    </Form.Select>
                  </Form.Group>
                </td>
                <td>
                  <Form.Group as={Col} md="12">
                    <Form.Control type="number" min='0' onChange={(e) => { dispatch(perprimaryorder(e.target.value, item, item.unitofmeasurement)); calculateSum(); }} disabled={item.unitofmeasurement == "kg" ? true : false || item.unitofmeasurement == "" ? true : false} value={item.measurement} size="sm" />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group as={Col} md="12">
                    <Form.Control type="number" min='0' onChange={(e) => { dispatch(changeqtyprimaryorder(e.target.value, item, item.unitofmeasurement)); calculateSum(); }} disabled={item.unitofmeasurement == "pcs" || item.unitofmeasurement == "box" ? true : false || item.unitofmeasurement == "" ? true : false} value={item.unitofmeasurement == "box" ? "" : item.qty} size="sm" />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group as={Col} md="12">
                    <Form.Control type="number" min='0' disabled={item.unitofmeasurement == "" ? true : false} onChange={(e) => { dispatch(changestandardQtyPerBoxprimaryorder(e.target.value, item)); calculateSum(); }} step={item.standardQtyPerBox} value={item.quantity_placed} size="sm" />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group as={Col} md="12">
                    <Form.Control type="number" min='0' value={Number(item.quantity_placed_kg)} disabled={item.unitofmeasurement == "box" ? true : false || item.unitofmeasurement == "" ? true : false} size="sm" requied />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group as={Col} md="12">
                    <Form.Control type="number" min='0' disabled={item.unitofmeasurement == "" ? true : false} onChange={(e) => { dispatch(changecostprimaryorder(e.target.value, item, item.unitofmeasurement)); calculateSum(); }} value={item.dlp} size="sm" />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group as={Col} md="12">
                    <Form.Control type="number" min='0' disabled={item.unitofmeasurement == "" ? true : false} value={item.discount} size="sm" />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group as={Col} md="12">
                    <Form.Control type="number" min='0' value={Number(item.grossamount)} size="sm" disabled={item.unitofmeasurement == "box" ? true : false || item.unitofmeasurement == "" ? true : false} requied />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group as={Col} md="12">
                    <Form.Control type="number" min='0' disabled={item.unitofmeasurement == "" ? true : false} onChange={(e) => { dispatch(changediscount1primaryorder(e.target.value, item, item.unitofmeasurement)) }} value={Number(item.discount1)} size="sm" requied />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group as={Col} md="12">
                    <Form.Control type="number" min='0' disabled={item.unitofmeasurement == "" ? true : false} value={Number(item.netAmount)} size="sm" readOnly requied />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group as={Col} md="12">
                    <Form.Control type="number" min='0' disabled={item.unitofmeasurement == "" ? true : false} value={Number(item.gstvalue)} size="sm" readOnly requied />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group as={Col} md="12">
                    <Form.Control type="number" min='0' disabled={item.unitofmeasurement == "" ? true : false} value={Number(item.total)} size="sm" readOnly requied />
                  </Form.Group>
                </td>
                <td>
                  <div onClick={(e) => { handleShow(e); productdetails(item.id) }} style={{ textAlign: 'center', border: '1px solid grey', marginTop: '15px', cursor: 'pointer', padding: '4px 8px' }} > <BsFillEyeFill style={{ color: 'var(--bs-link-color)' }} /></div>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    size='lg'
                  >
                    <Modal.Header closeButton style={{ background: 'white', color: 'white' }}>
                    <Modal.Title style={{color:"#164194"}}>Product Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {imagedisplay.map((user,index) => (
                <img src={"http://43.225.54.138:8080/scm/Images/" + user.name} className="images" style={{ width: '200px', height: '150px' }} />
              ))}
                      <Row className="mb-1">
                        <Form.Group as={Col} md="12" controlId="formBasicName">
                          <span style={{ fontSize: '18px', fontWeight: 600 }}>Product Name : - {productdata.productName}</span>

                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Short Name : - {productdata.shortName}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Ean Code No : - {productdata.eanCode}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Standard Qty Per Box : - {productdata.standardQtyPerBox}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Groups : - {productdata.productGroup}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Category : - {productdata.category}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>UOM Primary : - {productdata.uomPrimary}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>UOM Secondary : - {productdata.uomSecondary}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>DLP(₹) : - {productdata.dlp}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>MRP(₹) : - {productdata.mrp}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Capacity(ml) : - {productdata.capacity}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Diameter : - {productdata.diameter}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>HSN Code : - {productdata.hsnCode}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Brand Name : - {productdata.brand ? "Neelam" : ''}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Product Type : - {productdata.productType}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>IGST% : - {productdata.igst}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>CGST% : - {productdata.cgst}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>SGST% : - {productdata.sgst}</span>
                        </Form.Group>
                      </Row>
                    </Modal.Body>
                  </Modal>
                </td>
                <td>
                  <Form.Group as={Col} md="12">
                    <Form.Control type='button' className='btn btn-danger' value="X" onClick={() => dispatch(removefromcartprimaryorder(item.id))} size="sm" />
                  </Form.Group>
                </td>
              </tr>
                :
                <tr key={item.productId}>
                  <td> <Form.Group as={Col} md="12" >
                    {/* <Form.Control type="text" value={item.productName} size="sm" readOnly /> */}
                    <textarea type="text" className="form-control" value={item.productName} readOnly size="sm" ></textarea>
                  </Form.Group>
                    <Form.Control as="textarea" rows={2} value={item.text} className='form-control' readOnly />
                  </td>
                  <td><Form.Group as={Col} md="12">
                    <Form.Control type="text" value={item.unitofmeasurement !== "kg" ? "PCS" : item.unitofmeasurement} size="sm" />
                  </Form.Group></td>
                  <td><Form.Group as={Col} md="12">
                    <Form.Control type="number" min="0" onChange={(e) => { dispatch(perprimaryorder(e.target.value, item, item.unitofmeasurement)); }} disabled={item.unitofmeasurement == "kg" ? true : false} value={item.measurement} size="sm" />
                  </Form.Group></td>
                  <td><Form.Group as={Col} md="12">
                    <Form.Control type="number" min="0" onChange={(e) => { dispatch(changeqtyprimaryorder(e.target.value, item, item.unitofmeasurement)) }} disabled={item.unitofmeasurement == "pcs" || item.unitofmeasurement == "box" ? true : false} value={item.unitofmeasurement == "box" ? "" : item.qty} size="sm" />
                  </Form.Group></td>
                  <td><Form.Group as={Col} md="12">
                    <Form.Control type="number" onChange={(e) => { dispatch(changestandardQtyPerBoxprimaryorder(e.target.value, item)) }} min="0" step={item.standardQtyPerBox} value={item.quantity_placed} size="sm" />
                  </Form.Group></td>
                  <td><Form.Group as={Col} md="12">
                    <Form.Control type="number" min="0" value={Number(item.quantity_placed_kg)} size="sm" disabled={item.unitofmeasurement == "box" ? true : false} requied />
                  </Form.Group></td>
                  <td><Form.Group as={Col} md="12">
                    <Form.Control type="number" min="0" value={item.dlp} size="sm" />
                  </Form.Group></td>
                  <td><Form.Group as={Col} md="12">
                    <Form.Control type="number" min="0" value={item.discount} size="sm" />
                  </Form.Group></td>
                  <td><Form.Group as={Col} md="12">
                    <Form.Control type="number" min="0" value={Number(item.grossamount)} size="sm" readOnly requied />
                  </Form.Group></td>
                  <td><Form.Group as={Col} md="12">
                    <Form.Control type="number" min="0" onChange={(e) => { dispatch(changediscount1primaryorder(e.target.value, item, item.unitofmeasurement)) }} value={Number(item.discount1)} size="sm" requied />
                  </Form.Group></td>
                  <td><Form.Group as={Col} md="12">
                    <Form.Control type="number" min="0" value={Number(item.netAmount)} size="sm" readOnly requied />
                  </Form.Group></td>
                  <td><Form.Group as={Col} md="12">
                    <Form.Control type="number" min="0" value={Number(item.gstvalue)} size="sm" readOnly requied />
                  </Form.Group></td>
                  <td><Form.Group as={Col} md="12">
                    <Form.Control type="number" min="0" value={Number(item.total)} size="sm" readOnly requied />
                  </Form.Group></td>
                  <td>
                    <td>
                      <div onClick={(e) => { handleShow(e); productdetails(item.id) }} style={{ textAlign: 'center', border: '1px solid grey', marginTop: '15px', cursor: 'pointer', padding: '4px 8px' }} > <BsFillEyeFill style={{ color: 'var(--bs-link-color)' }} /></div>
                      <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        size='lg'
                      >
                        <Modal.Header closeButton style={{ background: '#164194', color: 'white' }}>
                          <Modal.Title>Product Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Row className="mb-1">
                            <Form.Group as={Col} md="12" controlId="formBasicName">
                              <span style={{ fontSize: '18px', fontWeight: 600 }}>Product Name : - {productdata.productName}</span>

                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="formBasicName">
                              <span style={{ fontSize: '15px', fontWeight: 600 }}>Short Name : - {productdata.shortName}</span>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="formBasicName">
                              <span style={{ fontSize: '15px', fontWeight: 600 }}>Ean Code No : - {productdata.eanCode}</span>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="formBasicName">
                              <span style={{ fontSize: '15px', fontWeight: 600 }}>Standard Qty Per Box : - {productdata.standardQtyPerBox}</span>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="formBasicName">
                              <span style={{ fontSize: '15px', fontWeight: 600 }}>Groups : - {productdata.productGroup}</span>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="formBasicName">
                              <span style={{ fontSize: '15px', fontWeight: 600 }}>Category : - {productdata.category}</span>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="formBasicName">
                              <span style={{ fontSize: '15px', fontWeight: 600 }}>UOM Primary : - {productdata.uomPrimary}</span>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="formBasicName">
                              <span style={{ fontSize: '15px', fontWeight: 600 }}>UOM Secondary : - {productdata.uomSecondary}</span>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="formBasicName">
                              <span style={{ fontSize: '15px', fontWeight: 600 }}>DLP(₹) : - {productdata.dlp}</span>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="formBasicName">
                              <span style={{ fontSize: '15px', fontWeight: 600 }}>MRP(₹) : - {productdata.mrp}</span>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="formBasicName">
                              <span style={{ fontSize: '15px', fontWeight: 600 }}>Capacity(ml) : - {productdata.capacity}</span>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="formBasicName">
                              <span style={{ fontSize: '15px', fontWeight: 600 }}>Diameter : - {productdata.diameter}</span>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="formBasicName">
                              <span style={{ fontSize: '15px', fontWeight: 600 }}>HSN Code : - {productdata.hsnCode}</span>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="formBasicName">
                              <span style={{ fontSize: '15px', fontWeight: 600 }}>Brand Name : - {productdata.brand ? "Neelam" : ''}</span>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="formBasicName">
                              <span style={{ fontSize: '15px', fontWeight: 600 }}>Product Type : - {productdata.productType}</span>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="formBasicName">
                              <span style={{ fontSize: '15px', fontWeight: 600 }}>IGST% : - {productdata.igst}</span>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="formBasicName">
                              <span style={{ fontSize: '15px', fontWeight: 600 }}>CGST% : - {productdata.cgst}</span>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="formBasicName">
                              <span style={{ fontSize: '15px', fontWeight: 600 }}>SGST% : - {productdata.sgst}</span>
                            </Form.Group>
                          </Row>
                        </Modal.Body>
                      </Modal>
                    </td>
                    {/* <Form.Group as={Col} md="12">
                      <Form.Control type='button' className='btn btn-danger' value="X" onClick={() => dispatch(removefromcartprimaryorder(item.id))} size="sm" />
                    </Form.Group> */}
                  </td>

                </tr>


            )}

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              {/* <td> <span className="cname">Gross&nbsp;amount&nbsp;<b>₹</b></span></td>
                <td>
                  <Form.Group as={Col} md="12">
                    <Form.Control type="text" value={grossAmount} size="sm" />
                  </Form.Group>
                </td> */}
              <td></td>
            </tr>
            {primaryOrderItems.length !== 0 &&
              (
                <tr>
                  <td className="bold"></td>
                  <td></td>
                  <th className="bold">{totalPcsQuantity}</th>
                  <th className="bold">{kg.toFixed(2)}</th>
                  <td>{stdpcs}</td>
                  <td>{stdkgs}</td>
                  <td></td>
                  <td > </td>
                  <th className="bold">{grossfull.toFixed(2)}</th>
                  <td></td>
                  <th className="bold">{totalnet.toFixed(2)}</th>

                  <th className="bold">{gstvalue.toFixed(2)}</th>
                  <th className="bold">{amount.toFixed(2)}</th>
                  <th></th>
                  <th></th>
                  {/* <td>Total Amount</td> */}
                </tr>
              )}
          </tbody>
        </Table>
        {/* {primaryOrderItems.length !== 0 &&
         ( <table className="table">
                <thead>
                  <tr>
                    <th className="qty1">{totalPcsQuantity}</th>
                    <th className="qty2">{kg}</th>
                    <th className="gross">{grossfull}</th>
                    <th className="net">{totalnet}</th>
                    <th className="gst">{gstvalue}</th>
                    <th className="total">{amount}</th>

                  </tr>
                </thead>
              </table>)

          } */}
        <table>
          <tr>
            {primaryOrderItems.length !== 0 && (
              <>
                <tr>
                  <th className='namee'>Summary</th>
                  <th className='namee'> Total Qty(KG)</th>
                  <th className='namee'>Total Qty(Pcs)</th>
                  <th className='namee'>Total price</th>
                </tr>
                <tr>
                  <th>Box Product</th>
                  <th></th>
                  <th>{sumbox}</th>
                  <th>{boxamt.toFixed(2)}</th>
                </tr>
                <tr>
                  <th>KG Product</th>
                  <th>{kg1}</th>
                  <th>{kg2}</th>
                  <th>{kg3.toFixed(2)}</th>
                </tr>
                <tr>
                  <th>Corporate Product</th>
                  <th></th>
                  <th>{sumofcorp}</th>
                  <th>{amt.toFixed(2)}</th>
                </tr>
                <tr>
                  <th>Cooker Product</th>
                  <th></th>
                  <th>{c1}</th>
                  <th>{c3.toFixed(2)}</th>
                </tr>
                <tr>
                  <th>Nosh Product</th>
                  <th>{n3}</th>
                  <th>{n1}</th>
                  <th>{n2.toFixed(2)}</th>
                </tr>
                <tr>
                  <th>Total</th>
                  <th>{kg.toFixed(2)}</th>
                  <th>{totalPcsQuantity}</th>
                  <th>{amount.toFixed(2)}</th>
                </tr>
              </>
            )}
          </tr>



        </table>

        <br></br>

        <Button variant="primary" style={{width:'auto'}} onClick={finaldataSubmit} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
      </div>
    </MDBCard>
    // </div>
  )
}