import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";

import authHeader from "../../services/auth-header";
import ProductService from "../../services/ProductService";
import BrandService from "../../services/Brand";
import { MDBCard } from "mdbreact";
import Select from "react-select";
import Swal from "sweetalert2";
export default function PCreate() {
  const [productName, setproductName] = useState("");
  const [shortName, setshortName] = useState("");
  const [eanCode, seteanCode] = useState("");
  const [standardQtyPerBox, setstandardQtyPerBox] = useState("");
  const [category, setcategory] = useState("");
  const [uomPrimary, setuomPrimary] = useState("");
  const [uomSecondary, setuomSecondary] = useState("");
  const [mrp, setmrp] = useState("");
  const [capacity, setcapacity] = useState("");
  const [diameter, setdiameter] = useState("");
  const [hsnCode, sethsnCode] = useState("");
  const [brand, setbrand] = useState("");
  const [branddata, setbranddata] = useState([]);
  const [igst, setigst] = useState("");
  const [cgst, setcgst] = useState("");
  const [sgst, setsgst] = useState("");
  const [igstdata1, setIgstdata1] = useState([]);
  const [cgstLedger, setCgstLedger] = useState("");
  const [sgstLedger, setSgstLedger] = useState("");
  const [productType, setproductType] = useState("");
  const [productKind, setproductKind] = useState("");
  const [productGroup, setproductGroup] = useState("");
  const [dlp, setdlp] = useState("");
  const [igstdata, setIgstdata] = useState([]);
  const [error, setError] = useState(false);
  const [files, setFiles] = useState([]);
  const [costprice, setcostprice] = useState("");
  const [uom, setuom] = useState("");
  const navigate = useNavigate();

  let Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = "font-size 2s";
    element.style.fontSize = "30px";
    element.style.fontWeight = "600";
    // font-weight: 600;
    setTimeout(() => {
      document.getElementById("typer1").classList.add("promote");
      element.style.fontSize = "24px";
    }, 1000);
  };

  useEffect(() => {
    /* IGST DATA */
    const id = 1;
    ProductService.getProductIgst(id).then((response) => {
      let aa = response.data;
      setIgstdata(aa.map((user) => ({ value: user.id, label: user.per })));
    });

    /* Brand Data */
    BrandService.getBrand().then((res) => {
      let aa = res.data;
      setbranddata(aa.map((user) => ({ value: user.id, label: user.brandName })));
    });
  }, []);

  const [igstLedger, setIgstLedger] = useState("");

  const handleChangeigst = (e) => {
    const value = e.value;
    setIgstLedger(value);
  };
  const handleUomChange = (selectedOption) => {
    setuom(selectedOption ? selectedOption.value : "");
    setError(false);
  };
  const options = [
    { value: "", label: "Select" },
    { value: "bag", label: "Bags (BAG)" },
    { value: "bale", label: "Bale (BAL)" },
    { value: "bundle", label: "Bundles (BDL)" },
    { value: "buckle", label: "Buckles (BKL)" },
    { value: "box", label: "Box (BOX)" },
    { value: "bottle", label: "Bottles (BTL)" },
    { value: "can", label: "Cans (CAN)" },
    { value: "carton", label: "Cartons (CTN)" },
    { value: "ccm", label: "Cubic centimeters (CCM)" },
    { value: "cbm", label: "Cubic meters (CBM)" },
    { value: "cm", label: "Centimeters (CMS)" },
    { value: "drum", label: "Drums (DRM)" },
    { value: "dozen", label: "Dozens (DOZ)" },
    { value: "gg", label: "Great gross (GGK)" },
    { value: "gross", label: "Gross (GRS)" },
    { value: "grams", label: "Grams (GMS)" },
    { value: "km", label: "Kilometre (KME)" },
    { value: "kg", label: "Kilograms (KGS)" },
    { value: "kl", label: "Kilo litre (KLR)" },
    { value: "metricTon", label: "Metric ton (MTS)" },
    { value: "ml", label: "Milliliter (MLT)" },
    { value: "meter", label: "Meters (MTR)" },
    { value: "nos", label: "Numbers (NOS)" },
    { value: "pack", label: "Packs (PAC)" },
    { value: "pcs", label: "Pieces (PCS)" },
    { value: "pair", label: "Pairs (PRS)" },
    { value: "quintal", label: "Quintal (QTL)" },
    { value: "roll", label: "Rolls (ROL)" },
    { value: "squareYard", label: "Square Yards (SQY)" },
    { value: "set", label: "Sets (SET)" },
    { value: "squareFeet", label: "Square feet (SQF)" },
    { value: "squareMeter", label: "Square meters (SQM)" },
    { value: "tablet", label: "Tablets (TBS)" },
    { value: "tube", label: "Tubes (TUB)" },
    { value: "tenGross", label: "Ten Gross (TGM)" },
    { value: "thousand", label: "Thousands (THD)" },
    { value: "ton", label: "Tonnes (TON)" },
    { value: "unit", label: "Units (UNT)" },
    { value: "usGallon", label: "US Gallons (UGS)" },
    { value: "yard", label: "Yards (YDS)" },
  ];

  const gstcal = (e) => {
    const value = e.value;
    /* CGST & SGST  DATA*/
    ProductService.getgetIgstByIdonedata(value).then((response) => {
      setigst(response.data.per);
      var igstper = response.data.per;
      var cgstper = parseInt(igstper / 2);
      var sgstper = parseInt(igstper / 2);

      setcgst(cgstper);
      setsgst(sgstper);

      ProductService.getcgstByIdonedata(cgstper).then((response1) => {
        setCgstLedger(response1.data.id);
      });
      ProductService.getsgstByIdonedata(sgstper).then((response2) => {
        setSgstLedger(response2.data.id);
      });
    });
  };
  const onselectbrand = (e) => {
    setbrand(e.value);
  };

  const handleFileChange = (event) => {
    const selectedFile = [...files, ...event.target.files];
    console.log(selectedFile);
    const maxSize = 100 * 1024;
    if (selectedFile[0] && selectedFile[0].size < maxSize) {
      if (
        (selectedFile[0] && selectedFile[0].type === "image/jpeg") ||
        (selectedFile[0] && selectedFile[0].type === "image/jpg") ||
        (selectedFile[0] && selectedFile[0].type === "image/png" && selectedFile[0].size <= maxSize)
      ) {
        // File is a valid JPG image
        setFiles(selectedFile);
        // You can upload the file or perform other actions here
      } else {
        if ((selectedFile[0] && selectedFile[0].type !== "image/jpeg") || (selectedFile[0] && selectedFile[0].type !== "image/jpg") || (selectedFile[0] && selectedFile[0].type !== "image/png")) {
          Swal.fire({
            title: "Please select a valid JPG,PNG,JPEG image.",
            icon: "warning",
            button: {
              ok: "Ok",
            },
          }).then((ok) => {
            if (ok) {
              window.location.reload();
            }
          });
          // alert('Only Accept JPG,PNG,JPEG image.');
        }
      }
    } else {
      Swal.fire({
        title: "Please select an image that is no larger than 100KB.",
        icon: "warning",
        button: {
          ok: "Ok",
        },
      }).then((ok) => {
        if (ok) {
          window.location.reload();
        }
      });
    }
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...files];
    newFiles[index] = null;
    setFiles(newFiles);
  };

  const sendDataToAPI = () => {
    const formdata = new FormData();

    formdata.append("productName", productName);
    formdata.append("shortName", shortName);
    formdata.append("eanCode", eanCode);
    formdata.append("standardQtyPerBox", standardQtyPerBox);
    formdata.append("category", category);
    formdata.append("uomPrimary", uomPrimary);
    formdata.append("uomSecondary", uomSecondary);
    formdata.append("mrp", mrp);
    formdata.append("capacity", capacity);
    formdata.append("diameter", diameter);
    formdata.append("hsnCode", hsnCode);
    formdata.append("brand", brand);
    formdata.append("productGroup", productGroup);
    formdata.append("dlp", dlp);
    formdata.append("igst", igst);
    formdata.append("cgst", cgst);
    formdata.append("sgst", sgst);
    formdata.append("igstLedger", igstLedger);
    formdata.append("cgstLedger", cgstLedger);
    formdata.append("sgstLedger", sgstLedger);
    formdata.append("productType", productType);
    formdata.append("productKind", productKind);
    formdata.append("costprice", costprice);
    formdata.append("uom", uom);
    
    for (let i = 0; i < files.length; i++) {
      formdata.append("files", files[i]);
    }
    // formdata.append('files',files);

    if (
      (productType.length !== 0 &&
        sgstLedger.length !== 0 &&
        cgstLedger.length !== 0 &&
        igstLedger.length !== 0 &&
        sgst.length !== 0 &&
        cgst.length !== 0 &&
        igst.length !== 0 &&
        brand.length !== 0 &&
        productGroup.length !== 0 &&
        dlp.length !== 0 &&
        productName.length !== 0 &&
        shortName.length !== 0 &&
        eanCode.length !== 0 &&
        standardQtyPerBox.length !== 0 &&
        category.length !== 0 &&
        uomPrimary.length !== 0 &&
        uomSecondary.length !== 0 &&
        mrp.length !== 0 &&
        productKind.length !== 0 &&
        costprice.length !== 0) ||
      uom.length !== 0
    ) {
      setIsLoading(true);

      setError(false);
      ProductService.getProductAdd(formdata)
        .then((response) => {
          navigate("/product/1");
          if (response.status == 201 || response.status == 200) {
            // alert("ee")
            Toast.fire({
              icon: "success",
              title: "Successfully added",
            });
          }
        })
        .catch((error) => {
          console.error("Error adding  :", error);
          if (error.message.includes("Network Error") || error.message.includes("Failed to fetch")) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
          
              <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
              didOpen: () => {
                applyFontSizeTransition("issueText");
              },
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setError(true);
    }
  };
  const handleCostPrice = (e) => {
    const value = e.target.value;
    let parseFloatValue = parseFloat(value);
    if (!isNaN(parseFloatValue)) {
      parseFloatValue = parseFloatValue.toFixed(2);
    }
    setcostprice(parseFloatValue);
  };

  return (
    <MDBCard>
      <div className="d-flex bd-highlight">
        <div className="card-header card-title w-100">ADD</div>
        <Link className="p-2 w-200 card-header card-title bd-highlight" to={"/product/1"}>
          <Button>Back</Button>
        </Link>
      </div>
      <Form className="product2">
        <Row className="mb-1">
          <Form.Group as={Col} md="12" controlId="formBasicName">
            <Form.Label className="cname2">Product Name*</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              style={{
                borderColor: error && productName.length == 0 ? "red" : "",
              }}
              onChange={(e) => setproductName(e.target.value)}
              placeholder="Enter Product Name"
            />

            {error && productName.length === 0 ? (
              <p
                style={{
                  color: error && productName.length === 0 ? "red" : " ",
                }}
              >
                This field is Required
              </p>
            ) : (
              ""
            )}
          </Form.Group>
        </Row>

        <Row className="mb-1">
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Short Name*</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              style={{
                borderColor: error && shortName.length == 0 ? "red" : "",
              }}
              onChange={(e) => setshortName(e.target.value)}
              placeholder="Enter Short Name"
            />
            {error && shortName.length === 0 ? <p style={{ color: error && shortName.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Ean Code No*</Form.Label>
            <Form.Control type="text" autoComplete="off" style={{ borderColor: error && eanCode.length == 0 ? "red" : "" }} onChange={(e) => seteanCode(e.target.value)} placeholder="Enter Ean Code" />
            {error && eanCode.length === 0 ? <p style={{ color: error && eanCode.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Standard Qty Per Box*</Form.Label>
            <Form.Control
              type="number"
              autoComplete="off"
              style={{
                borderColor: error && standardQtyPerBox.length == 0 ? "red" : "",
              }}
              onChange={(e) => setstandardQtyPerBox(e.target.value)}
              placeholder="Enter Standard Quantity Per Box"
            />
            {error && standardQtyPerBox.length === 0 ? (
              <p
                style={{
                  color: error && standardQtyPerBox.length === 0 ? "red" : " ",
                }}
              >
                This field is Required
              </p>
            ) : (
              ""
            )}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Groups*</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              style={{
                borderColor: error && productGroup.length == 0 ? "red" : "",
              }}
              onChange={(e) => setproductGroup(e.target.value)}
            />
            {error && productGroup.length === 0 ? (
              <p
                style={{
                  color: error && productGroup.length === 0 ? "red" : " ",
                }}
              >
                This field is Required
              </p>
            ) : (
              ""
            )}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Category*</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              style={{
                borderColor: error && category.length == 0 ? "red" : "",
              }}
              onChange={(e) => setcategory(e.target.value)}
              placeholder="Enter Category"
            />
            {error && category.length === 0 ? <p style={{ color: error && category.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">UOM*</Form.Label>
            <Select
              options={options}
              value={options.find((option) => option.value === uom)}
              onChange={handleUomChange}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  borderColor: error && !uom ? "red" : provided.borderColor,
                  boxShadow: state.isFocused ? "0 0 0 0.25rem rgba(0,123,255,.25)" : provided.boxShadow,
                }),
                menuList: (provided) => ({
                  ...provided,
                  maxHeight: 300,
                  overflowY: "auto",
                }),
              }}
              placeholder="Select"
            />
            {error && !uom && <p style={{ color: "red" }}>This field is required</p>}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">UOM(PCS)*</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              style={{
                borderColor: error && uomPrimary.length == 0 ? "red" : "",
              }}
              onChange={(e) => setuomPrimary(e.target.value)}
              placeholder="Enter Uom Primary"
            />
            {error && uomPrimary.length === 0 ? (
              <p
                style={{
                  color: error && uomPrimary.length === 0 ? "red" : " ",
                }}
              >
                This field is Required
              </p>
            ) : (
              ""
            )}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">UOM(KG)*</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              style={{
                borderColor: error && uomSecondary.length == 0 ? "red" : "",
              }}
              onChange={(e) => setuomSecondary(e.target.value)}
              placeholder="Enter Uom Secondary"
            />
            {error && uomSecondary.length === 0 ? (
              <p
                style={{
                  color: error && uomSecondary.length === 0 ? "red" : " ",
                }}
              >
                This field is Required
              </p>
            ) : (
              ""
            )}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">DLP(₹)*</Form.Label>
            <Form.Control type="number" autoComplete="off" style={{ borderColor: error && dlp.length == 0 ? "red" : "" }} onChange={(e) => setdlp(e.target.value)} placeholder="Enter DLP" />
            {error && dlp.length === 0 ? <p style={{ color: error && dlp.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">MRP(₹)*</Form.Label>
            <Form.Control type="number" autoComplete="off" style={{ borderColor: error && mrp.length == 0 ? "red" : "" }} onChange={(e) => setmrp(e.target.value)} placeholder="Enter Mrp" />
            {error && mrp.length === 0 ? <p style={{ color: error && mrp.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Capacity(ml)</Form.Label>
            <Form.Control type="number" autoComplete="off" onChange={(e) => setcapacity(e.target.value)} placeholder="Enter Capacity" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Diameter(cm)</Form.Label>
            <Form.Control type="text" autoComplete="off" onChange={(e) => setdiameter(e.target.value)} placeholder="Enter Diameter" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">HSN Code*</Form.Label>
            <Form.Control type="text" autoComplete="off" style={{ borderColor: error && hsnCode.length == 0 ? "red" : "" }} onChange={(e) => sethsnCode(e.target.value)} placeholder="Enter HSN Code" />
            {error && hsnCode.length === 0 ? <p style={{ color: error && hsnCode.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Brand Name*</Form.Label>
            <Select options={branddata} style={{ borderColor: error && hsnCode.length == 0 ? "red" : "" }} onChange={(e) => onselectbrand(e)} />
            {error && brand.length === 0 ? <p style={{ color: error && brand.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Product Type*</Form.Label>
            <Form.Select
              style={{
                borderColor: error && productType.length == 0 ? "red" : "",
              }}
              onChange={(e) => setproductType(e.target.value)}
            >
              <option>Select</option>
              <option value="boxproduct">Box Product</option>
              <option value="kgproduct">KG Product</option>
              <option value="corporateproduct">Corporate Product</option>
              <option value="cookerproduct">Cooker Product</option>
              <option value="noshproduct">Nosh Product</option>
            </Form.Select>
            {error && productType.length === 0 ? (
              <p
                style={{
                  color: error && productType.length === 0 ? "red" : " ",
                }}
              >
                This field is Required
              </p>
            ) : (
              ""
            )}
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Product Kind*</Form.Label>
            <Form.Select
              style={{
                borderColor: error && productKind.length == 0 ? "red" : "",
              }}
              onChange={(e) => setproductKind(e.target.value)}
              name="productKind"
            >
              <option>Select</option>
              <option value="Raw material">Raw Material</option>
              <option value="Packing material">Packing Material</option>
              <option value="Semi finished">Semi-Finished</option>
              <option value="finished">Finished</option>
            </Form.Select>
            {error && productKind.length === 0 ? (
              <p
                style={{
                  color: error && productKind.length === 0 ? "red" : " ",
                }}
              >
                This field is Required
              </p>
            ) : (
              ""
            )}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Cost Price</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              value={costprice}
              placeholder="Enter Cost Price"
              onChange={(e) => {
                const value = e.target.value;

                const regex = /^[0-9]*\.?[0-9]{0,2}$/;

                if (regex.test(value)) {
                  setcostprice(value);
                }
              }}
            />
            {error && (costprice.length === 0 || isNaN(costprice)) ? (
              <p
                style={{
                  color: error && costprice.length === 0 ? "red" : " ",
                }}
              >
                This field is Required
              </p>
            ) : (
              ""
            )}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">IGST%*</Form.Label>
            <Select
              name="igstdata"
              options={igstdata}
              style={{
                borderColor: error && igstLedger.length == 0 ? "red" : "",
              }}
              getOptionLabel={(option) => option.label}
              onChange={(e) => {
                handleChangeigst(e);
                gstcal(e);
              }}
            />
            {error && igstLedger.length === 0 ? (
              <p
                style={{
                  color: error && igstLedger.length === 0 ? "red" : " ",
                }}
              >
                This field is Required
              </p>
            ) : (
              ""
            )}
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">CGST%</Form.Label>
            <Form.Control type="text" autoComplete="off" value={cgst} onChange={(e) => setcgst(e.target.value)} />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">SGST%</Form.Label>
            <Form.Control type="text" autoComplete="off" value={sgst} onChange={(e) => setsgst(e.target.value)} />
          </Form.Group>
          {[...Array(5)].map((_, index) => (
            <div
              key={index}
              style={{
                height: "100px",
                width: "20%",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                borderWidth: "2px",
                borderRadius: "1rem",
                borderStyle: "dashed",
                borderColor: "#cbd5e1",
                backgroundColor: "#f8fafc",
                marginTop: "5px",
              }}
            >
              <Form.Group as={Col} md="3" controlId="formBasicName">
                <Form.Control type="file" id="fileInput" multiple onChange={handleFileChange} style={{ display: "none" }} />
              </Form.Group>

              {files[index] ? (
                <div>
                  <img key={index} src={URL.createObjectURL(files[index])} alt={`Image ${index}`} style={{ width: "200px", height: "100px", margin: "5px" }} />
                  <button onClick={() => handleRemoveFile(index)}>X</button>
                </div>
              ) : (
                <Form.Label htmlFor="fileInput" className="cname2">
                  Image
                </Form.Label>
              )}
            </div>
          ))}
        </Row>
        <Button variant="primary" style={{ width: "auto" }} onClick={sendDataToAPI} disabled={isLoading}>
          {isLoading ? "Loading..." : "Submit"}
        </Button>
      </Form>
    </MDBCard>
  );
}
