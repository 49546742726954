import React from 'react'
import { Mydatatable } from '../Master/Product/Mydatatable'
import { HiOutlineDotsVertical } from "react-icons/hi";
export const Newpurchaseorder = () => {

    let header = [
        { "label": "Id", "field": "id" },
        { "label": "Voucher Series", "field": "voucherseries" },
        { "label": "PoDate", "field": "podate" },

        

// podate
        // voucherseries
        { "label": " Company Name", "field": "companyname" },
    
        { "label": "City  ", "field":"cities" },
    
      
        { "label": "Taxable ", "field": "net_Amount" },
        { "label": "Tax", "field": "gstvalue" },
        { "label": "Grand Total  ", "field": "grandtotal" },
     { "label": "Remarks", "field": "remarks" },
     { "label": "Status", "field": "status" },
     { "label": "Created Date", "field": "createddate" },
     { "label": "Created Time", "field": "createdtime" },
     { "label": "Created By", "field": "createbyname" },
     { "label": "Updated Date", "field": "updateddate" },
     { "label": "Updated Time", "field": "updatedtime" },
     { "label": "Updated By", "field": "updatedbyname" },
        { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> },
        { "label": <HiOutlineDotsVertical />, "field":'hideOrshow'},
      ]; 

      

      const sumofkeys=[{"first":'grandtotal'},{'first':'net_Amount'} ]




  return (
    <Mydatatable
    head={header}
    title={"Purchase Order"}
    axiostabledata={'po'}
    Add={"Add Po"}
    Create={'/pocreate'}
    Edit={"/po"}
    View={"/po"}
    Sumofkeys={sumofkeys}
    />
    
  )
}
