import React, { useEffect, useState } from "react";
import { MDBCard } from "mdbreact";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import authHeader from "../../services/auth-header";
import { BASE_URL } from "../../services/Api";
import { useParams, Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import Swal from "sweetalert2";

const ViewGroupn4 = () => {
  const [title, setTitle] = useState("");
  const { id, pointindex } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const user = AuthService.getCurrentUser();
  const navigate = useNavigate();

  const getTitleData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn4/${id}`, {
        headers: authHeader(),
      });

      setTitle(response.data.title);
    } catch (error) {
      console.log(error);
    }
  };

  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = "font-size 2s";
    element.style.fontSize = "30px";
    element.style.fontWeight = "600";
    // font-weight: 600;
    setTimeout(() => {
      document.getElementById("typer1").classList.add("promote");
      element.style.fontSize = "24px";
    }, 1000);
  };

  const handledelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        axios
          .delete(BASE_URL + "v1/groupn4/" + id, {
            headers: authHeader(),
          })
          .then((res) => {
            navigate(`/group4indextable/${pointindex}`);

            Swal.fire("Deleted!", "Your file has been deleted.", "success");

            // window.location.reload();
          })
          .catch((error) => {
            console.error("Error adding  :", error);
            if (error.message.includes("Network Error") || error.message.includes("Failed to fetch")) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `
              <div>
                <p id="issueText">Why do I have this issue?</p>
                <ul>
            
                <li id="typer1">You might have an internet issue</li>
                </ul>
              </div>
            `,
                didOpen: () => {
                  applyFontSizeTransition("issueText");
                },
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Group cannot be deleted!",
              });
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    });
  };

  useEffect(() => {
    getTitleData();
  }, []);

  return (
    <MDBCard className="company">
      <div className="d-flex bd-highlight">
        <div className="card-header card-title w-100">View Group4</div>
        {user.roles[0] === "ROLE_ADMIN" && (
          <Link className="p-2 w-200 card-header card-title bd-highlight" variant="primary" style={{ width: "auto" }} onClick={() => handledelete(id)} disabled={isLoading}>
            <Button className="btn-sm"> {isLoading ? "Loading..." : "Delete"}</Button>
          </Link>
        )}

        <Link className="p-2 w-200 card-header card-title bd-highlight" to={`/update/group4/${id}/${pointindex}`}>
          <Button className="btn-sm">Update</Button>
        </Link>

        <Link className="p-2 w-200 card-header card-title bd-highlight" to={`/group4indextable/${pointindex}`}>
          <Button className="btn-sm">Back</Button>
        </Link>
      </div>
      <Form className="product1">
        <Row className="mb-1">
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="label">Title</Form.Label>
            <Form.Control type="text" name="title" value={title} readOnly />
          </Form.Group>
        </Row>
      </Form>
    </MDBCard>
  );
};

export default ViewGroupn4;
