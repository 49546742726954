import { CHANGE_SUPPLIER_DISCOUNT_EDIT, REMOVE_FROM_CART_FROM_SUPPLIER_eDIT, SUPPLIER_ADD_TO_CART_EDIT, SUPPLIER_EDIT_CHANGE_CAL_MEASUREMENT_EDIT, SUPPLIER_EDIT_CHANGE_MEASUREMENTtn, SUPPLIER_EDIT_CHANGE_QTYKG_EDIT, SUPPLIER_EDIT_CHANGE_QTYPCS_EDIT, SUPPLIER_EDIT_GET_DATA_TO_LOAD } from "../constant";
import { SUPPLIER_EDIT_ADD_TO_CART } from "../constant";
import { SUPPLIER_EDIT_CHANGE_MEASUREMENT } from "../constant";
import { SUPPLIER_EDIT_CHANGE_QTYKGs } from "../constant";
import { CHANGE_SUPPLIER_EDIT_QTY } from "../constant";
import { REMOVE_FROM_CART_FROM_SUPPLIER_EDIT } from "../constant";
import { CHANGE_COST_SUPPLIER_EDIT } from "../constant";
import { CHANGE_SUPPLIER_EDIT_DISCOUNT } from "../constant";
import { CHANGE_SUPPLIER_EDIT_TEXT } from "../constant";
import { EMPTY_SUPPLIER_EDIT_DATA } from "../constant";


export const supplierdeliveryeditmeasurement = (data, item, data2) => {

    return {
        type: SUPPLIER_EDIT_CHANGE_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}


export const getsupplierdeliveryeditdatatoload = (data) => {

 
    return {

        type : SUPPLIER_EDIT_GET_DATA_TO_LOAD,
        data : data
    }

}


export const supplierdeliveryeditaddtocart = (data) => {
     console.warn("action called", data);
    return {

        type : SUPPLIER_EDIT_ADD_TO_CART,
        data : data
    }
}

export const supplierdeliverymeasurementtn = (data, item, data2) => {
console.log(data,data2)
    return {
        type: SUPPLIER_EDIT_CHANGE_MEASUREMENTtn,
        // data: data2,
        item: item,
        data: data
    }
}
export const supplierdeliveryeditqtypcs = (data, item, data2) => {


    return {

        type: SUPPLIER_EDIT_CHANGE_QTYPCS_EDIT,
        data: data,
        item: item,
        // data2: data2
    }

}
export const supplierdeliveryeditqtykgs = (data, item, data2) => {

    return {
        type: SUPPLIER_EDIT_CHANGE_QTYKG_EDIT,
        data: data,
        item: item,
        // data2: data2

    }
}
export const changecostsupplierdeliveryeditS = (data, item, data2) => {

    // console.warn("cost change called", data);
    return {

        type : CHANGE_COST_SUPPLIER_EDIT,
        data : data,
        item : item,
        data2 : data2

    }

}
export const supplierdeliverycalmeasurementedit = (data, item, data2 ) => {

    return{
        type:SUPPLIER_EDIT_CHANGE_CAL_MEASUREMENT_EDIT,
        data: data,
        item: item,
        data2: data2
    }
}
export const changesupplierdeliverydiscountedit = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type : CHANGE_SUPPLIER_DISCOUNT_EDIT,
        data : data,
        item : item,
        data2 : data2

    }

}
export const supplierdeliveryaddtocartedit = (data) => {

      console.warn("action called", data);
    return {

        type : SUPPLIER_ADD_TO_CART_EDIT,
        data : data
    }

}

export const removefromcartfromsupplierdeliveryEdit = (data) => {

    console.warn("action REMOVE_TO_CART called", data);
    return {

        type : REMOVE_FROM_CART_FROM_SUPPLIER_eDIT,
        data : data
    }

}
export const removefromcartfromsupplierdeliveryedit = (data) => {

    // console.warn("action REMOVE_TO_CART called", data);
    return {

        type : REMOVE_FROM_CART_FROM_SUPPLIER_EDIT,
        data : data
    }

}



export const changesupplierdeliveryeditqty = (data, item, data2) => {

    // console.warn("qty change called", data);
    return {

        type : CHANGE_SUPPLIER_EDIT_QTY,
        data : data,
        item : item,
        data2 : data2
    }

}

export const changesupplierdeliveryeditkg = (data, item, data2) => {

    // console.warn("qty change called", data);
    return {

        type : SUPPLIER_EDIT_CHANGE_QTYKGs,
        data : data,
        item : item,
        data2 : data2
    }

}

export const changecostsupplierdeliveryedit = (data, item, data2) => {

    // console.warn("cost change called", data);
    return {

        type : CHANGE_COST_SUPPLIER_EDIT,
        data : data,
        item : item,
        data2 : data2

    }

}


export const changesupplierdeliveryeditdiscount = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type : CHANGE_SUPPLIER_EDIT_DISCOUNT,
        data : data,
        item : item,
        data2 : data2

    }

}


export const changesupplierdeliveryedittext = (data, item) => {

    // console.warn("text change called", data);
    return {

        type : CHANGE_SUPPLIER_EDIT_TEXT,
        data : data,
        item : item
    }

}





export const emptysupplierdeliveryeditdata = () => {

    return {

        type : EMPTY_SUPPLIER_EDIT_DATA,
    }

}