// import React from "react";
// import { useEffect } from "react";
// import { Row, Form, Col, Button } from "react-bootstrap";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import ProductService from "../../services/ProductService";
// import { useState } from "react";
// import BrandService from "../../services/Brand";
// import { MDBCard } from "mdbreact";
// import Swal from "sweetalert2";
// import AuthService from "../../services/auth.service";

// export default function PView(){
  
//   /* Product Id */
//    const { id } = useParams();
//    const { pointindex } = useParams();
//    const[productName,setproductName]=useState('');
//    const[shortName,setshortName]=useState('');
//    const[eanCode,seteanCode]=useState('');
//    const[standardQtyPerBox,setstandardQtyPerBox]=useState('');
//    const[group,setGroup]=useState('');
//    const[category,setcategory]=useState('');
//    const[uomPrimary,setuomPrimary]=useState('');
//    const[uomSecondary,setuomSecondary]=useState('');
//    const[mrp,setmrp]=useState('');
//    const[dlp,setdlp]=useState('');
//    const[capacity,setcapacity]=useState('');
//    const[diameter,setdiameter]=useState('');
//    const[hsnCode,sethsnCode]=useState('');
//    const[igst,setigst]=useState('');
//    const[cgst,setcgst]=useState('');
//    const[sgst,setsgst]=useState('');
//    const[brandName,setBrandName]=useState('');
//    const[productKind,setproductKind]=useState('');
//    const[productType,setProductType]=useState('');
//    const[imagedisplay,setdiplay]=useState([])
//    const navigate = useNavigate();
//    const user = AuthService.getCurrentUser();
//    const [isLoading, setIsLoading] = useState(false);

//    const applyFontSizeTransition = (elementId) => {
//      const element = document.getElementById(elementId);
//      element.style.transition = 'font-size 2s';
//      element.style.fontSize = '30px';
//      element.style.fontWeight = '600';
//      // font-weight: 600;
//      setTimeout(() => {
        
//        document.getElementById("typer1").classList.add('promote');
//        element.style.fontSize = '24px';
//      }, 1000);
//    };
//     useEffect(()=>{
//       /* product Data */
//       if(id){
//        ProductService.getProductEdit(id).then(res=>{
//         console.log("mello",res.data)
//         setproductName(res.data.productName);
//         setshortName(res.data.shortName);
//         seteanCode(res.data.eanCode);
//         setstandardQtyPerBox(res.data.standardQtyPerBox);
//         setGroup(res.data.productGroup);
//         setcategory(res.data.category);
//         setuomPrimary(res.data.uomPrimary);
//         setuomSecondary(res.data.uomSecondary);
//         setmrp(res.data.mrp);
//         setdlp(res.data.dlp);
//         setcapacity(res.data.capacity);
//         setdiameter(res.data.diameter);
//         sethsnCode(res.data.hsnCode);
//         setigst(res.data.igst);
//         setcgst(res.data.cgst);
//         setsgst(res.data.sgst);
//         setProductType(res.data.productType);
//         setdiplay(res.data.productImages);
//         setproductKind(res.data.productKind);
//         /* Brand */

//         BrandService.getBrandEdit(res.data.brand).then(respo=>{
//           setBrandName(respo.data.brandName);
          
//         })
      
//        })
//       }else{
//         alert("pk")
//       }
//     },[])
//     const handleChange12=()=>{
 
//       navigate(`/product/${pointindex}`);
//       // window.location.reload();
    
//     }
//     const handledelete=(id)=>{
//       console.log(id);

//       Swal.fire({
//         title: 'Are you sure?',
//         text: "You won't be able to revert this!",
//         icon: 'warning',
//         showCancelButton: true,
//         confirmButtonColor: '#3085d6',
//         cancelButtonColor: '#d33',
//         confirmButtonText: 'Yes, delete it!'
//       }).then((result) => {
//         if (result.isConfirmed) {
//           setIsLoading(true); 

//           ProductService.getDelete(id).then(res=>{
//             // window.location.reload();
//          console.log(res.data);
//        if(1==1){
//           Swal.fire(
//             'Deleted!',
//             'Your file has been deleted.',
//             'success'
//           )
//           navigate(`/product/${pointindex}`)
//        }
//           }).catch(error => {
//             console.error('Error adding  :', error);
//             if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
//             Swal.fire({
//               icon: "error",
//               title: "Oops...",
//               text: "Something went wrong!",
//               footer: `
//                 <div>
//                   <p id="issueText">Why do I have this issue?</p>
//                   <ul>
              
//                   <li id="typer1">You might have an internet issue</li>
//                   </ul>
//                 </div>
//               `,
//               didOpen: () => {
//                 applyFontSizeTransition('issueText');
//               }
//             });
//           }
//           })
//           .finally(() => {
//             setIsLoading(false); 
//           });
         
//         }
//       })
//       }
//     return(
//         <MDBCard data-aos="fade">

//          <div className='d-flex bd-highlight'>
//         <div className="card-header card-title w-100">VIEW PRODUCTS</div>
//         {/* <button className='prox btn' onClick={()=>handleChange12()} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"/><polyline points="12 19 5 12 12 5"/></svg></button> */}
//         {/* <a className=' delete btn' onClick={()=>handledelete(id)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg></a> */}
//         {user.roles[0] === 'ROLE_ADMIN' && 
        
//         <Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{width:'auto'}} onClick={()=>handledelete(id)} disabled={isLoading}>
        
//         <Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button>
//       </Link>        
        
//         }
        
//         <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/pedit/${id}/${pointindex}`}><Button className="btn-sm">edit</Button></Link>

//         <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/product/${pointindex}`}><Button  className="btn-sm">Back</Button></Link>
     
//       </div>
//             <div className="card">
//                 <div className='card card-body'>
//             <Row className="mb-1 wer">
//               {imagedisplay.map((user,index) => (
//                 <img src={"http://43.225.54.138:8080/scm/Images/" + user.name} className="images" style={{ width: '200px', height: '150px' }} />
//               ))}
            

//             <Form.Group as={Col} md="12" controlId="formBasicName">
//               <span className="form-label">Product Name : - {productName}</span>
//               </Form.Group>
              
//             </Row>

//             <Row className="mb-1">
//             <Form.Group as={Col} md="6" controlId="formBasicName">
//               <span className="form-label">Short Name : - {shortName}</span>
//               </Form.Group>
//               <Form.Group as={Col} md="6" controlId="formBasicName">
//               <span className="form-label">Ean Code No : - {eanCode}</span>
//               </Form.Group>
//             </Row>

//             <Row className="mb-1">
//             <Form.Group as={Col} md="6" controlId="formBasicName">
//               <span className="form-label">Standard Qty Per Box : - {standardQtyPerBox}</span>
//               </Form.Group>
//               <Form.Group as={Col} md="6" controlId="formBasicName">
//               <span>Groups : - {group}</span>
//               </Form.Group>
//             </Row>

//             <Row className="mb-1">
//             <Form.Group as={Col} md="6" controlId="formBasicName">
//               <span className="form-label">Category : - {category}</span>
//               </Form.Group>
//             <Form.Group as={Col} md="6" controlId="formBasicName">
//               <span className="form-label">UOM Primary : - {uomPrimary}</span>
//               </Form.Group>
//             </Row>

//             <Row className="mb-1">
//             <Form.Group as={Col} md="6" controlId="formBasicName">
//               <span className="form-label">UOM Secondary : - {uomSecondary}</span>
//               </Form.Group>
//               <Form.Group as={Col} md="6" controlId="formBasicName">
//               <span className="form-label">DLP : - {dlp}</span>
//               </Form.Group>
//               </Row>
//               <Row className="mb-1">
//             <Form.Group as={Col} md="6" controlId="formBasicName">
//               <span className="form-label">MRP(₹) : - {mrp}</span>
//               </Form.Group>
//               <Form.Group as={Col} md="6" controlId="formBasicName">
//               <span className="form-label">Capacity(ml) : - {capacity}</span>
//               </Form.Group>
//             </Row>

//             <Row className="mb-1">
//             <Form.Group as={Col} md="6" controlId="formBasicName">
//               <span className="form-label">Diameter : - {diameter}</span>
//               </Form.Group>
//               <Form.Group as={Col} md="6" controlId="formBasicName">
//               <span className="form-label">HSN Code : - {hsnCode}</span>
//               </Form.Group>
//             </Row>

//             <Row className="mb-1">
//             <Form.Group as={Col} md="6" controlId="formBasicName">
//               <span className="form-label">Brand Name : - {brandName}</span>
//               </Form.Group>
//               <Form.Group as={Col} md="6" controlId="formBasicName">
//               <span className="form-label">Product Type : - {productType}</span>
//               </Form.Group>
//             </Row>
//             <Row className="mb-1">
//               <Form.Group as={Col} md="6" controlId="formBasicName">
//               <span className="form-label">Product Kind : - {productKind}</span>
//               </Form.Group>
//               <Form.Group as={Col} md="6" controlId="formBasicName">
//               <span className="form-label">IGST% : - {igst}</span>
//               </Form.Group>
//             </Row>
//             <Row className="mb-1">

//             <Form.Group as={Col} md="6" controlId="formBasicName">
//               <span className="form-label">CGST% : - {cgst}</span>
//               </Form.Group>
//               <Form.Group as={Col} md="6" controlId="formBasicName">
//               <span className="form-label">SGST% : - {sgst}</span>
//               </Form.Group>
//             </Row>
//             </div>
//             </div>
//         </MDBCard>



//     )
// }



import React from "react";
import { useEffect } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProductService from "../../services/ProductService";
import { useState } from "react";
import BrandService from "../../services/Brand";
import { MDBCard } from "mdbreact";
import Swal from "sweetalert2";
import AuthService from "../../services/auth.service";

export default function PView(){
  
  /* Product Id */
   const { id } = useParams();
   const { pointindex } = useParams();
   const[productName,setproductName]=useState('');
   const[shortName,setshortName]=useState('');
   const[eanCode,seteanCode]=useState('');
   const[standardQtyPerBox,setstandardQtyPerBox]=useState('');
   const[group,setGroup]=useState('');
   const[category,setcategory]=useState('');
   const[uomPrimary,setuomPrimary]=useState('');
   const[uomSecondary,setuomSecondary]=useState('');
   const[mrp,setmrp]=useState('');
   const[dlp,setdlp]=useState('');
   const[capacity,setcapacity]=useState('');
   const[diameter,setdiameter]=useState('');
   const[hsnCode,sethsnCode]=useState('');
   const[igst,setigst]=useState('');
   const[cgst,setcgst]=useState('');
   const[sgst,setsgst]=useState('');
   const[brandName,setBrandName]=useState('');
   const[productKind,setproductKind]=useState('');
   const[productType,setProductType]=useState('');
   const[imagedisplay,setdiplay]=useState([])
   const navigate = useNavigate();
   const user = AuthService.getCurrentUser();
   const [isLoading, setIsLoading] = useState(false);
   const [costprice, setcostprice] = useState("");
   const [uom, setuom] = useState("");
   const applyFontSizeTransition = (elementId) => {
     const element = document.getElementById(elementId);
     element.style.transition = 'font-size 2s';
     element.style.fontSize = '30px';
     element.style.fontWeight = '600';
     // font-weight: 600;
     setTimeout(() => {
        
       document.getElementById("typer1").classList.add('promote');
       element.style.fontSize = '24px';
     }, 1000);
   };
    useEffect(()=>{
      /* product Data */
      if(id){
       ProductService.getProductEdit(id).then(res=>{
        console.log("mello",res.data)
        setproductName(res.data.productName);
        setshortName(res.data.shortName);
        seteanCode(res.data.eanCode);
        setstandardQtyPerBox(res.data.standardQtyPerBox);
        setGroup(res.data.productGroup);
        setcategory(res.data.category);
        setuomPrimary(res.data.uomPrimary);
        setuomSecondary(res.data.uomSecondary);
        setmrp(res.data.mrp);
        setdlp(res.data.dlp);
        setcapacity(res.data.capacity);
        setdiameter(res.data.diameter);
        sethsnCode(res.data.hsnCode);
        setigst(res.data.igst);
        setcgst(res.data.cgst);
        setsgst(res.data.sgst);
        setProductType(res.data.productType);
        setdiplay(res.data.productImages);
        setproductKind(res.data.productKind);
        setcostprice(res.data.costprice);
        setuom(res.data.uom);
        /* Brand */

        BrandService.getBrandEdit(res.data.brand).then(respo=>{
          setBrandName(respo.data.brandName);
          
        })
      
       })
      }else{
        alert("pk")
      }
    },[])
    const handleChange12=()=>{
 
      navigate(`/product/${pointindex}`);
      // window.location.reload();
    
    }
    const handledelete=(id)=>{
      console.log(id);

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          setIsLoading(true); 

          ProductService.getDelete(id).then(res=>{
            // window.location.reload();
         console.log(res.data);
       if(1==1){
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
          navigate(`/product/${pointindex}`)
       }
          }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          })
          .finally(() => {
            setIsLoading(false); 
          });
         
        }
      })
      }
    return(
        <MDBCard data-aos="fade">

         <div className='d-flex bd-highlight'>
        <div className="card-header card-title w-100">VIEW PRODUCTS</div>
        {/* <button className='prox btn' onClick={()=>handleChange12()} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"/><polyline points="12 19 5 12 12 5"/></svg></button> */}
        {/* <a className=' delete btn' onClick={()=>handledelete(id)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg></a> */}
        {user.roles[0] === 'ROLE_ADMIN' && 
        
        <Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{width:'auto'}} onClick={()=>handledelete(id)} disabled={isLoading}>
        
        <Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button>
      </Link>        
        
        }
        
        <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/pedit/${id}/${pointindex}`}><Button className="btn-sm">edit</Button></Link>

        <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/product/${pointindex}`}><Button  className="btn-sm">Back</Button></Link>
     
      </div>
            <div className="card">
                <div className='card card-body'>
            <Row className="mb-1 wer">
              {imagedisplay.map((user,index) => (
                <img src={"http://43.225.54.138:8080/scm/Images/" + user.name} className="images" style={{ width: '200px', height: '150px' }} />
              ))}
            

            <Form.Group as={Col} md="12" controlId="formBasicName">
              <span className="form-label">Product Name : - {productName}</span>
               </Form.Group>
              


   
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <span className="form-label">Short Name : - {shortName}</span>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
              <span className="form-label">Ean Code No : - {eanCode}</span>
              </Form.Group>
     

       
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <span className="form-label">Standard Qty Per Box : - {standardQtyPerBox}</span>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
              <span>Groups : - {group}</span>
              </Form.Group>
         


            <Form.Group as={Col} md="6" controlId="formBasicName">
              <span className="form-label">Category : - {category}</span>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
              <span className="form-label">UOM : - {uom}</span>
              </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <span className="form-label">UOM Primary : - {uomPrimary}</span>
              </Form.Group>
           

        
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <span className="form-label">UOM Secondary : - {uomSecondary}</span>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
              <span className="form-label">DLP : - {dlp}</span>
              </Form.Group>
        
        
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <span className="form-label">MRP(₹) : - {mrp}</span>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
              <span className="form-label">Capacity(ml) : - {capacity}</span>
              </Form.Group>
    

     
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <span className="form-label">Diameter : - {diameter}</span>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
              <span className="form-label">HSN Code : - {hsnCode}</span>
              </Form.Group>
  

    
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <span className="form-label">Brand Name : - {brandName}</span>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
              <span className="form-label">Product Type : - {productType}</span>
              </Form.Group>
      
     
              <Form.Group as={Col} md="6" controlId="formBasicName">
              <span className="form-label">Product Kind : - {productKind}</span>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
              <span className="form-label">Cost Price: - {costprice}</span>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
              <span className="form-label">IGST% : - {igst}</span>
              </Form.Group>
   
     

            <Form.Group as={Col} md="6" controlId="formBasicName">
              <span className="form-label">CGST% : - {cgst}</span>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
              <span className="form-label">SGST% : - {sgst}</span>
              </Form.Group>
            </Row>
            </div>
            </div>
        </MDBCard>



    )
}