import React from "react";
import Datatable2 from "../services/DataTable2";

const ProjectionEntryTable = () => {
  const header = [
    { label: "Product Name", field: "productname" },
    { label: "Quantity", field: "qty" },
    { label: "Projection Entry Date", field: "pdate" },
    { label: "Group 1", field: "title1" },
    { label: "Group 2", field: "title2" },
    { label: "Group 3", field: "title3" },
    { label: "Group 4", field: "title4" },
    { label: "Group 5", field: "title5" },
    {
      label: "Action",
      field: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-eye"
        >
          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
          <circle cx="12" cy="12" r="3" />
        </svg>
      ),
    },
  ];
  return (
<Datatable2
      header={header}
      tableTitle={"Projection Order"}
      apiField={"projectionentry"}
      addButton={"Add Projection"}
      createRoute={"/create/projectionentry"}
      updateRoute={"/projectionentry/update"}
      viewRoute={"/projectionentry/view"}
    />
  );
};

export default ProjectionEntryTable;
