
import React, { useEffect } from "react";
import { useState } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import Select from "react-select";
import UserService from "../../services/user.service";
import ReatilerService from "../../services/Reatiler";
import { Link, useNavigate, useParams } from "react-router-dom";
import DistrbutorService from "../../services/Distrbutor";
import { MDBCard } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import authHeader from "../../services/auth-header";
import { BASE_URL } from "../../services/Api";
import Profilestaffcom from "../Staff/Profilestaffcom";
export default function Create() {
  const [aadharcard, setaadharcard] = useState("");
  const [tradeName, settradeName] = useState("");
  const [transporterName, settransporterName] = useState("");
  const [billingAddress, setbillingAddress] = useState("");
  const [deliveryAddress, setdeliveryAddress] = useState("");
  const [gstNumber, setgstNumber] = useState("");
  const [panNumber, setpanNumber] = useState("");
  const [countryoptions, setCountryoptions] = useState({ value: "101", label: "India" });
  const [statedata, setStatedata] = useState([]);
  const [statedatavalue, setStatedatavalue] = useState([]);
  const [city, setcity] = useState("");
  const [gstType, setgstType] = useState("register");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [pinCode, setpinCode] = useState("");
  const [perMobileNumber, setperMobileNumber] = useState("");
  const [alterMobileNumber, setalterMobileNumber] = useState("");
  const [perEmail, setperEmail] = useState("");
  const [alterEmail, setalterEmail] = useState("");
  const [creditLimit, setcreditLimit] = useState("");
  const [creditDays, setcreditDays] = useState("");
  const [deliveryLocation, setdeliveryLocation] = useState("");
  const [boxProductDiscount, setboxProductDiscount] = useState("");
  const [schemeboxProductDiscount, setschemeboxProductDiscount] = useState("0");
  const [kgProductDiscount, setkgProductDiscount] = useState("");
  const [schemekgProductDiscount, setschemekgProductDiscount] = useState("0");
  const [corporaetProductDiscount, setcorporaetProductDiscount] = useState("");
  const [schemecorporateProductDiscount, setschemecorporateProductDiscount] = useState("0");
  const [cookerProductDiscount, setcookerProductDiscount] = useState("");
  const [schemecookerProductDiscount, setschemecookerProductDiscount] = useState("0");
  const [noshProductDiscount, setnoshProductDiscount] = useState("");
  const [schemenoshProductDisocunt, setschemenoshProductDisocunt] = useState("0");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = "font-size 2s";
    element.style.fontSize = "30px";
    element.style.fontWeight = "600";
    // font-weight: 600;
    setTimeout(() => {
      document.getElementById("typer1").classList.add("promote");
      element.style.fontSize = "24px";
    }, 1000);
  };
  const id= useParams();
console.log(id)
  const [state, setState] = useState("");
  const [distributorName1, setdistributorName1] = useState("");
  const [distributorName2, setdistributorName2] = useState("");
  const [dob1, setdob1] = useState("");
  const [dob2, setdob2] = useState("");
  const [doa1, setdoa1] = useState("");
  const [doa2, setdoa2] = useState("");
  const [mobNo1, setmobNo1] = useState("");
  const [mobNo2, setmobNo2] = useState("");
  const [email1, setemail1] = useState("");
  const [email2, setemail2] = useState("");
  const [aseid, setaseid] = useState("");
  const [asmid, setasmid] = useState("");
  const [rsmid, setrsmid] = useState("");
  const [nsmid, setnsmid] = useState(0);
  const [zonesdata, setzonesdata] = useState([]);
  const [zonesvalue, setzonesvalue] = useState([]);
  const [rsm, setRsm] = useState([]);
  const [rsmvalue, setRsmvalue] = useState([]);
  const [nsm, setNsm] = useState([]);
  const [zonesid, setzonesid] = useState("");
  const [stateid, setstateid] = useState("");
  const [asm, setasm] = useState([]);
  const [asmvalue, setasmvalue] = useState([]);
  const [ase, setase] = useState([]);
  const [asevalue, setasevalue] = useState([]);
  const [error, setError] = useState(false);
  const [GstDetails, setGstdetails] = useState([]);
  const [colourtype, setcolourtype] = useState("");
  const [status, setstatus] = useState("");
  const [sendotp, setsendotp] = useState("");
  const [verifydone, setverifydone] = useState("");
  const [opttimeout, setopttimeout] = useState("");
  const [emailLoginStatus, setemailLoginStatus] = useState("Disable");
  /* Navigator */

  const [addresses, setAddresses] = useState([]); // Array of addresses

  const handleChange = (index, event) => {
    console.log(index, event);
    const newAddresses = [...addresses];
    newAddresses[index][event.target.name] = event.target.value;
    setAddresses(newAddresses);
  };
  const addAddress = () => {
    console.log(addAddress);
    setAddresses([...addresses, { delivery_address: "", state: "", pincode: "" }]);
  };
  const removeAddress = (index) => {
    const newAddresses = [...addresses];
    newAddresses.splice(index, 1);
    setAddresses(newAddresses);
  };
  const navigate = useNavigate();
  let Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handtype = (e) => {
    switch (e) {
      case "REG":
        setgstType("register");
        break;
      case "UNR":
        setgstType("unregister");
        break;
      case "COM":
        setgstType("composition");
        break;
      case "CON":
        setgstType("consumer");
        break;
      default:
        setgstType("unknown");
    }
  };
  function extractPAN(inputString) {
    console.log(inputString.slice(2, 12));
    setpanNumber(inputString.slice(2, 12));
  }
  function checksum(g) {
    let p;
    let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(g);
    if (regTest) {
      let a = 65,
        b = 55,
        c = 36;
      return Array["from"](g).reduce((i, j, k, g) => {
        p = (p = (j.charCodeAt(0) < a ? parseInt(j) : j.charCodeAt(0) - b) * ((k % 2) + 1)) > c ? 1 + (p - c) : p;
        return k < 14 ? i + p : j == ((c = c - (i % c)) < 10 ? c : String.fromCharCode(c + b));
      }, 0);
    }
    return regTest;
  }

  const getgstdetails = () => {
    if (checksum(gstNumber)) {
      let cap;
      axios
        .get(`http://43.225.54.138:8080/scm/gst/gstin/${gstNumber}`, { headers: authHeader() })
        .then((response) => {
          console.log(response.data);
          // setGstdetails(response.data)
          const gstTypeChar = gstNumber[5].toUpperCase();
          console.log(gstTypeChar);
          // settradeName(response.data.TradeName)
          let businessType;
          switch (gstTypeChar) {
            case "P":
              businessType = "Proprietor";
              settradeName(response.data.TradeName);
              setdistributorName1(response.data.LegalName);
              handtype(response.data.TxpType);
              extractPAN(gstNumber);

              break;
            case "F":
              businessType = "Firm";
              // setcompanyname(response.data.LegalName)
              settradeName(response.data.TradeName);
              setdistributorName1(response.data.LegalName);
              handtype(response.data.TxpType);
              extractPAN(gstNumber);

              break;
            case "C":
              businessType = "Company";
              // setcompanyname(response.data.LegalName)
              settradeName(response.data.TradeName);
              setdistributorName1(response.data.LegalName);
              handtype(response.data.TxpType);
              extractPAN(gstNumber);

              break;
            default:
              businessType = "Unknown type";
          }
          axios
            .get(
              `http://43.225.54.138:8080/scm/api/state
            `,
              { headers: authHeader() }
            )
            .then((response1) => {
              // setgststated(response1.data)
              //   setsname(response.data.name)
              let statename = response1.data
                .filter((item) => item.state_code == response.data.StateCode)
                .map((item) => item.name)
                .toString();
              console.log(response1.data);
              console.log(addresses.length);
              setbillingAddress(response.data.AddrBnm + " " + response.data.AddrBno + " " + response.data.AddrFlno + " " + response.data.AddrLoc + " " + response.data.AddrSt + " " + statename);

              if (addresses.length !== 0) {
                const updatedAddresses = addresses.map((item, index) => {
                  if (item.id === response.data.Gstin) {
                    return item;
                  } else {
                    const newValue = {
                      delivery_address: response.data.AddrBnm + " " + response.data.AddrBno + " " + response.data.AddrFlno + " " + response.data.AddrLoc + " " + response.data.AddrSt,
                      state: statename,
                      pincode: response.data.AddrPncd,
                      id: response.data.Gstin,
                    };
                    return newValue;
                  }
                });

                const indexOfItemToDelete = updatedAddresses.findIndex((item) => item.id === response.data.Gstin);
                if (indexOfItemToDelete !== -1) {
                  updatedAddresses.splice(indexOfItemToDelete, 1);
                }

                const newItem = {
                  delivery_address: response.data.AddrBnm + " " + response.data.AddrBno + " " + response.data.AddrFlno + " " + response.data.AddrLoc + "" + response.data.AddrSt,
                  state: statename,
                  pincode: response.data.AddrPncd,
                  id: response.data.Gstin,
                  id: response.data.Gstin,
                };
                updatedAddresses.push(newItem);

                setAddresses(updatedAddresses);
              } else {
                setAddresses([
                  ...addresses,
                  {
                    delivery_address: response.data.AddrBnm + " " + response.data.AddrBno + " " + response.data.AddrFlno + " " + response.data.AddrLoc + "" + response.data.AddrSt,
                    state: statename,
                    pincode: response.data.AddrPncd,
                    id: response.data.Gstin,
                  },
                ]);
              }

              console.log(cap);
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      alert("Entered Gst is Incorrect");
    }
  };

  // console.log(addresses)

  //-----------------------------------------------------------------------------
  const [groupn1Options, setGroupN1Options] = useState();
  const [groupn2Options, setGroupN2Options] = useState();
  const [groupn3Options, setGroupN3Options] = useState();
  const [groupn4Options, setGroupN4Options] = useState();
  const [groupn5Options, setGroupN5Options] = useState();
  const [group1Value, setGroup1Value] = useState();
  const [group2Value, setGroup2Value] = useState();
  const [group3Value, setGroup3Value] = useState();
  const [group4Value, setGroup4Value] = useState();
  const [group5Value, setGroup5Value] = useState();

  const [group1Search, setGroup1Search] = useState("");
  const [group2Search, setGroup2Search] = useState("");
  const [group3Search, setGroup3Search] = useState("");
  const [group4Search, setGroup4Search] = useState("");
  const [group5Search, setGroup5Search] = useState("");

  const [disabled1, setDisabled1] = useState(false);
  const [disabled2, setDisabled2] = useState(false);
  const [disabled3, setDisabled3] = useState(false);
  const [disabled4, setDisabled4] = useState(false);
  const [disabled5, setDisabled5] = useState(false);

  async function groupn1() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn1`, {
        headers: authHeader(),
      });

      setGroupN1Options(response.data.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn2() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn2/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group2Search}`, {
        headers: authHeader(),
      });

      setGroupN2Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn3() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn3/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group3Search}`, {
        headers: authHeader(),
      });

      setGroupN3Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn4() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn4/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group4Search}`, {
        headers: authHeader(),
      });

      setGroupN4Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn5() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn5/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group5Search}`, {
        headers: authHeader(),
      });

      setGroupN5Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    groupn1();
    groupn2();
    groupn3();
    groupn4();
    groupn5();
  }, []);

  const setDisabledFunctions = [setDisabled1, setDisabled2, setDisabled3, setDisabled4, setDisabled5];

  function handleDisabledConditions(groupValue) {
    if (!groupValue) {
      return;
    }
    console.log(groupValue);

    // Reset all disabled states to false
    setDisabledFunctions.forEach((setDisabled) => setDisabled(false));

    // Determine the index of the selected group (1-indexed)
    const groupIndex = parseInt(groupValue.replace("group", ""));

    // Disable all previous groups and all groups after the next one
    for (let i = 0; i < setDisabledFunctions.length; i++) {
      if (i < groupIndex - 1) {
        setDisabledFunctions[i](true); // Disable previous groups
      } else if (i > groupIndex) {
        setDisabledFunctions[i](true); // Disable subsequent groups
      }
    }

    // Handle specific group logic
    if (groupValue === "group2") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
    }

    if (groupValue === "group3") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2Value || group2Value?.value?.length === 0 || group2Value?.value === "neelam") {
        setDisabled2(true);
      }
    }

    if (groupValue === "group4") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2Value || group2Value?.value?.length === 0 || group2Value?.value === "neelam") {
        setDisabled2(true);
      }
      if (!group3Value || group3Value?.value?.length === 0 || group3Value?.value === "neelam") {
        setDisabled3(true);
      }
    }

    if (groupValue === "group5") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2Value || group2Value?.value?.length === 0 || group2Value?.value === "neelam") {
        setDisabled2(true);
      }
      if (!group3Value || group3Value?.value?.length === 0 || group3Value?.value === "neelam") {
        setDisabled3(true);
      }
      if (!group4Value || group4Value?.value?.length === 0 || group4Value?.value === "neelam") {
        setDisabled4(true);
      }
    }
  }

  function changeHandler(e, element) {
    console.log(e);

    if (element === "group1") {
      setGroup1Value(e);
    } else if (element === "group2") {
      setGroup2Value(e);
    } else if (element === "group3") {
      setGroup3Value(e);
    } else if (element === "group4") {
      setGroup4Value(e);
    } else if (element === "group5") {
      setGroup5Value(e);
    }

    handleDisabledConditions(element);
  }

  useEffect(() => {
    // getgstdetails();
    /* Zone data */
    UserService.getZoneData().then((res) => {
      let zone = res.data;
      setzonesdata([{ value: "NAN", label: "NA" }, ...zone.map((user) => ({ value: user.id, label: user.zoneName }))]);
      setzonesvalue(res.data);
    });

    /* NSM Data */
    UserService.getNSMData().then((res) => {
      setNsm([{ value: 0, label: "NA" }, ...res.data.map((user) => ({ value: user.id, label: user.staffName }))]);
    });
  }, []);

  const setbillingtodelivery = (e) => {
    let name = e.target.value;

    setAddresses([{ delivery_address: name }]);
  };
  /* Zone Change */
  const getstatecodedata = (e) => {
    let name = e.value;

    setzonesid(name);
    setStatedatavalue("");
    setRsmvalue("");
    setasmvalue("");
    setasevalue("");
    setStatedata("");
    setRsm("");
    setasm("");
    setase("");
    /* State Data */
    zonesvalue.map((user) => {
      if (user.id == name) {
        setStatedata([{ value: "NAN", label: "NA" }, ...user.state_zone.map((item) => ({ value: item.id, label: item.state_name }))]);
      }
    });

    /* RSM Data */
    UserService.getRSMData(e.value).then((res) => {
      setRsm([{ value: "NAN", label: "NA" }, ...res.data.map((user) => ({ value: user.id, label: user.staffName }))]);
    });
  };

  /* State Change */

  const onSelect = (e) => {
    setstateid(e.value);
    setStatedatavalue({ value: e.value, label: e.label });
    DistrbutorService.getstaffforret(e.value).then((res) => {
      // setDistributordata(res.data);

      setasm([res.data.filter((item) => item.rolename == "ROLE_ASM").map((user) => ({ value: user.id, label: user.staff_name }))]);
      setRsm([res.data.filter((item) => item.rolename == "ROLE_RSM").map((user) => ({ value: user.id, label: user.staff_name }))]);
      setNsm([res.data.filter((item) => item.rolename == "ROLE_NSM").map((user) => ({ value: user.id, label: user.staff_name }))]);

      // setNsm
      setase([res.data.filter((item) => item.rolename == "ROLE_ASE").map((user) => ({ value: user.id, label: user.staff_name }))]);
      console.log([res.data.filter((item) => item.rolename == "ROLE_ASE").map((user) => ({ value: user.id, label: user.staff_name }))]);
    });
  };

  /* NSM Change */
  // const onNsm = (e) => {
  //     setnsmid(e.value);
  // };
  console.log(asm);
  // /* RSM Change */
  // const onRsm = (e) => {

  //     let name = e.value;
  //     setrsmid(e.value);
  //     setasmvalue('');
  //     setasevalue('');
  //     setasm('');
  //     setase('');
  //     setRsmvalue({ value: (e.value), label: (e.label) });
  //     /* ASM Data */
  //     UserService.getASMData(name).then(res => {
  //         setasm([{ value: "NAN", label: "NA" }, ...(res.data).map(user => ({ value: user.id, label: user.staffName }))])
  //     });

  //     /* ASE Data */
  //     UserService.getASEData1(name).then(res => {
  //         setase([{ value: "NAN", label: "NA" }, ...(res.data).map(user => ({ value: user.id, label: user.staffName }))])
  //     });

  // };

  // /* ASM Change */
  // const onAsm = (e) => {
  //     setasmid(e.value);
  //     setasevalue('');
  //     setase('');
  //     setasmvalue({ value: (e.value), label: (e.label) });

  //     /* ASE Data */
  //     UserService.getASEData(e.value).then(res => {
  //         setase([{ value: "NAN", label: "NA" }, ...(res.data).map(user => ({ value: user.id, label: user.staffName }))])
  //     });

  // };

  // /* ASE Change */
  // const onAse = (e) => {
  //     setaseid(e.value);
  //     setasevalue({ value: (e.value), label: (e.label) })
  // };
  const onNsm = (e) => {
    // setnsmid(e.map((item)=>item.value));
    setnsmid(e.map((item) => ({ value: item.value, label: item.label })));
  };

  /* RSM Change */
  const onRsm = (e) => {
    // setrsmid(e.map((item)=>item.value));
    setrsmid(e.map((item) => ({ value: item.value, label: item.label })));
  };

  /* ASM Change */
  const onAsm = (e) => {
    // setase('');
    // setasmid(e.map((item)=>item.value));
    setasmid(e.map((item) => ({ value: item.value, label: item.label })));

    // setasevalue('');
    // setasmvalue({ value: (e.value), label: (e.label) });
    /* ASE Data */
    // UserService.getASEData(e.value).then(res => {
    //     setase([{ value: "NAN", label: "NA" }, ...(res.data).map(user => ({ value: user.id, label: user.staffName }))])
    // });
  };

  /* ASE Chnage */
  const onAse = (e) => {
    // setaseid(e.map((item)=>item.value));
    setaseid(e.map((item) => ({ value: item.value, label: item.label })));

    console.log(e.map((item) => item.value));
    // setasevalue({ value: (e.value), label: (e.label) })
  };
  /* Check Box same as billing Address */
  const [sameDeliveryAdd, setsameDeliveryAdd] = useState("");
  const [isChecked, setisChecked] = useState(false);
  const onCheck = (e) => {
    setsameDeliveryAdd(e);
    setisChecked(!isChecked);
    setbillingAddress(e);
  };

  const smart = (value) => {
    // console.log(billingAddress)
    const add = value;
    if (add == deliveryAddress) {
      const checkbox1 = document.getElementById("neway");
      checkbox1.checked = true;
      setisChecked(true);
      setsameDeliveryAdd(value);
      setbillingAddress(value);
    } else {
      const checkbox1 = document.getElementById("neway");
      checkbox1.checked = false;
      setisChecked(false);
    }
  };
  const deladd = (value) => {
    let add = value;
    if (add == billingAddress) {
      const checkbox1 = document.getElementById("neway");
      checkbox1.checked = true;
      setisChecked(true);
      setsameDeliveryAdd(value);
      setbillingAddress(value);
    }
  };

  const sendemail = (e) => {
    axios
      .post(
        BASE_URL + "auth/sendverificationcode/otp",
        { email: e },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        setstatus(res.data);
        setverifydone("");
        setopttimeout("");
      });
  };

  const verifyemail = (e, f) => {
    let dataverify = { email: e, otp: f };

    axios
      .post(BASE_URL + "auth/verifyEmailWithOtp", dataverify, {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log('mello',res.data)
        if (res.data == "invalid email or otp") {
          alert("Invalid OTP! Please Enter Valid OTP");
        } else if (res.data == "time limit exceed !!! please generate new otp .") {
          alert("Your OTP has been Expired! , Please Generate New OTP");
          setopttimeout(res.data);
        } else {
          setverifydone(res.data);
        }
      });
  };

  const SubmitForm = () => {
    const caddresses = addresses.map((address) => {
      const { id, ...rest } = address;
      return rest;
    });
    const country = countryoptions.value;
    let roles = [{ id: role }];
    const distributorToStaffs = [
      {
        asmid: asmid.length > 0 ? asmid.map((item) => item.value) : [],
        rsmid: rsmid.length > 0 ? rsmid.map((item) => item.value) : [],
        aseid: aseid.length > 0 ? aseid.map((item) => item.value) : [],
        nsmid: nsmid.length > 0 ? nsmid.map((item) => item.value) : [],
      },
    ];
    const reatiler = {
      tradeName,
      transporterName,
      billingAddress,
      deliveryAddress: caddresses,
      gstNumber,
      panNumber,
      country,
      zonesid,
      stateid,
      city,
      gstType,
      pinCode,
      perMobileNumber,
      alterMobileNumber,
      perEmail,
      latitude,
      longitude,
      alterEmail,
      distributorToStaffs,
      creditLimit,
      creditDays,
      deliveryLocation,
      boxProductDiscount,
      schemeboxProductDiscount,
      kgProductDiscount,
      schemekgProductDiscount,
      corporaetProductDiscount,
      schemecorporateProductDiscount,
      cookerProductDiscount,
      schemecookerProductDiscount,
      noshProductDiscount,
      schemenoshProductDisocunt,
      distributorName1,
      distributorName2,
      dob1,
      dob2,
      doa1,
      doa2,
      mobNo1,
      mobNo2,
      email1,
      email2,

      // aseid,
      // asmid,
      // rsmid,
      // nsmid,
      password,
      roles,
      aadharcard,
      colourtype,
      emailLoginStatus,
      ...(group1Value?.value ? { groupn1: { id: group1Value.value } } : {}),
      ...(group2Value?.value ? { groupn2: { id: group2Value.value } } : {}),
      ...(group3Value?.value ? { groupn3: { id: group3Value.value } } : {}),
      ...(group4Value?.value ? { groupn4: { id: group4Value.value } } : {}),
      ...(group5Value?.value ? { groupn5: { id: group5Value.value } } : {}),
    };
    setIsLoading(true);

    // console.log(reatiler);
    DistrbutorService.getReatilerCreate(reatiler)
      .then((res) => {
        // navigate("/Newdis/1");
        navigate(`/distrbutorcreate/${res?.data?.id}`)

        if (res.status == 201 || res.status == 200) {
          // alert("ee")
          Toast.fire({
            icon: "success",
            title: "Successfully added",
          });
        }
      })
      .catch((error) => {
        console.error("Error adding  :", error);
        if (error.message.includes("Network Error") || error.message.includes("Failed to fetch")) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
            didOpen: () => {
              applyFontSizeTransition("issueText");
            },
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const Validations = () => {
    if (
      tradeName.length !== 0 &&
      emailLoginStatus !== "" &&
      billingAddress.length !== 0 &&
      gstNumber.length !== 0 &&
      zonesid.length !== 0 &&
      stateid.length !== 0 &&
      city.length !== 0 &&
      pinCode.length !== 0 &&
      gstType.length !== 0 &&
      perMobileNumber.length === 10 &&
      perEmail.length !== 0 &&
      creditLimit.length !== 0 &&
      creditDays.length !== 0 &&
      distributorName1.length !== 0 &&
      dob1.length !== 0 &&
      mobNo1.length === 10 &&
      email1.length !== 0 &&
      password.length !== 0 &&
      role.length !== 0
    ) {
      // alert("filled")
      setError(false);
      SubmitForm();
    } else {
      // alert("fill out")

      setError(true);
      // console.log("first")
    }
  };
 
  return (
     
    <>
   {id.id==0?<MDBCard>   <div className="d-flex bd-highlight">
        <div className="card-header card-title w-100">ADD DISTRIBUTOR</div>
        <Link className="p-2 w-200 card-header card-title bd-highlight" to={"/Newdis/1"}>
          <Button>Back</Button>
        </Link>
      </div>

      <Form className="product1">
        <Row className="mb-1">
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Trade Name*</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              style={{ borderColor: error && tradeName.length == 0 ? "red" : "" }}
              value={tradeName}
              onChange={(e) => settradeName(e.target.value)}
              placeholder="Enter Trade Name"
            />
            {error && tradeName.length === 0 ? <p style={{ color: error && tradeName.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Billing Address</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              value={billingAddress}
              name="billingAddress"
              onChange={(e) => {
                setbillingAddress(e.target.value);
                setbillingtodelivery(e);
              }}
              placeholder="Enter Billing Address"
            />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">GST No*</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              style={{ borderColor: error && gstNumber.length == 0 ? "red" : "" }}
              onChange={(e) => setgstNumber(e.target.value)}
              placeholder="Enter GST NO"
            />
            {error && gstNumber.length === 0 ? <p style={{ color: error && gstNumber.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>

          <a className="btn btn-sm ertere" onClick={getgstdetails}>
            Get Gst Details
          </a>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">PAN Number</Form.Label>
            <Form.Control type="text" value={panNumber} autoComplete="off" onChange={(e) => setpanNumber(e.target.value)} placeholder="Enter PAN Number" />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Aadhar Card Number</Form.Label>
            <Form.Control type="text" autoComplete="off" onChange={(e) => setaadharcard(e.target.value)} placeholder="Enter  Aadhar card  Number" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Country</Form.Label>
            <Select value={countryoptions} />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Zone*</Form.Label>
            <Select
              options={zonesdata}
              style={{ borderColor: error && zonesid.length == 0 ? "red" : "" }}
              onChange={(e) => {
                getstatecodedata(e);
              }}
            />
            {error && zonesid.length === 0 ? <p style={{ color: error && zonesid.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">State*</Form.Label>
            <Select
              value={statedatavalue}
              options={statedata}
              style={{ borderColor: error && stateid.length == 0 ? "red" : "" }}
              onChange={(e) => {
                onSelect(e);
              }}
            />
            {error && stateid.length === 0 ? <p style={{ color: error && stateid.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">City*</Form.Label>
            <Form.Control type="text" autoComplete="off" style={{ borderColor: error && city.length == 0 ? "red" : "" }} onChange={(e) => setcity(e.target.value)} placeholder="Enter City" />
            {error && city.length === 0 ? <p style={{ color: error && city.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Zip Code*</Form.Label>
            <Form.Control
              type="number"
              min="0"
              autoComplete="off"
              style={{ borderColor: error && pinCode.length == 0 ? "red" : "" }}
              onChange={(e) => setpinCode(e.target.value)}
              placeholder="Enter Zip Code"
            />
            {error && pinCode.length === 0 ? <p style={{ color: error && pinCode.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Distributor Type</Form.Label>
            <Form.Select onChange={(e) => setcolourtype(e.target.value)}>
              <option>--Select--</option>
              <option value="bluedistributor">Blue Distributor</option>
              <option value="whitedistributor">White Distributor</option>
              <option value="orangedistributor">Orange Distributor</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">GST Type</Form.Label>
            <Form.Select value={gstType} style={{ borderColor: error && gstType.length == 0 ? "red" : "" }} onChange={(e) => setgstType(e.target.value)}>
              <option>Select</option>
              <option value="unregister">UnRegister</option>
              <option value="register">Register</option>
              <option value="composition">Composition</option>
              <option value="consumer">Consumer</option>
            </Form.Select>
            {error && gstType.length === 0 ? <p style={{ color: error && gstType.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Mobile No*</Form.Label>
            <Form.Control
              type="number"
              min="0"
              style={{ borderColor: error && perMobileNumber.length !== 10 ? "red" : "" }}
              autoComplete="off"
              onChange={(e) => setperMobileNumber(e.target.value)}
              placeholder="Enter Mobile Number"
            />
            {error && perMobileNumber.length !== 10 ? <p style={{ color: error && perMobileNumber.length !== 10 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Alter Mobile No</Form.Label>
            <Form.Control type="number" min="0" autoComplete="off" onChange={(e) => setalterMobileNumber(e.target.value)} placeholder="Enter Alter Mobile Number" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">OTP Login</Form.Label>
            <Form.Select value={emailLoginStatus} name="emailLoginStatus" onChange={(e) => setemailLoginStatus(e.target.value)}>
              <option>--Select--</option>
              <option value={"Enable"}>Enable</option>
              <option value={"Disable"}>Disable</option>
            </Form.Select>
          </Form.Group>
          {emailLoginStatus === "Disable" ? (
            <>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label className="cname2">Email*</Form.Label>
                <Form.Control
                  type="email"
                  autoComplete="off"
                  style={{ borderColor: error && perEmail.length === 0 ? "red" : "" }}
                  onChange={(e) => setperEmail(e.target.value)}
                  placeholder="Enter Email"
                />
                {error && perMobileNumber.length === 0 ? <p style={{ color: error && perMobileNumber.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
              </Form.Group>
            </>
          ) : (
            <>
              <Form.Group as={Col} md="4" controlId="formBasicName">
                <Form.Label className="cname2">Email*</Form.Label>
                <Form.Control
                  type="email"
                  autoComplete="off"
                  disabled={verifydone == "" ? false : opttimeout == "" ? true : false}
                  style={{ borderColor: error && perEmail.length === 0 ? "red" : "" }}
                  onChange={(e) => setperEmail(e.target.value)}
                  placeholder="Enter Email"
                />
                {error && perMobileNumber.length === 0 ? <p style={{ color: error && perMobileNumber.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
              </Form.Group>
              {verifydone == "" ? (
                <Form.Group as={Col} md="2" controlId="formBasicName">
                  <Form.Label className="cname2"></Form.Label>
                  <div style={{ marginTop: "27px" }}>
                    <span
                      style={{ color: "white", border: "0.5px solid #ededed", padding: "6px", background: "rgb(43 177 39)", borderRadius: "6px", cursor: "pointer" }}
                      onClick={() => sendemail(perEmail)}
                    >
                      OTP&nbsp;Generate
                    </span>
                  </div>
                  {/* <Button onClick={() => sendemail(perEmail)} style={{marginTop:'34px'}}>OTP Generate</Button> */}
                </Form.Group>
              ) : opttimeout == "" ? (
                <Form.Group as={Col} md="1" controlId="formBasicName">
                  <Form.Label className="cname2"></Form.Label>
                  <span class="snackbar_circle">
                    <span class="checkmark">L</span>
                  </span>
                </Form.Group>
              ) : (
                <Form.Group as={Col} md="1" controlId="formBasicName">
                  <Form.Label className="cname2"></Form.Label>
                  {/* <Button onClick={() => sendemail(perEmail)} style={{marginTop:'34px'}}>OTP Generate</Button> */}
                  <div style={{ marginTop: "27px" }}>
                    <span
                      style={{ color: "white", border: "0.5px solid #ededed", padding: "6px", background: "rgb(43 177 39)", borderRadius: "6px", cursor: "pointer" }}
                      onClick={() => sendemail(perEmail)}
                    >
                      OTP&nbsp;Generate
                    </span>
                  </div>
                </Form.Group>
              )}
              {status !== "" ? (
                verifydone == "" && opttimeout == "" ? (
                  <Row>
                    <Form.Group as={Col} md="1" controlId="formBasicName">
                      <Form.Label className="cname2">Verification Code*</Form.Label>
                      <Form.Control type="text" autoComplete="off" onChange={(e) => setsendotp(e.target.value)} placeholder="Enter OTP" />
                    </Form.Group>

                    <Form.Group as={Col} md="1" controlId="formBasicName">
                      <Form.Label className="cname2"></Form.Label>
                      <Button onClick={() => verifyemail(perEmail, sendotp)}>Verify</Button>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="formBasicName"></Form.Group>
                  </Row>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </>
          )}
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Alter Email</Form.Label>
            <Form.Control type="email" autoComplete="off" onChange={(e) => setalterEmail(e.target.value)} placeholder="Enter Alter Email" />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Credit Limit*</Form.Label>
            <Form.Control
              type="number"
              min="0"
              autoComplete="off"
              style={{ borderColor: error && creditLimit.length === 0 ? "red" : "" }}
              onChange={(e) => setcreditLimit(e.target.value)}
              placeholder="Enter Credit Limit"
            />
            {error && creditLimit.length === 0 ? <p style={{ color: error && creditLimit.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Credit Days*</Form.Label>
            <Form.Control
              type="number"
              min="0"
              autoComplete="off"
              style={{ borderColor: error && creditDays.length === 0 ? "red" : "" }}
              onChange={(e) => setcreditDays(e.target.value)}
              placeholder="Enter Credit Days"
            />
            {error && creditDays.length === 0 ? <p style={{ color: error && creditDays.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Transporter Name</Form.Label>
            <Form.Control type="text" autoComplete="off" onChange={(e) => settransporterName(e.target.value)} placeholder="Enter Transporter Name" />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Delivery Location*</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              style={{ borderColor: error && deliveryLocation.length === 0 ? "red" : "" }}
              onChange={(e) => setdeliveryLocation(e.target.value)}
              placeholder="Enter Delivery Location"
            />
            {error && deliveryLocation.length === 0 ? <p style={{ color: error && deliveryLocation.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>

          <h3></h3>

          <Row>
            <Form.Group as={Col} md="4" controlId="formBasicName">
              <br></br>
              <Form.Label className="cname2">Group-1</Form.Label>
              <Select value={group1Value} isDisabled={disabled1} options={groupn1Options} onChange={(e) => changeHandler(e, "group1")} onInputChange={(e) => setGroup1Search(e)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <br></br>
              <Form.Label className="cname2">Group-2</Form.Label>
              <Select value={group2Value} isDisabled={disabled2} options={groupn2Options} onChange={(e) => changeHandler(e, "group2")} onInputChange={(e) => setGroup2Search(e)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <br></br>
              <Form.Label className="label">Group-3</Form.Label>
              <Select value={group3Value} isDisabled={disabled3} options={groupn3Options} onChange={(e) => changeHandler(e, "group3")} onInputChange={(e) => setGroup3Search(e)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="cname2">Group-4</Form.Label>
              <Select value={group4Value} isDisabled={disabled4} options={groupn4Options} onChange={(e) => changeHandler(e, "group4")} onInputChange={(e) => setGroup4Search(e)} />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="cname2">Group-5</Form.Label>
              <Select value={group5Value} isDisabled={disabled5} options={groupn5Options} onChange={(e) => changeHandler(e, "group5")} onInputChange={(e) => setGroup5Search(e)} />
            </Form.Group>
          </Row>

          <h3></h3>

          <Form.Group as={Col} md="3" controlId="formBasicName">
            <Form.Label className="cname2">Box&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
            <Form.Control type="number" min="0" autoComplete="off" onChange={(e) => setboxProductDiscount(e.target.value)} placeholder="Enter Discount" />
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="formBasicName">
            <Form.Label className="cname2">Box&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
            <Form.Control type="number" min="0" autoComplete="off" onChange={(e) => setschemeboxProductDiscount(e.target.value)} placeholder="Enter Discount" />
          </Form.Group>

          <Form.Group as={Col} md="3" controlId="formBasicName">
            <Form.Label className="cname2">KG&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
            <Form.Control type="number" min="0" autoComplete="off" onChange={(e) => setkgProductDiscount(e.target.value)} placeholder="Enter Discount" />
          </Form.Group>

          <Form.Group as={Col} md="3" controlId="formBasicName">
            <Form.Label className="cname2">KG&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
            <Form.Control type="number" min="0" autoComplete="off" onChange={(e) => setschemekgProductDiscount(e.target.value)} placeholder="Enter Discount" />
          </Form.Group>

          <Form.Group as={Col} md="3" controlId="formBasicName">
            <Form.Label className="cname2">Corporate&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
            <Form.Control type="number" min="0" autoComplete="off" onChange={(e) => setcorporaetProductDiscount(e.target.value)} placeholder="Enter Discount" />
          </Form.Group>

          <Form.Group as={Col} md="3" controlId="formBasicName">
            <Form.Label className="cname2">Corporate&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
            <Form.Control type="number" min="0" autoComplete="off" onChange={(e) => setschemecorporateProductDiscount(e.target.value)} placeholder="Enter Discount" />
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="formBasicName">
            <Form.Label className="cname2">Cooker&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
            <Form.Control type="number" min="0" autoComplete="off" onChange={(e) => setcookerProductDiscount(e.target.value)} placeholder="Enter Discount" />
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="formBasicName">
            <Form.Label className="cname2">Cooker&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
            <Form.Control type="number" min="0" autoComplete="off" onChange={(e) => setschemecookerProductDiscount(e.target.value)} placeholder="Enter Discount" />
          </Form.Group>

          <Form.Group as={Col} md="3" controlId="formBasicName">
            <Form.Label className="cname2">NOSH&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
            <Form.Control type="number" min="0" autoComplete="off" onChange={(e) => setnoshProductDiscount(e.target.value)} placeholder="Enter Discount" />
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="formBasicName">
            <Form.Label className="cname2">NOSH&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
            <Form.Control type="number" min="0" autoComplete="off" onChange={(e) => setschemenoshProductDisocunt(e.target.value)} placeholder="Enter Discount" />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Distributor Name&nbsp;1*</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              style={{ borderColor: error && distributorName1.length === 0 ? "red" : "" }}
              value={distributorName1}
              onChange={(e) => setdistributorName1(e.target.value)}
              placeholder="Enter Name"
            />
            {error && distributorName1.length === 0 ? <p style={{ color: error && distributorName1.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Distributor Name&nbsp;2</Form.Label>
            <Form.Control type="text" autoComplete="off" onChange={(e) => setdistributorName2(e.target.value)} placeholder="Enter Name" />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Date&nbsp;of&nbsp;Birth&nbsp;1*</Form.Label>
            <Form.Control type="date" style={{ borderColor: error && dob1.length === 0 ? "red" : "" }} onChange={(e) => setdob1(e.target.value)} />
            {error && dob1.length === 0 ? <p style={{ color: error && dob1.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Date&nbsp;of&nbsp;Birth&nbsp;2</Form.Label>
            <Form.Control type="date" onChange={(e) => setdob2(e.target.value)} />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Date&nbsp;of&nbsp;Anniversary&nbsp;1</Form.Label>
            <Form.Control type="date" onChange={(e) => setdoa1(e.target.value)} />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Date&nbsp;of&nbsp;Anniversary&nbsp;2</Form.Label>
            <Form.Control type="date" onChange={(e) => setdoa2(e.target.value)} />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Mobile No.&nbsp;1*</Form.Label>
            <Form.Control
              type="number"
              min="0"
              style={{ borderColor: error && mobNo1.length !== 10 ? "red" : "" }}
              autoComplete="off"
              onChange={(e) => setmobNo1(e.target.value)}
              placeholder="Enter Mobile Number"
            />
            {error && mobNo1.length !== 10 ? <p style={{ color: error && mobNo1.length !== 10 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Mobile No.&nbsp;2</Form.Label>
            <Form.Control type="number" min="0" autoComplete="off" onChange={(e) => setmobNo2(e.target.value)} placeholder="Enter Mobile Number" />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Email&nbsp;1*</Form.Label>
            <Form.Control type="email" autoComplete="off" style={{ borderColor: error && email1.length === 0 ? "red" : "" }} onChange={(e) => setemail1(e.target.value)} placeholder="Enter Email" />
            {error && email1.length === 0 ? <p style={{ color: error && email1.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Email&nbsp;2</Form.Label>
            <Form.Control type="email" autoComplete="off" onChange={(e) => setemail2(e.target.value)} placeholder="Enter Email" />
          </Form.Group>
          <Form.Group as={Col} md="6" className="ert1" controlId="formBasicName">
            <Form.Label className="cname2">NSM</Form.Label>
            <Select
              options={nsm[0]}
              value={nsmid}
              isMulti
              onChange={(e) => {
                onNsm(e);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" className="ert2" controlId="formBasicName">
            <Form.Label className="cname2">RSM</Form.Label>
            <Select
              isMulti
              options={rsm[0]}
              value={rsmid}
              onChange={(e) => {
                onRsm(e);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" className="ert1" controlId="formBasicName">
            <Form.Label className="cname2">ASM</Form.Label>
            <Select
              isMulti
              options={asm[0]}
              value={asmid}
              onChange={(e) => {
                onAsm(e);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" className="ert2" controlId="formBasicName">
            <Form.Label className="cname2">ASE</Form.Label>
            <Select
              options={ase[0]}
              isMulti
              value={aseid}
              onChange={(e) => {
                onAse(e);
              }}
            />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Password*</Form.Label>
            <Form.Control
              type="text"
              style={{ borderColor: error && password.length === 0 ? "red" : "" }}
              autoComplete="off"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter Password"
            />
            {error && password.length === 0 ? <p style={{ color: error && password.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
          </Form.Group>
          <div className="row">
            <div className="col">
              <label className="form-label">Latitude</label>
              <input
                type="text"
                onChange={(e) => {
                  setLatitude(e.target.value);
                }}
                className="form-control"
              />
            </div>
            <div className="col">
              <label className="form-label">Longitude</label>
              <input
                type="text"
                onChange={(e) => {
                  setLongitude(e.target.value);
                }}
                className="form-control"
              />
            </div>
          </div>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Roles*</Form.Label>
            <Form.Select style={{ borderColor: error && role.length === 0 ? "red" : "" }} onChange={(e) => setRole(e.target.value)}>
              <option>Select</option>
              <option value="8">Distributor</option>
              {error && role.length === 0 ? <p style={{ color: error && role.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
            </Form.Select>
          </Form.Group>

          <div className="row">
            <div className="col-6 card">
              <label className="form-label">Adresss</label>
              {addresses.map((address, index) => (
                <div key={index}>
                  {/* <label>Address</label> */}
                  <textarea type="text" name="delivery_address" className="form-control" value={address.delivery_address} onChange={(e) => handleChange(index, e)}></textarea>
                  <label className="form-label">Statename</label>
                  <input type="text" value={address.state} name="state" className="form-control" />

                  <label className="form-label">Pin-Code</label>
                  <input type="text" value={address.pincode} name="pincode" className="form-control" />
                  <button type="button" className="btn btn-sm" onClick={() => removeAddress(index)}>
                    x
                  </button>
                </div>
              ))}
              <div className="row">
                <div className="col">
                  <button type="button" className="btn btn-sm  btm-primary" onClick={addAddress}>
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Row>

        <Button variant="primary" style={{ width: "auto" }} onClick={Validations} disabled={isLoading}>
          {isLoading ? "Loading..." : "Submit"}
        </Button>
      </Form> </MDBCard>: <Profilestaffcom axiosdata='distributor' butvisble='false' userfirend='distributorid' steper='false' routerarea='/Newdis/1'/>
    }
    </>

  
  );

 
}
