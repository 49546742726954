import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import AuthService from "../../services/auth.service";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import { useNavigate, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import { faL } from "@fortawesome/free-solid-svg-icons";

const Updatedisopeningstock = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [disword, setDisword] = useState("");
  const [product2, setProduct2] = useState([]);
  const [distributor1, setDistributor1] = useState([]);
  const [userDate, setUserDate] = useState("");
  const [distRoles, setDistRoles] = useState("");
  const [disableKg, setdisableKg] = useState(false);
  const [disablePcs, setdisablePcs] = useState(false);
  const [items, setItems] = useState([]);
  const [datap, setDatap] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [user_date, setuser_date] = useState("");
  const [itemId, setitemId] = useState('')
  const [inwardDistributorStockItems, setinwardDistributorStockItems] =
    useState([]);
  const [distributor, setdistributor] = useState("");
  const [dist_name, setdist_name] = useState("");
  const [distItems, setdistItems] = useState("");
  const [quantity, setquantity] = useState("");
  const currentUser = AuthService.getCurrentUser();

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const getdistoutwarddata = () => {
    setIsLoadingData(true);
    axios
      .get(BASE_URL + "distributor/openingstock/get/" + id, {
        headers: authHeader(),
      })
      .then((res) => {
         console.log('mello',res.data)
        setUserDate(res.data.user_date);
        setdistributor(res.data.distributor.id);
        setitemId(res.data.id);
        // setinwardDistributorStockItems(res.data.inwardDistributorStockItems);
        // setdistItems(res.data.inwardDistributorStockItems[0].product.productName);
        // setquantity(res.data.inwardDistributorStockItems[0].inwardqty)
        setItems(
          res.data.distributorOpeningStockItems.map((item) => ({
            id: item.product.id,
            product_Name: item.product.productName,
            total: Number(item.amount).toFixed(2),
            uomSecondary:item.product.uomSecondary,
            qty: item.qty,
            rate: item.rate,
            kgqty: item.kgqty,
            disableKg: false,
            disablePcs: false,
          }))
        );
        let dist_id = res.data.distributor;
        setdist_name(res.data.distributor.tradeName);

        // axios.get(BASE_URL + 'distributor/getById/' + dist_id, { headers: authHeader() }).then(res1 => {
        //     // console.log('mello',res1.data);
        //     setdist_name(res.data.distributor.tradeName)
        // })
      

      })
      .catch((err) => console.log(err))
      .finally(() => {
     
        setIsLoadingData(false);
      });
  };

  const onchangeproductstore = (option) => {
  
    const newProduct = datap.find((item) => item.id === option.value);

    if (newProduct) {
      const isProductInList = items.some((item) => item.id === newProduct.id);

      if (!isProductInList) {
        setItems((prevItems) => [
          ...prevItems,
          { ...newProduct, qty: 0, total: 0, kgqty: 0, rate: newProduct.dlp ,uomSecondary:newProduct.uom_secondary, disableKg: false,
            disablePcs: false,},
        ]);
  
      }
    }
  };

  const handleQuantityChange = (id, qty) => {
    let row = items;

    setdisableKg(false);
    // if (qty === "" || qty == 0) {
    //   setdisableKg(false);
    // }
   setdisablePcs(false);

    setItems((prevItems) =>
      prevItems.map((item) =>
      {
        item.id === id &&setdisableKg(true);
        item.id === id &&setdisablePcs(false);
         (qty === "" || qty == 0) &&
            setdisableKg(false);
           
           
        return(  item.id === id
          ? {
              ...item,
              qty: qty,
              total: item.rate*qty ,
              disableKg: qty > 0, 
            disablePcs: false,
              kgqty: (qty * item.uomSecondary).toFixed(2),
            }
          : item)
      }
      )
    );
 
  };

  const handleQuantityKgChange = (id, kgqty) => {
    // setdisablePcs(true);
    // if (kgqty === "" || kgqty == 0) {
    //   setdisablePcs(false);
    // }
 
    // setdisableKg(false);
    setdisablePcs(false);
    setdisableKg(false);
    setItems((prevItems) =>
      prevItems.map((item) =>
       {
        item.id === id &&setdisablePcs(true);
        item.id === id &&setdisableKg(false);
         (kgqty === "" || kgqty == 0) &&
         setdisablePcs(false);
        return(
          item.id === id
          ? {
              ...item,
              kgqty: kgqty,
              total: kgqty * item.rate,
              disablePcs: kgqty > 0,
              disableKg: false, 
              qty: (kgqty / item.uomSecondary).toFixed(2),
            }
          : item
        )
       }
      )
    );
  };

  // const handleSubmit = () => {
  //     const postData = {
  //         indate: userDate,
  //         distributorid: distributor,
  //         inwardDistributorStockItems: items.map(item => ({
  //             product:{"id":item.id},
  //             amount: item.total.toFixed(2)  ,
  //             outwardqty:item.qty,
  //             rate:item.mrp
  //         })),
  //     };

  //     setIsLoading(true);

  //     axios.post(`${BASE_URL}indiststock/`, postData, { headers: authHeader() })
  //         .then(response => {
  //             Toast.fire({
  //                 icon: 'success',
  //                 title: 'Successfully added'
  //             });
  //         }).catch(error => {
  //             console.error('Error adding  :', error);
  //             if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
  //                 Swal.fire({
  //                     icon: "error",
  //                     title: "Oops...",
  //                     text: "Something went wrong!",
  //                     footer: `
  //                         <div>
  //                           <p id="issueText">Why do I have this issue?</p>
  //                           <ul>
  //                               <li id="typer1">You might have an internet issue</li>
  //                           </ul>
  //                         </div>
  //                     `,
  //                     didOpen: () => {
  //                         applyFontSizeTransition('issueText');
  //                     }
  //                 });
  //             }
  //         })
  //         .finally(() => {
  //             setIsLoading(false);
  //         });
  // };
  const handleSubmit = () => {

    const postData = {
       
      user_date: userDate,
      distributor: { id: distributor },
      distributorOpeningStockItems: items.map((item) => ({
        id:item.id,
        product: { id: item.id },
        amount: Number(item.total).toFixed(2),
        qty: item.qty,
        rate: item.rate,
        kgqty: item.kgqty,
        uomSecondary:item.uomSecondary
      })),
    };

   setIsLoading(true);

    axios
      .put(BASE_URL + "distributor/openingstock/put/" + id, postData, {
        headers: authHeader(),
      })
      .then((response) => {
        navigate("/OpenCreate/1");

        Toast.fire({
          icon: "success",
          title: "Successfully added",
        });
      })
      .catch((error) => {
        console.error("Error adding  :", error);
        if (
          error.message.includes("Network Error") ||
          error.message.includes("Failed to fetch")
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `
                            <div>
                              <p id="issueText">Why do I have this issue?</p>
                              <ul>
                                  <li id="typer1">You might have an internet issue</li>
                              </ul>
                            </div>
                        `,
            didOpen: () => {
              applyFontSizeTransition("issueText");
            },
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = "font-size 2s";
    element.style.fontSize = "30px";
    element.style.fontWeight = "600";
    setTimeout(() => {
      document.getElementById("typer1").classList.add("promote");
      element.style.fontSize = "24px";
    }, 1000);
  };

  const getDistributorByRoles = () => {
    // axios.get(`${BASE_URL}distributor/getById/${currentUser.id}`, { headers: authHeader() })
    //     .then(res => {
    //         setDistRoles(res.data.tradeName);
    //         setDistributor(res.data.id);
    //     });
  };
  useEffect(() => {
    getdistoutwarddata();
  }, []);

  useEffect(() => {
    if (currentUser.roles[0] === "ROLE_DISTRIBUTOR") {
      getDistributorByRoles();
    }

    axios
      .get(
        `${BASE_URL}product/page/0/10/asc/id/${
          error.length ? error : encodeURIComponent(" ")
        }`,
        { headers: authHeader() }
      )
      .then((response) => {
       
        setDatap(response.data.data || response.data.Index);
        const products = (response.data.data || response.data.Index).map(
          (use) => ({
            value: use.id,
            label: `${use.product_Name} - ${use.ean_Code}`,
          })
        );
        setProduct2(products);
  
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(
        `${BASE_URL}distributor/page/0/10/asc/id/${
          disword.length ? disword : encodeURIComponent(" ")
        }`,
        { headers: authHeader() }
      )
      .then((response) => {
        if (response.data.data == undefined) {
          setDistributor1(
            response.data.Index.map((use) => ({
              value: use.id,
              label: use.trade_Name,
            }))
          );
        }
        if (response.data.Index == undefined) {
          setDistributor1(
            response.data.data.map((use) => ({
              value: use.id,
              label: use.trade_Name,
            }))
          );
        }
      });
  }, [error, disword]);

  // const handledelete = () => {
  //     setIsLoading(true);

  //     axios.delete(`${BASE_URL}distributor/openingstock/get/${id}`, { headers: authHeader() })
  //         .then(response => {
  //             navigate('/OpenCreate/1')

  //             Toast.fire({
  //                 icon: 'success',
  //                 title: 'Successfully deleted'
  //             });
  //         })
  //         .catch(error => {
  //             console.error('Error deleting:', error);
  //             if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
  //                 Swal.fire({
  //                     icon: 'error',
  //                     title: 'Oops...',
  //                     text: 'Something went wrong!',
  //                     footer: `
  //                         <div>
  //                           <p id="issueText">Why do I have this issue?</p>
  //                           <ul>
  //                               <li id="typer1">You might have an internet issue</li>
  //                           </ul>
  //                         </div>
  //                     `,
  //                     didOpen: () => {
  //                         // Apply a custom function for font size transition if necessary
  //                         applyFontSizeTransition && applyFontSizeTransition('issueText');
  //                     }
  //                 });
  //             }
  //         })
  //         .finally(() => {
  //             setIsLoading(false); // End loading
  //         });
  // };

  const getItem = () => {
    // UserService.getprimaryorderdelete(id).then(res=>{
    //   window.location.reload();
    // })

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.getdisdelete(Number(id)).then((res) => {
          // window.location.reload();
          navigate(`/OpenCreate/1`);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        });
      }
    });
  };

  const handleback = () => {
    console.log("first");
    navigate("/OpenCreate/1");
  };

  return (
    <div className="card card-body">
      <div className="card-header card-title">
        <h2 style={{ fontSize: "16px", fontWeight: 700 }}>Update Distributor Opening Stock</h2>
      </div>
      <div className="row">
        <div className="col-1">
          <Button variant="danger" onClick={() => getItem()}>
            Delete
          </Button>
        </div>
        <div className="col-1">
          <button
            onClick={() => handleback()}
            className="btn btn-primary ml-3"
            style={{
              marginLeft: " 47px",
              padding: "16px 12px",
            }}
          >
            Back
          </button>
        </div>
      </div>
      {isLoadingData ? (
        <>
          <div className="d-flex jusify-content-center">
            <div className="spinner"></div>
          </div>
        </>
      ) : (
        <>
          <div className="row">
            <div className="col">
              <label className="form-label">Distributor</label>
              {/* {currentUser.roles[0] === 'ROLE_DISTRIBUTOR' ? (
                        <input type='text' className='form-control' value={distRoles} readOnly />
                    ) : ( */}
              <input
                type="text"
                value={dist_name}
                className="form-control"
                readOnly
              />

              {/* )} */}
            </div>
            <div className="col">
              <label className="form-label">Date</label>
              <input
                type="date"
                value={userDate}
                className="form-control"
                onChange={(e) => setUserDate(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label className="form-label">Product</label>
              <Select
                options={product2}
                onInputChange={(inputValue) => setError(inputValue)}
                onChange={(options) => onchangeproductstore(options)}
              />
            </div>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Qty(PCS)</th>
                <th>Qty(KG)</th>
                <th>DLP</th>
                <th>Total</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr key={item.id}>
                  <td width="200px">{item.product_Name}</td>
                  <td width="100px">
                    <input
                      type="number"
                    className="form-control"
                      value={item.qty}
                      disabled={item.disablePcs} 
                     // disabled={disablePcs && item.id ? true : false}
                     onChange={(e) =>
                      {
                       let value=e.target.value;
                       const regex = /^[0-9]*\.?[0-9]{0,2}$/;
                       if (regex.test(value)) {
                         handleQuantityChange(item.id, parseInt(e.target.value) || 0)
                       }
   
                       
                      
                      }
                     }
                    />
                  </td>
                  <td width="100px">
                    <input
                      type="number"
                      className="form-control"
                      value={item.kgqty}
                      disabled={item.disableKg}
                     // disabled={disableKg && item.id ? true : false}
                     onChange={(e) =>
                      {
                     let value=e.target.value;
                     const regex = /^[0-9]*\.?[0-9]{0,2}$/;
                     if (regex.test(value)) {
                       handleQuantityKgChange(
                         item.id,
                         parseFloat(e.target.value) || 0
                       )
                     }
                   
                      }
         
                       }
                    />
                  </td>
                  <td width="100px">{item.rate}</td>
                  <td width="100px">
                    <input
                      type="number"
                      className="form-control"
                      value={item.total}
                      readOnly
                    />
                  </td>
                  <td width="100px">
                    <Button
                      variant="danger"
                      onClick={() =>
                        setItems(items.filter((i) => i.id !== item.id))
                      }
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      <div className="row">
        <div className="col">
          <Button
            variant="primary"
            style={{ width: "auto" }}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Submit"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Updatedisopeningstock;
