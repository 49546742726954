import { MDBCard } from "mdbreact";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Row, Col, Table } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import Select from "react-select";
import Swal from "sweetalert2";

const CreateProjectionEntry = () => {
  const navigate = useNavigate();

  const [projectionEntryData, setProjectionEntryData] = useState({
    projectionentryitem: [],
  });
  const [loading, setLoading] = useState(false);

  const [groupn1Options, setGroupN1Options] = useState();
  const [groupn2Options, setGroupN2Options] = useState();
  const [groupn3Options, setGroupN3Options] = useState();
  const [groupn4Options, setGroupN4Options] = useState();
  const [groupn5Options, setGroupN5Options] = useState();
  const [productOptions, setProductOptions] = useState();

  const [group1Value, setGroup1Value] = useState();
  const [group2Value, setGroup2Value] = useState();
  const [group3Value, setGroup3Value] = useState();
  const [group4Value, setGroup4Value] = useState();
  const [group5Value, setGroup5Value] = useState();

  const [group1Search, setGroup1Search] = useState("");
  const [group2Search, setGroup2Search] = useState("");
  const [group3Search, setGroup3Search] = useState("");
  const [group4Search, setGroup4Search] = useState("");
  const [group5Search, setGroup5Search] = useState("");
  const [productSearch, setProductSearch] = useState(encodeURIComponent(" "));

  const [disabled1, setDisabled1] = useState(false);
  const [disabled2, setDisabled2] = useState(false);
  const [disabled3, setDisabled3] = useState(false);
  const [disabled4, setDisabled4] = useState(false);
  const [disabled5, setDisabled5] = useState(false);

  async function groupn1() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn1`, {
        headers: authHeader(),
      });

      setGroupN1Options(response.data.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn2() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn2/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group2Search}`, {
        headers: authHeader(),
      });

      setGroupN2Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn3() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn3/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group3Search}`, {
        headers: authHeader(),
      });

      setGroupN3Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn4() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn4/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group4Search}`, {
        headers: authHeader(),
      });

      setGroupN4Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn5() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn5/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group5Search}`, {
        headers: authHeader(),
      });

      setGroupN5Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function getProductData() {
    try {
      const response = await axios
        .get(`${BASE_URL}product/page/0/30/asc/id/${productSearch ? productSearch : encodeURIComponent(" ")}`, {
          headers: authHeader(),
        })
        .then((res) => {
          setProductOptions(res.data.Index.map((use) => ({ value: use.id, label: use.product_Name + " - " + use.ean_Code + " - " + use.short_Name })));
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }

  async function submitData(e) {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${BASE_URL}v1/projectionentry`, projectionEntryData, {
        headers: authHeader(),
      });

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Data has been successfully added!",
      });
      navigate(`/projectionentrytable/1`);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function handleProductChange(e) {
    try {
      const response = await axios.get(`${BASE_URL}product/getById/${e.value}`, {
        headers: authHeader(),
      });

      let row = {
        product: {
          id: response.data.id,
        },
        uom: response.data.producttype !== "kgproduct" ? "pcs" : "",
        product_name: response.data.productName + "-" + response.data.eanCode + "-" + response.data.shortName,
        qty: "",
        producttype: response.data.productType,
        uomsecondary: response.data.uomSecondary,
      };

      setProjectionEntryData({
        ...projectionEntryData,
        projectionentryitem: [...projectionEntryData.projectionentryitem, row],
      });
    } catch (error) {
      console.log(error);
    }
  }

  const setDisabledFunctions = [setDisabled1, setDisabled2, setDisabled3, setDisabled4, setDisabled5];

  function removeRow(id) {
    const filteredData = projectionEntryData.projectionentryitem.filter((_, index) => index !== id);
    console.log(filteredData);

    setProjectionEntryData({
      ...projectionEntryData,
      projectionentryitem: filteredData,
    });
  }

  function handleDisabledConditions(groupValue) {
    if (!groupValue) {
      return;
    }
    console.log(groupValue);

    // Reset all disabled states to false
    setDisabledFunctions.forEach((setDisabled) => setDisabled(false));

    // Determine the index of the selected group (1-indexed)
    const groupIndex = parseInt(groupValue.replace("groupn", ""));

    // Disable all previous groups and all groups after the next one
    // for (let i = 0; i < setDisabledFunctions.length; i++) {
    //   if (i < groupIndex - 1) {
    //     setDisabledFunctions[i](true); // Disable previous groups
    //   } else if (i > groupIndex) {
    //     setDisabledFunctions[i](true); // Disable subsequent groups
    //   }
    // }

    if (groupValue === "groupn1") {
      if (!group3Value || group3Value?.value?.length === 0 || group3Value?.value === "neelam") {
        setDisabled3(true);
      }
      if (!group4Value || group4Value?.value?.length === 0 || group4Value?.value === "neelam") {
        setDisabled4(true);
      }
      if (!group5Value || group5Value?.value?.length === 0 || group5Value?.value === "neelam") {
        setDisabled5(true);
      }
    }

    // Handle specific group logic
    if (groupValue === "groupn2") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }

      if (!group4Value || group4Value?.value?.length === 0 || group4Value?.value === "neelam") {
        setDisabled4(true);
      }
      if (!group5Value || group5Value?.value?.length === 0 || group5Value?.value === "neelam") {
        setDisabled5(true);
      }
    }

    if (groupValue === "groupn3") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2Value || group2Value?.value?.length === 0 || group2Value?.value === "neelam") {
        setDisabled2(true);
      }

      if (!group5Value || group5Value?.value?.length === 0 || group5Value?.value === "neelam") {
        setDisabled5(true);
      }
    }

    if (groupValue === "groupn4") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2Value || group2Value?.value?.length === 0 || group2Value?.value === "neelam") {
        setDisabled2(true);
      }
      if (!group3Value || group3Value?.value?.length === 0 || group3Value?.value === "neelam") {
        setDisabled3(true);
      }
    }

    if (groupValue === "groupn5") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2Value || group2Value?.value?.length === 0 || group2Value?.value === "neelam") {
        setDisabled2(true);
      }
      if (!group3Value || group3Value?.value?.length === 0 || group3Value?.value === "neelam") {
        setDisabled3(true);
      }
      if (!group4Value || group4Value?.value?.length === 0 || group4Value?.value === "neelam") {
        setDisabled4(true);
      }
    }
  }

  function changeHandler(e, element) {
    console.log(e);

    if (element) {
      setProjectionEntryData({
        ...projectionEntryData,
        [element]: {
          id: e.value,
        },
      });
    } else {
      const { name, value } = e.target;

      setProjectionEntryData({
        ...projectionEntryData,
        [name]: value,
      });
    }

    if (element === "groupn1") {
      setGroup1Value(e);
    } else if (element === "groupn2") {
      setGroup2Value(e);
    } else if (element === "groupn3") {
      setGroup3Value(e);
    } else if (element === "groupn4") {
      setGroup4Value(e);
    } else if (element === "groupn5") {
      setGroup5Value(e);
    }

    handleDisabledConditions(element);
  }

  function pcsHandler(e, index) {
    console.log(e.target.value);

    const newRow = projectionEntryData.projectionentryitem;

    newRow[index].qty = e.target.value;

    if (newRow[index].producttype === "kgproduct") {
      newRow[index].kgqty = Number((newRow[index].qty * newRow[index].uomsecondary).toFixed(3));
    }

    setProjectionEntryData({
      ...projectionEntryData,
      projectionentryitem: newRow,
    });
  }

  function kgHandler(e, index) {
    const newRow = projectionEntryData.projectionentryitem;

    newRow[index].kgqty = e.target.value;
    newRow[index].qty = Number((newRow[index].kgqty / newRow[index].uomsecondary).toFixed(1));

    setProjectionEntryData({
      ...projectionEntryData,
      projectionentryitem: newRow,
    });
  }

  function handleUom(e, index) {
    let rows = projectionEntryData.projectionentryitem;

    rows[index].uom = e.target.value;

    setProjectionEntryData({
      ...projectionEntryData,
      projectionentryitem: rows,
    });
  }

  function disableKgHandler(index) {
    console.log(projectionEntryData.projectionentryitem[index].uom);
    if (
      projectionEntryData.projectionentryitem[index].producttype === "kgproduct" &&
      (projectionEntryData.projectionentryitem[index].uom === "kg" || projectionEntryData.projectionentryitem[index].uom === "")
    ) {
      return false;
    }

    return true;
  }

  function disablePcsHandler(index) {
    if (projectionEntryData.projectionentryitem[index].uom === "pcs") {
      return false;
    }
    if (projectionEntryData.projectionentryitem[index].producttype !== "kgproduct") {
      return false;
    }
    return true;
  }

  useEffect(() => {
    groupn1();
    groupn2();
    groupn3();
    groupn4();
    groupn5();
    getProductData();
  }, []);

  useEffect(() => {
    getProductData();
  }, [productSearch]);

  console.log(projectionEntryData);

  return (
    <div>
      <MDBCard className="company">
        <h2 className="projection-header">Projection Report</h2>
        <Link className="p-2 w-200 card-header card-title bd-highlight projection-back-btn" to={"/projectionentrytable/1"}>
          <Button>Back</Button>
        </Link>

        <Form className="product1">
          <Row>
            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="label">
                Starting Date<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control type="date" autoComplete="off" value={projectionEntryData.projectionDate} name="projectionDate" onChange={(e) => changeHandler(e)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="cname2">Group-1</Form.Label>
              <Select value={group1Value} isDisabled={disabled1} options={groupn1Options} onChange={(e) => changeHandler(e, "groupn1")} onInputChange={(e) => setGroup1Search(e)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="cname2">Group-2</Form.Label>
              <Select value={group2Value} isDisabled={disabled2} options={groupn2Options} onChange={(e) => changeHandler(e, "groupn2")} onInputChange={(e) => setGroup2Search(e)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="label">Group-3</Form.Label>
              <Select value={group3Value} isDisabled={disabled3} options={groupn3Options} onChange={(e) => changeHandler(e, "groupn3")} onInputChange={(e) => setGroup3Search(e)} />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="cname2">Group-4</Form.Label>
              <Select value={group4Value} isDisabled={disabled4} options={groupn4Options} onChange={(e) => changeHandler(e, "groupn4")} onInputChange={(e) => setGroup4Search(e)} />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="formBasicName">
              <Form.Label className="cname2">Group-5</Form.Label>
              <Select value={group5Value} isDisabled={disabled5} options={groupn5Options} onChange={(e) => changeHandler(e, "groupn5")} onInputChange={(e) => setGroup5Search(e)} />
            </Form.Group>
          </Row>

          <br></br>
          <Row className="mb-1">
            <Form.Group as={Col} md="12" controlId="formBasicName">
              <Form.Label className="label">Product</Form.Label>
              <Select value="" options={productOptions} onInputChange={(e) => setProductSearch(e)} onChange={(e) => handleProductChange(e)} />
            </Form.Group>
          </Row>
          <br></br>

          <Table striped responsive hover size="sm">
            <thead className="p-3 mb-2 bg-success text-white">
              <tr>
                <th>PRODUCT&nbsp;NAME</th>
                <th>UOM</th>
                <th>Quantity (PCS)</th>
                <th>Quantity (KG)</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {projectionEntryData.projectionentryitem &&
                projectionEntryData.projectionentryitem.map((data, index) => (
                  <tr>
                    <td>{data.product_name}</td>
                    <td>
                      <Form.Group as={Col} md="12">
                        <Form.Select name="uom" disabled={data.producttype !== "kgproduct" ? true : false} size="sm" onChange={(e) => handleUom(e, index)}>
                          <option>{data.producttype !== "kgproduct" ? "PCS" : "Select"}</option>
                          <option value="pcs">PCS</option>
                          <option value="kg">KG</option>
                        </Form.Select>
                      </Form.Group>
                    </td>

                    <td>
                      <Form.Group as={Col} md="12">
                        <Form.Control
                          type="number"
                          name="qty"
                          onChange={(e) => pcsHandler(e, index)}
                          min="0"
                          className="addprojection-productname"
                          value={data.qty}
                          size="sm"
                          disabled={disablePcsHandler(index)}
                        />
                      </Form.Group>
                    </td>

                    <td>
                      <Form.Group as={Col} md="12">
                        <Form.Control
                          type="number"
                          name="kgqty"
                          onChange={(e) => kgHandler(e, index)}
                          className="addprojection-productname"
                          min="0"
                          size="sm"
                          value={data.kgqty}
                          disabled={disableKgHandler(index)}
                        />
                      </Form.Group>
                    </td>

                    <td>
                      <Form.Group as={Col} md="12" onClick={() => removeRow(index)}>
                        <input type="button" className=" x-btn" value="X" />
                      </Form.Group>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <Button variant="primary" style={{ width: "auto" }} disabled={loading} onClick={submitData}>
            {loading ? "Loading..." : "Submit"}
          </Button>
        </Form>
      </MDBCard>
    </div>
  );
};

export default CreateProjectionEntry;
