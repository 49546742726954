import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker, DirectionsRenderer } from '@react-google-maps/api';
import Componentmap from './Componentmap';

const MapView = () => {
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [error, setError] = useState(null);
  const [data, setData] = useState([
    
  ]);
  const [mapLoaded, setMapLoaded] = useState(false); // Track if the map API is loaded
  // const apiKey = 'AIzaSyAi8GeH0OVLzpqznM3pFy-0Lt6ecDofWro';
  const apiKey = 'AIzaSyAi8GeH0OVLzpqznM3pFy-0Lt6ecDofWro';

   const mapStyles = {
    height: '85vh',
    width: '100%',
    top: '1px',
  };

  const fetchDirections = async () => {
    if (!mapLoaded) return; // Ensure the API is loaded before running

    if (data.length < 2) {
      setError('Not enough data points for directions');
      return;
    }

    const origin = { lat: Number(data[0].checkinLocationLatitude), lng: Number(data[0].checkinLocationLongitude) };
    const destination = {
      lat: Number(data[data.length - 1].checkinLocationLatitude),
      lng: Number(data[data.length - 1].checkinLocationLongitude),
    };

    const waypoints = data.slice(1, -1).map((location) => ({
      location: { lat: Number(location.checkinLocationLatitude), lng: Number(location.checkinLocationLongitude) },
      stopover: true,
    }));

    const directionsService = new window.google.maps.DirectionsService();

    directionsService.route(
      {
        origin,
        destination,
        waypoints,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirectionsResponse(result);
        } else {
          setError(`Error fetching directions: ${status}`);
        }
      }
    );
  };
const [poitnersize, setPoitnersize] = useState([])
  const handleLocationDetails = (latitude, longitude) => {
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    setPoitnersize({lat: Number(latitude), lng: Number(longitude)})
  };
  console.log(poitnersize)
  useEffect(() => {
    if (mapLoaded) {
      fetchDirections();
    }
  }, [mapLoaded, data]);
const [logindating, setLogindating] = useState([])
  const handleData = (newData,logindata) => {
    const timer=[...newData,...logindata]
const opener=logindata.map(use=>({checkinLocationLatitude:`${use.checkin_location_latitude}`||use.checkinLocationLatitude,checkinLocationLongitude:`${use.checkin_location_longitude}`||use.checkinLocationLongitude,  loginStatus: use.checkin_location_latitude && use.checkin_location_longitude ? true : false}))
console.log(opener)
const outer = logindata.map(use => ({
  checkinLocationLatitude: `${use.checkout_location_latitude}`,
  checkinLocationLongitude: `${use.checkout_location_longitude}`,
  logoutStatus: use.checkout_location_latitude && use.checkout_location_longitude ? true : false
}));

// const outer=logindata.map(use=>({checkinLocationLatitude:`${use.checkout_location_latitude}` ,checkinLocationLongitude:`${use.checkout_location_longitude}` }))
const middle=newData.map(use=>({checkinLocationLatitude:use.checkinLocationLatitude,checkinLocationLongitude:use.checkinLocationLongitude}))
// setData(logindata);
setLogindating(logindata)
console.log([...opener,...middle,...outer])
    setData([...opener,...middle,...outer])
    // )
  };
console.log(data)
const [truw, setTruw] = useState(false)
//   return (
//     <div>
// <button onClick={()=>setTruw(true)}>click me</button>
// <button onClick={()=>setTruw(!truw)}>click me</button>
 

//       <LoadScript
//         googleMapsApiKey={apiKey}
//         onLoad={() => setMapLoaded(true)} // Mark the API as loaded
//       >
//         <GoogleMap
//   mapContainerStyle={mapStyles}
//   zoom={truw && poitnersize.lat ? 15 : 10} // Dynamically set zoom level
//   center={poitnersize.lat && poitnersize.lng ? poitnersize : { lat: 19.076, lng: 72.8777 }} // Default to Mumbai if no coordinates
// >
//         {/* <GoogleMap mapContainerStyle={mapStyles}   zoom={30} 
//   center={poitnersize}> */}
//           {/* Render the directions on the map */}
//           {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}

//           {/* Render markers */}
//           {/* {data.map((item, index) => (
//             <Marker
//               key={index}
//               title={`Location ${index + 1}`}
//               position={{ lat: Number(item.checkinLocationLatitude) , lng: Number(item.checkinLocationLongitude)  }}
//             />
//           ))} */}
//       {logindating.length!==0? data.map((item, index) => (
//   <Marker
//     key={index}
//     title={
//       index === 0 && item.loginStatus
//         ? "Login Location"
//         : item.logoutStatus
//         ? "Logout Location"
//         : `Location ${index + 1}`
//     }
//     position={{ lat: Number(item.checkinLocationLatitude), lng: Number(item.checkinLocationLongitude) }}
//     icon={
//       index === 0 && item.loginStatus
//         ? {
//             path: window.google.maps.SymbolPath.CIRCLE,
//             scale: 12, // Larger size for the first login marker
//             fillColor: "red", // Red color for login marker
//             fillOpacity: 1,
//             strokeWeight: 1,
//             strokeColor: "black",
//           }
//         : item.logoutStatus
//         ? {
//             path: window.google.maps.SymbolPath.CIRCLE,
//             scale: 10, // Slightly larger size for logout marker
//             fillColor: "green", // Green color for logout marker
//             fillOpacity: 1,
//             strokeWeight: 1,
//             strokeColor: "black",
//           }
//         : {
//             path: window.google.maps.SymbolPath.CIRCLE,
//             scale: 8, // Default size for other markers
//             fillColor: "blue", // Default color for other markers
//             fillOpacity: 1,
//             strokeWeight: 1,
//             strokeColor: "black",
//           }
//     }
//   />
// ))
// :data.map((item, index) => (
//   <Marker
//     key={index}
//     title={`Location ${index + 1}`}
//     position={{ lat: Number(item.checkinLocationLatitude) , lng: Number(item.checkinLocationLongitude)  }}
//   />
// ))}


//         </GoogleMap>
//       </LoadScript>
//       <Componentmap onDateChange1={handleData} handleLocationDetails={handleLocationDetails}/>
//     </div>
//   );
return (
  <div>
    {/* <button onClick={() => setTruw(true)}>Set Zoom & Highlight</button>
    <button onClick={() => setTruw(!truw)}>Toggle Zoom</button> */}

    <LoadScript
      googleMapsApiKey={apiKey}
      onLoad={() => setMapLoaded(true)} // Mark the API as loaded
    >
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={truw && poitnersize.lat ? 18 : 10} // Dynamically set zoom level
        center={poitnersize.lat && poitnersize.lng ? poitnersize : { lat: 19.076, lng: 72.8777 }} // Default to Mumbai if no coordinates
      >
        {/* Render the directions on the map */}
        {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}

        {/* Render markers */}
        {logindating.length !== 0
          ? data.map((item, index) => (
              <Marker
                key={index}
                title={
                  index === 0 && item.loginStatus
                    ? "Login Location"
                    : item.logoutStatus
                    ? "Logout Location"
                    : `Location ${index + 1}`
                }
                position={{
                  lat: Number(item.checkinLocationLatitude),
                  lng: Number(item.checkinLocationLongitude),
                }}
                icon={
                  poitnersize.lat == Number(item.checkinLocationLatitude) &&
                  poitnersize.lng == Number(item.checkinLocationLongitude)
                    ? {
                        path: window.google.maps.SymbolPath.CIRCLE,
                        scale: 14,  
                        fillColor: "yellow",  
                        fillOpacity: 1,
                        strokeWeight: 2,
                        strokeColor: "black",
                      }
                    : index === 0 && item.loginStatus
                    ? {
                        path: window.google.maps.SymbolPath.CIRCLE,
                        scale: 12,
                        fillColor: "red",
                        fillOpacity: 1,
                        strokeWeight: 1,
                        strokeColor: "black",
                      }
                    : item.logoutStatus
                    ? {
                        path: window.google.maps.SymbolPath.CIRCLE,
                        scale: 10,
                        fillColor: "green",
                        fillOpacity: 1,
                        strokeWeight: 1,
                        strokeColor: "black",
                      }
                    : {
                        path: window.google.maps.SymbolPath.CIRCLE,
                        scale: 8,
                        fillColor: "blue",
                        fillOpacity: 1,
                        strokeWeight: 1,
                        strokeColor: "black",
                      }
                }
              />
            ))
          : data.map((item, index) => (
              <Marker
                key={index}
                title={`Location ${index + 1}`}
                position={{
                  lat: Number(item.checkinLocationLatitude),
                  lng: Number(item.checkinLocationLongitude),
                }}
                icon={
                  poitnersize.lat == Number(item.checkinLocationLatitude) &&
                  poitnersize.lng == Number(item.checkinLocationLongitude)
                    ? {
                        path: window.google.maps.SymbolPath.CIRCLE,
                        scale: 14,
                        fillColor: "yellow",
                        fillOpacity: 1,
                        strokeWeight: 2,
                        strokeColor: "black",
                      }
                    : {
                        path: window.google.maps.SymbolPath.CIRCLE,
                        scale: 8,
                        fillColor: "blue",
                        fillOpacity: 1,
                        strokeWeight: 1,
                        strokeColor: "black",
                      }
                }
              />
            ))}
      </GoogleMap>
    </LoadScript>

    <Componentmap onDateChange1={handleData} handleLocationDetails={handleLocationDetails} />
  </div>
);


};

export default MapView;
