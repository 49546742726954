// import axios from 'axios';
// import React, { useEffect, useState } from 'react'
// import { Button, Modal } from 'react-bootstrap';
// import { useNavigate, useParams } from 'react-router-dom';
// import { BASE_URL } from '../../services/Api';
// import authHeader from '../../services/auth-header';
// import Swal from 'sweetalert2';

// export default function Disminupdate() {
// //   const navigate = useNavigate();
// //   const [isLoading, setIsLoading] = useState(false);
// //   const applyFontSizeTransition = (elementId) => {
// //     const element = document.getElementById(elementId);
// //     element.style.transition = 'font-size 2s';
// //     element.style.fontSize = '30px';
// //     element.style.fontWeight = '600';
// //     // font-weight: 600;
// //     setTimeout(() => {
       
// //       document.getElementById("typer1").classList.add('promote');
// //       element.style.fontSize = '24px';
// //     }, 1000);
// //   };
// //     const { id } = useParams();
// //     const [show, setShow] = useState(false);
// //     const handleClose = () => {setShow(false)
// //       // /Distributor/Outward/Table/:pointindex
// //       // Distributor/Outward/Table
// //       navigate('/Dismintable/1')
// //     };
// //     const handleShow = () => setShow(true);
// //     const [user_date,setuser_date]=useState('');
// //     const[distributorStockItems,setdistributorStockItems]=useState([]);
// //     const[distributor,setdistributor]=useState('');
// //     const[dist_name,setdist_name] = useState('');
// //     const[distItems,setdistItems] = useState('');
// //     const[quantity,setquantity] = useState('');
// // const [setsaveid, setSetsaveid] = useState([])
// //     const getdistoutwarddata = ( ) => {
// //         axios.get(BASE_URL + 'dms/' + id,{
// //             headers:authHeader()
// //         }).then(res=>{
// //           console.log('mello',res.data)
// //           setuser_date(res.data.dmsdate);
// //           setdistributor(res.data.distributorid);
// //           // setdistributorStockItems(res.data.distributorStockItems);
// //         setdistItems(res.data.product.productName);
// //         setquantity(res.data.stockqty)
// //         setSetsaveid(res.data.product.id);
// //           let dist_id = res.data.distributorid;

// //           axios.get(BASE_URL + 'distributor/getById/' + dist_id,{headers:authHeader()}).then(res1=>{
// //             // console.log('mello',res1.data);
// //             setdist_name(res1.data.tradeName)
// //           })
// //         })
// //     }

// //     useEffect(()=>{
// //         getdistoutwarddata();
// //         handleShow()
// //     },[]);

// //     const onChangeproduct = (e) => {
// //       let name = e;
// //       setquantity(e);
// //       setdistributorStockItems(distributorStockItems.map(user=>({
// //         "rate":user.rate,
// //         'amount':(user.rate)*name,
// //         "product":{"id":user.product.id},
// //         'outwardqty':name

// //       })))
// //     }

// //     const handlesupersubmit = ( ) => {
// //       let dmsdate = user_date;
// //       // alert(distributor)
// //         let postData = {
// //           distributorid: distributor,
// //           dmsdate,
// //           product:{
// //             id:setsaveid
// //           },
// //           stockqty:quantity
// //         };
// //         setIsLoading(true); 

// //         axios.put(BASE_URL + 'dms/'+id,postData,{headers:authHeader()}).then(res=>{
// //             console.log('mello',res.data)
// //             window.location.reload()

// //         })  .catch(error => {
// //           console.error('Error adding  :', error);
// //           if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
// //           Swal.fire({
// //             icon: "error",
// //             title: "Oops...",
// //             text: "Something went wrong!",
// //             footer: `
// //               <div>
// //                 <p id="issueText">Why do I have this issue?</p>
// //                 <ul>
            
// //                 <li id="typer1">You might have an internet issue</li>
// //                 </ul>
// //               </div>
// //             `,
// //             didOpen: () => {
// //               applyFontSizeTransition('issueText');
// //             }
// //           });
// //         }
// //         })
// //         .finally(() => {
// //           setIsLoading(false); 
// //         });
        
// //     }
// //   return (
// //     <div>
// //         <Modal show={show} onHide={handleClose}>
// //         <Modal.Header closeButton>
// //           <Modal.Title>Update</Modal.Title>
// //         </Modal.Header>
// //         <Modal.Body>
// //         <div className='row'>
// //     <div className='col'>
// // <label className='form-label'>Distributor Name</label>
// // <input type='text' value={dist_name} className='form-control' readOnly/>

// //     </div>
// // </div> 
// //         <div className='row'>
// //     <div className='col'>
// // <label className='form-label'>Product</label>
// // <input type='text' value={distItems}  className='form-control' readOnly/>
// //      </div>
// // </div> 

// // <div className='row'>
// //     <div className='col'>
// // <label  className='form-label' >Date</label>
// // <input type='Date' value={user_date} className='form-control' readOnly/>
// //     </div>
// //     <div className='col'>
// // <label  className='form-label'>Qty</label>
// // <input type='number' value={quantity} name='quantity' onChange={(e) => onChangeproduct(e.target.value)} className='form-control' />
// //     </div>
// // </div>



// //         </Modal.Body>
// //         <Modal.Footer>
// //           <a className="btn btn-sm btn-secondary" variant="secondary" onClick={handleClose}>
// //             Close
// //           </a>
// //           {/* <a className="btn btn-sm btn-secondary" variant="primary" onClick={()=>{handleClose();handlesupersubmit()}}>
// //             Save Changes
// //           </a> */}
// //           <Button variant="primary" style={{width:'auto'}}onClick={()=>{handleClose();handlesupersubmit()}} disabled={isLoading}>
// //           {isLoading ? 'Loading...' : 'Submit'}
// //         </Button>
// //         </Modal.Footer>
// //       </Modal>
// //     </div>
// //   )
// }


import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import AuthService from "../../services/auth.service";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import { useNavigate, useParams } from "react-router-dom";
import UserService from "../../services/user.service";

const Disminupdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [disword, setDisword] = useState("");
  const [product2, setProduct2] = useState([]);
  const [distributor1, setDistributor1] = useState([]);
  const [userDate, setUserDate] = useState("");
  const [distRoles, setDistRoles] = useState("");
  const [disableKg, setdisableKg] = useState(false);
  const [disablePcs, setdisablePcs] = useState(false);
  const [items, setItems] = useState([]);
  const [datap, setDatap] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingName, setIsLoadingName] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [user_date, setuser_date] = useState("");
  const [itemId, setitemId] = useState('')
  const [inwardDistributorStockItems, setinwardDistributorStockItems] =
    useState([]);
  const [distributor, setdistributor] = useState(0);
  const [dist_name, setdist_name] = useState("");
  const [distItems, setdistItems] = useState("");
  const [quantity, setquantity] = useState("");
  const currentUser = AuthService.getCurrentUser();

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const getdistoutwarddata = async() => {
    setIsLoadingData(true);
    axios
      .get(BASE_URL + "dms/" + id, {
        headers: authHeader(),
      })
      .then((res) => {
         console.log('mello',res.data)
        setUserDate(res.data.dmsdate);
        setdistributor(res.data?.distributorid);
        setitemId(res.data.id);
        // setinwardDistributorStockItems(res.data.inwardDistributorStockItems);
        // setdistItems(res.data.inwardDistributorStockItems[0].product.productName);
        // setquantity(res.data.inwardDistributorStockItems[0].inwardqty)
        setItems(
          res.data.distributorMinimumStockItems.map((item) => ({
            id: item.product.id,
            product_Name: item.product.productName,
           
            uomSecondary:item.product.uomSecondary,
            stockqty: item.stockqty,
            disableKg: false,
            disablePcs: false,
            kgstockqty  : item.kgstockqty  ,
          }))
       
        )
  
       // setdist_name(res.data.distributor.tradeName);

        // axios.get(BASE_URL + 'distributor/getById/' + dist_id, { headers: authHeader() }).then(res1 => {
        //     // console.log('mello',res1.data);
        //     setdist_name(res.data.distributor.tradeName)
        // })
        return (res?.data?.distributorid)
      })
      .then((res)=>{
        setIsLoadingName(true)
        console.log(res)
        axios
        .get(
          `${BASE_URL}distributor/getById/${
          res
          }`,
          { headers: authHeader() }
        )
        .then((response) => {
           setdist_name(response.data?.tradeName);
        }).catch((err)=>console.log(err))
        .finally(()=>setIsLoadingName(false))
      }
      )
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoadingData(false);
      });
  const dataDisc=await  axios
  .get(
    `${BASE_URL}distributor/getById/${
    distributor
    }`,
    { headers: authHeader() }
  )
   setdist_name(await dataDisc.data.tradeName);
  };

  const onchangeproductstore = (option) => {
    const newProduct = datap.find((item) => item.id === option.value);
    console.log(newProduct);
    if (newProduct) {
      const isProductInList = items.some((item) => item.id === newProduct.id);

      if (!isProductInList) {
        setdisableKg(false);
        setdisablePcs(false)
        setItems((prevItems) => [
          ...prevItems,
          { ...newProduct, stockqty: 0, total: 0, kgstockqty  : 0,uomSecondary:newProduct.uom_secondary  , disableKg: false,
            disablePcs: false, },
        ]);
   
      }
    }
  };

  const handleQuantityChange = (id, stockqty) => {


    setdisableKg(true);
    if (stockqty === "" || stockqty == 0) {
      setdisableKg(false);
    }
    setdisablePcs(false);

    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? {
              ...item,
              stockqty: stockqty,
              disableKg: stockqty > 0, 
              disablePcs: false,
              kgstockqty  : (stockqty * item.uomSecondary).toFixed(2),
            }
          : item
      )
    );
  };

  const handleQuantityKgChange = (id, kgstockqty  ) => {
    setdisablePcs(true);
    if (kgstockqty   === "" || kgstockqty   == 0) {
      setdisablePcs(false);
    }
    setdisableKg(false);
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? {
              ...item,
              kgstockqty  : kgstockqty  ,
              disablePcs: kgstockqty > 0,
              disableKg: false, 
              stockqty: (kgstockqty   / item.uomSecondary).toFixed(2),
            }
          : item
      )
    );
  };

  // const handleSubmit = () => {
  //     const postData = {
  //         indate: userDate,
  //         distributorid: distributor,
  //         inwardDistributorStockItems: items.map(item => ({
  //             product:{"id":item.id},
  //             amount: item.total.toFixed(2)  ,
  //             outwardqty:item.qty,
  //             rate:item.mrp
  //         })),
  //     };

  //     setIsLoading(true);

  //     axios.post(`${BASE_URL}indiststock/`, postData, { headers: authHeader() })
  //         .then(response => {
  //             Toast.fire({
  //                 icon: 'success',
  //                 title: 'Successfully added'
  //             });
  //         }).catch(error => {
  //             console.error('Error adding  :', error);
  //             if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
  //                 Swal.fire({
  //                     icon: "error",
  //                     title: "Oops...",
  //                     text: "Something went wrong!",
  //                     footer: `
  //                         <div>
  //                           <p id="issueText">Why do I have this issue?</p>
  //                           <ul>
  //                               <li id="typer1">You might have an internet issue</li>
  //                           </ul>
  //                         </div>
  //                     `,
  //                     didOpen: () => {
  //                         applyFontSizeTransition('issueText');
  //                     }
  //                 });
  //             }
  //         })
  //         .finally(() => {
  //             setIsLoading(false);
  //         });
  // };
  const handleSubmit = () => {

    const postData = {
       
      dmsdate: userDate,
      distributorid: distributor ,
      distributorMinimumStockItems: items.map((item) => ({
        id:item.id,
        product: { id: item.id },
      
        stockqty: item.stockqty,
        uomSecondary:item.uomSecondary,
        kgstockqty   : item.kgstockqty  ,
      })),
    };
     console.log(postData,items)
  setIsLoading(true);

    axios
      .put(BASE_URL + "dms/" + id, postData, {
        headers: authHeader(),
      })
      .then((response) => {
        navigate("/Dismintable/1");

        Toast.fire({
          icon: "success",
          title: "Successfully added",
        });
      })
      .catch((error) => {
        console.error("Error adding  :", error);
        if (
          error.message.includes("Network Error") ||
          error.message.includes("Failed to fetch")
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `
                            <div>
                              <p id="issueText">Why do I have this issue?</p>
                              <ul>
                                  <li id="typer1">You might have an internet issue</li>
                              </ul>
                            </div>
                        `,
            didOpen: () => {
              applyFontSizeTransition("issueText");
            },
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = "font-size 2s";
    element.style.fontSize = "30px";
    element.style.fontWeight = "600";
    setTimeout(() => {
      document.getElementById("typer1").classList.add("promote");
      element.style.fontSize = "24px";
    }, 1000);
  };

  const getDistributorByRoles = () => {
    // axios.get(`${BASE_URL}distributor/getById/${currentUser.id}`, { headers: authHeader() })
    //     .then(res => {
    //         setDistRoles(res.data.tradeName);
    //         setDistributor(res.data.id);
    //     });
  };
  useEffect(() => {
    getdistoutwarddata();
  }, []);

  useEffect(() => {
    if (currentUser.roles[0] === "ROLE_DISTRIBUTOR") {
      getDistributorByRoles();
    }

    axios
      .get(
        `${BASE_URL}product/page/0/10/asc/id/${
          error.length ? error : encodeURIComponent(" ")
        }`,
        { headers: authHeader() }
      )
      .then((response) => {
        setDatap(response.data.data || response.data.Index);
        const products = (response.data.data || response.data.Index).map(
          (use) => ({
            value: use.id,
            label: `${use.product_Name} - ${use.ean_Code}`,
          })
        );
        setProduct2(products);
      })
      .catch((error) => {
        console.error(error);
      });

    // axios
    //   .get(
    //     `${BASE_URL}distributor/getById/${
    //     distributor
    //     }`,
    //     { headers: authHeader() }
    //   )
    //   .then((response) => {
    //     console.log(response.data,'distributor')
    //     // if (response.data.data == undefined) {
    //     //   setDistributor1(
    //     //     response.data.Index.map((use) => ({
    //     //       value: use.id,
    //     //       label: use.trade_Name,
    //     //     }))
    //     //   );
    //     // }
    //     // if (response.data.Index == undefined) {
    //     //   setDistributor1(
    //     //     response.data.data.map((use) => ({
    //     //       value: use.id,
    //     //       label: use.trade_Name,
    //     //     }))
    //     //   );
    //     // }
    //   });
  }, [error, disword]);

  

  // const handledelete = () => {
  //     setIsLoading(true);

  //     axios.delete(`${BASE_URL}distributor/openingstock/get/${id}`, { headers: authHeader() })
  //         .then(response => {
  //             navigate('/OpenCreate/1')

  //             Toast.fire({
  //                 icon: 'success',
  //                 title: 'Successfully deleted'
  //             });
  //         })
  //         .catch(error => {
  //             console.error('Error deleting:', error);
  //             if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
  //                 Swal.fire({
  //                     icon: 'error',
  //                     title: 'Oops...',
  //                     text: 'Something went wrong!',
  //                     footer: `
  //                         <div>
  //                           <p id="issueText">Why do I have this issue?</p>
  //                           <ul>
  //                               <li id="typer1">You might have an internet issue</li>
  //                           </ul>
  //                         </div>
  //                     `,
  //                     didOpen: () => {
  //                         // Apply a custom function for font size transition if necessary
  //                         applyFontSizeTransition && applyFontSizeTransition('issueText');
  //                     }
  //                 });
  //             }
  //         })
  //         .finally(() => {
  //             setIsLoading(false); // End loading
  //         });
  // };

  const getItem = () => {
    // UserService.getprimaryorderdelete(id).then(res=>{
    //   window.location.reload();
    // })

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.getdisdelete(Number(id)).then((res) => {
          // window.location.reload();
          navigate(`/Dismintable/1`);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        });
      }
    });
  };

  const handleback = () => {
    console.log("first");
    navigate("/Dismintable/1");
  };

  return (
    <div className="card card-body">
      <div className="card-header card-title">
        <h2 style={{ fontSize: "16px", fontWeight: 700 }}>Update Distributor Minimum Stock</h2>
      </div>
      <div className="row">
        <div className="col-1">
          <Button variant="danger" onClick={() => getItem()}>
            Delete
          </Button>
        </div>
        <div className="col-1">
          <button
            onClick={() => handleback()}
            className="btn btn-primary ml-3"
            style={{
              marginLeft: " 47px",
              padding: "16px 12px",
            }}
          >
            Back
          </button>
        </div>
      </div>
      {isLoadingData ? (
        <>
          <div className="d-flex jusify-content-center">
            <div className="spinner"></div>
          </div>
        </>
      ) : (
        <>
          <div className="row">
            <div className="col">
              <label className="form-label">Distributor</label>
              {/* {currentUser.roles[0] === 'ROLE_DISTRIBUTOR' ? (
                        <input type='text' className='form-control' value={distRoles} readOnly />
                    ) : ( */}
              <input
                type="text"
                value={isLoadingName?'loading....':dist_name}
                className="form-control"
                readOnly
              />

              {/* )} */}
            </div>
            <div className="col">
              <label className="form-label">Date</label>
              <input
                type="date"
                value={userDate}
                className="form-control"
                onChange={(e) => setUserDate(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label className="form-label">Product</label>
              <Select
                options={product2}
                onInputChange={(inputValue) => setError(inputValue)}
                onChange={(options) => onchangeproductstore(options)}
              />
            </div>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Qty(PCS)</th>
                <th>Qty(KG)</th>
               
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr key={item.id}>
                  <td width="200px">{item.product_Name}</td>
                  <td width="100px">
                    <input
                      type="number"
                      className="form-control"
                      value={item.stockqty}
                      disabled={item.disablePcs} 
                      onChange={(e) =>
                        {
                         let value=e.target.value;
                         const regex = /^[0-9]*\.?[0-9]{0,2}$/;
                         if (regex.test(value)) {
                           handleQuantityChange(item.id, parseInt(e.target.value) || 0)
                         }
     
                         
                        
                        }
                       }
                    />
                  </td>
                  <td width="100px">
                    <input
                      type="number"
                      className="form-control"
                      value={item.kgstockqty  }
                      disabled={item.disableKg}
                      onChange={(e) =>
                        {
                       let value=e.target.value;
                       const regex = /^[0-9]*\.?[0-9]{0,2}$/;
                       if (regex.test(value)) {
                         handleQuantityKgChange(
                           item.id,
                           parseFloat(e.target.value) || 0
                         )
                       }
                     
                        }
           
                         }
                    />
                  </td>
                 
               
                  <td width="100px">
                    <Button
                      variant="danger"
                      onClick={() =>
                        setItems(items.filter((i) => i.id !== item.id))
                      }
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      <div className="row">
        <div className="col">
          <Button
            variant="primary"
            style={{ width: "auto" }}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Submit"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Disminupdate;
