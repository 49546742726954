import React, { useState } from "react";
import { MDBCard } from "mdbreact";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import authHeader from "../../services/auth-header";
import { BASE_URL } from "../../services/Api";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const CreateGroupn3 = () => {
  const [title, setTitle] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}v1/groupn3`,
        {
          title: title,
        },
        {
          headers: authHeader(),
        }
      );

      navigate(`/group3indextable/1`);
      Swal.fire({
        title: "Success!",
        text: "Title has been successfully added.",
        icon: "success",
        confirmButtonText: "Okay",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MDBCard className="company">
      <div className="d-flex bd-highlight">
        <div className="card-header card-title w-100">Create Group3</div>

        <Link className="p-2 w-200 card-header card-title bd-highlight" to={`/group3indextable/1`}>
          <Button className="btn-sm">Back</Button>
        </Link>
      </div>
      <Form className="product1">
        <Row className="mb-1">
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="label">Title</Form.Label>
            <Form.Control type="text" name="title" placeholder="Enter Title" onChange={(e) => setTitle(e.target.value)} />
          </Form.Group>
        </Row>
        <Button variant="primary" style={{ width: "auto" }} onClick={handleSubmit}>
          Submit
        </Button>
      </Form>
    </MDBCard>
  );
};

export default CreateGroupn3;
