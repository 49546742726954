import { takeEvery, put } from "redux-saga/effects";
import authHeader from "../Component/services/auth-header";



/* Purchase Create */
import { PURCHASE_GET_DATA_TO_LOAD, PUT_NEW_SUPPLIER_ADD_TO_CART_EDIT, SUPPLIER_ADD_TO_CART_EDIT } from "./constant";
import { PURCHASE_PUT_DATA_TO_LOAD } from "./constant";
import { PURCHASE_ADD_TO_CART } from "./constant";
import { PUT_NEW_PURCHASE_ADD_TO_CART } from "./constant";


/* Update Purchase */
import { PURCHASE_EDIT_GET_DATA_TO_LOAD } from './constant';
import { PURCHASE_EDIT_PUT_DATA_TO_LOAD } from './constant';
import { PURCHASE_EDIT_ADD_TO_CART } from './constant';
import { PUT_NEW_PURCHASE_EDIT_ADD_TO_CART } from './constant';

/* Purchase Return Create */
import { PURCHASE_RETURN_ADD_TO_CART } from "./constant";
import { PUT_NEW_PURCHASE_RETURN_ADD_TO_CART } from "./constant";

/* Purchase Return Update */
import { PR_UPDATE_GET_DATA } from "./constant";
import { PR_UPDATE_PUT_DATA } from "./constant";
import { PR_UPDATE_ADD_TO_CART } from "./constant";
import { PUT_NEW_PR_UPDATE_ADD_TO_CART } from "./constant";

/* PO  Create */

import { PO_ADD_TO_CART } from "./constant";
import { SET_PO_LIST } from "./constant";

/* PO Update */

import { PO_GET_EDIT_DATA_TO_LOAD } from "./constant";
import { PO_PUT_EDIT_DATA_TO_LOAD } from "./constant";
import { PO_EDIT_ADD_TO_CART } from "./constant";
import { PUT_NEW_PO_EDIT_ADD_TO_CART } from "./constant";

/* supplier delivery Note CREATE */
import { SUPPLIER_GET_DATA_TO_LOAD } from "./constant";
import { SUPPLIER_PUT_DATA_TO_LOAD } from "./constant";
import { SUPPLIER_ADD_TO_CART } from "./constant";
import { PUT_NEW_SUPPLIER_ADD_TO_CART } from "./constant";


/* supplier delivery Note UPDATE */
import { SUPPLIER_EDIT_GET_DATA_TO_LOAD } from "./constant";
import { SUPPLIER_EDIT_PUT_DATA_TO_LOAD } from "./constant";
import { SUPPLIER_EDIT_ADD_TO_CART } from "./constant";
import { PUT_NEW_SUPPLIER_EDIT_ADD_TO_CART } from "./constant";


/* Material Receipt Note CREATE */
import { MATERIAL_GET_DATA_TO_LOAD } from "./constant";
import { MATERIAL_PUT_DATA_TO_LOAD } from "./constant";
import { MATERIAL_ADD_TO_CART } from "./constant";
import { PUT_NEW_MATERIAL_ADD_TO_CART } from "./constant";

/* Material Receipt Note Update */
import { MATERIAL_EDIT_GET_DATA_TO_LOAD } from "./constant";
import { MATERIAL_EDIT_PUT_DATA_TO_LOAD } from "./constant";
import { MATERIAL_EDIT_ADD_TO_CART } from "./constant";
import { PUT_NEW_MATERIAL_EDIT_ADD_TO_CART } from "./constant";


/* WorkOrder Create */

import { ADD_TO_CART_WORKORDER } from "./constant";
import { SET_PRODUCT_LIST_WORKORDER } from "./constant";

/* WorkOrder Update */

import { GET_EDIT_DATA_TO_LOAD_WORKORDER } from "./constant";
import { PUT_EDIT_DATA_TO_LOAD_WORKORDER } from "./constant";
import { EDIT_ADD_TO_CART_WORKORDER } from "./constant";
import { PUT_NEW_EDIT_ADD_TO_CART_WORKORDER } from "./constant";

/* PRIMARY ORDER PRODUCT */

import { GET_PRODUTDATA_PRIMARYORDER } from "./constant";
import { GET_PRODUCT_PRIMARYORDER } from "./constant";


/* PRIMARY ORDER UPDATE */
import { GET_DATA_PRIMARYORDER_UPDATE } from "./constant";
import { GET_PRIMARYORDER_UPDATE } from "./constant";
import { GET_PRODUCT_ADD_PRIMARYORDER_UPDATE } from "./constant";
import { GET_PRODUCTDATA_ADD_PRIMARYORDER_UPDATE } from "./constant";

/* PrimaryOrder View */

import { ESTIMATED_DELIVERY } from "./constant";
import { ESTIMATED_DELIVERY_DATA } from "./constant";
import { BASE_URL } from "../Component/services/Api";

/* Sales Order CREATE */
import { SALESORDER_GET_DATA_TO_LOAD } from "./constant";
import { SALESORDER_PUT_DATA_TO_LOAD } from "./constant";
import { SALESORDER_ADD_TO_CART } from "./constant";
import { PUT_NEW_SALESORDER_ADD_TO_CART } from "./constant";
import { WORKORDERBYSALESORDER_GET_DATA_TO_LOAD } from "./constant";
import { WORKORDERBYSALESORDER_PUT_DATA_TO_LOAD } from "./constant";

/* SALES ORDER  Update */
import { SALESORDER_EDIT_GET_DATA_TO_LOAD } from "./constant";
import { SALESORDER_EDIT_PUT_DATA_TO_LOAD } from "./constant";
import { SALESORDER_EDIT_ADD_TO_CART } from "./constant";
import { PUT_NEW_SALESORDER_EDIT_ADD_TO_CART } from "./constant";

/* Delivery Challan CREATE */
import { DELIVERYCHALLAN_GET_DATA_TO_LOAD } from "./constant";
import { DELIVERYCHALLAN_PUT_DATA_TO_LOAD } from "./constant";
import { DELIVERYCHALLAN_ADD_TO_CART } from "./constant";
import { PUT_NEW_DELIVERYCHALLAN_ADD_TO_CART } from "./constant";

/* SO Pending Dc */
import { DC_ADD_TO_CART } from "./constant";
import { DC_PUT_NEW_ADD_TO_CART } from "./constant";

/* Sales Invoice CREATE */
import { SALES_GET_DATA_TO_LOAD } from "./constant";
import { SALES_PUT_DATA_TO_LOAD } from "./constant";
import { SALES_ADD_TO_CART } from "./constant";
import { PUT_NEW_SALES_ADD_TO_CART } from "./constant";

/* Sales Invoice Update */
import { SALES_EDIT_GET_DATA_TO_LOAD } from "./constant";
import { SALES_EDIT_PUT_DATA_TO_LOAD } from "./constant";
import { SALES_EDIT_ADD_TO_CART } from "./constant";
import { PUT_NEW_SALES_EDIT_ADD_TO_CART } from "./constant";

/* Sales Return Create */
import { SALES_RETURN_AAD_TO_CART } from "./constant";
import { SALES_RETURN_PUT_ADD_TO_CART } from "./constant";

/* Sales Return Update */
import { SALES_RETURN_UPDATE_DATA_LOAD } from "./constant";
import { SALES_RETURN_UPDATE_GET_DATA_LOAD } from "./constant";
import { SALES_RETURN_UPDATE_AAD_TO_CART } from "./constant";
import { SALES_RETURN_UPDATE_PUT_ADD_TO_CART } from "./constant";

/* Purchase Create */
function* putpurchasetoload(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'mrn/' + id, { headers: authHeader() });
    result = yield result.json()
    // console.log(result,"mello");
    yield put({ type: PURCHASE_PUT_DATA_TO_LOAD, data: result.materialReceiptNoteItems })

}

function* addpurchaserow(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    //  console.warn("lelo", result);

    yield put({ type: PUT_NEW_PURCHASE_ADD_TO_CART, data: result })


}


/* Update Purchase */

function* purchasegeteditloaddata(e) {

    let id = e.data;

    let result = yield fetch(BASE_URL + 'purchase/' + id, { headers: authHeader() });
    result = yield result.json()

    yield put({ type: PURCHASE_EDIT_PUT_DATA_TO_LOAD, data: result.purchaseItems })
}

function* purchaseeditaddtocart(e) {
    let id = e.data;

    let result = yield fetch(BASE_URL + 'product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    yield put({ type: PUT_NEW_PURCHASE_EDIT_ADD_TO_CART, data: result })
};


/* Purchase Return Create */

function* purchasereturnaddtocart(e) {
    let id = e.data;


    let result = yield fetch(BASE_URL + 'product/getById/' + id, { headers: authHeader() });
    result = yield result.json()


    yield put({ type: PUT_NEW_PURCHASE_RETURN_ADD_TO_CART, data: result })

}
/* Purchase Return Update */
function* purchasereturnupdateaddtocart(e) {
    let id = e.data;


    let result = yield fetch(BASE_URL + 'product/getById/' + id, { headers: authHeader() });
    result = yield result.json()
   

    yield put({ type: PUT_NEW_PR_UPDATE_ADD_TO_CART, data: result })

}

function*purchasereturnupdateloaddata(e){
    let id = e.data;
    let result = yield fetch(BASE_URL + 'pr/' + id, { headers: authHeader() });
    result = yield result.json()


    yield put({ type: PR_UPDATE_PUT_DATA, data: result.purchaseReturnItems})
}


/* PO Create */
function* ponewaddrow(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

     // console.warn("mello", result);

    yield put({ type: SET_PO_LIST, data: result })


}


/* PO update */
function* putpoedittoload(data) {

    const id = data.data;

    let result = yield fetch('http://43.225.54.138:8080/scm/api/po/' + id, { headers: authHeader() });
    result = yield result.json()
    // console.log(result,"mello");
    yield put({ type: PO_PUT_EDIT_DATA_TO_LOAD, data: result.purchaseOrderItems })

}

function* addeditporow(data) {

    const id = data.data;

    let result = yield fetch('http://43.225.54.138:8080/scm/api/product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    //  console.warn("lelo", result);

    yield put({ type: PUT_NEW_PO_EDIT_ADD_TO_CART, data: result })


}

/* Supplier Delivery Notes Create */
function* putsupplierdeliverytoload(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'po/' + id, { headers: authHeader() });
    result = yield result.json()
    // console.log(result,"mello");
    yield put({ type: SUPPLIER_PUT_DATA_TO_LOAD, data: result.purchaseOrderItems })

}

function* addsupplierdeliveryrow(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    //  console.warn("lelo", result);

    yield put({ type: PUT_NEW_SUPPLIER_ADD_TO_CART, data: result })


}
function* addsupplierdeliveryrowEdit(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    //  console.warn("lelo", result);

    yield put({ type: PUT_NEW_SUPPLIER_ADD_TO_CART_EDIT, data: result })


}

/* Supplier Delivery Notes UPDATE */
function* putsupplierdeliveryedittoload(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'sdn/' + id, { headers: authHeader() });
    result = yield result.json()
    // console.log(result,"mello");
    yield put({ type: SUPPLIER_EDIT_PUT_DATA_TO_LOAD, data: result.supplierDeliverNoteItems })

}

function* addsupplierdeliveryeditrow(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    //  console.warn("lelo", result);

    yield put({ type: PUT_NEW_SUPPLIER_EDIT_ADD_TO_CART, data: result })


}


/* Material Receipt Note Create */

function* putmaterialtoload(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'sdn/' + id, { headers: authHeader() });
    result = yield result.json()
    // console.log(result,"mello");
    yield put({ type: MATERIAL_PUT_DATA_TO_LOAD, data: result.supplierDeliverNoteItems })

}

function* addmaterialrow(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    //  console.warn("lelo", result);

    yield put({ type: PUT_NEW_MATERIAL_ADD_TO_CART, data: result })


}

/* Material Receipt Note UPDATE */
function* putmaterialreceiptnoteedittoload(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'mrn/' + id, { headers: authHeader() });
    result = yield result.json()
    // console.log(result,"mello");
    yield put({ type: MATERIAL_EDIT_PUT_DATA_TO_LOAD, data: result.materialReceiptNoteItems })

}

function* addmaterialreceiptnoteeditrow(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    //  console.warn("lelo", result);

    yield put({ type: PUT_NEW_MATERIAL_EDIT_ADD_TO_CART, data: result })


}


/* WORKORDER */
function* addworkorder(data) {

    console.log("check data to put", data);

    const id = data.data;

    let result = yield fetch('http://43.225.54.138:8080/scm/api/product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    console.warn("action data to send is called", result);

    yield put({ type: SET_PRODUCT_LIST_WORKORDER, data: result, data2: data.data2 })

}


/* WORKORDER UPDATE */
function* putworkorderedittoload(data) {

    const id = data.data;

    let result = yield fetch('http://43.225.54.138:8080/scm/api/workorder/GetWorkItemByWorkIds/' + id, { headers: authHeader() });
    result = yield result.json()
    // console.log("mello", result)
    yield put({ type: PUT_EDIT_DATA_TO_LOAD_WORKORDER, data: result })
}

function* addnewworkorderedit(data) {

    const id = data.data;

    let result = yield fetch('http://43.225.54.138:8080/scm/api/product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    //  console.warn("lelo", result);

    yield put({ type: PUT_NEW_EDIT_ADD_TO_CART_WORKORDER, data: result, data2: data.data2 })


}


/* Sales Order Create */
function* putsalesordertoload(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'primaryworkorder/getById/' + id, { headers: authHeader() });
    result = yield result.json()
    // console.log(result,"mello");
    yield put({ type: SALESORDER_PUT_DATA_TO_LOAD, data: result.primaryOrderItems })

}

function* putworkorderbysalesordertoload(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'workorder/GetWorkItemByWorkIds/' + id, { headers: authHeader() });
    result = yield result.json()
    // console.log(result,"mello");
    yield put({ type: WORKORDERBYSALESORDER_PUT_DATA_TO_LOAD, data: result })

}

function* addsalesorderrow(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    //  console.warn("lelo", result);

    yield put({ type: PUT_NEW_SALESORDER_ADD_TO_CART, data: result, data2: data.data2 })


}

/* Sales Order UPDATE */
function* putsalesorderedittoload(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'so/' + id, { headers: authHeader() });
    result = yield result.json()
    // console.log(result,"mello");
    yield put({ type: SALESORDER_EDIT_PUT_DATA_TO_LOAD, data: result.salesOrderItems })

}


function* addsalesordereditrow(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    //  console.warn("lelo", result);

    yield put({ type: PUT_NEW_SALESORDER_EDIT_ADD_TO_CART, data: result, data2: data.data2 })


}



/* Primary Order */

function* addnewprimaryordere(data) {

    const id = data.data;

    let result = yield fetch('http://43.225.54.138:8080/scm/api/product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    //  console.warn("lelo", result);

    yield put({ type: GET_PRODUCT_PRIMARYORDER, data: result, data2: data.data2 })


}


function* estimateddeliverydata(data) {

    const id = data.data;

    let result = yield fetch('http://43.225.54.138:8080/scm/api/primaryworkorder/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    yield put({ type: ESTIMATED_DELIVERY_DATA, data: result })

}


/* primary order update */

function* getprimaryorderdata(data){

   
    let id = data.data;

    let result = yield fetch(BASE_URL + 'primaryworkorder/getById/' + id,{headers:authHeader()});
    result = yield result.json()
    // console.log("mello",result)

    yield put({type:GET_PRIMARYORDER_UPDATE,data:result.primaryOrderItems})
}

function* addnewprimaryordereupdate(data) {
    const id = data.data;

    let result = yield fetch(BASE_URL + 'product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    //  console.warn("lelo", result);

    yield put({ type: GET_PRODUCTDATA_ADD_PRIMARYORDER_UPDATE, data: result, data2: data.data2 })
}

/* Delivery Challan Create */
function* putdeliverychallantoload(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'so/' + id, { headers: authHeader() });
    result = yield result.json()
    // console.log(result,"mello");
    yield put({ type: DELIVERYCHALLAN_PUT_DATA_TO_LOAD, data: result.salesOrderItems,data2:data.data2 })

}

function* addnewdeliverychallan(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    //  console.warn("lelo", result);

    yield put({ type: PUT_NEW_DELIVERYCHALLAN_ADD_TO_CART, data: result, data2: data.data2 })


}

/* so pending dc */

function* addnewdccartdata(data) {

    const id = data.data;

    let result = yield fetch(BASE_URL + 'so/soitems/' + id, { headers: authHeader() });
    result = yield result.json()

    yield put({ type: DC_PUT_NEW_ADD_TO_CART, data: result, data2: data.data2 })
}

/* Sales CREATE */

function* getdcdatafromsales(data) {
    const id = data.data;

    let result = yield fetch(BASE_URL + 'dc/' + id, { headers: authHeader() });
    result = yield result.json()

    yield put({ type: SALES_PUT_DATA_TO_LOAD, data: result.dcItems })
}

function* salesproductadd(data) {

    let id = data.data;

    let result = yield fetch(BASE_URL + 'product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    //    console.log("mello",result)
    yield put({ type: PUT_NEW_SALES_ADD_TO_CART, data: result, data2: data.data2 })
}

/* Sales Invoice Update */

function* getsaleseditdata(data) {
    const id = data.data;

    let result = yield fetch(BASE_URL + 'sales/' + id, { headers: authHeader() });
    result = yield result.json()

    yield put({ type: SALES_EDIT_PUT_DATA_TO_LOAD, data: result.salesItems })
}

function* saleseditproductadd(data) {

    let id = data.data;

    let result = yield fetch(BASE_URL + 'product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    //    console.log("mello",result)
    yield put({ type: PUT_NEW_SALES_EDIT_ADD_TO_CART, data: result, data2: data.data2 })
}

/* SALES RETURN CREATE */
function* salesreturnproductadd(data) {

    let id = data.data;

    let result = yield fetch(BASE_URL + 'product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    //    console.log("mello",result)
    yield put({ type: SALES_RETURN_PUT_ADD_TO_CART, data: result, data2: data.data2 })
}

/* SALES RETURN Update */
function* salesreturnupdateproductadd(data) {

    let id = data.data;

    let result = yield fetch(BASE_URL + 'product/getById/' + id, { headers: authHeader() });
    result = yield result.json()

    //    console.log("mello",result)
    yield put({ type: SALES_RETURN_UPDATE_PUT_ADD_TO_CART, data: result, data2: data.data2 })
}

function* salesreturnupdatedataload(data) {

    let id = data.data;

    let result = yield fetch(BASE_URL + 'sr/' + id, { headers: authHeader() });
    result = yield result.json()

    //    console.log("mello",result)
    yield put({ type: SALES_RETURN_UPDATE_GET_DATA_LOAD, data: result.salesReturnItems })
}
function* productSaga() {


    yield takeEvery(PURCHASE_GET_DATA_TO_LOAD, putpurchasetoload)

    yield takeEvery(PURCHASE_ADD_TO_CART, addpurchaserow)

    yield takeEvery(PURCHASE_EDIT_GET_DATA_TO_LOAD, purchasegeteditloaddata)

    yield takeEvery(PURCHASE_EDIT_ADD_TO_CART, purchaseeditaddtocart)

    yield takeEvery(PURCHASE_RETURN_ADD_TO_CART, purchasereturnaddtocart)

    yield takeEvery(PR_UPDATE_ADD_TO_CART, purchasereturnupdateaddtocart)

    yield takeEvery(PR_UPDATE_GET_DATA, purchasereturnupdateloaddata)

    yield takeEvery(PO_ADD_TO_CART, ponewaddrow)

    yield takeEvery(PO_GET_EDIT_DATA_TO_LOAD, putpoedittoload)

    yield takeEvery(PO_EDIT_ADD_TO_CART, addeditporow)

    yield takeEvery(SUPPLIER_GET_DATA_TO_LOAD, putsupplierdeliverytoload)

    yield takeEvery(SUPPLIER_ADD_TO_CART, addsupplierdeliveryrow)
    yield takeEvery(SUPPLIER_ADD_TO_CART_EDIT, addsupplierdeliveryrowEdit)

    yield takeEvery(SUPPLIER_EDIT_GET_DATA_TO_LOAD, putsupplierdeliveryedittoload)

    yield takeEvery(SUPPLIER_EDIT_ADD_TO_CART, addsupplierdeliveryeditrow)

    yield takeEvery(MATERIAL_GET_DATA_TO_LOAD, putmaterialtoload)

    yield takeEvery(MATERIAL_ADD_TO_CART, addmaterialrow)

    yield takeEvery(MATERIAL_EDIT_GET_DATA_TO_LOAD, putmaterialreceiptnoteedittoload)

    yield takeEvery(MATERIAL_EDIT_ADD_TO_CART, addmaterialreceiptnoteeditrow)

    yield takeEvery(ADD_TO_CART_WORKORDER, addworkorder);

    yield takeEvery(GET_EDIT_DATA_TO_LOAD_WORKORDER, putworkorderedittoload);

    yield takeEvery(EDIT_ADD_TO_CART_WORKORDER, addnewworkorderedit)

    yield takeEvery(GET_PRODUTDATA_PRIMARYORDER, addnewprimaryordere)

    yield takeEvery(GET_DATA_PRIMARYORDER_UPDATE,getprimaryorderdata)

    yield takeEvery(GET_PRODUCT_ADD_PRIMARYORDER_UPDATE,addnewprimaryordereupdate)

    yield takeEvery(ESTIMATED_DELIVERY, estimateddeliverydata)

    yield takeEvery(SALESORDER_GET_DATA_TO_LOAD, putsalesordertoload)

    yield takeEvery(WORKORDERBYSALESORDER_GET_DATA_TO_LOAD,putworkorderbysalesordertoload)

    yield takeEvery(SALESORDER_ADD_TO_CART, addsalesorderrow)

    yield takeEvery(SALESORDER_EDIT_GET_DATA_TO_LOAD, putsalesorderedittoload)

    yield takeEvery(SALESORDER_EDIT_ADD_TO_CART, addsalesordereditrow)

    yield takeEvery(DELIVERYCHALLAN_GET_DATA_TO_LOAD, putdeliverychallantoload)

    yield takeEvery(DELIVERYCHALLAN_ADD_TO_CART, addnewdeliverychallan)

    yield takeEvery(DC_ADD_TO_CART, addnewdccartdata)

    yield takeEvery(SALES_GET_DATA_TO_LOAD, getdcdatafromsales)

    yield takeEvery(SALES_ADD_TO_CART, salesproductadd)

    yield takeEvery(SALES_EDIT_GET_DATA_TO_LOAD,getsaleseditdata)

    yield takeEvery(SALES_EDIT_ADD_TO_CART,saleseditproductadd)

    yield takeEvery(SALES_RETURN_AAD_TO_CART,salesreturnproductadd)

    yield takeEvery(SALES_RETURN_UPDATE_AAD_TO_CART,salesreturnupdateproductadd)

    yield takeEvery(SALES_RETURN_UPDATE_DATA_LOAD,salesreturnupdatedataload)




}


export default productSaga;