import axios from 'axios'
import React, { useEffect, useState } from 'react'
// import Select from 'react-select/dist/declarations/src/Select'
import { BASE_URL } from '../../services/Api'
import authHeader from '../../services/auth-header'
import Select from "react-select";
import { Link, useNavigate, useParams } from 'react-router-dom';
import AuthService from '../../services/auth.service';
import Swal from 'sweetalert2';
import { Button } from 'react-bootstrap';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Meetingview = () => {
    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const [dis, setdistributordata1] = useState([])
const [data, setData] = useState([])
const[multiplestaff,setstaff]=useState([])
const navigate = useNavigate();
// const[multiplestaff,setstaff]=useState([])
const [meetingTitle, setMeetingTitle] = useState("");
const [meetingDate, setMeetingDate] = useState(null);
const [docName, setDocName] = useState("");
const [doc, setdoc] = useState("");
// const navigate = useNavigate();
    useEffect(() => {

        axios.get(`${BASE_URL}staff/page/0/200/asc/id/${disword}`, { headers: authHeader() })
            .then(response => {
                console.log(response)
              
                if (response.data.data == undefined) {
                   // setdistributordata1(response.data?.Index.map(use => ({ value: use.id, label: use.staff_Name })));
                    setData(response.data.Index)
                } if (response.data.Index == undefined) {
                    setData(response.data.data)

                    //setdistributordata1(response.data?.data.map(use => ({ value: use.id, label: use.staff_Name })));

                }

                // console.log(response.data.data.map(use => ({ value: use.id, label: use.product_Name })))
            })




    }, [ disword])

    const {id} = useParams();
    const [dist1, setDist] = useState([])
    const [ret1, setRet] = useState([])
    const [supplier, setSupplier] = useState([])
    useEffect(() => {
        axios.get(`${BASE_URL}meeting/${id}`, { headers: authHeader() })
                .then(response => {
    const extracted=[response.data].map((item)=>item.staff)[0].map((item)=>({
        id:item.id,
        staff_Name:item.staffName
    }))
    console.log([response.data].map((item)=>item.meetingtitle)[0],"mello")
    setMeetingTitle([response.data].map((item)=>item.meetingtitle)[0])
    setMeetingDate([response.data].map((item)=>item.meetingdate)[0])
    setdoc([response.data].map((item)=>item.doclocation)[0])
    setDocName([response.data].map((item)=>item.description)[0])
                    console.log([response.data].map((item)=>item.staff))
                    const html = [response.data].map((item) => item.description)[0];
                    const contentBlock = htmlToDraft(html);
                    if (contentBlock) {
                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                        setEditorState(EditorState.createWithContent(contentState));
                    }
                    setstaff(extracted)
setDist(response.data.distributor.map(use => ({ value: use.id, label: use.tradeName })))    
setRet(response.data.retailer.map(use => ({ value: use.id, label: use.tradeName }))) 
setSupplier(response.data.suppliers.map(use => ({ value: use.id, label: use.suppliername })))   
    console.log([response.data].map((item)=>item.doclocation)[0])
                    // console.log(response.data.data.map(use => ({ value: use.id, label: use.product_Name })))
                })
    }, [ ])


    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    const [showAllStaff, setShowAllStaff] = useState(false);

    // Assuming multiplestaff is your array of staff members
    const maxColumns = 3; // Maximum number of columns
    const visibleStaff = showAllStaff ? multiplestaff : multiplestaff.slice(0, maxColumns);

    const handleToggleShowAllStaff = () => {
        setShowAllStaff(prevShowAllStaff => !prevShowAllStaff);
    };
    const disid = (e) => {
     if(multiplestaff.filter((item)=>item.id==e).map((item)=>item.id)[0]!==e)
     {    const staffDetails = data.filter((item) => item.id === e);
        console.log(staffDetails);

         setstaff(prevStadddetails => [...prevStadddetails, ...staffDetails]);}else{
            alert("Already Exist");
        }
    }
console.log([multiplestaff.map((item)=>({id:item.id}))][0])


const handle=(e)=>{
    // alert('delete'+e)
    setstaff( multiplestaff.filter(item => item.id !== e))
}

const handleClick = () => {
    navigate("/Meettable/1"); 

};

const handleclikc=()=>{
    navigate(`/Meetingedit/${id}/1`); 
}

const handleClick1 = () => {
    navigate("/Meettable/1"); 

};

const getItem = (id) => {

    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {

            axios.delete(BASE_URL + 'meeting/' + id, {
                headers: authHeader()
            }).then(res => {
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
                navigate(`/Meettable/1}`)
            }).catch(error => {
                console.error('Error adding  :', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `
                    <div>
                      <p id="issueText">Why do I have this issue?</p>
                      <ul>
                  
                      <li id="typer1">You might have an internet issue</li>
                      </ul>
                    </div>
                  `,
                  didOpen: () => {
                    applyFontSizeTransition('issueText');
                  }
                });
              }
              })
              .finally(() => {
                setIsLoading(false); 
              });


        }
    })
};    const user = AuthService.getCurrentUser();
function handleDownload() {
    // Assuming doc is defined and contains the filename or identifier
    const doc = 'meeting-details.pdf'; // Example filename
    
    // Create a temporary anchor element
    const downloadLink = document.createElement('a');
    downloadLink.href = `http://43.225.54.138:8080/scm/${doc}`;
    downloadLink.download = doc;
  
    // Append the anchor element to the body
    document.body.appendChild(downloadLink);
  
    // Trigger the click event on the anchor element
    downloadLink.click();
  
    // Clean up: remove the anchor element from the DOM
    document.body.removeChild(downloadLink);
  }
  return (
    <div  >
        <div className=' card'>
            <div className='card-body'>
                <div className='card-header card-title'>
                    <h1>Minutes of Meetings</h1>
                 
                </div>
             

                {user.roles[0] === 'ROLE_ADMIN' &&
                   <>
                   <button className='btn btn-primary btn-sm' onClick={()=>{handleclikc()}}>Edit</button>
                   <button className='btn btn-primary btn-sm' onClick={()=>{handleClick1()}}>Back</button>
               
                        <Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{width:'auto'}} onClick={() => getItem(id)} disabled={isLoading}>
         <Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button>
      </Link>
                   </>
      }
                {/* <button className='btn btn-primary btn-sm' onClick={()=>{getItem(id)}}>Delete</button> */}
                {doc? <a  className='ended btn' style={{color:'white'}} href={`http://43.225.54.138:8080/scm/${doc}`}  download target="_blank">
          Download Meeting Details</a> :""}

          {/* <button className='btn btn-primary' onClick={handleDownload}>
  Download Meeting Details
</button> */}

            <div className='row'>
            <div className='col-8'>
                    <label className='form-label fgffg'>Meeting Title:    <h1>{meetingTitle}</h1></label>
            
                </div>
              
              <div className='col-4'>
          {/* <button className=' dfsdf btn btn-primary'>Download Meeting Details</button> */}

  

              </div>
                
                
             </div>
            
             <div className='row'>
              
             <div className='col '>
                <label className='form-label fgffg'>Date:<dd>{meetingDate}</dd></label>
                
                 </div>  

              { JSON.parse(localStorage.getItem('user')).roles[0]=='ROLE_ADMIN'?<>  
                 <div className='col'>
                    <label className='form-label'>  Distributor  </label>
                    <Select  isDisabled value={dist1} isMulti  />
                </div>
            
               
                
                <div className='col'>
                    <label className='form-label'>  Retailer  </label>
                    <Select   isDisabled value={ret1} isMulti  />

                 </div>
             


              
             <div className='col'>
                    <label className='form-label'>  Supplier  </label>
                    <Select   isDisabled value={supplier} isMulti  />

                 </div>
            </>:""    }
                
              
             </div><br></br>
           
             <div className='row'>
              
                <div className='col-3'>
                 </div>
             </div><br></br>

 
<Editor
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                readOnly={true}
                            />


{
JSON.parse(localStorage.getItem('user')).roles[0]=='ROLE_ADMIN'?
<div className='row'>
    {
        multiplestaff.map((item)=>{
            return<>
            <div className='col-3 ogcol3'>
      <div className='card newcardingforstaff'>
        <h1 className='profilestaff'>{item.staff_Name[0]}</h1>  
        <p className='profilename'>{item.staff_Name}</p>
        <p className='roless'>{item.rolename=='ROLE_ADMIN'?'Admin':''}{item.rolename=='ROLE_RSM'?'RSM':''}{item.rolename=='ROLE_NSM'?'NSM':''}{item.rolename=='ROLE_ASC'?'ASC':''}{item.rolename=='ROLE_ASM'?'ASM':''}</p>
       </div>
    </div>
            
            </>
        })
    }

    
    
</div>:""}


<br></br>
<br></br>
{/* <textarea className='form-control' placeholder='Desription'></textarea><br></br> */}
<div className='row'>
    <div className='col-3'>
        {/* <input type='file' className='form-control'/> */}
    </div>
</div>

{/* <button className='btn btn-primary btn-sm' onClick={handleClick}>
      Back
    </button>         */}
        </div>
        </div>

    </div>
  )
}

export default Meetingview