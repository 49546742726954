import React, { useCallback, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import getCroppedImg from '../Brand/getCroppedImg';
import axios from 'axios';
import Cropper from "react-easy-crop";
import { MDBCard } from 'mdb-react-ui-kit';
import authHeader from '../../services/auth-header';
import { useNavigate, useParams } from 'react-router-dom';
import pnghelper from './pnghelper.png';
import neelamlogo from './../logoneelam.png'
{/* <img src={neelamlogo} className='prrer' style={{ width: '67%', display: isOpenhh ? "none" : "block" }} /> */}
// 
const Profilestaffcom = ({userfirend,routerarea,steper,sendDataToParent,axiosdata,butvisble}) => {


    const [image, setImage] = useState(null);
    const [defaultimg, setDefault] = useState(pnghelper)
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [step, setStep] = useState(steper)
    
    const handleFileChange = (e) => {
        const file = e.target.files && e.target.files[0];
        if (!file) {
            console.error("No file selected");
            return;
        }
  
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result); // Convert file to data URL for preview
        };
  
        reader.onerror = (error) => {
            console.error("Error reading file:", error);
        };
  
        reader.readAsDataURL(file);
    };
    const { id } = useParams();
  
     const removeImage = () => {
        setImage(null);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        setCroppedAreaPixels(null);
    };
  
     const onCropComplete = useCallback((_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);
  const navigate=useNavigate();
     const saveCroppedImageLocally = async () => {
        if (!croppedAreaPixels || !image) {
            Swal.fire({
                icon: "warning",
                title: "No Image Selected",
                text: "Please select an image to crop and save.",
            });
            return;
        }
  
        try {
            const croppedImage = await getCroppedImg(image, croppedAreaPixels);
  
            // Create a download link for the cropped image
            const blob = new Blob([croppedImage], { type: "image/png" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "cropped-image.png";
            link.click();
            URL.revokeObjectURL(url); // Clean up the URL
        } catch (error) {
            console.error("Error saving cropped image locally:", error);
        }
    };
    const [loading, setLoading] = useState(false);

    const [profilepiclocation, setProfilepiclocation] = useState([])
    
    const fetchProfilePicture = async () => {
        try {
            setLoading(true)
            const response = await axios.get(
                `http://43.225.54.138:8080/scm/api/profilepic/${axiosdata}/${id}`,
                {
                    headers: {
                        ...authHeader(),
                    },
                }
            );
            setProfilepiclocation(response.data[0].profilepiclocation)
console.log(response.data[0].profilepiclocation)
         } catch (error) {
            console.error("Error fetching profile picture:", error);
            // alert("Failed to fetch the profile picture. Please try again.");
        }finally {
            setLoading(false); // Set loading to false after the API call is complete
            setStep('true');
        }
    };

useEffect(() => {
   if(step=='true') {
    fetchProfilePicture()
   }
}, [ ])



     const handleCropAndSave = async () => {
        if (!croppedAreaPixels || !image) {
            Swal.fire({
                icon: "warning",
                title: "No Image Selected",
                text: "Please select an image to crop and upload.",
            });
            return;
        }
  
        try {
            const croppedImage = await getCroppedImg(image, croppedAreaPixels);
  
            const formData = new FormData();
            formData.append("files", croppedImage);
            formData.append(userfirend, id); // Replace with dynamic staff ID if needed
  
            const response = await axios.post(
                "http://43.225.54.138:8080/scm/api/profilepic/upload",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        ...authHeader(),
                    },
                    // params: { userfirend: id },
                }
            );
  
            if (response.status === 200 || response.status === 201) {
                Swal.fire({
                    icon: "success",
                    title: "Cropped Image Successfully Uploaded",
                });
                // navigate("/Stafftable/1");
               
                fetchProfilePicture()
            }
        } catch (error) {
            console.error("Error uploading cropped file:", error);
  
            if (
                error.message.includes("Network Error") ||
                error.message.includes("Failed to fetch")
            ) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                });
            }
        }
    };

    const handleClick = () => {
        const data = "Hello from Child!";
        // Send data to parent using the callback
        sendDataToParent(false);
    };
  return (
    <> 
    
       <div className="everycenter">
        
    <MDBCard data-aos="fade" className="newcardsize" style={{padding:'20px',background:'white',borderRadius:'12px'}}>
{butvisble=='true'?<h1 style={{cursor:'pointer'}}  onClick={handleClick}>Back</h1>
:''}  {step=='false'?  <div style={{ paddingBlock: "4%" ,  }}>
                 <h2 style={{fontSize:'17px',fontWeight:500,color:'#1B43BB'}}>Add Profile Picture </h2>
                <input type="file" className='form-control' onChange={handleFileChange} accept="image/*" />
                {/* {image && ( */}
                
                    <> 
                        <div style={{ position: "relative", height: "200px", width: "100%", marginTop: "20px" }}>
                            <Cropper
                                image={image||defaultimg}
                                crop={crop}
                                zoom={zoom}
                                aspect={1}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={onCropComplete}
                            />
                        </div>
                        <button className='btn' onClick={removeImage} style={{ margin: "10px" }}>
                       x
                        </button>
                    </>
                {/* )} */}


                <div style={{ display: "flex", justifyContent: "center" }}>
                 
                    <button className='btn' onClick={handleCropAndSave} style={{ margin: "10px" }} disabled={!image}>
                        Upload Cropped
                    </button>
                </div>
            </div>:
            
            <div className='newcenterforprofile' style={{ paddingBlock: "15%" }}>
            <h2 style={{fontSize:'17px'}}>New Profile Picture Added </h2>
            
<img
  src={`http://43.225.54.138:8080/scm/${profilepiclocation}?t=${new Date().getTime()}`}
  alt="Profile"
  style={{
    width: "200px",
    height: "200px",
    borderRadius: "50%", // Optional: Make it circular
    border: "2px solid #ccc", // Optional: Add a border
  }}
/>
            
            <div>
             
                <button className='btn' onClick={()=>{setStep('false');removeImage();setProfilepiclocation(null)}} style={{ margin: "10px" }} >
                    Re-upload Cropped
                </button>
                <button className='btn' onClick={()=>{navigate('/')}} style={{ margin: "10px" }}>
                  Save and Continue
                </button>
            </div>
        </div>
            
            }
    </MDBCard>
    </div>

    </>

  )
}

export default Profilestaffcom