import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import AuthService from "../../services/auth.service";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import { useNavigate } from "react-router-dom";

const Createdisopening = () => {
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [disword, setDisword] = useState("");
  const [product2, setProduct2] = useState([]);
  const [distributor1, setDistributor1] = useState([]);
  const [userDate, setUserDate] = useState("");
  const [distributor, setDistributor] = useState("");
  const [distRoles, setDistRoles] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [datap, setDatap] = useState([]);
  const [disableKg, setdisableKg] = useState(false);
  const [disablePcs, setdisablePcs] = useState(false);
  const currentUser = AuthService.getCurrentUser();

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const onchangeproductstore = (option) => {
    const newProduct = datap.find((item) => item.id === option.value);

    if (newProduct) {
      const isProductInList = items.some((item) => item.id === newProduct.id);

      if (!isProductInList) {
        setdisableKg(false);
        setdisablePcs(false);
        setItems((prevItems) => [
          ...prevItems,
          { ...newProduct, qty: 0, total: 0, kgqty: 0 , disableKg: false,
            disablePcs: false,},
        ]);
      }
    }
  };

  const handleQuantityChange = (id, qty) => {
    let row = items;

    setdisableKg(true);
    if (qty === "" || qty == 0) {
      setdisableKg(false);
    }
    setdisablePcs(false);

    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? {
              ...item,
              qty: qty,
              total: item.dlp * qty,
              disableKg: qty > 0, 
              disablePcs: false,
              kgqty: (qty * item.uom_secondary).toFixed(2),
            }
          : item
      )
    );
  };

  const handleQuantityKgChange = (id, kgqty) => {
    setdisablePcs(true);
    
    if (kgqty === "" || kgqty == 0) {
      setdisablePcs(false);
    }
    setdisableKg(false);
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? {
              ...item,
              kgqty: kgqty,
              total: kgqty * item.dlp,
              disablePcs: kgqty > 0,
              disableKg: false, 
              qty: (kgqty / item.uom_secondary).toFixed(2),
            }
          : item
      )
    );
  };

  const handleSubmit = () => {
    const postData = {
      user_date: userDate,
      distributor: { id: distributor },
      distributorOpeningStockItems: items.map((item) => ({
        product: { id: item.id },
        amount: item.total.toFixed(2),
        qty: item.qty,
        rate: item.dlp,
        kgqty: item.kgqty,
      })),
    };
    console.log(postData);
    setIsLoading(true);

    axios
      .post(
        `${BASE_URL}distributor/openingstock/post
/`,
        postData,
        { headers: authHeader() }
      )
      .then((response) => {
        navigate("/OpenCreate/1");

        Toast.fire({
          icon: "success",
          title: "Successfully added",
        });
      })
      .catch((error) => {
        console.error("Error adding  :", error);
        if (
          error.message.includes("Network Error") ||
          error.message.includes("Failed to fetch")
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `
                            <div>
                              <p id="issueText">Why do I have this issue?</p>
                              <ul>
                                  <li id="typer1">You might have an internet issue</li>
                              </ul>
                            </div>
                        `,
            didOpen: () => {
              applyFontSizeTransition("issueText");
            },
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = "font-size 2s";
    element.style.fontSize = "30px";
    element.style.fontWeight = "600";
    setTimeout(() => {
      document.getElementById("typer1").classList.add("promote");
      element.style.fontSize = "24px";
    }, 1000);
  };

  const getDistributorByRoles = () => {
    axios
      .get(`${BASE_URL}distributor/getById/${currentUser.id}`, {
        headers: authHeader(),
      })
      .then((res) => {
        setDistRoles(res.data.tradeName);
        setDistributor(res.data.id);
      });
  };

  useEffect(() => {
    if (currentUser.roles[0] === "ROLE_DISTRIBUTOR") {
      getDistributorByRoles();
    }

    axios
      .get(
        `${BASE_URL}product/page/0/10/asc/id/${
          error.length ? error : encodeURIComponent(" ")
        }`,
        { headers: authHeader() }
      )
      .then((response) => {
        setDatap(response.data.data || response.data.Index);
        const products = (response.data.data || response.data.Index).map(
          (use) => ({
            value: use.id,
            label: `${use.product_Name} - ${use.ean_Code}`,
          })
        );
        setProduct2(products);
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(
        `${BASE_URL}distributor/page/0/10/asc/id/${
          disword.length ? disword : encodeURIComponent(" ")
        }`,
        { headers: authHeader() }
      )
      .then((response) => {
        if (response.data.data == undefined) {
          setDistributor1(
            response.data.Index.map((use) => ({
              value: use.id,
              label: use.trade_Name,
            }))
          );
        }
        if (response.data.Index == undefined) {
          setDistributor1(
            response.data.data.map((use) => ({
              value: use.id,
              label: use.trade_Name,
            }))
          );
        }
        // setDistributor1(response.data.data || response.data.Index.map(use => ({ value: use.id, label: use.trade_Name })));
      });
  }, [error, disword]);

  return (
    <div className="card card-body">
      <div className="card-header card-title">
        <h2 style={{ fontSize: "16px", fontWeight: 700 }}>
          Distributor Opening Stock
        </h2>
      </div>
      <div className="row">
        <div className="col">
          <label className="form-label">Distributor</label>
          {currentUser.roles[0] === "ROLE_DISTRIBUTOR" ? (
            <input
              type="text"
              className="form-control"
              value={distRoles}
              readOnly
            />
          ) : (
            <Select
              options={distributor1}
              onInputChange={(inputValue) => setDisword(inputValue)}
              onChange={(options) => setDistributor(options.value)}
            />
          )}
        </div>
        <div className="col">
          <label className="form-label">Date</label>
          <input
            type="date"
            className="form-control"
            onChange={(e) => setUserDate(e.target.value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <label className="form-label">Product</label>
          <Select
            options={product2}
            onInputChange={(inputValue) => setError(inputValue)}
            onChange={(options) => onchangeproductstore(options)}
          />
        </div>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Qty(pcs)</th>
            <th>Qty(kg)</th>
            <th>DLP</th>
            <th>Total</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.id}>
              <td width="200px">{item.product_Name}</td>
              <td width="100px">
                <input
                  type="number"
                  className="form-control"
                  value={item.qty}
                  disabled={item.disablePcs} 
                  onChange={(e) =>
                   {
                    let value=e.target.value;
                    const regex = /^[0-9]*\.?[0-9]{0,2}$/;
                    if (regex.test(value)) {
                      handleQuantityChange(item.id, parseInt(e.target.value) || 0)
                    }

                    
                   
                   }
                  }
                />
              </td>
              <td width="100px">
                <input
                  type="number"
                  className="form-control"
                  value={item.kgqty}
                  disabled={item.disableKg}
                  onChange={(e) =>
                 {
                let value=e.target.value;
                const regex = /^[0-9]*\.?[0-9]{0,2}$/;
                if (regex.test(value)) {
                  handleQuantityKgChange(
                    item.id,
                    parseFloat(e.target.value) || 0
                  )
                }
              
                 }
    
                  }

                />
              </td>
              <td width="100px">{item.dlp}</td>
              <td width="100px">
                <input
                  type="text"
                  className="form-control"
                  value={item.total.toFixed(2)}
                  readOnly
                />
              </td>
              <td width="100px">
                <Button
                  variant="danger"
                  onClick={() =>
                    setItems(items.filter((i) => i.id !== item.id))
                  }
                >
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="row">
        <div className="col">
          <Button
            variant="primary"
            style={{ width: "auto" }}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Submit"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Createdisopening;
