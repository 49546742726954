import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import BrandService from "../../services/Brand";
import { Link, useNavigate } from "react-router-dom";
import { MDBCard } from "mdbreact";
import Swal from 'sweetalert2'
import axios from "axios";
import authHeader from "../../services/auth-header";
export const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})
export default function Create(){
const[brandName,setbrandName]=useState('');
const [error,setError]=useState(false );
const [isLoading, setIsLoading] = useState(false);
const applyFontSizeTransition = (elementId) => {
  const element = document.getElementById(elementId);
  element.style.transition = 'font-size 2s';
  element.style.fontSize = '30px';
  element.style.fontWeight = '600';
  // font-weight: 600;
  setTimeout(() => {
    //  
    document.getElementById("typer1").classList.add('promote');
    element.style.fontSize = '24px';
  }, 1000);
};

const navigate = useNavigate();

 

 

const submitform = () =>{
  if(brandName.length===0){
   
    setError(true)
  
  }else{
    setError(false)
    setIsLoading(true); 

    let brand = {brandName};
    BrandService.getbrandCreate(brand)
    .then(res => {
      Toast.fire({
        icon: 'success',
        title: 'Successfully added'
      });
      console.log(res.data)
      navigate('/brand/1');  
    })
      .catch(error => {
      console.error('Error adding  :', error);
      if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `
          <div>
            <p id="issueText">Why do I have this issue?</p>
            <ul>
        
            <li id="typer1">You might have an internet issue</li>
            </ul>
          </div>
        `,
        didOpen: () => {
          applyFontSizeTransition('issueText');
        }
      });
    }
    })
    .finally(() => {
      setIsLoading(false); 
    });
  }
  
  }
  
  const numbers = [{id:12,name:'admin'},{id:19,name:'sales'}];
  const odds = numbers.filter((num) => num.name==='admin');
  console.log(odds.map((item)=>item.name)); // [19, 5 , 9, 13]


    return(
        <MDBCard data-aos="fade" className="   ">
             <h1>ADD BRAND</h1>
             <Link to={`/brand/0`}><button className="btn btn-primary">Back</button></Link>
      <Form className='product'>
      <Row className="mb-3">
      <Form.Group as={Col} md="12" controlId="formBasicName">
        <Form.Label className="cname2">Brand Name</Form.Label>
        <Form.Control type="text" autoComplete="off" style={{borderColor:error&&brandName.length==0  ? "red" : ""}}  name="brand" onChange={(e)=>setbrandName(e.target.value)} placeholder="Enter Brand Name" />
        {  error&&brandName.length===0 ? <p style={{color:error&&brandName.length===0 ? "red" : " "}}>This field is Required</p> :""}

      </Form.Group>
      </Row>




      <Button variant="primary" style={{width:'auto'}} onClick={submitform} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
      </Form>
        </MDBCard>
    )
}